import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import {
	setItemColorInApproverTable, setItemVisibilityInApproverTable, setPhaseAnswer, approveSubtaskFromEdit,
	approveSubtaskFromSendBack, approveSubtaskWithoutChanges
} from "../../../../redux/approve/approverActions"
import {
	getApprovalSubtaskBoundingBoxFromStore, getApproverAnswerForGrading, getApproverAnswerForShapes,
	getApproverAnswerForTags, getSupervisorCommentFromState, getTableItemsSelections, getExistingShapesAddedBySubtaskSupervisor,
	getExistingShapesNotAddedBySubtaskSupervisor
} from "../../../../redux/approve/selectors"
import "./stageTwo.css"
import { APPROVAL_DATA_TYPES } from "../../../../redux/saga/approverSaga"
import { ApproveStageMapContainer } from "../approveStageMapContainer/approveStageMapContainer"
import ApproveCommentBox from "./../approveCommentBox/approveCommentBox"
import { QuestionToApprove } from "../questionToApprove/questionToApprove"
import "./../stages.css"
import { GenericTable } from "../../../../common/components"
import { SHAPE_COLORS_CONSTS } from "../../../genericTableComponents/shapeColor/shapeColors"

import { Button, Glyphicon } from "react-bootstrap"
import { GtShapeVisibility } from "../../../genericTableComponents/shapeVisibility/shapeVisibility"
import { GtShapeColor } from "../../../genericTableComponents/shapeColor/shapeColor"
import { StageTwoMainTableActions } from "./mainTableActions/mainTableActions"
import { GtSupervisorInfo } from "../../../genericTableComponents/info/supervisor"
import { getNearbyGeocode } from "../../../../redux/saga/utils"
import { SupervisorAnswersTable } from "../../approvePhases/supervisorsAnswerTable/supervisorsAnswerTable"
import AddedShapeDisplay from "../../../paintPage/component/AddedShapeDisplay"
import { ShapeUtils } from "../../../../common/sharedGlobal"
var keygen = require("keygenerator");


export class StageTwo extends React.Component {
	state = {
		isEditMode: false,
		isSendBackMode: false,
		mapCenterToFocus: null,
		initialStateShapes: null,
		itemIdCurrentlyEditingBB: null,
		clickedItemId: null,
		areasToDelete: {},
		shapesIds: [],
		onDoneBtnHoverShowMessage: false,
	}


	componentDidMount = () => {
		this.generateInitialState()
		this.generateMapCenter()
	}


	componentDidUpdate(prevProps) {
		if (!prevProps.tableItemsSelections && this.props.tableItemsSelections) {
			this.generateMapCenter()
		}
	}


	generateMapCenter = () => {
		const { approvalSubtaskBoundingBox, tableItemsSelections } = this.props
		// Set initial center
		const tableItems = _.filter(tableItemsSelections, item => item.type === APPROVAL_DATA_TYPES.EXISTING_AREA_ADDED_BY_SUBTASK_SUPERVISOR
			|| item.type === APPROVAL_DATA_TYPES.EXISTING_AREA_NOT_ADDED_BY_SUBTASK_SUPERVISOR)

		const allShapesBB = ShapeUtils.convertArrayOfDiagonalRectanglesAndRectanglesToArrayOfNeSwBoundingBoxes([
			approvalSubtaskBoundingBox,
			..._.map(tableItems).reduce((arr, item) => {
				return [...arr, ...item.shapes]
			}, [])])

		const centerOfAllShapes = ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(ShapeUtils.RECTANGLE.biggestBoundingBox(allShapesBB))
		this.setState({ mapCenterToFocus: centerOfAllShapes })
	}


	static getDerivedStateFromProps(props, state) {
		if (state.isEditMode && state.initialStateShapes) {
			const shapesIds = _(state.initialStateShapes).filter(item => (!!state.areasToDelete[item.id] || item.type === APPROVAL_DATA_TYPES.ADDED_BY_APPROVER)
			).map(item => item.id).value()
			return { ...state, shapesIds }
		}
		if (state.isSendBackMode && state.initialStateShapes) {
			const shapesIds = _(state.initialStateShapes).filter(item => (item.type === APPROVAL_DATA_TYPES.ADDED_BY_APPROVER)
			).map(item => item.id).value()
			return { ...state, shapesIds }
		}
		return { ...state }

	}


	generateInitialState = () => {
		const { tableItemsSelections, existingShapesAddedBySubtaskSupervisor, existingShapesNotAddedBySubtaskSupervisor } = this.props

		const shapesAddedBySupervisor = _.filter(tableItemsSelections, item => item.type === APPROVAL_DATA_TYPES.EXISTING_AREA_ADDED_BY_SUBTASK_SUPERVISOR)
		const shapesNotAddedBySupervisor = _.filter(tableItemsSelections, item => item.type === APPROVAL_DATA_TYPES.EXISTING_AREA_NOT_ADDED_BY_SUBTASK_SUPERVISOR)
		let shapes = [...shapesAddedBySupervisor, ...shapesNotAddedBySupervisor]
		const dataKeyedById = _.keyBy([...existingShapesAddedBySubtaskSupervisor, ...existingShapesNotAddedBySubtaskSupervisor], "id")

		// extend shapes data
		for (let index in shapes) {
			shapes[index] = {
				...shapes[index],
				...dataKeyedById[shapes[index].id],
				isEditable: false,
				isDeleted: false,
				visible: true
			}
		}

		const shapesIds = _.map(shapes).map(item => item.id)

		this.setState({
			initialStateShapes: shapes,
			shapesIds
		})
	}


	generateinitialStateForSingleItem = (id) => {
		const { initialStateShapes } = this.state
		const { tableItemsSelections, existingShapesAddedBySubtaskSupervisor, existingShapesNotAddedBySubtaskSupervisor } = this.props

		const shapesAddedBySupervisor = _.filter(tableItemsSelections, item => item.type === APPROVAL_DATA_TYPES.EXISTING_AREA_ADDED_BY_SUBTASK_SUPERVISOR)
		const shapesNotAddedBySupervisor = _.filter(tableItemsSelections, item => item.type === APPROVAL_DATA_TYPES.EXISTING_AREA_NOT_ADDED_BY_SUBTASK_SUPERVISOR)
		let shapes = [...shapesAddedBySupervisor, ...shapesNotAddedBySupervisor]
		shapes = _.keyBy(shapes, "id")

		const dataKeyedById = _.keyBy([...existingShapesAddedBySubtaskSupervisor, ...existingShapesNotAddedBySubtaskSupervisor], "id")

		let initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, item => item.id === id)
		if (indexOfItem > -1) {
			initialStateShapesCopy[indexOfItem] = {
				...shapes[id],
				...dataKeyedById[id],
				isEditable: false,
				isDeleted: false,
				visible: true
			}
			this.setState({
				initialStateShapes: initialStateShapesCopy,
			})
		}
	}




	onDiagonalRectangleMarkerRightClick = (pointOrderToDelete) => {
		const { initialStateShapes } = this.state

		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.isEditable)

		initialStateShapesCopy[indexOfItem].shapes[0].points[pointOrderToDelete] = null
		initialStateShapesCopy[indexOfItem].shapes[0].points = _.filter(initialStateShapesCopy[indexOfItem].shapes[0].points)

		this.setState({
			initialStateShapes: initialStateShapesCopy
		})
	}




	addDiagonalRectanglePointToExistingShape = (point) => {
		const { initialStateShapes } = this.state

		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.isEditable)
		if (indexOfItem > -1) {
			if (initialStateShapesCopy[indexOfItem].shapes[0].points.length < 3) {
				initialStateShapesCopy[indexOfItem].shapes[0].points = [...initialStateShapesCopy[indexOfItem].shapes[0].points, point]

				// get updated description
				if (initialStateShapesCopy[indexOfItem].shapes[0].points.length === 3) {
					getNearbyGeocode(ShapeUtils.getCenter(initialStateShapesCopy[indexOfItem].shapes[0])).then((data) => {
						const description = data && data.length > 0 && data[0].formatted_address
						initialStateShapesCopy[indexOfItem].description = description
						this.setState({
							initialStateShapes: initialStateShapesCopy
						})
					})
				} else {
					this.setState({ initialStateShapes: initialStateShapesCopy })
				}
			}
		}
	}



	onEditDiagonalRectanglePoint = (pointOrder, newPoint) => {
		const { initialStateShapes } = this.state
		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.isEditable)
		const item = _.cloneDeep(initialStateShapesCopy[indexOfItem])


		if (indexOfItem > -1) {
			item.shapes[0].points[pointOrder] = { ...newPoint }


			// get updated description
			if (item.shapes[0].points.length === 3) {
				getNearbyGeocode(ShapeUtils.getCenter(initialStateShapesCopy[indexOfItem].shapes[0])).then((data) => {
					const description = data && data.length > 0 && data[0].formatted_address
					item.description = description
					initialStateShapesCopy[indexOfItem] = { ...item }
					this.setState({
						initialStateShapes: initialStateShapesCopy
					})
				})
			} else {
				initialStateShapesCopy[indexOfItem] = { ...item }
				this.setState({ initialStateShapes: initialStateShapesCopy })
			}
		}
	}




	onShapeDraw = (mapShape) => {
		const { initialStateShapes } = this.state
		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.isEditable)

		if (indexOfItem > -1) {
			initialStateShapesCopy[indexOfItem] = { ...initialStateShapes[indexOfItem] }
			initialStateShapesCopy[indexOfItem].shapes = [...initialStateShapes[indexOfItem].shapes]
			initialStateShapesCopy[indexOfItem].shapes[0] = { ...mapShape }
			initialStateShapesCopy[indexOfItem].description = " "
			this.setState({ initialStateShapes: initialStateShapesCopy })

		} else {
			// This is a new shape
			getNearbyGeocode(ShapeUtils.getCenter(mapShape)).then(data => {
				const description = data && data.length > 0 && data[0].formatted_address
				this.addANewShape(mapShape, description)
			})
		}


	}



	addANewShape = (shape, description) => {
		const { initialStateShapes, isEditMode } = this.state
		const newId = keygen._()
		const newShapeObject = {
			id: newId,
			type: APPROVAL_DATA_TYPES.ADDED_BY_APPROVER,
			topLevel: null,
			bottomLevel: null,
			isComplex: false,
			description,
			shapes: [{ ...shape }],
			visible: true,
			isDeleted: false,
			isEditable: false,
			colorInfo: {
				fillColor: "transparent",
				strokeColor: "#0f00ff"
			}
		}
		this.setState({
			clickedItemId: newId,
			initialStateShapes: isEditMode ? [newShapeObject, ...initialStateShapes] : [...initialStateShapes, newShapeObject]
		})
	}


	onItemFocus = (boundingBox, itemId = null) => this.setState({ mapCenterToFocus: null }, () => { this.setState({ mapCenterToFocus: boundingBox, clickedItemId: itemId }) })


	onItemEdit = (id) => {
		const { initialStateShapes, isSendBackMode } = this.state
		const actualId = isSendBackMode ? id.id : id
		const initialStateShapesCpy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCpy, shape => shape.id === actualId)
		if (indexOfItem > -1) {
			initialStateShapesCpy[indexOfItem].isEditable = true
		}

		this.setState({
			initialStateShapes: initialStateShapesCpy,
			itemIdCurrentlyEditingBB: actualId,
			clickedItemId: actualId
		})
	}



	onitemFinishBBEdit = (id) => {
		const { initialStateShapes, isSendBackMode, itemIdCurrentlyEditingBB } = this.state
		const actualId = isSendBackMode ? itemIdCurrentlyEditingBB : id
		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.id === actualId)

		if (indexOfItem > -1) {
			initialStateShapesCopy[indexOfItem].isEditable = false

			const mapShape = initialStateShapesCopy[indexOfItem].shapes[0]

			getNearbyGeocode(ShapeUtils.getCenter(mapShape)).then(data => {
				const description = data && data.length > 0 && data[0].formatted_address
				initialStateShapesCopy[indexOfItem].description = description
				this.setState({ initialStateShapes: initialStateShapesCopy, itemIdCurrentlyEditingBB: null, clickedItemId: null })
			})
		}

	}



	onAnwserMultilevelChange = (itemId, type, value) => {
		const { initialStateShapes } = this.state
		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.id === itemId)

		if (indexOfItem > -1) {
			if (type === "isComplex") {
				initialStateShapesCopy[indexOfItem]["topLevel"] = null
				initialStateShapesCopy[indexOfItem]["bottomLevel"] = null
			}
			else {
				initialStateShapesCopy[indexOfItem]["isComplex"] = false
			}
			initialStateShapesCopy[indexOfItem] = { ...initialStateShapes[indexOfItem] }
			initialStateShapesCopy[indexOfItem][type] = value
		}
		this.setState({ initialStateShapes: initialStateShapesCopy })
	}



	onDescriptionRefreshClicked = (id) => {
		const { initialStateShapes } = this.state
		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.id === id)

		getNearbyGeocode(ShapeUtils.getCenter(initialStateShapesCopy[indexOfItem].shapes[0])).then((data) => {
			const description = data && data.length > 0 && data[0].formatted_address
			if (indexOfItem > -1) {
				initialStateShapesCopy[indexOfItem] = { ...initialStateShapes[indexOfItem] }
				initialStateShapesCopy[indexOfItem].description = description
				this.setState({ initialStateShapes: initialStateShapesCopy })
			}
		})
	}




	onDeleteFromMainTables = (item) => {
		const { initialStateShapes } = this.state

		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.id === item.id)
		if (indexOfItem > -1) {
			initialStateShapesCopy[indexOfItem].isDeleted = true

			this.setState({
				initialStateShapes: initialStateShapesCopy,
			})

		}

		this.setState(pstate => ({
			areasToDelete: {
				...pstate.areasToDelete,
				[item.id]: item.id,
			}
		}))
	}


	generateExistingAreasFromTaskTable = () => {
		const { existingShapesAddedBySubtaskSupervisor } = this.props
		const { initialStateShapes, clickedItemId, isSendBackMode, isEditMode } = this.state
		if (existingShapesAddedBySubtaskSupervisor.length === 0 || !initialStateShapes) { return null }

		return <div>
			<div className="tableTitle">Existing areas from this task</div>

			<GenericTable
				highlightedItemId={clickedItemId}
				highlightedItemColor={isSendBackMode || isEditMode ? null : SHAPE_COLORS_CONSTS.black}
				data={existingShapesAddedBySubtaskSupervisor}
				mapColumnToRenderer={[
					{
						column: {
							title: "",
							icon: <Glyphicon glyph="eye-open" />
						},
						cellRenderer: (item) => {
							const keyById = _.keyBy(initialStateShapes, "id")
							const isVisible = keyById[item.id].visible

							return <GtShapeVisibility
								item={item}
								isVisible={isVisible}
								changeVisibility={this.toggleItemVisibility} />

						}
					},
					{
						column: {
							title: "Description",
							icon: ""
						},
						cellRenderer: (item) => {
							const { areasToDelete } = this.state
							return <div style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}><span style={{ textDecoration: areasToDelete[item.id] ? "line-through" : "none" }}>{item.description}</span></div>
						}
					},
					{
						column: {
							title: "Type",
							icon: ""
						},
						cellRenderer: (item) => {
							const { areasToDelete } = this.state
							return <GtSupervisorInfo
								isTextWithLineThrough={!!areasToDelete[item.id]}
								data={item} />
						}
					},
					{
						column: {
							title: "Actions",
							icon: ""
						},
						cellRenderer: (item) => <StageTwoMainTableActions
							onMainTablesUndoDeleteClicked={this.onMainTablesUndoDeleteClicked}
							isItemDeleted={!!this.state.areasToDelete[item.id]}
							onItemDelete={this.onDeleteFromMainTables}
							onFocusClicked={this.onItemFocus} item={item} />

					},
					{
						column: {
							title: "Color",
							icon: ""
						},
						cellRenderer: (item) => {
							const { tableItemsSelections, setItemColorInApproverTableAction } = this.props

							const onColorSelect = (fill, stroke) => {
								const itemId = item.id
								setItemColorInApproverTableAction(itemId, fill, stroke)
							}

							return <GtShapeColor
								fillStrokeCombo={tableItemsSelections[item.id].colorInfo}
								onColorSelect={(fill, stroke) => onColorSelect(fill, stroke)}
								isDisabled={true} />
						}
					}
				]}
			/>
		</div>
	}


	generateExistinAreasFromOtherTasksTable = () => {
		const { existingShapesNotAddedBySubtaskSupervisor } = this.props
		const { initialStateShapes, clickedItemId, isSendBackMode, isEditMode } = this.state
		if (existingShapesNotAddedBySubtaskSupervisor.length === 0 || !initialStateShapes) { return null }

		return <div>
			<div className="tableTitle">Existing areas from others tasks</div>

			<GenericTable
				highlightedItemId={clickedItemId}
				highlightedItemColor={isSendBackMode || isEditMode ? null : SHAPE_COLORS_CONSTS.green}
				data={existingShapesNotAddedBySubtaskSupervisor}
				mapColumnToRenderer={[
					{
						column: {
							title: "",
							icon: <Glyphicon glyph="eye-open" />
						},
						cellRenderer: (item) => {
							const keyById = _.keyBy(initialStateShapes, "id")
							const isVisible = keyById[item.id].visible
							return <GtShapeVisibility
								item={item}
								isVisible={isVisible}
								changeVisibility={this.toggleItemVisibility} />
						}
					},
					{
						column: {
							title: "Description",
							icon: ""
						},
						cellRenderer: (item) => {
							const { areasToDelete, isSendBackMode } = this.state
							return <div style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}><span style={{ textDecoration: areasToDelete[item.id] ? "line-through" : "none" }}>{item.description}</span></div>
						}
					},
					{
						column: {
							title: "Info",
							icon: ""
						},
						cellRenderer: (item) => {
							const { areasToDelete } = this.state
							return <GtSupervisorInfo
								isTextWithLineThrough={!!areasToDelete[item.id]}
								data={item} />
						}

					},
					{
						column: {
							title: "Actions",
							icon: ""
						},
						cellRenderer: (item) => <StageTwoMainTableActions
							onMainTablesUndoDeleteClicked={this.onMainTablesUndoDeleteClicked}
							isItemDeleted={!!this.state.areasToDelete[item.id]}
							onItemDelete={this.onDeleteFromMainTables}
							onFocusClicked={this.onItemFocus}
							item={item} />

					},
					{
						column: {
							title: "Color",
							icon: ""
						},
						cellRenderer: (item) => {
							const { tableItemsSelections, setItemColorInApproverTableAction } = this.props

							const onColorSelect = (fill, stroke) => {
								const itemId = item.id
								setItemColorInApproverTableAction(itemId, fill, stroke)
							}

							return <GtShapeColor
								fillStrokeCombo={tableItemsSelections[item.id].colorInfo}
								onColorSelect={(fill, stroke) => onColorSelect(fill, stroke)}
								isDisabled={true} />
						}
					}
				]}
			/>
		</div>
	}




	onApproverQuestionAnswer = (answer) => {
		const { setPhaseAnswerAction } = this.props
		setPhaseAnswerAction(answer)
	}


	enterEditMode = () => this.setState({ isEditMode: true, clickedItemId: null })
	exitEditMode = () => {
		this.setState({
			isEditMode: false,
			itemIdCurrentlyEditingBB: null,
			clickedItemId: null
		})
		this.generateInitialState()
	}

	enterSendBackMode = () => this.setState({ isSendBackMode: true })
	exitSendBackMode = () => {
		this.setState({
			isSendBackMode: false,
			itemIdCurrentlyEditingBB: null,
			clickedItemId: null
		})
		this.generateInitialState()
	}


	clearAllSendBack = () => {
		this.setState({
			itemIdCurrentlyEditingBB: null,
			clickedItemId: null
		})
		this.generateInitialState()
	}


	onShapeClicked = (item) => {
		const clickedItemId = this.state.clickedItemId === item.id ? "" : item.id
		this.setState({ clickedItemId })
	}


	generateAppliedShapes = () => {
		const { areasToDelete, initialStateShapes } = this.state

		return _(initialStateShapes)
			.filter(item => (!!areasToDelete[item.id] || item.type === APPROVAL_DATA_TYPES.ADDED_BY_APPROVER) && !item.isDeleted)
			.map(item => ({
				shape: item.shapes[0],
				description: item.description,
				multilevelTypes: {
					topLevel: item.topLevel,
					bottomLevel: item.bottomLevel,
					isComplex: item.isComplex
				}
			})).value()
	}




	onDone = () => {
		const { isEditMode, isSendBackMode, initialStateShapes } = this.state
		const { approveSubtaskFromEdit, approveSubtaskFromSnedBack, approveSubtaskWithoutChanges } = this.props
		if (isEditMode) {
			const appliedShapes = this.generateAppliedShapes()
			const areasToDelete = _.map(this.state.areasToDelete)

			approveSubtaskFromEdit({
				appliedShapes,
				areasToDelete
			})
		}

		if (isSendBackMode) {
			const approverSendBackSuggestions = _(initialStateShapes).filter(item => item.type === APPROVAL_DATA_TYPES.ADDED_BY_APPROVER).map(item => ({ ...item.shapes[0] })).value()
			const areasToDelete = _.map(this.state.areasToDelete)

			approveSubtaskFromSnedBack({
				approverSendBackSuggestions,
				areasToDelete
			})
		}
		if (!isEditMode && !isSendBackMode) {
			approveSubtaskWithoutChanges()
		}
	}


	onItemDeleteFromEditModeTable = (id) => {
		const { initialStateShapes, shapesIds } = this.state
		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.id === id)
		if (indexOfItem > -1) {
			initialStateShapesCopy[indexOfItem].isDeleted = true
			initialStateShapesCopy[indexOfItem].isEditable = false


			const index = _.findIndex(shapesIds, shapeid => id === shapeid)
			const nextClickedItemId = index + 1 > shapesIds.length - 1 ? shapesIds.length - 1 : index + 1

			this.setState({
				clickedItemId: index === nextClickedItemId ? null : shapesIds[nextClickedItemId],
				initialStateShapes: initialStateShapesCopy,
			})


			// find shape id BB and focus
			const shape = _(initialStateShapes).filter(item => item.id === shapesIds[nextClickedItemId]).value()[0]
			const shapeBB = ShapeUtils.getCenter(shape.shapes[0])
			//set focus
			this.onItemFocus(shapeBB, shapesIds[nextClickedItemId])
		}
	}


	onItemDeleteFromSendBackModeTable = (id) => {
		const { initialStateShapes, shapesIds } = this.state
		let initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.id === id)
		if (indexOfItem > -1) {
			initialStateShapesCopy[indexOfItem] = null
			initialStateShapesCopy = _.filter(initialStateShapesCopy)
			this.setState({
				clickedItemId: null,
				initialStateShapes: initialStateShapesCopy,
				itemIdCurrentlyEditingBB: null
			})
		}
	}


	toggleItemVisibility = (id) => {
		const { initialStateShapes } = this.state

		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.id === id)
		if (indexOfItem > -1) {
			initialStateShapesCopy[indexOfItem].visible = !initialStateShapesCopy[indexOfItem].visible

			this.setState({
				initialStateShapes: initialStateShapesCopy,
			})

		}
	}


	setVisibilityToAll = (isVisible) => {
		const { initialStateShapes, areasToDelete } = this.state
		const initialStateShapesCopy = [...initialStateShapes]

		for (let key in initialStateShapesCopy) {
			if (initialStateShapesCopy[key].type !== APPROVAL_DATA_TYPES.ADDED_BY_APPROVER) {
				initialStateShapesCopy[key].visible = isVisible
			}
		}


		this.setState({
			initialStateShapes: initialStateShapesCopy,
		})
	}


	onUndoItemDeleteFromEditTable = (id) => {
		const { initialStateShapes } = this.state

		const initialStateShapesCopy = [...initialStateShapes]
		const indexOfItem = _.findIndex(initialStateShapesCopy, shape => shape.id === id)
		if (indexOfItem > -1) {
			initialStateShapesCopy[indexOfItem].isDeleted = false

			this.setState({
				initialStateShapes: initialStateShapesCopy,
			})

		}
	}


	onMainTablesUndoDeleteClicked = (id) => {
		const { areasToDelete, initialStateShapes } = this.state

		let areasToDeleteCopy = { ...areasToDelete }
		let { [id]: deleted, ...rest } = areasToDeleteCopy

		areasToDeleteCopy = rest

		this.generateinitialStateForSingleItem(id)
		this.setState({
			areasToDelete: areasToDeleteCopy
		})


	}


	onKeyboardUpPressedInEditMode = () => {
		// current item focused
		const { clickedItemId, shapesIds, initialStateShapes, isEditMode } = this.state
		if (shapesIds && shapesIds.length === 0) return
		// if (!isEditMode) return
		// if none item is selected - do nothing.
		if (!clickedItemId) {
			const item = _.find(initialStateShapes, item => item.id === shapesIds[0])
			const shape = item.shapes[0]
			switch (shape.type) {
				case "Rectangle": {
					this.onItemFocus(ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(shape.boundingBox), shapesIds[0])
					return

				}
				case "DiagonalRectangle": {
					this.onItemFocus(ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(ShapeUtils.POLYGON.convertPointsToBoundingBox(shape.points)), shapesIds[0])
					return
				}
			}
		} else {

			const index = _.findIndex(shapesIds, id => clickedItemId === id)
			if (index > 0) {

				// find shape id BB and focus
				const shape = _.filter(initialStateShapes, item => item.id === shapesIds[index - 1])[0]
				const shapeBB = ShapeUtils.getCenter(shape.shapes[0])
				//set focus
				this.onItemFocus(shapeBB, shapesIds[index - 1])
			}

		}

	}


	onKeyboardDownPressedInEditMode = () => {
		// current item focused
		const { clickedItemId, shapesIds, initialStateShapes, isEditMode } = this.state
		if (shapesIds && shapesIds.length === 0) return
		// // if none item is selected - do nothing.
		// if (!clickedItemId) return
		if (!clickedItemId) {
			const item = _.find(initialStateShapes, item => item.id === shapesIds[0])
			const shape = item.shapes[0]
			switch (shape.type) {
				case "Rectangle": {
					this.onItemFocus(ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(shape.boundingBox), shapesIds[0])
					return

				}
				case "DiagonalRectangle": {
					this.onItemFocus(ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(ShapeUtils.POLYGON.convertPointsToBoundingBox(shape.points)), shapesIds[0])
					return
				}
			}
		} else {
			// find next item and highlight it
			const index = _.findIndex(shapesIds, id => clickedItemId === id)

			if (index === -1 || index === shapesIds.length - 1) {
				return
			}
			else {

				// find shape id BB and focus
				const shape = _(initialStateShapes).filter(item => item.id === shapesIds[index + 1]).value()[0]
				const shapeBB = ShapeUtils.getCenter(shape.shapes[0])
				//set focus
				this.onItemFocus(shapeBB, shapesIds[index + 1])
			}
		}

	}

	onKeyboardBackspacePressedInEditMode = () => {
		// if item is focused and backspace is pressed - delete the item.
		const { clickedItemId, shapesIds, isEditMode, initialStateShapes } = this.state
		if (!clickedItemId || !isEditMode) return

		const index = _.findIndex(shapesIds, id => clickedItemId === id)
		const nextClickedItemId = index + 1 > shapesIds.length - 1 ? shapesIds.length - 1 : index + 1

		this.onItemDeleteFromEditModeTable(clickedItemId)
		this.setState({ clickedItemId: shapesIds[nextClickedItemId] })

		// find shape id BB and focus
		const shape = _(initialStateShapes).filter(item => item.id === shapesIds[nextClickedItemId]).value()[0]
		const shapeBB = ShapeUtils.getCenter(shape.shapes[0])
		//set focus
		this.onItemFocus(shapeBB, shapesIds[nextClickedItemId])

	}


	validateAddedItemsInEditMode = () => {
		const { initialStateShapes, itemIdCurrentlyEditingBB } = this.state
		if (!initialStateShapes) { return { isValid: true, validationMessage: "" } }
		// when can i save?
		// 1. itemIdCurrentlyEditingBB is null - no item is not in active edit
		// 2 . is complex and toplevel is null and bottomLevel is null
		// 3 . complex is false and topLevel Is not null and bottom level is not null
		//4 . has description
		if (itemIdCurrentlyEditingBB) {
			return { isValid: false, validationMessage: "An area is in active edit mode" }
		}

		for (let i = 0; i < initialStateShapes.length; i++) {
			//Igonre DELETED items when validation
			if (initialStateShapes[i].isDeleted) {
				continue;
			}
			if (!initialStateShapes[i].description) {
				return { isValid: false, validationMessage: "Please make sure all areas have a description" }
			}
			if (initialStateShapes[i].isComplex && (!!initialStateShapes[i].bottomLevel || !!initialStateShapes[i].topLevel)) {
				return { isValid: false, validationMessage: "Please make sure all areas have proper multilevel selections" }
			}
			if (!initialStateShapes[i].isComplex && (!initialStateShapes[i].bottomLevel || !initialStateShapes[i].topLevel)) {
				return { isValid: false, validationMessage: "Please make sure all areas have proper multilevel selections" }
			}
		}

		return { isValid: true, validationMessage: "" }
	}


	hoverOutDoneDiv = () => {
		this.setState({ onDoneBtnHoverShowMessage: false })
	}


	hoverInDoneDiv = () => {
		this.setState({ onDoneBtnHoverShowMessage: true })
	}


	render() {
		const { areasToDelete, mapCenterToFocus, isEditMode, isSendBackMode, initialStateShapes, itemIdCurrentlyEditingBB, clickedItemId, onDoneBtnHoverShowMessage } = this.state
		const { isAnyTextInputFocused, onTextInputFocus, onTextInputBlur, approvalSubtaskBoundingBox, tableItemsSelections, supervisorComment,
			approverAnswerForShapes, approverAnswerForTags, approverAnswerForGrading } = this.props


		if (!tableItemsSelections || !approvalSubtaskBoundingBox) return null

		const mapItems = initialStateShapes

		const sendBackShapes = _.filter(initialStateShapes, item => item.type === APPROVAL_DATA_TYPES.ADDED_BY_APPROVER)

		const editTableItems = _.filter(initialStateShapes, item => !!areasToDelete[item.id] ||
			item.type === APPROVAL_DATA_TYPES.ADDED_BY_APPROVER)



		let validationErrorMessage = ""
		const { validationMessage, isValid: isEditItemsValid } = this.validateAddedItemsInEditMode()
		validationErrorMessage = validationMessage

		if (!isEditMode && !isSendBackMode && _.map(areasToDelete).length > 0) {
			validationErrorMessage = "You have deleted areas"
		}

		const isDoneBtnEnabled = (!isEditMode && !isSendBackMode && _.map(areasToDelete).length === 0)
			|| isEditMode && editTableItems.length > 0 && isEditItemsValid
			|| isEditMode && editTableItems.length === 0
			|| isSendBackMode && !itemIdCurrentlyEditingBB && (_.map(areasToDelete).length > 0 || sendBackShapes.length > 0)


		if (isSendBackMode && sendBackShapes.length === 0) {
			validationErrorMessage = "You must add some area to focus the supervisor"
		}

		return <div className="StageTwo stage">
			<div className="leftContainer">
				<div className="tableWrapper">
					<div className="showHideContainer">
						<span onClick={() => { this.setVisibilityToAll(true) }}>Show All</span>
						<span onClick={() => { this.setVisibilityToAll(false) }}>Hide All</span>
					</div>
					{this.generateExistingAreasFromTaskTable()}
					{this.generateExistinAreasFromOtherTasksTable()}


					{isEditMode && editTableItems.length > 0 &&
						<div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
							<div className="tableTitle">Edit</div>
							<SupervisorAnswersTable
								onDescriptionRefreshClicked={this.onDescriptionRefreshClicked}
								itemIdCurrentlyEditingBB={itemIdCurrentlyEditingBB}
								highlightedItemId={clickedItemId}
								onFocusClicked={this.onItemFocus}
								onEditClicked={this.onItemEdit}
								onDeleteClicked={this.onItemDeleteFromEditModeTable}
								onUndoDeleteClicked={this.onUndoItemDeleteFromEditTable}
								onitemFinishBBEdit={this.onitemFinishBBEdit}
								onAnwserMultilevelChange={this.onAnwserMultilevelChange}
								tableData={editTableItems}
								isEditing={isEditMode} />
						</div>}

					{isSendBackMode && sendBackShapes.length > 0 && <div style={{ display: "flex", flex: 0.4, flexDirection: "column" }}>
						{<div className="tableTitle">Send Back</div>}
						<div><small>Manage your answer<Button style={{ float: "right" }} bsStyle="danger" bsSize="xs" onClick={this.clearAllSendBack} >Clear all</Button></small></div>

						<AddedShapeDisplay
							countAsIdTitle={true}
							areasArray={sendBackShapes}
							activeShapeID={clickedItemId}
							onEdit={this.onItemEdit}
							editShapeID={itemIdCurrentlyEditingBB}
							onDoneEdit={this.onitemFinishBBEdit}
							onDelete={this.onItemDeleteFromSendBackModeTable}
						/>
					</div>}
				</div>



				<div className="bottomWrapper">
					<div className="questionsWrapper">
						<QuestionToApprove
							answer={approverAnswerForShapes}
							question={"Did the supervisor mark areas with a multilevel?"}
							onTrue={() => this.onApproverQuestionAnswer({ shapes: true })}
							onWrong={() => this.onApproverQuestionAnswer({ shapes: false })} />

						<QuestionToApprove
							answer={approverAnswerForGrading}
							question={"Did the supervisor marked the painters answers correctly?"}
							onTrue={() => this.onApproverQuestionAnswer({ grading: true })}
							onWrong={() => this.onApproverQuestionAnswer({ grading: false })} />

						<QuestionToApprove
							answer={approverAnswerForTags}
							question={"Did the supervisor marked the shapes types correctly?"}
							onTrue={() => this.onApproverQuestionAnswer({ tags: true })}
							onWrong={() => this.onApproverQuestionAnswer({ tags: false })} />

					</div>
					<div className="commentsWrapper">
						<ApproveCommentBox onTextInputFocus={onTextInputFocus} onTextInputBlur={onTextInputBlur} />
						<div className="supervisorsAnswerWrapper">
							<span>Supervisor's Comment</span>
							<textarea value={supervisorComment} disabled />
						</div>
					</div>
					<div className="actionBtns">
						{!isDoneBtnEnabled && onDoneBtnHoverShowMessage && validationErrorMessage && <span className="validationErrorMessage">{validationErrorMessage}</span>}
						<Button bsSize='sm' disabled={!isDoneBtnEnabled} onClick={this.onDone} onPointerEnter={this.hoverInDoneDiv}
							onPointerLeave={this.hoverOutDoneDiv} bsStyle="success">Done </Button>
						{!isEditMode && !isSendBackMode && <Button bsSize='sm' onClick={this.enterEditMode} bsStyle="warning">Edit </Button>}
						{isEditMode && <Button bsSize='sm' onClick={this.exitEditMode} bsStyle="danger">Cancel </Button>}
						{!isEditMode && !isSendBackMode && <Button bsSize='sm' onClick={this.enterSendBackMode} bsStyle="warning">Send back </Button>}
						{isSendBackMode && <Button bsSize='sm' onClick={this.exitSendBackMode} bsStyle="danger">Cancel </Button>}
					</div>

				</div>

			</div>

			<div className="mapWrapper">
				<ApproveStageMapContainer
					itemIdCurrentlyEditingBB={itemIdCurrentlyEditingBB}
					addDiagonalRectanglePointToExistingShape={this.addDiagonalRectanglePointToExistingShape}
					isAnyTextInputFocused={isAnyTextInputFocused}
					center={mapCenterToFocus}
					isEditing={isEditMode || isSendBackMode}
					subtaskShape={approvalSubtaskBoundingBox}
					items={mapItems}
					clickedItemId={clickedItemId}
					onShapeDraw={this.onShapeDraw}
					onShapeClicked={this.onShapeClicked}
					onDiagonalRectangleMarkerRightClick={this.onDiagonalRectangleMarkerRightClick}
					onEditDiagonalRectanglePoint={this.onEditDiagonalRectanglePoint}
					onKeyboardUpPressed={this.onKeyboardUpPressedInEditMode}
					onKeyboardDownPressed={this.onKeyboardDownPressedInEditMode}
					onKeyboardBackspacePressed={this.onKeyboardBackspacePressedInEditMode}
				/>
			</div>

		</div >
	}
}

const mapStateToProps = (state) => ({
	tableItemsSelections: getTableItemsSelections(state),
	approvalSubtaskBoundingBox: getApprovalSubtaskBoundingBoxFromStore(state),
	supervisorComment: getSupervisorCommentFromState(state),
	approverAnswerForShapes: getApproverAnswerForShapes(state),
	approverAnswerForTags: getApproverAnswerForTags(state),
	approverAnswerForGrading: getApproverAnswerForGrading(state),
	existingShapesAddedBySubtaskSupervisor: getExistingShapesAddedBySubtaskSupervisor(state),
	existingShapesNotAddedBySubtaskSupervisor: getExistingShapesNotAddedBySubtaskSupervisor(state),
})

const mapDispatchToProps = (dispatch) => ({
	setItemColorInApproverTableAction: bindActionCreators(setItemColorInApproverTable, dispatch),
	setItemVisibilityInApproverTableAction: bindActionCreators(setItemVisibilityInApproverTable, dispatch),
	setPhaseAnswerAction: bindActionCreators(setPhaseAnswer, dispatch),
	approveSubtaskFromEdit: bindActionCreators(approveSubtaskFromEdit, dispatch),
	approveSubtaskWithoutChanges: bindActionCreators(approveSubtaskWithoutChanges, dispatch),
	approveSubtaskFromSnedBack: bindActionCreators(approveSubtaskFromSendBack, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StageTwo)