import React from "react"
import PropTypes from "prop-types";

import "./NavigationButton.css"

class NavigationBarButton extends React.Component {

	buttonStyle = {
		width: "50%",
		fontSize: "2vh",
		cursor: "pointer",
		textAlign: "center",
		margin: "0 auto"
	}

	selectedStyle = {
		...this.buttonStyle,
		borderBottom: "#5eb2e0 solid 3px",
		borderRadius: "7px"

	}

	unselectedStyle = {
		opacity: "50%",
		...this.buttonStyle,
	}

	render() {
		const { label, onClick, isSelected } = this.props;

		return (
			<div className="NavigationBtn" style={{ flexGrow: "1" }}>
				<div style={isSelected ? this.selectedStyle : this.unselectedStyle} onClick={onClick}>{label}</div>
			</div>
		)
	}



}

NavigationBarButton.propTypes = {
	label: PropTypes.string,
	onClick: PropTypes.func,
	isSelected: PropTypes.bool
}

export default NavigationBarButton