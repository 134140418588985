import React from "react";
import "./tableHeader.css"

export default ({ title, style, children }) => <div className="tableHeader" style={style}>
	<span style={{
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "20px",
		fontWeight: "bolder"
	}}>{title}</span >
	{children && children}
</div>