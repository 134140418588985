import React, { Component } from "react";
import { Prompt } from "react-router";
import { connect } from "react-redux";
import _ from "lodash";
import { sagaActions, resetState, loadShapesThatFitMapBounds, onFocusShape } from "../../redux/shapes/shapeActions";
import ListContainer from "./containers/ListContainer";
import MapContainer from "./containers/MapContainer";
import { getTasks } from "../../redux/tasks/tasksActions";
import "./style.css";
import { AreasFilterByTasks } from "./components/areasFilterByTasks/areasFilterByTasks";

class Areas extends Component {
	state = {
		mapBounds: null,
		isFilteringByTasks: false
	}



	componentWillMount() {
		const { getTasks } = this.props
		getTasks()
	}


	componentDidUpdate = (prevProps, prevState) => {
		const { loadShapesThatFitMapBounds } = this.props

		// Initial load of shapes
		if (!prevState.mapBounds && this.state.mapBounds) {
			loadShapesThatFitMapBounds(this.state.mapBounds)
		}
	}


	onBoundsChanged = (mapBounds) => {
		const { loadShapesThatFitMapBounds, isMapAutoLoadActive } = this.props
		const { isFilteringByTasks } = this.state
		this.setState({ mapBounds }, () => {
			!isFilteringByTasks && isMapAutoLoadActive && loadShapesThatFitMapBounds(mapBounds)
		})
	}


	componentWillUnmount() {
		const { resetState } = this.props
		resetState()
	}


	onFilterByTask = (tasksBound) => {
		const { loadShapesThatFitMapBounds, focusMap } = this.props
		this.setState({ isFilteringByTasks: true })
		focusMap("filter", tasksBound)
		loadShapesThatFitMapBounds(tasksBound)
		// generate center point  and pass to map container :TODO:
	}


	dectivateFilterByTask = () => {
		const { loadShapesThatFitMapBounds } = this.props
		this.setState({ isFilteringByTasks: false })
		loadShapesThatFitMapBounds(this.state.mapBounds)
	}


	onListRefresh = () => {
		const { loadShapesThatFitMapBounds } = this.props
		const { mapBounds, isFilteringByTasks } = this.state
		if (mapBounds && !isFilteringByTasks) loadShapesThatFitMapBounds(mapBounds)
	}


	render() {
		const { mapState, tasks, getShapesAsync } = this.props;
		const { mapBounds } = this.state;
		return (
			<div className='areas_manager'>
				<Prompt
					when={mapState !== "READ"}
					message={"You have unsaved changes, Leave page?"}
				/>

				<div className='list_container'>
					<AreasFilterByTasks
						getAllAreas={getShapesAsync}
						onFilterByTask={this.onFilterByTask}
						dectivateFilterByTask={this.dectivateFilterByTask}
						tasks={tasks} />

					<ListContainer
						onListRefresh={this.onListRefresh}
						mapBounds={mapBounds} />

				</div>
				<div className='map_container'><MapContainer onBoundsChanged={this.onBoundsChanged} /></div>
			</div>
		)
	}

}


let mapStateToProps = ({ shapesStore, areasStore, tasksStore }) => ({
	mapState: shapesStore.mapState,
	isMapAutoLoadActive: areasStore.isMapAutoLoadActive,
	tasks: tasksStore.tasks
})


let mapDispatchToProps = (dispatch) => ({
	getTasks: () => dispatch(getTasks()),
	getShapesAsync: () => dispatch(sagaActions.getShapes()),
	resetState: () => dispatch(resetState()),
	focusMap: (id, boundingBox) => dispatch(onFocusShape(id, boundingBox)),
	loadShapesThatFitMapBounds: _.throttle((bounds) => dispatch(loadShapesThatFitMapBounds(bounds)), 1000)
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Areas);
