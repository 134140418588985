import React from "react"
import NavigationBarComponent from "./../NavigationBarComponent";
import { connect } from "react-redux"
// import { portalActions } from './../../../ReduxService/actions';
import { withRouter } from "react-router-dom";
import isempty from "lodash.isempty";
import sortby from "lodash.sortby";

import "./NavigationBar.less"
import { getUserGroups } from "../../../utils/utils";





const _NavigationBar = (props) => {

	const filterRoutsByGroup = (user) => {

		const routes = {
			"taskManager": {
				route: "task-manager",
				pretty: "Tasks",
				index: 2
			},
			"areasManager": {
				route: "areas-manager",
				pretty: "Areas",
				index: 1

			},
			"preview": {
				route: "preview",
				pretty: "History",
				index: 8
			},
			"paint": {
				route: "paint",
				pretty: "Paint",
				index: 3
			},
			"supervisors": {
				route: "supervise",
				pretty: "Supervise",
				index: 4
			},
			"approver": {
				route: "approver",
				pretty: "Approve",
				index: 5
			},
			"leaderboard": {
				route: "leaderboard",
				pretty: "Leaderboard",
				index: 6
			},
			"report": {
				route: "report",
				pretty: "Report",
				index: 7
			},
		};

		const userGroups = getUserGroups(user);

		const isAdmin = userGroups.indexOf("administrators") >= 0;
		const isApprover = userGroups.indexOf("approvers") >= 0;
		const isPainter = userGroups.indexOf("painters") >= 0;
		const isSupervisor = userGroups.indexOf("supervisors") >= 0;
		const isReport = userGroups.indexOf("report") >= 0;


		// default views to all users
		let defaultPages = [{ ...routes.paint }, { ...routes.leaderboard }, { ...routes.preview }]
		let finalViews = [];
		// ADMIN CAN SEE ALL ROUTES
		if (isAdmin) {
			finalViews = [...defaultPages, { ...routes.areasManager }, { ...routes.report }, { ...routes.taskManager }, { ...routes.supervisors }, { ...routes.approver }]
		}
		// Same as supervisor + approver page
		else if (isApprover) {
			let approverViewsArr = [...defaultPages];
			approverViewsArr = [...approverViewsArr, { ...routes.supervisors }, { ...routes.approver }]
			if (isReport)
				approverViewsArr = [...approverViewsArr, { ...routes.report }]
			finalViews = [...approverViewsArr]

		}

		// SUPERVISOR CANT SEE areas-manger AND task-manager
		else if (isSupervisor) {
			let supervisorsViewArr = [...defaultPages]
			supervisorsViewArr = [...supervisorsViewArr, { ...routes.supervisors }]
			if (isReport)
				supervisorsViewArr = [...supervisorsViewArr, { ...routes.report }]
			finalViews = [...supervisorsViewArr]
		}
		// PAINTER CANT SEE areas-manger AND task-manager AND supervisor
		else if (isPainter) {
			let paintersViewsArr = [...defaultPages];
			if (isReport)
				paintersViewsArr = [...paintersViewsArr, { ...routes.report }]
			finalViews = [...paintersViewsArr]
		}

		return sortby(finalViews, "index")
	}



	const { history, user } = props;

	const currentLocation = history.location.pathname.slice(1);

	const isUserExist = !isempty(user);


	if (isUserExist)
		return (
			<NavigationBarComponent
				currentLocation={currentLocation}
				availableRoutes={filterRoutsByGroup(user)
				}
			/>
		)
	else return <div>Waiting for user data . . .</div>

}


const mapStateToProps = ({ authStore }) => ({
	user: authStore.user
})



const NavigationBar = withRouter(connect(mapStateToProps, null)(_NavigationBar))
export default NavigationBar