//react
import React from "react";



export default ({ name }) => {


	return (
		<div style={{ height: "11%", fontSize: "20px", paddingTop: "1.5vh", color: "#5eb2e1" }} >
			<h3>
				Paint
				<span style={{ color: "gray", padding: "0px 10px 0px 10px" }}> > </span>
				<strong>
					{name}
				</strong>
			</h3>
		</div >
	)
}


