import React from "react"
import "./breadcrumbs.css"

export const Breadcrumbs = ({ subtaskName, currentStage, remainingTasks }) => <div className="Breadcrumbs">
	<span className="title">Approve</span>
	<span className="arrow">&gt;</span>
	<span className="title">{`${subtaskName}`}</span>
	<span className="arrow">&gt;</span>
	<span className="title">{`stage ${currentStage}/2`}</span>
	<span className="title"><span className="black">(</span>{`Tasks left: ${remainingTasks}`}<span className="black">)</span></span>
</div>