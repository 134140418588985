import moment from "moment"

export const timeFormatsConst = {
	previewTable: "DD/MM/YYYY HH:mm:ss",
	acceptedPreviewFormats: ["YYYY-MM-DDTHH:mm:ssZ"],
	// acceptedPreviewFormats: ["YYYY-MM-DDTHH:mm:ssZ", "YYYY-MM-DDTHH:mm:SSZ"],
	outputPreviewFormats: "DD/MM/YYYY HH:mm:ss",
	localTimeGmtPreviewFormats: "DD-MM-YYYY HH:mm:ss Z",
	epochTime: "X"

}

export const formatTimePretty = (time, acceptedFormat = timeFormatsConst.acceptedPreviewFormats, outputFormat = timeFormatsConst.outputPreviewFormats) => {

	let formats = []

	// check if string or array
	// if string then push to array
	if (typeof acceptedFormat === "string") formats.push(acceptedFormat)
	// if array (object) then keep it as array
	else if (typeof acceptedFormat === "object") formats = acceptedFormat


	// format the time string with the given formats and output with the given output (to local time)
	return moment.utc(time, formats).local().format(outputFormat)
}


export const MAP_STATE = {
	edit: "edit",
	add: "add",
	read: "read"
}

export const SHAPES_VIEW_TYPES = {
	absolute: "absolute",
	relative: "relative"
}

export const SHAPE_TYPES = {
	rectangle: "rectangle",
	circle: "circle",
	polyline: "polyline",
	marker: "marker"
}


export const reduxLoggerOpt = () => ({
	collapsed: true,
	diff: true,
	level: "info",
	duration: true
})


export const toPercentage = (num) => {
	let number
	if (typeof num === "string")
		number = Number(num)
	else
		number = num

	return Math.round(number.toFixed(2) * 100)





}




const getDates = {
	now: () => moment().format("DD/MM/YY HH:mm"),
	start_yesterday: () => moment().startOf("day").subtract(1, "days").format("DD/MM/YY HH:mm"),
	end_yesterday: () => moment().endOf("day").subtract(1, "days").format("DD/MM/YY HH:mm"),
	today: () => moment().startOf("day").format("DD/MM/YY HH:mm"),
	thisWeek: () => moment().startOf("week").format("DD/MM/YY HH:mm"),
	thisMonth: () => moment().startOf("month").format("DD/MM/YY HH:mm"),
	last_7_days: () => moment().subtract(7, "days").startOf("day").format("DD/MM/YY HH:mm"),
	last_30_days: () => moment().subtract(30, "days").startOf("day").format("DD/MM/YY HH:mm"),
	allTime: () => "01/01/00 00:00"
}




export const getStartEndDateTimeStringFromIntervalConst = {
	NOW: `${getDates.now()} - ${getDates.now()}`,
	YESTERDAY: `${getDates.start_yesterday()} - ${getDates.end_yesterday()}`,
	START_YESTERDAY: `${getDates.start_yesterday()} - ${getDates.end_yesterday()}`,
	// END_YESTERDAY: `${getDates.end_yesterday()}`,
	TODAY: `${getDates.today()} - ${getDates.now()}`,
	WEEK: `${getDates.thisWeek()} - ${getDates.now()}`,
	LAST_7_DAYS: `${getDates.last_7_days()} - ${getDates.now()}`,
	MONTH: `${getDates.thisMonth()} - ${getDates.now()}`,
	LAST_30_DAYS: `${getDates.last_30_days()} - ${getDates.now()}`,
	ALL_TIME: `${getDates.allTime()} -  ${getDates.now()}`
}

export const prettyTimeIntervalConsts = {
	YESTERDAY: "Yesterday",
	TODAY: "Today",
	WEEK: "This week",
	LAST_7_DAYS: "Last 7 days",
	MONTH: "This month",
	LAST_30_DAYS: "Last 30 days",
	ALL_TIME: "All",
	CUSTOM: "Custom"
}


export const getUserGroups = (user) => user ? user.groups
	.filter(g => g.split("/")[0] === "areas-blacklist-manager")
	.map(g => g.split("/")[1]) : null





export const refreshPage = () => { window.location.reload() }


export const sortListByPropDesc = (list, sortByProp) => {
	return list.sort(function (a, b) {
		if ((a[sortByProp] && !b[sortByProp]) || (a[sortByProp] > b[sortByProp])) {
			return -1
		}
		if ((b[sortByProp] && !a[sortByProp]) || (b[sortByProp] > a[sortByProp])) {
			return 1
		}
		return a[sortByProp] - b[sortByProp]
	});
}
