import React from "react"
import { Button, Glyphicon } from "react-bootstrap"
import { ShapeUtils } from "../../../../../common/sharedGlobal"


export class StageTwoMainTableActions extends React.Component {

	onDeleteClicked = () => {
		const { onItemDelete, item } = this.props
		onItemDelete(item)
	}


	render() {
		const { item, onFocusClicked, isItemDeleted, onMainTablesUndoDeleteClicked } = this.props
		const boundingBoxToFocus = ShapeUtils.getBoundingBox(item.shapes[0])
		return <div style={
			{
				display: "flex",
				justifyContent: "center",
				alignItems: "center"
			}
		} >
			<Button title="FOCUS" bsSize="small" bsStyle="primary" onClick={() => onFocusClicked(boundingBoxToFocus, item.id)}>
				<Glyphicon glyph="map-marker" />
			</Button>

			{!isItemDeleted && <Button style={{ margin: "5px" }} title="DELETE" bsSize="small" bsStyle="danger" onClick={this.onDeleteClicked}>
				<Glyphicon glyph="trash" />
			</Button>}
			{isItemDeleted && <Button style={{ margin: "5px" }} title="UNDO" bsSize="small" bsStyle="success" onClick={() => onMainTablesUndoDeleteClicked(item.id)}>
				<Glyphicon glyph="repeat" />
			</Button>}
		</div>
	}
}