import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { answer } from "../paintConsts"



export default class PaintManager extends Component {


	constructor(props) {
		super(props)


		this.state = {
			onDoneBtnHoverShowMessage: false
		}

	}



	isReadyForAnswer = ({ status, conflict_text, areas }) => {


		if (status === answer.conflict) {
			if (conflict_text === null || conflict_text === "" || conflict_text.length < 3)
				return false
			return true
		}
		if (status === answer.has_areas) {
			return areas.length >= 1
		}
		if (status === answer.no_areas)
			return true

		return false

	}

	getDoneBtnMessage = (answerObject) => {
		if (answerObject.status === undefined)
			return <div key="doneBtnHover">* Please select one of the options</div>


		if (answerObject.status === "has_areas" && answerObject.areas.length === 0)
			return <div key="doneBtnHover">* Please mark at least one area</div>

		if (answerObject.status === "problem" && (answerObject.conflict_text === null || answerObject.conflict_text.length < 3))
			return <div key="doneBtnHover">* Please write a remark (at least 3 character )</div>

	}

	hoverOutDoneDiv = () => {

		this.setState({ onDoneBtnHoverShowMessage: false })

	}

	hoverInDoneDiv = () => {

		this.setState({ onDoneBtnHoverShowMessage: true })
	}


	onDoneClicked = () => {
		const { answerObject } = this.props
		if (this.isReadyForAnswer(answerObject))
			this.props.sendAnswer()
	}


	componentDidMount() {
		window.addEventListener("keypress", this.onEnterPress)
	}


	componentWillUnmount() {
		window.removeEventListener("keypress", this.onEnterPress)
	}


	onEnterPress = (event) => {
		if (event.isComposing || event.keyCode === 13) {
			this.onDoneClicked()
		}
	}


	render() {

		const { answerObject } = this.props;
		const { onDoneBtnHoverShowMessage } = this.state;
		const isDoneDisabled = !this.isReadyForAnswer(answerObject)

		return (
			<div>
				<div style={{
					borderTop: "#2ec1dc57 solid 0.5px",
					borderBottom: "#8ccd977d solid 0.5px",
					borderRadius: "12px",
					display: "flex",
					justifyContent: "space-between",
					margin: "10px 0",
					padding: "9px 0px 9px 10px"
				}}>

					<Button key="realBtn" bsStyle="success" onMouseEnter={this.hoverInDoneDiv}
						onMouseLeave={this.hoverOutDoneDiv} onClick={this.onDoneClicked} style={{
							cursor: isDoneDisabled ? "not-allowed" : "pointer",
							opacity: isDoneDisabled ? 0.5 : 1
						}} >Done</Button>


				</div>
				<div>
					{onDoneBtnHoverShowMessage && <div style={{ color: "red" }}>{this.getDoneBtnMessage(answerObject)}</div>}
				</div>
			</div>
		)
	}

}
