
import React from "react";
import { Glyphicon, Tooltip, OverlayTrigger } from "react-bootstrap"
import _ from "lodash"
import "./leaderboard.css"
import { sortListByPropDesc } from "../../../utils/utils";

// each user stats include: urlUserPick, name,email,good,bad,auto,role 
const SupervisorsLeaderboardTable = ({ stats }) => {

	const displayStanding = (standing = 0) =>
		<div>
			<span style={{ color: standing === 1 ? "green" : "inherit" }} >
				{standing}
			</span>
		</div>



	const displayUserInfo = (user) => <div>
		<div>{user}</div>
	</div>



	const displayUserScore = ({ totalSupervised, totalSendBacks, supervisorScore, perfectSupervisionsCount = 0 }) => {
		return <div className="scoresWrapper" style={{ width: "100%", height: "100%", display: "flex", fontSize: "20px" }} >
			{(totalSupervised || supervisorScore) && <div className="tableRowSection">

				{typeof totalSupervised === "number" && <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><strong>Total Supervised</strong></Tooltip>}>
					{iconScore("ok", totalSupervised, "green")}
				</OverlayTrigger>}

				{typeof supervisorScore === "number" && <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><strong>Supervisor Score</strong></Tooltip>}>
					{iconScore("star", supervisorScore, "#ffe700")}
				</OverlayTrigger>}

				{typeof perfectSupervisionsCount === "number" && perfectSupervisionsCount > 0 && <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><strong>Perfect Supervisions</strong></Tooltip>}>
					{iconScore("asterisk", perfectSupervisionsCount, "#2196f3")}
				</OverlayTrigger>}
				{typeof totalSendBacks === "number" && <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><strong>Total Send Backs</strong></Tooltip>}>
					{iconScore("transfer", totalSendBacks, "orange")}
				</OverlayTrigger>}
			</div>}

		</div >
	}




	const iconScore = (glyphString, score = 0, color, background) =>
		<div style={{ textAlign: "center", border: "#6f6f6f54 solid 1px", backgroundColor: background ? background : "white" }}>

			<div>
				<Glyphicon style={{ color: color }} glyph={glyphString} />
			</div>

			<div>
				{Number.isNaN(score) ? 0 : score}
			</div>

		</div >



	const userScoreRow = ({ user, place, totalSupervised, totalSendBacks, supervisorScore, perfectSupervisionsCount, key }) =>
		totalSupervised > 0 && <div key={key} style={{ display: "flex", border: "2px solid #b7b7b7", padding: "10px", margin: "10px", alignItems: "center", justifyContent: "space-around", backgroundColor: "white" }}  >
			<div id="leaderboard_place" style={{ fontSize: "45px", color: "#afafaf", flexGrow: "0.5", textAlign: "center" }} > {displayStanding(place)}</div>
			<div id="leaderboard_userInfo" style={{ flexGrow: "6", fontSize: "23px" }} >{displayUserInfo(user)}</div>
			<div id="leaderboard_score" style={{ flexGrow: "4" }}  >{displayUserScore({ totalSupervised, totalSendBacks, supervisorScore, perfectSupervisionsCount })}</div>
		</div >


	return (
		stats.length > 0 ? <div >
			{stats && stats.map((userStats, index) => userScoreRow({ ...userStats, key: index }))}
		</div> : null
	)
}


// sort the stats array before
const sortLeaderBoardComponent = (WrappedComponent) => class extends React.Component {

	sortPaintersLeaderboardrList = (list, sortBy) => {
		switch (sortBy) {
			case "SCORE":
				return sortListByPropDesc(list, "supervisorScore")
			case "TOTAL_SUPERVISED":
				return sortListByPropDesc(list, "totalSupervised")
			case "TOTAL_SUPERVISED_PERFECTLY":
				return sortListByPropDesc(list, "perfectSupervisionsCount")
			case "TOTAL_SENDBACKS":
				return sortListByPropDesc(list, "totalSendBacks")
			default:
				return list
		}
	}


	// generate place  - sort by score. place is the position
	generatePlaces = (list) => {
		const sortedByScore = sortListByPropDesc(list, "supervisorScore")
		const withPlaceCalculated = _.map(sortedByScore, (item, index) => ({ ...item, place: index + 1 }))
		return withPlaceCalculated
	}


	render() {
		const { sortBy } = this.props;
		let newProps = { ...this.props, stats: this.sortPaintersLeaderboardrList(this.generatePlaces(this.props.stats), sortBy) }
		return <WrappedComponent {...newProps} />
	}
}

// export default LeaderBoardTable
export default sortLeaderBoardComponent(SupervisorsLeaderboardTable)