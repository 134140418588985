import React from "react"
import { GenericTable } from "../../../../common/components"
import { GtSupervisorInfo } from "../../../genericTableComponents/info/supervisor"
import { Button, Glyphicon } from "react-bootstrap"
import { GtDescription } from "../../../genericTableComponents/description"
import { SHAPE_COLORS_CONSTS } from "../../../genericTableComponents/shapeColor/shapeColors"
import { ApprovePhaseMapContainer } from "../approvePhaseMapContainer"
import { ShapeUtils } from "../../../../common/sharedGlobal"


export class SupervisorAnswersTable extends React.Component {

	generateTable = () => {
		const { tableData, isEditing, onDescriptionRefreshClicked, highlightedItemId } = this.props
		return <GenericTable
			data={tableData}
			highlightedItemId={highlightedItemId}
			highlightedItemColor={SHAPE_COLORS_CONSTS.blue}
			mapColumnToRenderer={[
				{
					column: {
						title: "Description",
						icon: ""
					},
					cellRenderer: (item) => <GtDescription
						onDescriptionRefreshClicked={onDescriptionRefreshClicked}
						item={item}
						isEditable={isEditing && !item.isDeleted} />
				},


				{
					column: {
						title: "Info",
						icon: ""
					},
					cellRenderer: (item) => {

						const { isEditing, onAnwserMultilevelChange } = this.props
						return <GtSupervisorInfo
							isEditable={isEditing && !item.isDeleted}
							data={item}
							onMultilevelComplexChange={(value) => onAnwserMultilevelChange(item.id, "isComplex", value)}
							onMultilevelTopChange={(value) => onAnwserMultilevelChange(item.id, "topLevel", value)}
							onMultilevelBottomChange={(value) => onAnwserMultilevelChange(item.id, "bottomLevel", value)}
						/>
					}
				},


				{
					column: {
						title: "Action",
						icon: ""
					},
					cellRenderer: (item) => {
						const { onFocusClicked, onitemFinishBBEdit, onEditClicked, onDeleteClicked, onUndoDeleteClicked, isEditing, itemIdCurrentlyEditingBB } = this.props
						const boundingBoxToFocus = ShapeUtils.getBoundingBox(item.shapes[0])
						if (isEditing) {
							return <div style={
								{
									width: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"

								}
							}>
								<Button style={{ margin: "5px" }} title="FOCUS" bsSize="small" bsStyle="primary" onClick={() => onFocusClicked(boundingBoxToFocus, item.id)}>
									<Glyphicon glyph="map-marker" />
								</Button>

								{!item.isEditable && <Button style={{ margin: "5px" }} disabled={item.isDeleted || (itemIdCurrentlyEditingBB && itemIdCurrentlyEditingBB !== item.id)} title="EDIT" bsSize="small" bsStyle="warning" onClick={() => onEditClicked(item.id)}>
									<Glyphicon glyph="pencil" />
								</Button>
								}

								{
									item.isEditable && <Button style={{ margin: "5px" }} title="FINISH EDIT" bsSize="small" bsStyle="success" onClick={() => onitemFinishBBEdit(item.id)}>
										<Glyphicon glyph="ok" />
									</Button>
								}

								{!item.isDeleted && <Button style={{ margin: "5px" }} title="DELETE" bsSize="small" bsStyle="danger" onClick={() => onDeleteClicked(item.id)}>
									<Glyphicon glyph="trash" />
								</Button>}

								{item.isDeleted && <Button style={{ margin: "5px" }} title="UNDO" bsSize="small" bsStyle="success" onClick={() => onUndoDeleteClicked(item.id)}>
									<Glyphicon glyph="repeat" />
								</Button>}
							</div >
						} else {

							return <div style={
								{
									display: "flex",
									justifyContent: "center",
									alignItems: "center"
								}
							} >
								<Button title="FOCUS" bsSize="small" bsStyle="primary" onClick={() => onFocusClicked(boundingBoxToFocus, item.id)}>
									<Glyphicon glyph="map-marker" />
								</Button>
							</div>
						}
					}
				}
			]}
		/>
	}


	render() {
		return this.generateTable()
	}
}