
const { LEADER_BOARD } = require("../reduxConsts").ACTION_TYPES
// const { LEADERBOARD_SORT_BY } = require("../reduxConsts")



/* * * * * * * * * * * * * * * * * async actions * * * * * * * * * * * * * * */
/**@param {String} req async start function*/
export const async_start = () => ({ type: LEADER_BOARD.ASYNC_START })

/**@param {Object} data async success function */
export const async_success = () => ({ type: LEADER_BOARD.ASYNC_SUCCESS })

/**@param {String} error async error function */
export const async_error = error => ({ type: LEADER_BOARD.ASYNC_ERROR, value: error })
/* * * * * * * * * * * * * * * * * async actions * * * * * * * * * * * * * * */


/* * * * * * * * * * * * * * * * *SAGA ACTIONS* * * * * * * * * * * * * * */

export const get_leaderboard = () => ({ type: LEADER_BOARD.GET_LEADERBOARD })

export const reset_leaderboard = () => ({ type: LEADER_BOARD.RESET_STORE })
export const set_leaderBoard = (lb) => ({ type: LEADER_BOARD.SET_LEADERBOARD, value: lb })
export const set_custom_time_from = (time) => ({ type: LEADER_BOARD.SET_CUSTOMTIME_FROM, value: { time } })
export const set_custom_time_to = (time) => ({ type: LEADER_BOARD.SET_CUSTOMTIME_TO, value: { time } })

export const sort_by = (sortBy) => ({ type: LEADER_BOARD.SET_SORT_BY, value: sortBy })


/* * * * * * * * * * * * * * * * *SAGA ACTIONS* * * * * * * * * * * * * * */

// type : interval || custom
// data: if interval:{interval:"TODAY"} || if custom:{startData,endDate}
export const set_interval = ({ type, data }) => ({ type: LEADER_BOARD.SET_TIME_INTERVAL, value: { type, data } })
export const setCustomIntervalForLeaderboard = (from, to) => ({ type: LEADER_BOARD.SET_CUSTOM_LEADERBOARD_TIME_INTERVAL, value: { from, to } })
