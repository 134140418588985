import React from "react";
import axios from "axios"
import NoContentComponent from "./../../hoc/NoContentComponent"




const WrapWithSubtaskFetcher = (WrappedComponent) => {
	return class extends React.Component {

		constructor(props) {
			super(props)
			this.state = {
				fetchedSubtask: null
			}
		}

		goBackToHistoryPage = () => this.props.history.push("./../preview")

		renderNoSubtaskFound = (name) => <NoContentComponent refreshAction={this.goBackToHistoryPage} title={`${name} Does not exists or ready to preview.`} text={"Press refresh to return to History page"} />
		// renderNoSubtaskFound = (name) => <div style={{ textAlignLast: "center" }}><h3>"{name}" not exists or  </h3></div>

		getSubtaskNameFromUrlPath = () => window.location.pathname.split("/")[window.location.pathname.split("/").length - 1]

		componentDidMount = async () => {
			const { state } = this.props.location;
			const { fetchedSubtask } = this.state;

			if (!state && fetchedSubtask === null) {

				const subtaskName = this.getSubtaskNameFromUrlPath()


				const response = await this.fetchSubtask(subtaskName);

				if (response.data.length > 0) {

					if (Object.keys(response.data[0]).indexOf("supervisor") < 0)
						Promise.resolve()

					else {
						this.setState({ fetchedSubtask: response.data[0] })


						return Promise.resolve()
					}
				}
				else return Promise.resolve()
			}
			else
				return Promise.resolve()
		}

		fetchSubtask = (subtaskid) => axios.get("/api/subtasks/preview/" + subtaskid)




		render() {
			//  look if the subtask object exists
			const { state } = this.props.location;
			const { fetchedSubtask } = this.state;
			const subtaskName = this.getSubtaskNameFromUrlPath()

			if (!state && fetchedSubtask === null)
				return this.renderNoSubtaskFound(subtaskName)

			else {
				if (state && state.subtask)
					return <WrappedComponent {...this.props} />;
				else if (fetchedSubtask !== null) {
					let newProps = { ...this.props, location: { ...this.props.location, state: { subtask: fetchedSubtask } } }
					return <WrappedComponent {...newProps} />;
				}
				else return this.renderNoSubtaskFound(subtaskName)
			}
		}
	}

}



export default WrapWithSubtaskFetcher