import { handleActions } from "redux-actions";
import { ACTION_TYPES } from "../reduxConsts";

const initialState = {
	isMapAutoLoadActive: false
}

const setisMapAutoLoad = (state, action) => {
	return { ...state, isMapAutoLoadActive: action.value }
}

export const areasStore = handleActions({
	[ACTION_TYPES.AREAS.SET_IS_MAP_AUTOLOAD_ACTIVE]: setisMapAutoLoad
}, initialState)