import { getBounds } from "geolib";
import moment from "moment";
import _ from "lodash"
/**
 * takes task object and extract the results bounding boxes to array of points 
 */
export const getMinMaxPoints = ({ results = [], boundingBox = {} }) => {
	// get all areas from the subtask, turn to array of [{lat,lng},{lat,lng},{lat,lng},{lat,lng} .....]
	let allResultsArray = results
		// get only the areas array
		.map(({ areas }) => areas)
		// union 2 arrays to 1
		.reduce((totalAreas, currentArea) => [...totalAreas, ...currentArea], [])
		// object to array
		.map(({ sw, ne }) => [ne, sw])
		// union 2 arrays to 1
		.reduce((totalAreas, currentArea) => [...totalAreas, ...currentArea], [])
		// switch from lat to latitude
		.map(({ lat, lng }) => ({ latitude: lat, longitude: lng }))


	let boundingBoxPoints = [
		{ latitude: boundingBox.ne.lat, longitude: boundingBox.ne.lng },
		{ latitude: boundingBox.sw.lat, longitude: boundingBox.sw.lng }
	]

	// return the max,min lat and lng
	return getBounds([...allResultsArray, ...boundingBoxPoints])
}

/**
 * takes task object and extract the results bounding boxes to array of points 
 */
// export const getMinMaxPointsFromSubtasks = ({ results = [], boundingBox = {} }) => {
// 	// get all areas from the subtask, turn to array of [{lat,lng},{lat,lng},{lat,lng},{lat,lng} .....]
// 	let allResultsArray = results
// 		.filter(result => result.status === "has_areas")
// 		// get only the areas array
// 		.map(({ areas }) => areas)
// 		// union 2 arrays to 1
// 		.reduce((totalAreas, currentArea) => [...totalAreas, ...currentArea], [])
// 		// object to array
// 		.map(({ sw, ne }) => [ne, sw])
// 		// union 2 arrays to 1
// 		.reduce((totalAreas, currentArea) => [...totalAreas, ...currentArea], [])
// 		// switch from lat to latitude
// 		.map(({ lat, lng }) => ({ latitude: lat, longitude: lng }))


// 	let boundingBoxPoints = [
// 		{ latitude: boundingBox.ne.lat, longitude: boundingBox.ne.lng },
// 		{ latitude: boundingBox.sw.lat, longitude: boundingBox.sw.lng }
// 	]

// 	// return the max,min lat and lng
// 	return getBounds([...allResultsArray, ...boundingBoxPoints])
// }

/**
 * will return a unix time:
 * seconds since 1970 (UTC)
 */
export const getUnixTime = () => moment().unix()


/**
 *
 * helper function to fetch nearby places by google
 * returns a promise  
 */
export const getNearbyGeocode = _.throttle(({ lat, lng }) => new Promise((res, rej) => {
	try {

		const service = new window.google.maps.Geocoder();
		service.geocode(
			{
				location: { lat, lng }
			},
			(response, status) => {
				res(response, status)
			}
		);
	} catch (e) {

		rej(e)
	}
}), 300)

export const getNearbyGeocodeWithStatus = _.throttle(({ lat, lng }) => new Promise((res, rej) => {
	try {

		const service = new window.google.maps.Geocoder();
		service.geocode(
			{
				location: { lat, lng }
			},
			(response, status) => {
				res({ response, status })
			}
		);
	} catch (e) {

		rej(e)
	}
}), 300)




export const googleResultsOptions = {
	"ZERO_RESULTS": "ZERO_RESULTS",
	"OVER_QUERY_LIMIT": "OVER_QUERY_LIMIT",
	"OVER_DAILY_LIMIT": "OVER_DAILY_LIMIT",
	"REQUEST_DENIED": "REQUEST_DENIED",
	"UNKNOWN_ERROR": "UNKNOWN_ERROR",
	"OK": "OK"
}