import React from "react"
import "./info.css"


export class GtSnapshotWhileSupervisorInfo extends React.Component {

	render() {
		const { data } = this.props
		return (<div className="info GT_snapshotWhileSupervisorInfo">
			<div>{`Snapshot areas: ${data.numOfSnapshots}`}</div>
		</div>)
	}

}
