import React from "react";


import NavArrow from "./../../utils/navArrow"
import { getStartEndDateTimeStringFromIntervalConst, prettyTimeIntervalConsts, formatTimePretty } from "./../../../utils/utils"

export default ({ mainLabel, intervalTitle }) => {

	let timeString = "";
	let location = intervalTitle.type === "CUSTOM" ? prettyTimeIntervalConsts[intervalTitle.type] : prettyTimeIntervalConsts[intervalTitle.data.interval]
	// if custom then format date separately
	if (intervalTitle.type === "CUSTOM")
		timeString = `${formatTimePretty(intervalTitle.data.from)} - ${formatTimePretty(intervalTitle.data.to)}`

	else
		timeString = getStartEndDateTimeStringFromIntervalConst[intervalTitle.data.interval]


	return (
		<div style={{ paddingTop: "4vh", color: "#5eb2e1", height: "10%", paddingLeft: "20px", fontSize: "22px", display: "flex" }} >

			{/* page info */}
			<div >
				<h3 style={{ margin: "0" }}>
					{mainLabel}
					<NavArrow />
					<strong>{location}</strong> - <small>( {timeString} )</small>
				</h3>
			</div>

		</div>)
}	