import { handleActions } from "redux-actions"
import { ACTION_TYPES } from "../reduxConsts"
import _ from "lodash"


const initialState = null

const storeSubTaskForApproval = (state, action) => {
	const subTask = action.value
	return { ...subTask }
}


const deleteDbSnapshot = (state, action) => {
	const id = action.value
	const newState = _.cloneDeep(state)
	let newPhaseFourDbSnapshotsAreas = [...newState.phaseFourDbSnapshotsAreas]
	_.remove(newPhaseFourDbSnapshotsAreas, snapshot => snapshot.id === id)
	newState.phaseFourDbSnapshotsAreas = newPhaseFourDbSnapshotsAreas
	return newState
}


const resetState = (state, action) => {
	return { ...initialState }
}

export const approverDataStore = handleActions({
	[ACTION_TYPES.APPROVER.STORE_SUB_TASK_FOR_APPROVAL]: storeSubTaskForApproval,
	[ACTION_TYPES.APPROVER.CONFIRM_DELETE_DBSNAPSHOT_AREA]: deleteDbSnapshot,
	[ACTION_TYPES.APPROVER.RESET_APPROVER_STATE]: resetState,

}, initialState)