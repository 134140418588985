const { omitBy, isNull, isEmpty } = require("lodash")




const prepareAnswerForPost = (answer) => {

	// answer must be an object
	if (typeof answer !== "object")
		return false;


	return omitBy(answer, isEmpty, isNull)
}


module.exports = {
	answer: {
		has_areas: "has_areas",
		no_areas: "no_areas",
		conflict: "problem",
		conflict_text: "conflict_text"
	},
	prepareAnswerForPost: prepareAnswerForPost
}