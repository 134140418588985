const { APPROVER } = require("../reduxConsts").ACTION_TYPES

export const fetchSubTaskToApprove = (subtaskName) => ({ type: APPROVER.FETCH_SUB_TASK_FOR_APPROVAL, value: { subtaskName } })
export const clearFilterAndFetchSubtask = () => ({ type: APPROVER.CLEAR_FILTERS_AND_FETCH_SUB_TASK_FOR_APPROVAL })
export const storeSubTaskToApprove = (subtask) => ({ type: APPROVER.STORE_SUB_TASK_FOR_APPROVAL, value: subtask })
export const resetApproverState = () => ({ type: APPROVER.RESET_APPROVER_STATE })

export const setDisplayDataForApproverTables = (data) => ({ type: APPROVER.SET_DATA_FOR_APPROVER_TABLE, value: data })
export const setItemColorInApproverTable = (id, fill, stroke) => ({ type: APPROVER.SET_COLOR_FOR_APPROVER_TABLE_ITEM, value: { id, fill, stroke } })
export const setItemVisibilityInApproverTable = (id, isVisible) => ({ type: APPROVER.SET_VISIBILITY_FOR_APPROVER_TABLE_ITEM, value: { id, isVisible } })

export const updateApproveCommentBoxValue = (value) => ({ type: APPROVER.SET_APPROVE_COMMENT_BOX_VALUE, value: value })

export const setPhaseAnswer = (answer) => ({ type: APPROVER.SET_PHASE_ANSWER, value: answer })

export const phaseChanged = () => ({ type: APPROVER.PHASE_CHANGED })

export const submitApproverAnswers = (data) => ({ type: APPROVER.SUBMIT_APPROVER_ANSWERS, value: data })

export const setVisibilityToAll = (isVisible, itemsTypes) => ({ type: APPROVER.SET_IS_ALL_VISIBLE, value: { isVisible, types: itemsTypes } })

export const setApproverSubtaskApiType = (type) => ({ type: APPROVER.SET_APPROVER_SUBTASK_API_TYPE, value: type })

export const resetApproveTask = (type) => ({ type: APPROVER.RESET_APPROVE_TASK, value: type })

export const deleteDbSnapshotArea = (id) => ({ type: APPROVER.DELETE_DBSNAPSHOT_AREA, value: id })
export const confirmDeleteDbSnapshotArea = (id) => ({ type: APPROVER.CONFIRM_DELETE_DBSNAPSHOT_AREA, value: id })

export const approveSubtaskFromEdit = ({ appliedShapes, areasToDelete }) => ({ type: APPROVER.APPROVE_DONE_EDIT_MODE, value: { appliedShapes, areasToDelete } })
export const approveSubtaskFromSendBack = ({ approverSendBackSuggestions, areasToDelete }) => ({ type: APPROVER.APPROVE_DONE_SEND_BACK, value: { approverSendBackSuggestions, areasToDelete } })
export const approveSubtaskWithoutChanges = () => ({ type: APPROVER.APPROVE_DONE })


export const approverSetFilter = (filterData) => ({ type: APPROVER.SET_APPROVER_FILTER, value: filterData })
export const approverResetFilter = () => ({ type: APPROVER.RESET_APPROVER_FILTER })
