import React from "react"
import { connect } from "react-redux"
import StageOne from "./stageOne/stageOne"
import StageTwo from "./stageTwo/stageTwo"
import { Breadcrumbs } from "../breadcrumbs/breadcrumbs"
import "./stages.css"
import { ApproverFilter } from "./approverFilter/approverFilter"
import { Badge, Button } from "react-bootstrap"
import { getApproverFilter, getApproverSendBack } from "../../../redux/approve/selectors"
import moment from "moment"
import { bindActionCreators } from "redux"
import { fetchSubTaskToApprove, clearFilterAndFetchSubtask } from "../../../redux/approve/approverActions"



class ApproveStages extends React.Component {

	constructor(props) {
		super(props)
		this.state = {
			currentStage: 1,
			isFilterVisible: false,
			isAnyTextInputFocused: false
		}
	}

	onTextInputFocus = () => this.setState({ isAnyTextInputFocused: true })
	onTextInputBlur = () => this.setState({ isAnyTextInputFocused: false })

	toggleFilterVisibility = () => this.setState(pstate => ({ isFilterVisible: !pstate.isFilterVisible }))
	setFilterHidden = () => this.setState({ isFilterVisible: false })
	goToNextStage = () => this.setState(pState => ({ currentStage: pState.currentStage + 1 }))


	generateCurrentFilterValues = (filter) => {
		const { intervalType = "", ...filterData } = filter
		const jsx = []
		for (let key in filterData) {
			if (typeof filterData[key] === "string" && filterData[key].length === 0) {
				continue;
			}
			let value = filterData[key]
			if (key === "dateFrom" || key === "dateTo") {
				value = moment(parseInt(value)).format("DD/MM/YY HH:mm")
			}
			jsx.push(<Badge> {`${key}: ${value}`}</Badge>)
		}
		return jsx
	}

	onRestartClick = () => {
		const { clearFilterAndFetchSubtask } = this.props
		clearFilterAndFetchSubtask()
	}

	onSkipClick = () => {
		const { fetchSubTaskToApprove } = this.props
		fetchSubTaskToApprove()
	}


	onShowOriginalClick = () => {
		// open the preview in a new window (popup)
		window.open("preview/" + this.props.approverSendBack.previousSubTaskName, "popup", "width=800,height=800")

	}


	render() {
		const { currentStage, isFilterVisible, isAnyTextInputFocused } = this.state
		const { remainingTasks, subtaskName, filterData, approverSendBack } = this.props

		return <div className="Approve">
			<div style={{ display: "flex", alignItems: "center" }}>
				<Breadcrumbs subtaskName={subtaskName} currentStage={currentStage} remainingTasks={remainingTasks} />
				<Button bsSize="xs" bsStyle="success" onClick={this.toggleFilterVisibility}> Filter Options</Button>
				<div className="filterValuesContainer">
					{filterData && this.generateCurrentFilterValues(filterData)}
				</div>
				<div style={{ display: "flex", paddingRight: "5px", width: approverSendBack ? "230px" : "120px", justifyContent: "space-between" }}>
					<Button bsSize="sm" bsStyle="info" onClick={this.onRestartClick}>Restart</Button>
					<Button bsSize="sm" bsStyle="info" onClick={this.onSkipClick}>Skip</Button>
					{approverSendBack && <Button bsSize="sm" bsStyle="info" onClick={this.onShowOriginalClick}>Show Original</Button>}
				</div>
			</div>

			<div className="StagesContainer">
				{isFilterVisible && <ApproverFilter onTextInputFocus={this.onTextInputFocus} onTextInputBlur={this.onTextInputBlur} hide={this.setFilterHidden} />}
				{currentStage === 1 && <StageOne isAnyTextInputFocused={isAnyTextInputFocused} onTextInputFocus={this.onTextInputFocus} onTextInputBlur={this.onTextInputBlur} goToNextStage={this.goToNextStage} />}
				{currentStage === 2 && <StageTwo isAnyTextInputFocused={isAnyTextInputFocused} onTextInputFocus={this.onTextInputFocus} onTextInputBlur={this.onTextInputBlur} />}
			</div>
		</div>
	}
}

const mapStateToProps = (state) => ({
	filterData: getApproverFilter(state),
	approverSendBack: getApproverSendBack(state),
})

const mapStateToActions = (dispatch) => ({
	fetchSubTaskToApprove: bindActionCreators(fetchSubTaskToApprove, dispatch),
	clearFilterAndFetchSubtask: bindActionCreators(clearFilterAndFetchSubtask, dispatch),
})

export default connect(mapStateToProps, mapStateToActions)(ApproveStages)