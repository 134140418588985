import React from "react";
import { Button } from "react-bootstrap";


export default ({ isActive, isActiveAction, id }) => {
	const marginButton = { marginLeft: "10px" }
	return <div >
		<small style={{ color: isActive ? "rgb(0, 130, 0)" : "rgb(241, 27, 27)" }}>
			Status: {isActive ? "Active" : "Disabled"}</small>
		{
			isActive ?
				//button for active status isActive = false (ACTIVE SHAPE)
				<Button title="Set this area as disabled" style={marginButton} bsSize="xs" bsStyle="danger" onClick={() => { isActiveAction(id, false) }}>Disable</Button> :
				//button for disabled status isActive = true (DISABLED SHAPE)
				<Button title="Set this area as active" style={marginButton} bsSize="xs" bsStyle="success" onClick={() => { isActiveAction(id, true) }}>Activate</Button>
		}
	</div>
}
