import React from "react"
import { Button, Glyphicon } from "react-bootstrap"
import "./description.css"
import * as classNames from "classnames"


export class GtDescription extends React.Component {

	render() {
		const { isEditable, item, onDescriptionRefreshClicked } = this.props

		const GT_DescriptionClassNames = classNames({
			"GT_Description": true,
			"errorBg": !item.description
		})
		const description = item.description || "Google suggestion returned with 0 results (try marking in other place)"
		return (
			<div className={GT_DescriptionClassNames}>
				<span className="description">{description}</span>
				{isEditable && <Button size="xs" onClick={() => onDescriptionRefreshClicked(item.id)}>
					<Glyphicon glyph="refresh"></Glyphicon></Button>}
			</div>
		)
	}
}