const { ACTION_TYPES, ASYNC_STATUS, MAP_STATE } = require("../reduxConsts")

// initial state for tasks store
const tasks_initial = {
	// tasks to display on the tasks list
	tasks: [],
	// map state
	mapState: MAP_STATE.READ,
	// whats the async status
	asyncStatus: null,
	// focus object with counter
	focus: null,
	// the edit shape been edited
	shapeEditID: null,
	// subtask array to display in the tasks
	subTasksList: [],
	// tmp shape for adding a new subtask
	tmpShape: {},
	// errors array
	errors: null
}

export function tasksStore(state = tasks_initial, action) {
	const { type, value } = action;


	switch (type) {

		case ACTION_TYPES.TASK.RESET_STATE: {
			return {
				tasks: [],
				mapState: MAP_STATE.READ,
				asyncStatus: null,
				focus: null,
				shapeEditID: null,
				subTasksList: [],
				tmpShape: {},
				errors: null
			}
		}

		case ACTION_TYPES.TASK.ON_CANCEL_ADD_EDIT: {
			return {
				...state,
				shapeEditID: null,
				mapState: MAP_STATE.READ,
				tmpShape: {}
			}
		}

		case ACTION_TYPES.TASK.ON_ADD_SHAPE: {
			return {
				...state,
				mapState: MAP_STATE.ADD
			}
		}

		case ACTION_TYPES.TASK.ON_FOCUS_SHAPE: {


			return {
				...state,
				focus: ({
					minLat: value.boundingBox.sw.lat,
					minLon: value.boundingBox.sw.lng,

					maxLat: value.boundingBox.ne.lat,
					maxLon: value.boundingBox.ne.lng
				})
			}
		}

		case ACTION_TYPES.TASK.ADD_BOUNDS_TO_TEMP: {
			return {
				...state,
				tmpShape: {
					...state.tmpShape,
					shape: { ...value }
				}
			}
		}

		case ACTION_TYPES.TASK.RESET_TMP: {
			return {
				...state,
				tmpShape: {},
				shapeEditID: null
			}
		}

		case ACTION_TYPES.TASK.SET_MAP_STATE: {
			return {
				...state,
				mapState: value
			}
		}

		// case ACTION_TYPES.TASK.ASYNC_GET_SUBTASKS_SUCCESS: {
		// 	const { taskid, subtasks } = value
		// 	// adding subtasks fetched to the subtaskList 
		// 	// adding withSubtasks boolean to the task object
		// 	return {
		// 		...state,
		// 		asyncStatus: ASYNC_STATUS.SUCCESS,
		// 		tasks: state.tasks.map(t => {
		// 			if (t._id === taskid)
		// 				return { ...t, withSubtasks: true }
		// 			return t
		// 		}),
		// 		subTasksList:
		// 			[
		// 				...subtasks,
		// 				...state.subTasksList
		// 			]
		// 	}
		// }

		case ACTION_TYPES.TASK.ADD_SUBTASK_TO_TASK: {
			const { taskid, subtasks } = value
			// adding subtasks fetched to the subtaskList 
			// adding withSubtasks boolean to the task object
			return {
				...state,
				tasks: state.tasks.map(t => {
					if (t._id === taskid)
						return { ...t, withSubtasks: true }
					return t
				}),
				subTasksList:
					[
						...subtasks,
						...state.subTasksList
					]
			}
		}

		// case ACTION_TYPES.TASK.REMOVE_SUBTASKS_BY_PARENT_ID: {
		// 	return {
		// 		...state,
		// 		subTasksList: state.subTasksList.filter(s => s.painting.parentId !== value.parentId),
		// 		tasks: state.tasks.map(t => {

		// 			if (t._id === value.parentId) {
		// 				return { ...t, withSubtasks: false }
		// 			}
		// 			return t
		// 		}),
		// 	}
		// }

		case ACTION_TYPES.TASK.ASYNC_START: {
			return {
				...state,
				asyncStatus: ASYNC_STATUS.START
			}
		}

		case ACTION_TYPES.TASK.UPDATE_TASKS_LIST: {
			return {
				...state,
				tasks: value.tasks,
				subTasksList: []
			}
		}

		case ACTION_TYPES.TASK.CLEAN_ERROR: {
			return {
				...state,
				errors: null
			}
		}
		case ACTION_TYPES.TASK.RESET_FOCUS: {
			return {
				...state,
				focus: null
			}
		}

		default: {
			return state
		}
	}
}




// case ACTION_TYPES.TASK.ON_DRAW_SHAPE: {
// 	return {
// 		...state,
// 		tmpShape: value
// 	}
// }

// case ACTION_TYPES.TASK.ON_EDIT_SHAPE: {
// 	return {
// 		...state,
// 		mapState: MAP_STATE.EDIT,
// 		shapeEditID: value.id,
// 		tmpShape: state.tasks.filter(s => s._id === value.id)[0]
// 	}
// }

// case ACTION_TYPES.TASK.ON_DONE_ADD_SHAPE: {

// 	return {
// 		...state,

// 	}
// }


// case ACTION_TYPES.TASK.ASYNC_ADD_TASK_SUCCESS: {
// 	return {
// 		...state,
// 		asyncStatus: ASYNC_STATUS.SUCCESS,
// 		tasks: value.tasks
// 	}
// }

// case ACTION_TYPES.TASK.ASYNC_DELETE_TASK_SUCCESS: {
// 	return {
// 		...state,
// 		asyncStatus: ASYNC_STATUS.SUCCESS,
// 		tasks: value.tasks
// 	}
// }

// case ACTION_TYPES.TASK.ASYNC_ERROR: {
// 	// let newErrorArr = state.errors;
// 	// newErrorArr.push(...value)

// 	return {
// 		...state,
// 		// update errors and output array
// 		errors: value,
// 		asyncStatus: ASYNC_STATUS.ERROR
// 	}
// }

