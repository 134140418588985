// import saga helpers
import { put, call, takeLatest, all, spawn, select } from "redux-saga/effects";
import { getInterval } from "./../leaderBoard/selectors";
import { SafeSaga } from "../../common/modules"

// import api functions

// import action type
import { ACTION_TYPES } from "../reduxConsts";



import {

	async_start,
	async_success,
	set_leaderBoard,
	reset_leaderboard

} from "../leaderBoard/leaderBoardAction";

const leaderboardUrls = {
	getLeaderboard: "api/leaderboard"
}



function* fetchLeaderboard(network) {
	// get interval from store
	const interval = yield select(({ leaderBoardStore }) => getInterval(leaderBoardStore))

	// dispatch async start action
	yield put(async_start());

	// const leaderboardResponse = dummy
	const leaderboardResponse = yield call(
		network.sendPost,
		leaderboardUrls.getLeaderboard,

		// interval || timeframe ? depends on the type the user chooses
		interval
	);
	if (!leaderboardResponse) {
		yield put(reset_leaderboard())
	}
	else {
		yield put(set_leaderBoard(leaderboardResponse.data));

		return yield put(async_success());
	}
}



function* fetchCustomTimeSortLeaderboardSaga(network) {
	yield takeLatest(ACTION_TYPES.LEADER_BOARD.SET_CUSTOM_LEADERBOARD_TIME_INTERVAL, SafeSaga(function* ({ value }) {
		yield call(fetchLeaderboard, network)
	}))
}
function* getLeaderboardSaga(network) {
	yield takeLatest(ACTION_TYPES.LEADER_BOARD.GET_LEADERBOARD, SafeSaga(function* ({ value }) {
		yield call(fetchLeaderboard, network)
	}))
}


export function* leaderboardSaga(network) {
	yield all([
		spawn(getLeaderboardSaga, network),
		spawn(fetchCustomTimeSortLeaderboardSaga, network),
	])
}

