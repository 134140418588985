import React from "react"
import { connect } from "react-redux"
import { getApproveCommentBoxValue, getApproverFilter, getRemainingSubtasksToApprove, getSubtaskNameFromState } from "../../redux/approve/selectors"
import WrapWithFetchSpecificSubtask from "./WrapWithFetchSpecificSubtask"
import ApproveStages from "./stages/stages"
import { fetchSubTaskToApprove } from "../../redux/approve/approverActions"
import { getSubtaskNameFromUrlPath } from "../utils/urlSearchStringHandler"
import NoContentComponent from "../hoc/NoContentComponent"
import { ApproverFilter } from "./stages/approverFilter/approverFilter"
import { Badge, Button } from "react-bootstrap"
import moment from "moment"
import "./approver.css"


class ApproveV2 extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isFilterVisible: false
		}
	}


	componentDidMount() {
		const isSubtaskNameExistsInSearchParams = getSubtaskNameFromUrlPath() && getSubtaskNameFromUrlPath() !== ""
		if (!isSubtaskNameExistsInSearchParams) {

			this.getSubtask()
		}
	}

	getSubtask = () => {
		this.props.fetchSubTaskToApproveAction()
	}


	toggleFilterVisibility = () => this.setState(pstate => ({ isFilterVisible: !pstate.isFilterVisible }))
	setFilterHidden = () => this.setState({ isFilterVisible: false })

	generateCurrentFilterValues = (filter) => {
		const { intervalType = "", ...filterData } = filter
		const jsx = []
		for (let key in filterData) {
			if (typeof filterData[key] === "string" && filterData[key].length === 0) {
				continue;
			}
			let value = filterData[key]
			if (key === "dateFrom" || key === "dateTo") {
				value = moment(parseInt(value)).format("DD/MM/YY HH:mm")
			}
			jsx.push(<Badge> {`${key}: ${value}`}</Badge>)
		}
		return jsx
	}


	render() {
		const { subtaskName, remainingTasks, filterData, showLoader } = this.props
		const { isFilterVisible } = this.state

		return <div className="ApproverContainer">
			{!subtaskName && !showLoader && <div style={{ display: "flex", flexDirection: "column" }}>
				<div className="filterWrapper">
					<Button bsSize="xs" bsStyle="success" onClick={this.toggleFilterVisibility}> Filter Options</Button>
					{isFilterVisible && <ApproverFilter hide={this.setFilterHidden} />}
					<div className="filterValuesContainer">
						{filterData && this.generateCurrentFilterValues(filterData)}
					</div>
				</div>
				<NoContentComponent
					title="NO SUBTASKS LEFT TO APPROVE"
					text="no subtask left to approve, good for you!"
					refreshAction={this.getSubtask} />
			</div>
			}
			{subtaskName && <ApproveStages
				subtaskName={subtaskName}
				remainingTasks={remainingTasks}
			/>}
		</div >
	}
}

const mapActionToProps = (dispatch) => ({
	fetchSubTaskToApproveAction: (subtask) => dispatch(fetchSubTaskToApprove(subtask)),
})

const mapStateToProps = (state) => ({
	showLoader: state.loaderStore.showLoader,
	subtaskName: getSubtaskNameFromState(state),
	remainingTasks: getRemainingSubtasksToApprove(state),
	approveCommentBoxValue: getApproveCommentBoxValue(state),
	filterData: getApproverFilter(state),
})

export default connect(mapStateToProps, mapActionToProps)(WrapWithFetchSpecificSubtask(ApproveV2))