export const constHelpers = {
	HEADLINE_COLOR: "#000fff38",
	layersTypes: {
		subtask: "subtask",
		supervisorAnswer: "supervisorAnswer",
		approverAnswer: "approverAnswer",
		painterAnswer: "painterAnswer",
		snapShotWhileSupervise: "snapShotWhileSupervise"
	},
	layersTypeStyle: {
		subtask: {
			fill: "transparent",
			border: "red"
		},
		painterAnswer: {
			fill: "transparent",
			border: "black"
		},
		approverAnswer: {
			fill: "transparent",
			border: "blue"
		},
		supervisorAnswer: {
			fill: "transparent",
			border: "blue"
		},
		snapShotWhileSupervise: {
			fill: "transparent",
			border: "#1cff00"
		}
	},
	previewDisplay: {
		approver: "Approver",
		supervisor: "Supervisor"
	}
}

export const prepareToMapStyle = (layerStyle) => ({ fillColor: layerStyle.fill, strokeColor: layerStyle.border })


export const spreadSupervisorAnswer = (supervisorAnswer) => supervisorAnswer.appliedShapes.map(shape => ({
	...shape,
	layerType: constHelpers.layersTypes.supervisorAnswer,
	id: supervisorAnswer._id,
	visible: supervisorAnswer.visible,
	styles: prepareToMapStyle(supervisorAnswer.style),
	type: "Rectangle"
}))

export const spreadApproverAnswer = (approverAnswer) => approverAnswer.appliedShapes.map(shape => ({
	...shape,
	layerType: constHelpers.layersTypes.approverAnswer,
	id: approverAnswer._id,
	visible: approverAnswer.visible,
	styles: prepareToMapStyle(approverAnswer.style),
	type: "Rectangle"
}))
