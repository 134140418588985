import React from "react";

import { Route, BrowserRouter, Router, Redirect, Switch } from "react-router-dom";

import { connect } from "react-redux";
import includes from "lodash.includes";
import * as Sentry from "@sentry/browser";

import Areas from "../manageAreasPage/Areas";
import Manage from "../manageTasksPage/Manage";
import LeaderBoard from "../leaderBoardPage/container/LeaderBoard";
import PaintManager from "../paintPage/PaintManager";
// import Preview from "../previewPage/PreviewRoute";
import History from "../previewPage/History";
import Report from "../ReportPage/Report";
import Supervise from "../supervisePage/Supervise";
import ErrorBoundaries from "../../components/Errors/ErrorBoundaries/RootWrapper";

import ConfirmModal from "../utils/ConfirmModal";
import ModalContainer from "../modalContainer";

import HeaderContainer from "../HeaderContainer/HeaderContainer";
import NavigationBar from "../NavigationBar/NavigationBarContainer/index";
import Loader from "../LoaderContainer";

import { auth0module, auth0redux, auth0ui, auth0ErrorComponents } from "./../../common/components/Authentication";

import { LocalStorageService } from "../../common/modules";
import { setIsAutoLoadShapesActive } from "../../redux/areas/areasActions";


import { getUserGroups } from "./../../utils/utils"
import ApproveV2 from "../approverPage/approveV2"

const groups = {
	painters: process.env.REACT_APP_AUTH0_PAINTER_GROUP,
	administrators: process.env.REACT_APP_AUTH0_ADMIN_GROUP,
	approvers: process.env.REACT_APP_AUTH0_APPROVERS_GROUP,
	supervisors: process.env.REACT_APP_AUTH0_SUPERVISORS_GROUP,
	report: process.env.REACT_APP_AUTH0_REPORT_GROUP
}


// const isSwagger = window.location.search.split("=").length > 0 && window.location.search.split("=")[1] === "swagger";

// let redirectUri = `${window.location.origin}${isSwagger ? "/areas-blacklist-manager/api-docs" : process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URI}`

// console.log({ isSwagger, redirectUri });
auth0module.init({
	redirectUri: window.location.origin + process.env.REACT_APP_AUTH0_LOGIN_REDIRECT_URI,
	validGroups: groups
})

// init withAuth connected to our redux store to have the user object
let WithAuth = auth0ui.WithAuth(connect)


const routeByGroup = (user) => {

	const mapRouteGroup = {
		administrators: "/areas-manager",
		supervisors: "/supervise",
		painters: "/paint",
		report: "/report",
		approvers: "/approver"
	}

	const userGroups = getUserGroups(user);


	const isAdmin = userGroups.indexOf("administrators") >= 0;
	const isApprover = userGroups.indexOf("approvers") >= 0;
	const isSupervisor = userGroups.indexOf("supervisors") >= 0;
	const isPainter = userGroups.indexOf("painters") >= 0;


	// ADMIN CAN SEE ALL ROUTES
	if (isAdmin)
		return mapRouteGroup.administrators

	// APPROVER CANT SEE areas-manger AND task-manager
	if (isApprover)
		return mapRouteGroup.approvers

	// SUPERVISOR CANT SEE areas-manger AND task-manager
	if (isSupervisor)
		return mapRouteGroup.supervisors

	// PAINTER CANT SEE areas-manger AND task-manager AND approve
	if (isPainter)
		return mapRouteGroup.painters


	return "/"
}

const SuccessComponent = ({ props }) => {
	const { location, user } = props;

	const getRedirectFromQueryString = (location) => new URLSearchParams(location.search.slice(1, location.search.length));

	// check if there is a "redirect" query string
	const urlParams = getRedirectFromQueryString(location);



	if (user)
		Sentry.configureScope(function (scope) {
			scope.setUser({ "email": user.profile.name });
		});

	// first route by group
	let routeTo = routeByGroup(user)

	// then check if redirect flag exists
	if (urlParams.get("redirect"))
		routeTo = urlParams.get("redirect");

	return <Router history={props.history}><Redirect to={routeTo} /></Router>
}

const callbackMapStateToProps = (SuccessComponent, allowedGroups) => ({ authStore }) => {
	return {
		user: authStore.user,
		error: authStore.error,
		SuccessComponent,
		allowedGroups
	};
};

const AccessDeniedErrorMessage = () => <div style={{ textAlignLast: "center", fontSize: "20px" }}>
	<div>
		Eye-Net must be allowed access to the account you created. Please click this <a href="/areas-blacklist-manager/ui">link</a> and then click the green V button.
	</div>
	<div>
		Please note that this only gives Eye-Net access to the Painting Tool account, <b>not</b> to the email with which you registered it.
	</div>
</div>

let Callback = (props) => {
	const { dispatch, user, error, SuccessComponent, allowedGroups } = props


	// AUTH PROCESS ERROR CASE
	if (error) {
		Sentry.captureMessage(`Authentication error : ${JSON.stringify(error)}`);

		if (error.error === "access_denied")
			return AccessDeniedErrorMessage()
		if (error.error === "invalid_token")
			return window.location.replace(`${window.location.origin}/${window.location.pathname.split("/")[1]}/ui`)

		return <div>Error: {JSON.stringify(error)}</div>;
	}

	// if user authenticated
	if (user) {


		Sentry.configureScope(function (scope) {
			scope.setUser({ "email": user.profile.name });
		});
		// EMAIL NOT VERIFIED CASE
		if (!user.accessTokenParsed["https://eyenet-mobile.net/email_verified"])
			return <auth0ErrorComponents.EmailNotVerified email={user.profile.name} />;

		// if the user have groups	
		if (user.accessTokenParsed["https://eyenet-mobile.net/groups"]) {

			// get the user groups
			let _groups = user.accessTokenParsed["https://eyenet-mobile.net/groups"];

			// go over the user groups and see if one of the group include in the allowed groups
			const isUserValidGroup = _groups
				// return if the user groups is in the valid page groups 
				.map(group => includes(allowedGroups, group))
				// if false remove from array
				.filter(bool => bool);

			// if (isUserValidGroup.length < 1)
			if (isUserValidGroup && isUserValidGroup.length < 1)
				return <auth0ErrorComponents.UserNotApproved
					allowedGroups={groups}
					refreshUrl={`${window.location.origin}/${window.location.pathname.split("/")[1]}/ui`} />;

		}

		// if all OK then proceed to the desired component
		return <SuccessComponent props={props} />;
	}

	dispatch(auth0redux.actions.handleAuthenticationCallback());


	return <div className="text-center">Loading user profile . . . </div>;
};

export default function ({ store }) {

	//load data from local storage and store in redux
	// Auto map loading for Areas 
	const isAreasMapAutoLoad = LocalStorageService.get("isAreasMapAutoLoad")
	store.dispatch(setIsAutoLoadShapesActive(isAreasMapAutoLoad))

	return (

		<BrowserRouter basename="/areas-blacklist-manager/ui">
			<ErrorBoundaries >
				{/* main app div */}
				<div style={{ width: "100%", height: "100%" }}>

					{/* header div will always be visible  */}
					<div style={{ height: "12%", boxShadow: "0px 9px 20px 5px #a0a0a0c7" }} >
						<HeaderContainer />
					</div>

					<div className="Navigation" style={{ height: "4%" }} >
						<NavigationBar />

					</div>


					{/* app div, will change according to the path */}
					<div className="App" style={{ position: "relative", width: "100%", height: "84%" }}>
						<Switch>
							{/* Route to handle "/callback" from auth0 */}
							{/* <Route
							exact
							path='/callback'
							component={auth0ui.Callback_auth0(connect, SuccessComponent, groups)} /> */}
							<Route exact path='/callback' component={connect(callbackMapStateToProps(SuccessComponent, groups))(Callback)} />


							<Route
								exact
								path='/'
								component={(routerHelpers) => <WithAuth WrappedComponent={SuccessComponent} allowedRole={groups.painters} {...routerHelpers} />} />



							<Route
								exact
								path='/task-manager'
								component={(routerHelpers) => <WithAuth WrappedComponent={Manage} allowedRole={groups.administrators} {...routerHelpers} />} />

							<Route
								exact
								path='/areas-manager'
								component={(routerHelpers) => <WithAuth WrappedComponent={Areas} allowedRole={groups.administrators} {...routerHelpers} />} />

							<Route
								exact
								path='/paint'
								component={(routerHelpers) => <WithAuth WrappedComponent={PaintManager} allowedRole={groups.painters} {...routerHelpers} />} />

							<Route
								exact
								path='/supervise'
								component={(routerHelpers) => <WithAuth WrappedComponent={Supervise} allowedRole={groups.supervisors} {...routerHelpers} />} />


							<Route
								exact
								path='/approver'
								component={(routerHelpers) => <WithAuth WrappedComponent={ApproveV2} allowedRole={groups.approvers} {...routerHelpers} />} />


							<Route
								exact
								path='/leaderboard' component={(routerHelpers) => <WithAuth WrappedComponent={LeaderBoard} allowedRole={groups.painters} {...routerHelpers} />} />

							<Route
								path='/preview'
								component={(routerHelpers) => <WithAuth WrappedComponent={History} allowedRole={groups.painters} {...routerHelpers} />} />
							<Route
								path='/report'
								component={(routerHelpers) => <WithAuth WrappedComponent={Report} allowedRole={groups.report} {...routerHelpers} />} />

						</Switch>
						<ConfirmModal />

					</div>
					<ModalContainer />
					{/* <SystemNotificationsContainer /> */}
					<Loader />

				</div>
			</ErrorBoundaries >

		</BrowserRouter >
	)



}