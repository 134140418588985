import React from "react";
import { Glyphicon, Tooltip, OverlayTrigger } from "react-bootstrap"
import "./leaderboard.css"
import { sortListByPropDesc } from "../../../utils/utils";
import _ from "lodash"


// each user stats include: urlUserPick, name,email,good,bad,auto,role 
const PaintersLeaderboardTable = ({ stats }) => {
	const displayStanding = (standing = 0) =>
		<div>
			<span style={{ color: standing === 1 ? "green" : "inherit" }} >
				{standing}
			</span>
		</div>



	const displayUserInfo = (user) => <div>
		<div>{user}</div>
	</div>



	const displayUserScore = ({ totalRight, totalWrong, totalScore, totalAnswered }) => {
		return <div className="scoresWrapper" style={{ width: "100%", height: "100%", display: "flex", fontSize: "20px" }} >

			{typeof totalAnswered === "number" && <div className="tableRowSection">
				<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><strong>Total Painted</strong></Tooltip>}>
					{iconScore("menu-hamburger", totalAnswered, "rgb(46, 193, 220)")}
				</OverlayTrigger>

				{typeof totalScore === "number" && <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><strong>Score</strong></Tooltip>}>
					{iconScore("star", Math.round(totalScore), "#ffe700")}
				</OverlayTrigger>}
			</div>}

			<div className="tableRowSection">
				<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><strong>Total Right</strong></Tooltip>}>
					{iconScore("thumbs-up", totalRight, "green")}
				</OverlayTrigger>

				<OverlayTrigger placement="top" overlay={<Tooltip id="tooltip"><strong>Total Wrong</strong></Tooltip>}>
					{iconScore("thumbs-down", totalWrong, "red")}
				</OverlayTrigger>
			</div>
		</div >
	}



	const iconScore = (glyphString, score = 0, color, background) =>
		<div style={{ textAlign: "center", border: "#6f6f6f54 solid 1px", backgroundColor: background ? background : "white" }}>

			<div>
				<Glyphicon style={{ color: color }} glyph={glyphString} />
			</div>

			<div>
				{Number.isNaN(score) ? 0 : score}
			</div>

		</div >



	const userScoreRow = ({ user, totalAnswered, totalRight, totalWrong, place, totalScore, key }) => <div key={key} style={{ display: "flex", border: "2px solid #b7b7b7", padding: "10px", margin: "10px", alignItems: "center", justifyContent: "space-around", backgroundColor: "white" }}  >
		<div id="leaderboard_place" style={{ fontSize: "45px", color: "#afafaf", flexGrow: "0.5", textAlign: "center" }} > {displayStanding(place)}</div>
		<div id="leaderboard_userInfo" style={{ flexGrow: "6", fontSize: "23px" }} >{displayUserInfo(user)}</div>
		<div id="leaderboard_score" style={{ flexGrow: "4" }}  >{displayUserScore({ totalAnswered, totalRight, totalWrong, totalScore })}</div>
	</div >


	return (
		stats.length > 0 ? <div >
			{stats && stats.map((userStats, index) => userScoreRow({ ...userStats, key: index }))}
		</div> : null
	)
}



// sort the stats array before
const sortLeaderBoardComponent = (WrappedComponent) => class extends React.Component {

	sortPaintersLeaderboardrList = (list, sortBy) => {

		switch (sortBy) {
			case "SCORE":
				return sortListByPropDesc(list, "totalScore")
			case "TOTAL_PAINTED":
				return sortListByPropDesc(list, "totalPainted")
			case "TOTAL_RIGHT":
				return sortListByPropDesc(list, "totalRight")
			case "TOTAL_WRONG":
				return sortListByPropDesc(list, "totalWrong")
			default:
				return list
		}
	}


	// remove items with totalAnswered=0
	removePaintersWithoutAnswers = (list) => _.filter(list, item => item.totalAnswered > 0)


	render() {
		const { sortBy } = this.props
		let newProps = { ...this.props, stats: this.sortPaintersLeaderboardrList(this.removePaintersWithoutAnswers(this.props.stats), sortBy) }

		return <WrappedComponent {...newProps} />
	}
}

// export default LeaderBoardTable
export default sortLeaderBoardComponent(PaintersLeaderboardTable)