import "./../superviseStyle.css"
import React, { useState } from "react";
import { GenericTable } from "./../../../common/components";
// import ControlledInputPure from "./../../hoc/ControlledInputPure"
import { Button, Glyphicon, MenuItem, DropdownButton, Label, OverlayTrigger, Popover } from "react-bootstrap"

import { connect } from "react-redux";
import { GtShapeColor } from "./../../genericTableComponents/shapeColor/shapeColor";
import * as superviseActions from "../../../redux/supervise/superviseAction";
import ControlledInputPure from "../../hoc/ControlledInputPure"
import AddMultilevelType from "../../manageAreasPage/components/MultilevelPicker/AddMultilevelType"
import SuperviseAction from "../components/superviseAction"
import "./supsevisorTable.css"
import { ShapeUtils } from "../../../common/sharedGlobal";


const constHelpers = {
	colors: {
		ERROR_COLOR: "#ff00005c",
		INFO_COLOR: "#000fff38",
		BLUE_HEADLINE_COLOR: "#46b8da"
	},
	defaults: {
		DEFAUlT_APPLIED_SHAPES_TYPE: "Types"
	},
	strings: {
		couldNotFetch: "Could not fetch suggestion from Google (try refreshing)",
		zeroResults: "Google suggestion returned with 0 results (try marking in other place)",

		reachedDailyLimit: "We have reached the daily limit (please contact us)",
		googleTooMuchQuery: "Google asking to cool it down a bit",

		unknownErrorAndDenied: "Google returned with error, if this keeps happening please contact us",
		fetchingData: "Fetching area information . . .",
		missingPainterAnswerMark: " * All Painters answers must be marked by Right or Wrong Values",
		missingTagForAppliedShapes: " * All added shapes by the supervisor must be tagged by type",
		missingDescriptionAppliedShapes: " * Some of the applied shapes missing a description",
		aShapeIsInAcitveEditMode: " * Some of the applied shapes are in active edit mode"
	},
	statusValues: {
		ZERO_RESULTS: "ZERO_RESULTS",
		OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
		OVER_DAILY_LIMIT: "OVER_DAILY_LIMIT",
		REQUEST_DENIED: "REQUEST_DENIED",
		UNKNOWN_ERROR: "UNKNOWN_ERROR",
		OK: "OK"
	}
}


const prettyResultStatus = (status) => {
	switch (status) {
		case "has_areas": {
			return "Multi level"
		}
		case "no_areas": {
			return "No multi level"
		}
		case "problem": {
			return "Other"
		}
		default: {
			return status
		}
	}
}



function SupervisorTable(props) {


	const [shouldShowDoneBtnErrorMsg, setShouldShowDoneBtnErrorMsg] = useState(false);


	const [isShapesInRadiusVisible, setIsShapesInRadiusVisible] = useState(true);


	const [visibilityObject, setVisibilityObject] = useState(props.subtask.results.reduce((prevValue, result) => ({
		...prevValue,
		[result._id]: props.subtask.approverSendBack ? false : true
	}), {}));


	const [correctObject, setCorrectObject] = useState(props.subtask.results.reduce((prevValue, result) => ({
		...prevValue,
		[result._id]: null
	}), {}));


	const [colorObject, setColorObject] = useState(props.subtask.results.reduce((prevValue, result) => ({
		...prevValue,
		[result._id]: "inherit"
	}), {}));




	const visibilityCheckboxHandler = ({ _id }) => (value) => {
		props.setUserResultDisplay({ id: _id, isVisible: value.target.checked });

		setVisibilityObject({
			...visibilityObject,
			[_id]: value.target.checked
		})
	}

	const dbSnapshotAreasHandler = (value) => {
		props.setAreasInRadiusDisplay(value.target.checked)
		setIsShapesInRadiusVisible(value.target.checked)
	}
	const approverSendbackShapeVisibilityHandler = (value) => {
		props.setApproverSendbackShapesDisplay(value.target.checked)
	}

	const correctArrHandler = (isCorrect, { _id }) => {
		setCorrectObject({
			...correctObject,
			[_id]: isCorrect
		})


		if (isCorrect)
			props.addCorrectResult(_id)
		else
			props.removeCorrectResult(_id)

	}

	const generateErrorStringForDoneBtnHover = () => {
		const { editShapeID } = props
		const { appliedShapes } = props.supervisorResultObject

		const isNullValueExistInCorrectObject = Object.values(correctObject).indexOf(null) > -1;
		if (isNullValueExistInCorrectObject && !props.isSendBack)
			return constHelpers.strings.missingPainterAnswerMark


		const isAllMultilevelReady = appliedShapes.filter(({ multilevelTypes }) => {
			if (!multilevelTypes.isComplex)
				if (multilevelTypes.topLevel === constHelpers.defaults.DEFAUlT_APPLIED_SHAPES_TYPE || multilevelTypes.bottomLevel === constHelpers.defaults.DEFAUlT_APPLIED_SHAPES_TYPE)
					return true
			return false
		});

		if (isAllMultilevelReady.length > 0) {
			return constHelpers.strings.missingTagForAppliedShapes
		}

		if (editShapeID) {
			return constHelpers.strings.aShapeIsInAcitveEditMode
		}
		let isAtleasteOneAppliedShapeMissingDesctiption = appliedShapes.some(({ autoSuggestions = false }) => {
			if (!autoSuggestions)
				return true
			else if (autoSuggestions.async_status === "START")
				return true
			else if (autoSuggestions.results === null)
				return true
			else if (autoSuggestions.results.length === 0)
				return true
			else return false
		})


		if (isAtleasteOneAppliedShapeMissingDesctiption)
			return constHelpers.strings.missingDescriptionAppliedShapes

	}

	const prettyGoogleResultsArray = (arr) => {
		// autoSuggestions.results[0].formatted_address
		return <div>
			{arr.map((res, i) => {
				return <div key={i}> #{i + 1}: {JSON.stringify(res["formatted_address"], null, 4)}</div>
			})}
		</div>
	}

	const renderHover = (text, title) => <OverlayTrigger trigger={"hover"} placement="bottom" overlay={
		<Popover id="popover-positioned-bottom" bsSize="small" title={title ? title : ""}>
			<pre>{(text)}</pre>
		</Popover>}>
		<Glyphicon glyph="info-sign" />
	</OverlayTrigger>


	const setSupervisorsComment = val => {
		props.addSupervisorComment(val.target.value)
	}


	const appliedShapesRenderer = {

		descriptionRenderer: ({ autoSuggestions, id }) => {

			const centerTextStyle = {
				padding: "10px 0 10px 0",
				textAlignLast: "start"
			}

			const renderRefreshBtn = (idToRefreshFor) => <Button bsSize="xs" onClick={() => props.retryFetchSuggestions({ id: idToRefreshFor })} ><Glyphicon glyph="refresh" /></Button>


			if (!autoSuggestions)
				return null

			if (autoSuggestions.async_status === "START")
				return <div style={{ width: "270px", color: constHelpers.colors.INFO_COLOR, ...centerTextStyle }}>{constHelpers.strings.fetchingData}</div>

			else if (autoSuggestions.async_status === "SUCCESS") {


				if (autoSuggestions.requestStatus === constHelpers.statusValues.OK)
					return <div style={{ width: "270px", ...centerTextStyle }}>{autoSuggestions.results[0].formatted_address} {renderHover(prettyGoogleResultsArray(autoSuggestions.results), "Full google suggestions")}</div>


				else if (autoSuggestions.requestStatus === constHelpers.statusValues.ZERO_RESULTS)
					return <div style={{ width: "270px", backgroundColor: constHelpers.colors.ERROR_COLOR, ...centerTextStyle }}>{constHelpers.strings.zeroResults}</div>


				else if (autoSuggestions.requestStatus === constHelpers.statusValues.OVER_DAILY_LIMIT)
					return <div style={{ width: "270px", backgroundColor: constHelpers.colors.ERROR_COLOR, ...centerTextStyle }}>{constHelpers.strings.reachedDailyLimit}</div>


				else if (autoSuggestions.requestStatus === constHelpers.statusValues.OVER_QUERY_LIMIT)
					return <div style={{ width: "270px", backgroundColor: constHelpers.colors.ERROR_COLOR, ...centerTextStyle }}>{constHelpers.strings.googleTooMuchQuery} {renderRefreshBtn(id)}</div>


				else if (autoSuggestions.requestStatus === constHelpers.statusValues.UNKNOWN_ERROR || autoSuggestions.requestStatus === constHelpers.statusValues.REQUEST_DENIED)
					return <div style={{ width: "270px", backgroundColor: constHelpers.colors.ERROR_COLOR, ...centerTextStyle }}>{constHelpers.strings.unknownErrorAndDenied} {renderRefreshBtn(id)}</div>


				else
					return <div style={{ width: "270px", backgroundColor: constHelpers.colors.ERROR_COLOR, ...centerTextStyle }}>{constHelpers.strings.couldNotFetch} {renderRefreshBtn(id)}</div>

			}
		},

		infoRenderer: ({ id, multilevelTypes }) => {
			const { topLevel, bottomLevel, isComplex } = multilevelTypes
			const isMultilevelPicked = (isComplex || (topLevel !== constHelpers.defaults.DEFAUlT_APPLIED_SHAPES_TYPE && bottomLevel !== constHelpers.defaults.DEFAUlT_APPLIED_SHAPES_TYPE));

			return <div style={{
				backgroundColor: isMultilevelPicked ? "inherit" : constHelpers.colors.ERROR_COLOR,
				padding: "10px",
				borderRadius: "4px"
			}}>
				<AddMultilevelType
					defaultType={constHelpers.defaults.DEFAUlT_APPLIED_SHAPES_TYPE}
					bottom={bottomLevel}
					bottomHandler={(type) => { props.addMultilevelTypesBottomToAppliedShapeById(id, type) }}
					top={topLevel}
					topHandler={(type) => { props.addMultilevelTypesTopToAppliedShapeById(id, type) }}
					isComplex={isComplex}
					complexHandler={(type) => { props.addMultilevelTypesIsComplexToAppliedShapeById(id, type) }}
				/>
			</div>
		},

		actionsRenderer: (appliedShape) => {
			let boundingBox;
			let isMapShapeComplete = true


			switch (appliedShape.shape.type) {
				case "Rectangle": {
					boundingBox = appliedShape.shape.boundingBox
					break
				}
				case "DiagonalRectangle": {
					boundingBox = ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(ShapeUtils.getBoundingBox(appliedShape))

					if (appliedShape.shape.points.length !== 3) { isMapShapeComplete = false }
					break
				}
			}
			return (<div>
				<Button bsSize="small" bsStyle="primary" onClick={() => props.focusMap(boundingBox)}><Glyphicon glyph="map-marker" /></Button>
				&nbsp;
				<Button bsSize="small" bsStyle="danger" onClick={() => props.onDeleteShape(appliedShape.id)} ><Glyphicon glyph="trash" /></Button>
				&nbsp;
				{
					props.editShapeID === appliedShape.id ? <Button
						disabled={!isMapShapeComplete}
						bsSize="small"
						onClick={props.onDoneEdit}> <Glyphicon glyph="ok" /></Button>

						: < Button bsSize="small" bsStyle="warning" onClick={() => props.setEditShapeID({ id: appliedShape.id })}> <Glyphicon glyph="pencil" /></Button >
				}
			</div>)
		}
	}

	const layersRenderer = {
		infoRenderer: ({ status, conflict_text, _id, type, ...params }) => {

			const correctDropDownRenderer = (result) => {
				const isCorrect = correctObject[result._id];
				// const isCorrect = painterResultsMarkedAnswerManager[result._id];

				const rightOptionDisplay = () => <span style={{ color: "green" }}><Glyphicon glyph="ok" /> Right</span>
				const wrongOptionDisplay = () => <span style={{ color: "red" }}><Glyphicon glyph="remove" /> Wrong</span>

				return <div style={
					{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
						width: "100px"
					}
				}>

					<DropdownButton
						pullRight
						bsSize="sm"
						id="correct_dropdown"

						title={
							isCorrect === true ?
								rightOptionDisplay() :
								isCorrect === false ?
									wrongOptionDisplay() :
									"choose answer... "}
					>

						<MenuItem
							disabled
							active={isCorrect === null}
							title="choose answer..." >
							choose answer...
						</MenuItem>

						<MenuItem
							active={isCorrect === true}
							title="Right"
							onClick={() => correctArrHandler(true, result)} >
							{rightOptionDisplay()}
						</MenuItem>

						<MenuItem
							active={isCorrect === false}
							title="Wrong"
							onClick={() => correctArrHandler(false, result)} >
							{wrongOptionDisplay()}
						</MenuItem>
					</DropdownButton>
				</div >
			}



			if (type === "painterResult") {
				if (props.approverSendBack.length > 0) return "-------"
				return <div style={{
					backgroundColor: correctObject[_id] === null ? constHelpers.colors.ERROR_COLOR : "inherit",
					padding: "10px",
					borderRadius: "5px",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					flexDirection: "column",
				}
				}>
					<div className="painter_result_info_div">{prettyResultStatus(status)}</div>
					<div className="painter_result_info_div">{status === "problem" && <Label>{conflict_text}</Label>}</div>
					<div className="painter_result_info_div">{correctDropDownRenderer({ _id })}</div>
				</div >
			}
			else if (type === "inRadiusShape") {
				return <div>Count: {params.length}</div>
			}
			else if (type === "approverSuggestions") {
				return <div>Count: {params.approverSuggestions.length}</div>
			}
			else return null
		},

		colorRenderer: (result) => {
			const areasColor = colorObject[result._id];

			const setColor = ({ id, styles }) => {
				if (result.type === "approverSuggestions") {
					props.setApproverSuggestionsColor({ styles })
					return
				}
				props.setUserResultColor({ id, styles })
				setColorObject({
					...colorObject,
					[result._id]: styles.fillColor
				})
			}


			return <GtShapeColor
				fillStrokeCombo={result.type === "approverSuggestions" ? props.approverSuggestionColorDisplayHandler :
					result.type === "inRadiusShape" ? { fillColor: "transparent", strokeColor: "green" } : { fillColor: areasColor, strokeColor: "black" }}
				onColorSelect={(fill) => setColor({ id: result._id, styles: { fillColor: fill } })}
				isDisabled={result.type !== "approverSuggestions" && (result.status !== "has_areas" || result.type === "inRadiusShape")} />


		},

		layerRenderer: ({ userCreated, type }) => {
			return type === "painterResult" ? <div>
				<div>Painter</div>
				<div>{userCreated.userEmail}</div>
			</div> :
				type === "inRadiusShape" ? <div>Existing Areas</div> :
					type === "approverSuggestions" ? <div>Approver’s suggestions</div> : null
		},

		visibilityRenderer: (result) => {
			if (result.status === "has_areas")
				return <ControlledInputPure
					required={false}
					height={"20px"}
					width={"20px"}
					type="checkbox"
					checked={visibilityObject[result._id]}
					onChangeValue={visibilityCheckboxHandler(result)}
					controlledField="Display" />
			else if (result.type === "inRadiusShape") {
				return <ControlledInputPure
					required={false}
					height={"20px"}
					width={"20px"}
					type="checkbox"
					checked={isShapesInRadiusVisible}
					onChangeValue={dbSnapshotAreasHandler}
					controlledField="Display" />
			}
			else if (result.type === "approverSuggestions") {
				return <ControlledInputPure
					required={false}
					height={"20px"}
					width={"20px"}
					type="checkbox"
					checked={props.displayApproverSendBackShapes}
					onChangeValue={approverSendbackShapeVisibilityHandler}
					controlledField="Display" />
			}

			else return <Glyphicon glyph="minus-sign" />
		},
	}


	const onClearAllClicked = () => {
		props.removeAllAppliedShapes()
		props.onDoneEdit()
	}



	const doneButtonHoverHandler = {
		mouseEnter: () => {
			setShouldShowDoneBtnErrorMsg(true)
		},
		mouseLeave: () => {
			setShouldShowDoneBtnErrorMsg(false)
		},
		isDoneButtonDisabled: () => {

			const { editShapeID } = props;
			const { appliedShapes } = props.supervisorResultObject;

			const isNullValueExistInCorrectObject = props.approverSendBack.length > 0 ? false : Object.values(correctObject).indexOf(null) > -1;

			const isAllMultilevelReady = appliedShapes.filter(({ multilevelTypes }) => {
				if (!multilevelTypes.isComplex)
					if (multilevelTypes.topLevel === constHelpers.defaults.DEFAUlT_APPLIED_SHAPES_TYPE || multilevelTypes.bottomLevel === constHelpers.defaults.DEFAUlT_APPLIED_SHAPES_TYPE)
						return true
				return false
			});


			let isAtleasteOneAppliedShapeMissingDesctiption = appliedShapes.some(({ autoSuggestions = false }) => {
				if (!autoSuggestions)
					return true
				else if (autoSuggestions.async_status === "START")
					return true
				else if (autoSuggestions.results === null)
					return true
				else if (autoSuggestions.results.length === 0)
					return true
				else return false
			})


			return isAllMultilevelReady.length > 0 || isNullValueExistInCorrectObject || isAtleasteOneAppliedShapeMissingDesctiption || editShapeID
		}
	}



	// always add results array to table data
	const tableData = [...props.subtask.results, ...props.approverSendBack];
	// console.log({ tableData })
	// add dbSnapshot if exist
	if (props.shapesInSubtaskRadius.length > 0) tableData.push(props.shapesInSubtaskRadius);

	return (
		<div className="supervisorTableContainer">
			<div className="tableWrapper">
				<div className="tableTitle">Layers</div>

				<GenericTable
					data={tableData}
					mapColumnToRenderer={[{
						column: { title: "Visibility" },
						cellRenderer: layersRenderer.visibilityRenderer
					},
					{
						column: { title: "Layer" },
						cellRenderer: layersRenderer.layerRenderer
					},
					{
						column: { title: "Info" },
						cellRenderer: layersRenderer.infoRenderer
					},
					{
						column: { title: "Color" },
						cellRenderer: layersRenderer.colorRenderer
					}]}
				>
				</GenericTable>
			</div>

			{props.supervisorResultObject.appliedShapes.length > 0 &&
				<div className="tableWrapper">
					<div className="tableTitle">Supervisor's Answer</div>
					<Button bsSize="xs" bsStyle="danger" style={{ marginBottom: "10px", float: "left" }} onClick={onClearAllClicked} >Clear all</Button>

					<GenericTable
						data={[...props.supervisorResultObject.appliedShapes]}
						mapColumnToRenderer={[{
							column: { title: "Description" },
							cellRenderer: appliedShapesRenderer.descriptionRenderer
						},
						{
							column: { title: "Info" },
							cellRenderer: appliedShapesRenderer.infoRenderer
						},
						{
							column: { title: "Actions" },
							cellRenderer: appliedShapesRenderer.actionsRenderer
						}]}
					></GenericTable>
				</div>}


			<div style={{
				display: "flex",
				flexDirection: "column",
				width: "100%",
				textAlignLast: "start"
			}} >
				<span>Supervisor's comment: </span>
				<textarea onChange={setSupervisorsComment} value={props.supervisorResultObject.comment} />
			</div>




			<div style={{ height: "15px", color: "red" }} >
				<b>{shouldShowDoneBtnErrorMsg && <div >{generateErrorStringForDoneBtnHover()}</div>}</b>
			</div>

			<SuperviseAction
				style={{ marginTop: "20px" }}
				isSendBack={props.isSendBack}
				onDone={props.postSupervisedSubtask}
				isRequestApprove={props.isRequestApprove}
				onDoneMouseEnter={doneButtonHoverHandler.mouseEnter}
				onDoneMouseLeave={doneButtonHoverHandler.mouseLeave}
				onDoneDisable={doneButtonHoverHandler.isDoneButtonDisabled()}
				onSetIsRequestApproveChanged={props.setIsRequestApprove}
				onFocus={props.onFocus}
				onDoneEdit={props.onDoneEdit}

			/>
		</div>
	);
}



let mapStateToProps = ({ supervisorStore }) => ({
	subtask: {
		...supervisorStore.subtask,
		results:
			supervisorStore.subtask && supervisorStore.subtask.results ? supervisorStore.subtask.results.map(res => ({ ...res, type: "painterResult" })) : []
	},
	approverSendBack: supervisorStore.subtask && supervisorStore.subtask.approverSendBack ? [{ ...supervisorStore.subtask.approverSendBack, _id: supervisorStore.subtask.approverSendBack.previousSubTaskId, type: "approverSuggestions" }] : [],
	supervisorResultObject: supervisorStore.supervisorResultObject,
	resultsColorDisplayHandler: supervisorStore.resultsColorDisplayHandler,
	clickShapeID: supervisorStore.clickShapeID,
	editShapeID: supervisorStore.editShapeID,
	shapesInSubtaskRadius: { length: supervisorStore.shapesInSubtaskRadius.length, data: supervisorStore.shapesInSubtaskRadius, type: "inRadiusShape" },
	displayShapesInSubtaskRadius: supervisorStore.displayShapesInSubtaskRadius,
	displayApproverSendBackShapes: supervisorStore.displayApproverSendBackShapes,
	approverSuggestionColorDisplayHandler: supervisorStore.approverSuggestionColorDisplayHandler,
	isSendBack: supervisorStore.isSendBack,
})

let mapDispatchToProps = ({
	retryFetchSuggestions: superviseActions.retryFetchSuggestions,
	setUserResultColor: superviseActions.setUserResultColor,
	setUserResultDisplay: superviseActions.setUserResultDisplay,
	addCorrectResult: superviseActions.addCorrectResult,
	addSupervisorComment: superviseActions.addSupervisorComment,
	setIsRequestApprove: superviseActions.setIsRequestApprove,
	// addAShapesPrefix: superviseActions.addAShapesPrefix,
	removeCorrectResult: superviseActions.removeCorrectResult,
	removeAllAppliedShapes: superviseActions.removeAllAppliedShapes,
	postSupervisedSubtask: superviseActions.postSupervisedSubtaskV2,
	// postSupervisedSubtask: superviseActions.postSupervisedSubtask,
	onFocus: superviseActions.onFocus,
	focusMap: superviseActions.focusMap,
	setEditShapeID: superviseActions.setEditShapeID,
	onDoneEdit: superviseActions.onDoneEdit,
	onDeleteShape: superviseActions.onDeleteShape,
	setAreasInRadiusDisplay: superviseActions.setAreasInRadiusDisplay,
	setApproverSendbackShapesDisplay: superviseActions.setApproverSendbackShapesDisplay,
	addMultilevelTypesBottomToAppliedShapeById: superviseActions.addMultilevelTypesBottomToAppliedShapeById,
	addMultilevelTypesIsComplexToAppliedShapeById: superviseActions.addMultilevelTypesIsComplexToAppliedShapeById,
	addMultilevelTypesTopToAppliedShapeById: superviseActions.addMultilevelTypesTopToAppliedShapeById,
	setApproverSuggestionsColor: superviseActions.setApproverSuggestionsColor,
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(SupervisorTable);
