
const { REPORT } = require("../reduxConsts").ACTION_TYPES









/* * * * * * * * * * * * * * * * * async actions * * * * * * * * * * * * * * */
/**@param {String} req async start function*/
export const async_start = () => ({ type: REPORT.ASYNC_START })

/**@param {Object} data async success function */
export const async_success = () => ({ type: REPORT.ASYNC_SUCCESS })

/**@param {String} error async error function */
export const async_error = error => ({ type: REPORT.ASYNC_ERROR, value: error })
/* * * * * * * * * * * * * * * * * async actions * * * * * * * * * * * * * * */


/* * * * * * * * * * * * * * * * *SAGA ACTIONS* * * * * * * * * * * * * * */

// TRIGGER FETCH SUBTASK SAGA
export const fetchReport = (startTime, endTime) => ({ type: REPORT.FETCH_REPORT, value: { startTime, endTime } })
export const setReportSortByInterval = (intervaltype) => ({ type: REPORT.SET_REPORT_INTERVAL_TYPE, value: intervaltype })
export const setReportCustomInterval = (from, to) => ({ type: REPORT.SET_REPORT_CUSTOM_INTERVAL, value: { from, to } })
export const setReportSortBy = (sortBy) => ({ type: REPORT.SET_REPORT_SORT_BY, value: sortBy })



export const setReportResponse = ({ report, sentBackWaiting, waitingReapprove }) => ({ type: REPORT.SET_REPORT_RESPONSE, value: { report, sentBackWaiting, waitingReapprove } })
export const clearReport = () => ({ type: REPORT.CLEAR_REPORT_RESPONSE })

