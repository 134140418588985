
const { ACTION_TYPES, ASYNC_STATUS } = require("../reduxConsts")

// initial state for tasks store
const paint_initial = {
	overlayFocus: null,
	async_status: null, // for async calls
	subtask_id: null, // to know on what the user working on
	fetched_sub_task: null,
	areas: [], // the user results (answers)
	tmpShape: {}, // tmo shape NOT SURE
	errors: null, // error for the async or any other
	user_pick: null, //
	conflictText: null,//
	answerObject: {
		subtask_id: null,
		status: undefined,
		areas: [],
		conflict_text: null
	},
	shapeClickedId: null,
	editShapeID: null
}


export function paintStore(state = paint_initial, action) {
	const { type, value } = action;

	switch (type) {

		/******************* ASYNC ACTIONS ******************* */
		case ACTION_TYPES.PAINT.ASYNC_START: {
			return {
				...state,
				async_status: ASYNC_STATUS.START
			}
		}

		case ACTION_TYPES.PAINT.ASYNC_SUCCESS: {
			return {
				...state,
				async_status: ASYNC_STATUS.SUCCESS

			}

		}

		case ACTION_TYPES.PAINT.ASYNC_ERROR: {
			return {
				...state,
				async_status: ASYNC_STATUS.ERROR,
				errors: value
			}

		}
		/******************************************** */

		/**************** SAGA ACTIONS **************** */
		case ACTION_TYPES.PAINT.RESET_STORE: {
			return paint_initial

		}
		case ACTION_TYPES.PAINT.LOAD_SUB_TASK_FOR_ANSWER: {
			return {
				...state,
				fetched_sub_task: value.painting,
				answerObject: {
					subtask_id: value._id,
					status: undefined,
					areas: [],
					conflict_text: null
				}
			}

		}

		case ACTION_TYPES.PAINT.CLEAN_ERROR: {
			return {
				...state,
				errors: null
			}
		}
		case ACTION_TYPES.PAINT.SHAPE_CLICKED: {
			return {
				...state,
				shapeClickedId: value.id
			}
		}
		case ACTION_TYPES.PAINT.ON_EDIT_SHAPE: {

			return {
				...state,
				editShapeID: value.id
			}
		}
		// case ACTION_TYPES.PAINT.RESET_EDIT_SHAPE_ID: {

		// 	return {
		// 		...state,
		// 		editShapeID: null
		// 	}
		// }

		case ACTION_TYPES.PAINT.CLEAN_SUBTASK: {
			return {
				...state,
				areas: [],
				tmpShape: {},
				subtask_id: null,
				fetched_sub_task: null,
				errors: null,
				user_pick: null,
				conflictText: null
			}
		}
		/************************************* */

		/*******ANSWER OBJECT CASE *********/

		case ACTION_TYPES.PAINT.ADD_ANSWER_AREA: {

			let newAreasArray = state.answerObject.areas;

			newAreasArray.push(value);

			return {
				...state,
				answerObject: {
					...state.answerObject,
					areas: newAreasArray

				}
			}
		}

		case ACTION_TYPES.PAINT.SET_ANSWER_CONFLICT_TEXT: {
			return {
				...state,
				answerObject: {
					...state.answerObject,
					conflict_text: value

				}
			}
		}

		case ACTION_TYPES.PAINT.SET_ANSWER_STATUS: {

			return {
				...state,
				answerObject: {
					...state.answerObject,
					conflict_text: value !== "problem" ? null : state.answerObject.conflict_text,
					areas: [],
					status: value
				},
				editShapeID: null
			}
		}

		case ACTION_TYPES.PAINT.SET_ERROR: {
			return {
				...state,
				errors: [value]
			}
		}

		case ACTION_TYPES.PAINT.CLEAN_ANSWER: {
			return {
				...state,
				answerObject: {
					subtask_id: null,
					status: null,
					areas: [],
					conflict_text: null
				}
			}
		}

		case ACTION_TYPES.PAINT.REMOVE_ANSWER_AREAS: {
			return {
				...state,
				answerObject: {
					...state.answerObject,
					areas: []
				},
				editShapeID: null
			}

		}

		case ACTION_TYPES.PAINT.MAP_FOCUS: {
			return {
				...state,
				overlayFocus: value
			}
		}
		case ACTION_TYPES.PAINT.CLEAR_FOCUS: {
			return {
				...state,
				overlayFocus: null
			}
		}


		case ACTION_TYPES.PAINT.REPLACE_ANSWER_OBJECT_AREAS_ARRAY: {

			// to determinate the new id to avoid duplication
			let currentId = value.reduce((prev, current) => current.id > prev ? current.id : prev, -1)




			let newAreasArr = value
				.map((s) => ({
					...s,
					id: s.id || s.id === 0 ?
						s.id :
						currentId += 1
				}))

			return {
				...state,
				answerObject: {
					...state.answerObject,
					areas: newAreasArr
				}
			}
		}
		case ACTION_TYPES.PAINT.ON_DONE_EDIT: {

			return {
				...state,
				editShapeID: null
			}
		}
		case ACTION_TYPES.PAINT.ON_DELETE_SHAPE: {

			let { areas } = state.answerObject;

			let updatedAreas = areas.filter(({ id }) => id !== value)

			return {
				...state,
				answerObject: {
					...state.answerObject,
					areas: updatedAreas
				}
			}
		}


		default: {
			return state
		}
	}
}
