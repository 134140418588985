
import React, { useState } from "react";
import DateTimePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";


export default ({ title = "Pick a date",
	withTime = false,
	returnedFormat = "dd/MM/yyyy HH:mm",
	withFutureDates = true,
	timeInterval = 30,
	onChange }) => {

	const [currentDate, setCurrentDate] = useState(new Date());

	const onChangeHandler = (value) => {
		// fire the on change
		onChange(value)
		// update state
		setCurrentDate(value)
	}

	return (
		<div>
			<DateTimePicker
				title={title}
				maxDate={withFutureDates ? false : new Date()}
				selected={currentDate}
				onChange={date => onChangeHandler(date)}
				showTimeSelect={withTime}
				timeFormat={withTime ? "HH:mm" : false}
				timeIntervals={timeInterval}
				dateFormat={returnedFormat}
			/>
		</div >
	)

}


