
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import NavigationBarButton from "./../NavigationBarButton";

import "./NavigationBar.css";



class NavigationBarComponent extends React.Component {



	changeLocation = (nextLocation) => {
		const { history } = this.props;
		history.push("/" + nextLocation);
	}


	generateButton = ({ route, pretty }, i) => {
		const { currentLocation } = this.props;
		return <NavigationBarButton
			key={i}
			label={pretty}
			isSelected={route === currentLocation}
			onClick={e => this.changeLocation(route)} />
	}

	render() {
		const { availableRoutes } = this.props;
		return (
			<div className="NavigationBar" >
				<div className="navigationBtnsWrapper">
					{availableRoutes.map(this.generateButton)}
				</div>
			</div>
		)
	}
}


NavigationBarComponent.propTypes = {
	currentLocation: PropTypes.string,
	availableRoutes: PropTypes.arrayOf(
		PropTypes.shape(
			{ route: PropTypes.string, pretty: PropTypes.string }))

}

export default withRouter(NavigationBarComponent)
