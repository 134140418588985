// import saga helpers
import { call, all, spawn } from "redux-saga/effects";
import { approverSaga } from "./approverSaga";



const { paintSaga } = require("./paintSaga");
const { tasksSaga } = require("./tasksSaga");
const { superviseSaga } = require("./superviseSaga");
const { areasSaga } = require("./areasSaga");
const { leaderboardSaga } = require("./leaderBoardSaga");
const { previewSaga } = require("./previewSaga");
const { devSaga } = require("./devSaga");
const { reportSaga } = require("./reportSaga");


const { auth0redux } = require("./../../common/components/Authentication");

let sagasArray = auth0redux.saga.attachSaga([
	areasSaga,
	tasksSaga,
	paintSaga,
	superviseSaga,
	approverSaga,
	previewSaga,
	leaderboardSaga,
	reportSaga,
	devSaga

])



// let sagasArray = [
// 	areasSaga(),
// 	tasksSaga(),
// 	approveSaga(),
// 	paintSaga(),
// 	leaderboardSaga(),
// 	previewSaga()
// ];

// attach our auth0 redux saga
// sagasArray = auth0redux.saga.attachSaga(sagasArray);


// export default function* rootSaga() {
// 	yield all(sagasArray)
// }


export const rootSaga = function* (network) {
	yield all(sagasArray.map(saga =>
		spawn(function* () {
			let whileBool = true;
			while (whileBool) {
				try {
					yield call(saga, network)
					break
				} catch (e) {

					console.log("Root saga exception", e)
					whileBool = false
				}
			}
		}))
	)
}

