
const { ACTION_TYPES, TIME_INTERVALS, REPORT_SORT_BY } = require("../reduxConsts")

// initial state for tasks store
const report_initial = {
	report: [],
	sentBackWaiting: [],
	waitingReapprove: [],
	interval: {
		type: "interval",
		data: {
			interval: TIME_INTERVALS.TODAY.system
		}
	},
	sortBy: REPORT_SORT_BY.PAINT_COUNT
}


export function reportStore(state = report_initial, action) {
	const { type, value } = action;

	switch (type) {

		case ACTION_TYPES.REPORT.SET_REPORT_RESPONSE: {
			return {
				...state,
				report: value.report,
				sentBackWaiting: value.sentBackWaiting,
				waitingReapprove: value.waitingReapprove,
			}
		}
		case ACTION_TYPES.REPORT.SET_REPORT_SORT_BY: {
			return {
				...state,
				sortBy: value
			}
		}
		case ACTION_TYPES.REPORT.SET_REPORT_INTERVAL_TYPE: {
			return {
				...state,
				interval: {
					type: value === TIME_INTERVALS.CUSTOM.system ? "custom" : "interval",
					data: {
						interval: value
					}
				}
			}
		}
		case ACTION_TYPES.REPORT.SET_REPORT_CUSTOM_INTERVAL: {
			const { from, to } = value
			return {
				...state,
				interval: {
					type: TIME_INTERVALS.CUSTOM.system,
					data: {
						from,
						to
					}
				}
			}
		}

		case ACTION_TYPES.REPORT.CLEAR_REPORT_RESPONSE: {
			return {
				...state,
				report: [],
				sentBackWaiting: [],
				waitingReapprove: [],
			}
		}

		default: {
			return state
		}
	}
}
