import axios from "axios";
import * as Sentry from "@sentry/browser";


const validateClientVersionWithServer = () => {


	if (process.env.NODE_ENV !== "development")
		axios.get("/areas-blacklist-manager/api/health")
			.then(function (response) {

				const runningClientVersion = process.env.REACT_APP_VERSION;
				const clientVersionFromServer = response.data.versioning.revision;
				if (runningClientVersion !== clientVersionFromServer) {
					alert("Your client version is not up to date, please preform 'hard refresh' (open dev tools for revision details)")
					console.error({ error: "Your client version is not up to date", versionFromClient: runningClientVersion, versionFromServer: clientVersionFromServer });
					Sentry.captureException("client version is out of date")
				}
			})
			.catch(function (error) {
				console.error("error while comparing revision hashes: " + error);
			})

	else console.log("version check is disabled")
}
validateClientVersionWithServer()