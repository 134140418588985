import React, { useState } from "react";
import { Map } from "./../../../common/components";
import { constHelpers } from "./utils";
import isequal from "lodash.isequal";
import { ShapeUtils } from "../../../common/sharedGlobal";


export default ({ layersArr, previewDisplayString, focusShape = null }) => {

	const [currentCenter, updateCurrentCenter] = useState(null)


	const prepareToMapStyle = (layerStyle) => ({ fillColor: layerStyle.fill, strokeColor: layerStyle.border })



	const filterByPreviewDisplay = (layer) => {
		if (previewDisplayString === constHelpers.previewDisplay.approver && layer.layerType === constHelpers.layersTypes.supervisorAnswer)
			return false
		else if (previewDisplayString === constHelpers.previewDisplay.supervisor && layer.layerType === constHelpers.layersTypes.approverAnswer)
			return false

		else return true

	}

	const spreadLayerShapes = (layer) => {
		if (layer.layerType === constHelpers.layersTypes.painterAnswer)
			return spreadPaintersResults(layer)
		else if (layer.layerType === constHelpers.layersTypes.snapShotWhileSupervise)
			return spreadDbSnapshot(layer)
		else if (layer.layerType === constHelpers.layersTypes.supervisorAnswer)
			return spreadSupervisorAnswer(layer)
		else if (layer.layerType === constHelpers.layersTypes.subtask)
			return prepareSubtask(layer)
		else if (layer.layerType === constHelpers.layersTypes.approverAnswer)
			return spreadApproverAnswer(layer)
		else return layer
	}

	const spreadPaintersResults = ({ areas, ...painterResult }) => areas.map(area => {
		return {
			...painterResult,
			shape: area,
			id: painterResult._id,
			styles: prepareToMapStyle(painterResult.style),
			visible: painterResult.visible
		}
	})

	const spreadDbSnapshot = (dbSnapShot) => dbSnapShot.shapes.map(shape => {
		return {
			...shape,
			shape: shape.shape,
			layerType: constHelpers.layersTypes.snapShotWhileSupervise,
			id: dbSnapShot._id,
			visible: dbSnapShot.visible,
			styles: prepareToMapStyle(dbSnapShot.style),
		}
	})

	const spreadSupervisorAnswer = (supervisorAnswer) => supervisorAnswer.appliedShapes.map(shape => {
		return {
			...shape,
			shape: shape.shape,
			layerType: constHelpers.layersTypes.supervisorAnswer,
			id: supervisorAnswer._id,
			visible: supervisorAnswer.visible,
			styles: prepareToMapStyle(supervisorAnswer.style),
		}
	})

	const spreadApproverAnswer = (approverAnswer) => approverAnswer.appliedShapes.map(shape => {
		return {
			...shape,
			layerType: constHelpers.layersTypes.approverAnswer,
			id: approverAnswer._id,
			visible: approverAnswer.visible,
			styles: prepareToMapStyle(approverAnswer.style),
		}
	})

	const prepareSubtask = (subtask) => {
		return {
			...subtask,
			styles: prepareToMapStyle(subtask.style),
			type: "Rectangle"
		}
	}


	const filterLayersWithoutAppliedShapes = (layer) => {
		// return true if the layers have applied shapes
		if ([constHelpers.layersTypes.supervisorAnswer, constHelpers.layersTypes.approverAnswer].indexOf(layer.layerType) > -1)
			if (Array.isArray(layer.appliedShapes))
				return true
			else return false
		else return true
	}


	const generateShapesForMap = (_layersArr) => _layersArr
		.filter(filterLayersWithoutAppliedShapes)
		.map(spreadLayerShapes)
		.flat()
		.filter(layer => layer.visible)
		.filter(filterByPreviewDisplay)
		.map(layer => ({ ...layer.shape, styles: layer.styles }))




	const generateDefaultCenter = (_layersArr) => {

		let allLayersArr = _layersArr
			.filter(filterLayersWithoutAppliedShapes)
			.map(spreadLayerShapes)
			.flat()
			.map(layer => {
				// if a shape is a diagonal Rectangle type - generate a regular bb for it
				switch (layer.shape.type) {
					case "Rectangle": {
						return layer.shape.boundingBox

					}
					case "DiagonalRectangle": {
						return ShapeUtils.POLYGON.convertPointsToBoundingBox(layer.shape.points)
					}
				}
			})

		return ShapeUtils.RECTANGLE.biggestBoundingBox(allLayersArr)
	}




	if (currentCenter === null)
		updateCurrentCenter(ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(generateDefaultCenter(layersArr)))
	if (focusShape !== null && !isequal(ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(focusShape), currentCenter))
		updateCurrentCenter(ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(focusShape))


	const defaultCenter = generateDefaultCenter(layersArr)
	return <div style={{ width: "100%", height: "100%", margin: "0 auto" }}>
		<Map
			drawer={{
				isVisible: false,
				position: 2,
				drawingModes: [null],
			}}
			defaultCenter={defaultCenter}
			center={currentCenter}
			listToRender={generateShapesForMap(layersArr)}
		/></div >
}

