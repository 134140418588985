// react
import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExport, faTrashAlt, faListUl, faCheck, faTimes, faCog } from "@fortawesome/free-solid-svg-icons"
import findIndex from "lodash.findindex";

// redux helpers
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// redux actions
import {
	cancelAddEdit,
	onFocusShape,
	onAddShape,
	onEditDraw,
	sagaActions,
	exportShapes,
	resetFocus,
	loadShapesThatFitMapBounds
} from "../../../redux/shapes/shapeActions";
import { modalActions } from "../../../redux/modal/actions";

import { showDialog, closeDialog } from "../../../redux/dialog/dialogActions";

// import searchList from "./../../hoc/searchList";
import AddEditComponent from "./../components/AddEditComponent";
import { ListV2 } from "./../../../common/components";
import ListItem from "./ListItem/ListItem";

// map consts
import { MAP_STATE } from "./../../../redux/reduxConsts";
import AreasSettingsModal from "../components/areasSettingsModal/areasSettingsModal";
import { setIsAutoLoadShapesActive } from "../../../redux/areas/areasActions";
import { ShapeUtils } from "../../../common/sharedGlobal";


class ListContainer extends Component {
	constructor(props) {
		super(props)
		this.state = {
			multiPleDeleteOn: false,
			multiPleDeleteSelectionArray: [],
			listItemSize: 120,
			scrollTo: undefined,
			env: window.location.host.split(".")[0]
		}

	}

	componentDidMount() {
		window.addEventListener("resize", this.changeListItemHeight);
	}


	componentWillUnmount() {
		window.removeEventListener("resize", this.changeListItemHeight);
	}


	componentWillMount() {
		this.setState({ listItemSize: this.getItemSizeFromInnerWidth(window.innerWidth) })

	}


	componentDidUpdate(prevProps) {
		const { clickedShapeID: current_clickedShapeID, shapeList } = this.props;

		const { clickedShapeID: prev_clickedShapeID } = prevProps;
		if (current_clickedShapeID !== prev_clickedShapeID) {
			const index = findIndex(shapeList, ["_id", current_clickedShapeID]);
			this.setState({ scrollTo: index })
		}
	}


	getItemSizeFromInnerWidth(currentWidth) {
		let newSize = 170
		if (currentWidth > 1470)
			newSize = 120
		if (currentWidth < 1470 && currentWidth > 1000)
			newSize = 175
		if (currentWidth < 1200)
			newSize = 230
		return newSize
	}

	changeListItemHeight = (e) => {
		const currentWidth = e.target.innerWidth;
		let newSize = this.getItemSizeFromInnerWidth(currentWidth)
		this.setState({ listItemSize: newSize })
	}


	onDoneAddShape = () => {
		const { shape, description } = this.props.addEditShape;

		// Test if the user drew a diagonal rectangle with 3 points and that the shape has a description
		if ((shape.points && shape.points.length === 3) && description) {
			// send the new shape to redux to handle
			this.props.onDoneAddShape(this.props.addEditShape);
		}
		else {
			// the tmp shape does not have bounding box meaning the user did not draw a shape in the map
			alert("did you forgot to draw a shape or add require info ??")

		}
	}


	onDeleteArea = ({ id, item }) => {
		const { description } = item

		const boundingBoxForFocus = ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(ShapeUtils.getBoundingBox(item))
		this.props.onFocusShape(id, boundingBoxForFocus)

		this.props.showDialog(
			{
				level: "info",
				title: "Delete Area",
				txt: `Are you sure you want to delete Area: "${description}"`,
				confirm: [this.props.onDeleteShape, id],
				cancel: this.props.closeDialog
			})



	}


	multipleDelete = (bool) => {
		// that means the user finished using  the "multi delete" 
		if (bool === false) {

			const { multiPleDeleteSelectionArray } = this.state;

			const deletionRunner = (array) => {
				this.props.sendMultipleDelete(array);
				// call the multi delete api
				this.setState({ multiPleDeleteOn: bool, multiPleDeleteSelectionArray: [] })
			}

			if (multiPleDeleteSelectionArray.length > 0)
				this.props.showDialog(
					{
						level: "info",
						title: "Delete Area",
						txt: `You are about to delete ${multiPleDeleteSelectionArray.length} areas (all areas), ARE YOU SURE?`,
						confirm: [deletionRunner, multiPleDeleteSelectionArray],
						cancel: this.props.closeDialog
					})
			else
				this.setState({ multiPleDeleteOn: bool, multiPleDeleteSelectionArray: [] })



		}
		else this.setState({ multiPleDeleteOn: bool })

	}



	multipleDeleteSelectAll = () => {
		const { multiPleDeleteSelectionArray } = this.state;
		const { shapeList } = this.props;

		// disable delete all in prod!!
		const isProdEnv = window.location.host === "prod.eyenet-mobile.net"
		if (!isProdEnv) {
			if (multiPleDeleteSelectionArray.length === shapeList.length)
				this.setState({ multiPleDeleteSelectionArray: [] })

			else
				this.setState({ multiPleDeleteSelectionArray: shapeList.map(s => s._id) })
		}
		else this.props.showDialog({ level: "info", txt: "YOU ARE TRYING TO DELETE ALL AREAS ON PROD!", title: "CANT DO THAT, SORRY" })
	}



	onMultipleDeleteCanceled = () => {
		this.setState({ multiPleDeleteOn: false, multiPleDeleteSelectionArray: [] })
	}

	onMultipleDeleteAdded = (id, bool) => {
		const { multiPleDeleteSelectionArray } = this.state;
		const newArray = multiPleDeleteSelectionArray;
		// if bool if true then preform insert
		if (bool)
			newArray.push(id)
		// if bool if false then preform delete
		else
			newArray.splice(newArray.indexOf(id), 1)

		this.setState({ multiPleDeleteSelectionArray: newArray })
	}


	listItemRender = (item, i) => {
		const { clickedShapeID } = this.props;
		const { multiPleDeleteOn, multiPleDeleteSelectionArray } = this.state;
		return <ListItem
			{...item}
			item={item}
			key={i}
			addEditShape={this.props.onDoneAddShape}
			setShapeActive={this.props.setShapeActive}
			onEdit={this.props.onEditDraw}
			onDelete={this.onDeleteArea}
			onFocus={this.props.onFocusShape}
			active={item._id === clickedShapeID}
			multiPleDeleteOn={multiPleDeleteOn}
			isMultipleDeleteSelected={multiPleDeleteSelectionArray.indexOf(item._id) > -1}
			multiPleDeleteChecked={this.onMultipleDeleteAdded}
		/>
	}



	listItemRenderNew = (multiPleDeleteOn, multiPleDeleteSelectionArray) => (filterItemBySearchTerm) => ({ index, key, style }) => {
		const { clickedShapeID } = this.props;
		let filteredList = filterItemBySearchTerm ? this.props.shapeList.filter(filterItemBySearchTerm) : this.props.shapeList;
		const item = filteredList[index]

		return <ListItem
			{...item}
			item={item}
			style={style}
			key={key}
			addEditShape={this.props.onDoneAddShape}
			setShapeActive={this.props.setShapeActive}
			onEdit={this.props.onEditDraw}
			onDelete={this.onDeleteArea}
			onFocus={this.props.onFocusShape}
			active={item._id === clickedShapeID}
			multiPleDeleteOn={multiPleDeleteOn}
			isMultipleDeleteSelected={multiPleDeleteSelectionArray.indexOf(item._id) > -1}
			multiPleDeleteChecked={this.onMultipleDeleteAdded}
		/>
	}



	openAreasSettingsModal = () => {
		const { openModal, closeModal } = this.props
		openModal({
			type: "info",
			headerTitle: "Areas Settings",
			actions: [],
			size: "s",
			bodyContent: <AreasSettingsModal closeModal={closeModal} />
		})
	}


	getCustomButtonArray = () => {
		const { multiPleDeleteOn, env } = this.state;
		const PROD = env === "prod" || env === "painting";
		let customButtonArray = [
			{
				handler: this.props.exportShapes,
				label: () => <span title="Export"> <FontAwesomeIcon size="lg" icon={faFileExport} /></span>,
				style: { backgroundColor: "#004500a6", padding: "0 5px", width: "50px", marginRight: "1px" }
			}]

		if (!multiPleDeleteOn) {
			customButtonArray.push({
				handler: () => this.multipleDelete(true),
				label: () => <span title="Multiple Delete"><FontAwesomeIcon size="lg" icon={faTrashAlt} /></span>,
				style: { backgroundColor: "#a22727e0", padding: "0 5px", width: "50px", marginRight: "1px" }
			})
			customButtonArray.push({
				handler: this.openAreasSettingsModal,
				label: () => <span title="Settings"> <FontAwesomeIcon size="lg" icon={faCog} /></span>,
				style: { backgroundColor: "#5eb2e1", padding: "0 5px", width: "50px", marginRight: "1px" }
			})
		}
		else if (multiPleDeleteOn) {
			customButtonArray = [
				{
					handler: () => this.multipleDelete(false),
					label: () => <span title="Done"><FontAwesomeIcon size="lg" icon={faCheck} /></span>,
					style: { backgroundColor: "#004500a6", fontSize: "13px", padding: "0 5px", width: "50px", marginRight: "1px" }
				},
				{
					handler: () => this.onMultipleDeleteCanceled(),
					label: () => <span title="Cancel"><FontAwesomeIcon size="lg" icon={faTimes} /></span>,
					style: { backgroundColor: "#a22727e0", fontSize: "13px", padding: "0 5px", width: "50px", marginRight: "1px" }
				}
			];
			// // remove clear all button from PROD
			if (!PROD)
				customButtonArray.push({
					handler: () => this.multipleDeleteSelectAll(),
					label: () => <span title="Select All"><FontAwesomeIcon size="lg" icon={faListUl} /></span>,
					style: { backgroundColor: "#3a7ab7", fontSize: "13px", padding: "0 5px", width: "50px", marginRight: "1px" }
				})
		}

		return customButtonArray
	}



	rowHightCalculator = ({ index }) => {

		if (Object.keys(this.props.shapeList[index]).indexOf("multilevelTypes") > -1) {
			return this.getItemSizeFromInnerWidth(window.innerWidth) + 20
		}
		return this.getItemSizeFromInnerWidth(window.innerWidth)
	}



	render() {
		const { scrollTo, multiPleDeleteOn, multiPleDeleteSelectionArray } = this.state
		const { onListRefresh, mapState = MAP_STATE.READ, shapeList } = this.props;
		const customButtonArray = this.getCustomButtonArray()

		return (
			mapState === MAP_STATE.READ ?
				<ListV2
					items={shapeList}
					itemRendererInfiniteScroll={this.listItemRenderNew(multiPleDeleteOn, multiPleDeleteSelectionArray)}
					onAddBtnPress={this.props.onAddShape}
					canAddItems={true}
					scrollToIndex={scrollTo}
					showSearchBox={true}
					onRefreshBtnPress={onListRefresh}
					onCustomGlobalButton={customButtonArray}
					infiniteScroll={true}
					itemHight={this.rowHightCalculator}
				/>
				:
				<AddEditComponent
					onDoneAddEdit={this.onDoneAddShape}
					onCancel={this.props.cancelAddEdit} />

		);
	}

}

const mapStateToProps = ({ shapesStore, areasStore }) => {
	return {
		shapeList: shapesStore.shapeList || [],
		mapState: shapesStore.mapState,
		addEditShape: shapesStore.addEditShape,
		clickedShapeID: shapesStore.clickedShapeID,
		isMapAutoLoadActive: areasStore.isMapAutoLoadActive
	}
}


const mapDispatchToProps = (dispatch) => ({
	showDialog: (...params) => dispatch(showDialog(...params)),
	closeDialog: () => dispatch(closeDialog()),
	onDeleteShape: (id) => dispatch(sagaActions.deleteShape(id)),
	onDoneAddShape: (data) => dispatch(sagaActions.addEditShape(data)),
	setShapeActive: (id, bool) => dispatch(sagaActions.changeDisableArea(id, bool)),
	sendMultipleDelete: (array) => dispatch(sagaActions.sendMultipleDelete(array)),
	exportShapes: () => dispatch(exportShapes()),

	onFocusShape: bindActionCreators(onFocusShape, dispatch),
	resetFocus: bindActionCreators(resetFocus, dispatch),
	onAddShape: bindActionCreators(onAddShape, dispatch),
	onEditDraw: bindActionCreators(onEditDraw, dispatch),
	cancelAddEdit: bindActionCreators(cancelAddEdit, dispatch),
	loadShapesThatFitMapBounds: bindActionCreators(loadShapesThatFitMapBounds, dispatch),
	setIsAutoLoadShapesActive: bindActionCreators(setIsAutoLoadShapesActive, dispatch),
	openModal: (modalData) => dispatch(modalActions.openModal(modalData)),
	closeModal: () => dispatch(modalActions.closeModal()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListContainer);
