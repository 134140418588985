import React, { Component } from "react";
// import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { answer } from "../paintConsts";
import { replaceAnswerAreasArray, shapeClicked, clearFocus, clean_subtask_from_store } from "../../../redux/paint/paintAction";

import { Map } from "../../../common/components";
import { ShapeUtils } from "../../../common/sharedGlobal";




class MapContainer extends Component {


	constructor(props) {
		super(props);
		this.state = {
			drawerToolModes: ["rectangle", null],
			currentCenter: props.areaToPaint.shape.boundingBox.ne

		};


		window.addEventListener("keypress", event => {

			const { answerObject } = this.props;

			const isVisible = answerObject.status === answer.has_areas
			if (event.isComposing || event.keyCode === 32) {
				if (isVisible) {
					this.setState({ drawerToolModes: [null, "rectangle",] })
				}
			}
		});

		window.addEventListener("keyup", event => {

			const { answerObject } = this.props;
			const isVisible = answerObject.status === answer.has_areas

			if (event.isComposing || event.keyCode === 32) {
				if (isVisible) {
					this.setState({ drawerToolModes: ["rectangle", null] })
				}
			}
		})



	}


	componentDidMount() {

		// will focus the map after render
		setTimeout(() => {
			this.setState({ currentCenter: ShapeUtils.getBoundingBox(this.props.areaToPaint) })
		}, 1);
	}


	componentWillUnmount() {
		this.props.resetState()
		// this.setState({ currentCenter: null, drawerToolModes: ["rectangle", null] }) // TODO: test
	}


	componentWillReceiveProps(nextProps) {
		if (nextProps.editShapeID !== null)
			this.setState({ drawerToolModes: [null, "rectangle",] })
		else
			this.setState({ drawerToolModes: ["rectangle", null] })

	}


	coordinatesParse = ({
		cornersToMinMax: ({ sw, ne }) => ({
			minLat: sw.lat, minLon: sw.lng, maxLat: ne.lat, maxLon: ne.lng
		}),
		minMaxToCorners: ({ minLon, minLat, maxLon, maxLat }) => ({
			sw: {
				lat: minLat, lng: minLon
			}, ne: {
				lat: maxLat, lng: maxLon
			}
		}),
		getCenterFromCorners: (bb) => {
			if (!bb.sw || !bb.ne)
				return null
			const generateCenter = (max, min) => {
				let subtract = max - min;
				return (subtract / 2) + min
			}


			return { lat: generateCenter(bb.ne.lat, bb.sw.lat), lng: generateCenter(bb.ne.lng, bb.sw.lng) }
		}
	})

	generateDrawerObject = () => {
		const { answerObject, editShapeID, replaceAnswerAreasArray } = this.props;
		const { drawerToolModes } = this.state;

		return {
			position: 2,
			drawingModes: answerObject.status === answer.has_areas ? drawerToolModes : [null],
			onRectangleDraw: ({ minLat, minLon, maxLat, maxLon }) => {
				//TODO : implement in map/V3 the ability to set the returned type from drawing corners OR minMax
				const boundingBox = {
					ne: { lat: maxLat, lng: maxLon },
					sw: { lat: minLat, lng: minLon }
				}
				if (editShapeID !== null) {
					replaceAnswerAreasArray(
						answerObject.areas.map(area =>
							area.id === editShapeID ? { shape: { type: "Rectangle", boundingBox }, id: editShapeID } : area)
					);

				}
				else {
					replaceAnswerAreasArray([...answerObject.areas, { shape: { type: "Rectangle", boundingBox } }]);
				}
			},
		}

	}


	generateListOfAreas = (subtaskObject, answerObjectAreas, editShapeID) => {

		let drawList = answerObjectAreas.map(a => ({
			boundingBox: a.shape.boundingBox,
			type: "Rectangle",
			styles: { strokeColor: "blue", fillColor: "#ffffff00", zIndex: 999 },
			onClick: () => {
				this.props.shapeClicked(a)
			},
			isEditable: a.id === editShapeID
		}))

		let subTaskArea = {
			...subtaskObject,
			...subtaskObject.shape,
			styles: { strokeColor: "#d9534f", fillColor: "#ffffff00", zIndex: -1 },

		}


		drawList.push(subTaskArea);


		return drawList

	}

	// will return the item ,marked as in edit by the edit item ID
	getItemInEdit = (list, editItemID) => {
		let filteredItem = list.filter(i => i.id === editItemID);
		return filteredItem.length > 0 ? filteredItem[0] : null
	}




	render() {

		const { areaToPaint, editShapeID, answerObject } = this.props;
		const { currentCenter } = this.state;

		const listToRender = this.generateListOfAreas(areaToPaint, answerObject.areas, editShapeID)

		return (
			<div className='paint_manager' style={{ width: "100%", height: "100%" }} >
				<div style={{ width: "100%", height: "100%", margin: "0 auto", padding: "18px" }} >
					<Map
						itemInEdit={this.getItemInEdit(answerObject.areas, editShapeID)}
						defaultCenter={this.coordinatesParse.getCenterFromCorners(areaToPaint.shape.boundingBox)}
						center={currentCenter}
						zoom={15}
						drawer={this.generateDrawerObject()}
						listToRender={listToRender}
					/>
				</div>
			</div >

		)
	}
}





let mapStateToProps = ({ paintStore }) => {
	return {
		tasksAsyncStatus: paintStore.asyncStatus,
		answerObject: paintStore.answerObject,
		editShapeID: paintStore.editShapeID,
		overlayFocus: paintStore.overlayFocus,
		// the required area to answer
		areaToPaint: paintStore.fetched_sub_task,
	}
}

let mapDispatchToProps = (dispatch) => {
	return {
		shapeClicked: (shape) => dispatch(shapeClicked(shape)),
		replaceAnswerAreasArray: (areas) => dispatch(replaceAnswerAreasArray(areas)),
		clearFocusAction: () => dispatch(clearFocus()),
		resetState: () => dispatch(clean_subtask_from_store())
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MapContainer);
