import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { onFocusShape, addBoundsTmpShape, resetFocus } from "./../../../redux/tasks/tasksActions";

// import Map from "./../../hoc/googleMapReact";
import { Map } from "./../../../common/components";


const mapStates = {
	READ: "READ",
	ADD: "ADD",
	EDIT: "EDIT"
}


const subtasksUtils = {
	isSubtaskSupervised: (subtask) => Object.keys(subtask).indexOf("supervisor") > -1,
	isSubtaskApproved: (subtask) => Object.keys(subtask).indexOf("approver") > -1
}


class MapContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			zoom: 10,
			defaultCenter: { lat: 32, lng: 35 },
			center: { lat: 32, lng: 35 },
		}
		this.getShapesToDraw = this.getShapesToDraw.bind(this)
	}

	static getDerivedStateFromProps(props, state) {
		// handle focus 

		let newState = state;

		let derivedCenterFromFocus = !isEmpty(props.focus) ? props.focus : null

		// 3) if user asked to focus (also in add edit)
		if (derivedCenterFromFocus !== null && !isEqual(derivedCenterFromFocus, state.center)) {
			if (
				isEmpty(state.center) ||
				(!isEmpty(state.center) && !isEmpty(derivedCenterFromFocus))) {
				newState = {
					...newState,
					center: derivedCenterFromFocus
				}
			}
		}
		if (derivedCenterFromFocus === null) {
			newState = {
				...newState,
				center: null
			}
		}


		// if (derivedCenterFromFocus !== null && !isEqual(derivedCenterFromFocus, state.center))
		// 	if (isEmpty(state.center) || (!isEmpty(state.center) && !isEmpty(derivedCenterFromFocus)))
		// 		newState = {
		// 			...newState,
		// 			center: derivedCenterFromFocus
		// 		}

		return newState
	}

	// remove tasks to edit from the task list
	getShapesToDraw = (array, addShape) => {
		// add type, and boundingBox
		let newArray = array.map(s => ({
			...s,
			...s.shape,
			styles: {
				fillColor: "#00000000"
			}
			// onClick: (e) => { this.onShapeClicked(s, e) }
		}))


		if (!isEmpty(addShape)) {
			// add the temp shape as editable
			newArray.push({ ...addShape.shape, isEditable: true })

		}

		return newArray

	}

	generateDrawerObject = () => {
		const { mapState } = this.props;
		return {
			position: 2,
			drawingModes: mapState === mapStates.ADD ? ["rectangle", null] : [null],
			onRectangleDraw: ({ minLat, minLon, maxLat, maxLon }) => {
				//TODO : implement in map/V3 the ability to set the returned type from drawing corners OR minMax
				const boundingBox = {
					sw: { lat: minLat, lng: minLon },
					ne: { lat: maxLat, lng: maxLon }
				}
				this.props.addBoundsTmpShape({ type: "Rectangle", boundingBox })
			},
		}
	}


	markSubtasks = (subtasksArr) =>
		subtasksArr.map(subtask => {

			let isSupervised = subtasksUtils.isSubtaskSupervised(subtask) || subtask.painting.autoApproved
			return {
				...subtask.painting,
				type: "Rectangle",
				styles: {
					fillColor: isSupervised ? "green" :
						subtask.painting.results.length === subtask.painting.requiredResults ? "blue" : "#00000000",
				}
			}
		})
	onDragStartHandler = () => {
		// reset focus
		this.props.resetFocus()
	}

	render() {

		const { center, zoom, defaultCenter, } = this.state;
		const { addShape, tasks = [], subTasksList = [] } = this.props;
		return (

			<Map
				showSearchBox={true}
				listToRender={[...this.getShapesToDraw(tasks, addShape), ...this.markSubtasks(subTasksList)]}
				defaultCenter={defaultCenter}
				onDragStart={this.onDragStartHandler}
				center={center || defaultCenter}
				zoom={zoom}
				drawer={this.generateDrawerObject()}
				itemInEdit={addShape}
			/>
		)
	}

}

let mapStateToProps = ({ tasksStore }) => {
	return {
		tasks: tasksStore.tasks,
		mapState: tasksStore.mapState,
		focus: tasksStore.focus ? tasksStore.focus : null,
		subTasksList: tasksStore.subTasksList,
		addShape: tasksStore.tmpShape
	}
}

let mapDispatchToProps = (dispatch) => {
	return {
		// onDeleteTask: bindActionCreators(onDeleteTask, dispatch),
		resetFocus: bindActionCreators(resetFocus, dispatch),
		onFocusShape: bindActionCreators(onFocusShape, dispatch),
		addBoundsTmpShape: bindActionCreators(addBoundsTmpShape, dispatch)

	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MapContainer);
