import _ from "lodash"
import { all, spawn, call, takeLatest, put, select } from "redux-saga/effects"
import { ACTION_TYPES } from "../reduxConsts"
import { storeSubTaskToApprove, setDisplayDataForApproverTables, resetApproverState, fetchSubTaskToApprove, confirmDeleteDbSnapshotArea, approverResetFilter } from "../approve/approverActions"
import { getApproveStoreDataState, getApproveCommentBoxValue, getApproverScore, getApproverFilter } from "../approve/selectors"
import { async_success } from "../leaderBoard/leaderBoardAction"
import { async_start } from "../shapes/shapeActions"
import { SafeSaga } from "../../common/modules"
import { SHAPE_COLORS_CONSTS } from "../../components/genericTableComponents/shapeColor/shapeColors"
import moment from "moment"
import { ShapeUtils } from "../../common/sharedGlobal"

var keygen = require("keygenerator");

export const approverSaga = function* (network) {
	yield all([
		spawn(fetchSubTaskSaga, network),
		spawn(clearFiltersAndFetchSubtask, network),
		spawn(fetchSubTaskOnFilterUpdateSaga, network),
		spawn(regenerateTableDataSaga),
		spawn(submitApproverAnswersSaga, network),
		spawn(deleteDbSnapshotSaga, network),
		spawn(submitApproverEditSaga, network),
		spawn(submitApproverSendBackSaga, network),
		spawn(submitApproverWithoutChangesSaga, network),
	])
}

export const APPROVER_API_TYPES = {
	withAppliedShapesFirst: "withAppliedShapesFirst",
	any: "any"
}

export const APPROVAL_DATA_TYPES = {
	ADDED_BY_APPROVER: "Added by approver",
	SUPERVISOR: "Supervisor answer",
	SNAPSHOT_WHILE_SUPERVISOR: "Existing areas while supervise",
	PAINTER: "Painter",
	DB_SNAPSHOT: "Existing areas",
	DB_SNAPSHOT_PHASE_FOUR: "Existing areas phase four",
	EXISTING_AREA_ADDED_BY_SUBTASK_SUPERVISOR: "Existing area added by subtask supervisor",
	EXISTING_AREA_NOT_ADDED_BY_SUBTASK_SUPERVISOR: "Existing area not added by subtask supervisor",
}


const ApprovalUrls = {
	fetchSubTask: "api/subtasks/approver",
	fetchWithAppliedShapes: "api/subtasks/approver/withshapes",
	subtaskApproverAnswer: "api/subtasks/approver",
	isInBox: "api/areas/inBox",
	isInBoxV2: "api/v2/areas/inbox",
	remainingSubtasks: "api/subtasks/approver/remain",
}



const fetchSubTaskOnFilterUpdateSaga = function* (network) {
	yield takeLatest(ACTION_TYPES.APPROVER.SET_APPROVER_FILTER, SafeSaga(function* (action) {
		yield put(fetchSubTaskToApprove())
	}))
}




const fetchSubTaskSaga = function* (network) {
	yield takeLatest(ACTION_TYPES.APPROVER.FETCH_SUB_TASK_FOR_APPROVAL, SafeSaga(function* (action) {

		const { subtaskName } = action.value;
		const withSubtaskName = !(!subtaskName || 0 === subtaskName.length);
		// reset state before fetching new subtask
		yield put(resetApproverState())

		// dispatch async start action
		yield put(async_start());

		const state = yield select()


		let apiUrl = ApprovalUrls.fetchSubTask + (withSubtaskName ? `?subtask=${subtaskName}` : "")
		// let apiUrl = apiType === APPROVER_API_TYPES.withAppliedShapesFirst ? ApprovalUrls.fetchWithAppliedShapes + (withSubtaskName ? `?subtask=${subtaskName}` : "") : ApprovalUrls.fetchSubTask
		const filter = getApproverFilter(state)

		if (filter) {
			const { intervalType, ...filterForFetch } = filter
			let queryParams = ""
			if (filterForFetch.subtaskName.length > 0) {
				queryParams = `subtaskName=${filterForFetch.subtaskName}`
			} else {
				queryParams = generateQuesryParamsFromObject(filterForFetch)
			}

			apiUrl = queryParams ? `${ApprovalUrls.fetchSubTask}?${queryParams}` : ApprovalUrls.fetchSubTask
		}

		const subtask = yield call(network.sendGet, apiUrl)
		const remainingSubtasks = yield call(network.sendGet, ApprovalUrls.remainingSubtasks)

		if (subtask && subtask.data) {
			const shapes = [
				..._.map(subtask.data.painting.results, r => _.map(r.areas, area => area)),
				subtask.data.painting.shape,
				..._.map(subtask.data.supervisor.appliedShapes, appliedShape => appliedShape.shape)
			].flat()
			const boundingBoxesOfAllShapes = ShapeUtils.convertArrayOfDiagonalRectanglesAndRectanglesToArrayOfNeSwBoundingBoxes(shapes)
			const biggestBoundingBoxOfAllShapes = ShapeUtils.RECTANGLE.biggestBoundingBox(boundingBoxesOfAllShapes)
			const dbSnapshot = yield call(network.sendPost, ApprovalUrls.isInBoxV2, { "shape": ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(biggestBoundingBoxOfAllShapes) })
			const dbSnapshotsAreas = dbSnapshot && dbSnapshot.areas && dbSnapshot.areas.length > 0 ? _.map(dbSnapshot.areas, db => db.shape) : []
			const approverSendBack = subtask.data.approverSendBack || null
			const phaseFourDbSnapshotsAreas = dbSnapshot && dbSnapshot.areas

			const existingShapesAddedBySubtaskSupervisor = dbSnapshot && dbSnapshot.areas && dbSnapshot.areas.length > 0 ? _.filter(dbSnapshot.areas, area => area.createdFrom.type === "supervisorAnswer" && area.createdFrom.data.subtaskId === subtask.data._id) : []
			const existingShapesNotAddedBySubtaskSupervisor = dbSnapshot && dbSnapshot.areas && dbSnapshot.areas.length > 0 ? _.filter(dbSnapshot.areas, area => !(area.createdFrom.type === "supervisorAnswer" && area.createdFrom.data.subtaskId === subtask.data._id)) : []

			const reshapedData = reshapeSubtaskData({ existingShapesAddedBySubtaskSupervisor, existingShapesNotAddedBySubtaskSupervisor, phaseFourDbSnapshotsAreas, dbSnapshotsAreas, ...subtask.data })
			yield put(storeSubTaskToApprove({ approverSendBack, remainingSubtasks, ...reshapedData }));
			const dataForTableDisplay = generateDataForTableDisplay(reshapedData)
			yield put(setDisplayDataForApproverTables(dataForTableDisplay))
		}

		yield put(async_success());
	}))
}





// THIS IS RESETTING TABLE SELECTIONS LIKE COLOR, VISIBILITY ...
const regenerateTableDataSaga = function* () {
	yield takeLatest(ACTION_TYPES.APPROVER.PHASE_CHANGED, SafeSaga(function* (action) {
		const state = yield select()
		const approveData = getApproveStoreDataState(state)
		const dataForTableDisplay = generateDataForTableDisplay(approveData)
		yield put(setDisplayDataForApproverTables(dataForTableDisplay))

	}))
}





const submitApproverAnswersSaga = function* (network) {
	yield takeLatest(ACTION_TYPES.APPROVER.SUBMIT_APPROVER_ANSWERS, SafeSaga(function* (action) {
		const state = yield select()
		const approverDataFromState = yield call(getApproveStoreDataState, state)
		const subtaskId = approverDataFromState.subtaskId
		const appliedShapes = generateAppliedShapesObject(action.value)
		const comment = yield call(getApproveCommentBoxValue, state)

		const score = yield call(getApproverScore, state)
		const data = {
			subtaskId,
			appliedShapes: appliedShapes,
			comment,
			score
		}


		yield call(network.sendPost, ApprovalUrls.subtaskApproverAnswer, data)
		yield put(fetchSubTaskToApprove())
	}))
}


const submitApproverEditSaga = function* (network) {
	yield takeLatest(ACTION_TYPES.APPROVER.APPROVE_DONE_EDIT_MODE, SafeSaga(function* (action) {
		const { appliedShapes, areasToDelete } = action.value
		const state = yield select()
		const approverDataFromState = yield call(getApproveStoreDataState, state)
		const subtaskId = approverDataFromState.subtaskId
		const comment = yield call(getApproveCommentBoxValue, state)

		const score = yield call(getApproverScore, state)
		const data = {
			type: "Edit",
			subtaskId,
			appliedShapes,
			comment,
			score,
			areasToDelete
		}

		yield call(network.sendPost, ApprovalUrls.subtaskApproverAnswer, data)
		yield put(fetchSubTaskToApprove())
	}))
}


const submitApproverSendBackSaga = function* (network) {
	yield takeLatest(ACTION_TYPES.APPROVER.APPROVE_DONE_SEND_BACK, SafeSaga(function* (action) {
		const { approverSendBackSuggestions, areasToDelete } = action.value
		const state = yield select()
		const approverDataFromState = yield call(getApproveStoreDataState, state)
		const subtaskId = approverDataFromState.subtaskId
		const comment = yield call(getApproveCommentBoxValue, state)

		const score = yield call(getApproverScore, state)
		const data = {
			subtaskId,
			type: "SendBack",
			comment,
			score,
			approverSendBackSuggestions,
			areasToDelete
		}

		yield call(network.sendPost, ApprovalUrls.subtaskApproverAnswer, data)
		yield put(fetchSubTaskToApprove())
	}))
}


const clearFiltersAndFetchSubtask = function* (network) {
	yield takeLatest(ACTION_TYPES.APPROVER.CLEAR_FILTERS_AND_FETCH_SUB_TASK_FOR_APPROVAL, SafeSaga(function* (action) {

		yield put(approverResetFilter())
		yield put(fetchSubTaskToApprove())
	}))
}


const submitApproverWithoutChangesSaga = function* (network) {
	yield takeLatest(ACTION_TYPES.APPROVER.APPROVE_DONE, SafeSaga(function* (action) {
		const state = yield select()
		const approverDataFromState = yield call(getApproveStoreDataState, state)
		const subtaskId = approverDataFromState.subtaskId
		const comment = yield call(getApproveCommentBoxValue, state)

		const score = yield call(getApproverScore, state)
		const data = {
			subtaskId,
			type: "Done",
			comment,
			score,
		}

		yield call(network.sendPost, ApprovalUrls.subtaskApproverAnswer, data)
		yield put(fetchSubTaskToApprove())
	}))
}


const deleteDbSnapshotSaga = function* (network) {
	yield takeLatest(ACTION_TYPES.APPROVER.DELETE_DBSNAPSHOT_AREA, SafeSaga(function* (action) {
		// dispatch async start action
		yield put(async_start());
		const id = action.value
		// TODO: handle error res if any!
		yield call(network.sendDelete, `api/areas/${id}`)
		// const res = yield call(network.sendDelete, `api/areas/${id}`)
		yield put(confirmDeleteDbSnapshotArea(id))
		// dispatch async start action
		yield put(async_success());
	}))
}


const generateAppliedShapesObject = (shapes) => {
	if (!shapes) return null
	if (shapes.length === 0) return []

	return _.map(shapes, s => ({
		...s.shapes[0], // TODO: test. old line - 	boundingBox: s.shapes[0],
		description: s.description,
		multilevelTypes: {
			isComplex: s.isComplex,
			topLevel: s.topLevel,
			bottomLevel: s.bottomLevel
		}
	}))
}


const reshapeSubtaskData = (data) => {
	let paintingData = []
	let supervisorData = []
	let snapshotWhileSuperviseData = {}
	let dbSnapshotData = {}
	let phaseFourDbSnapshotsAreas = []
	let existingShapesAddedBySubtaskSupervisor = []
	let existingShapesNotAddedBySubtaskSupervisor = []

	const subtaskId = data._id
	const subtaskName = data.painting.name
	const supervisorName = data.supervisor.userSupervised.userEmail
	const superviseTime = moment.unix(data.supervisor.superviseTime).format("DD/MM/YY HH:mm")

	// Painting data 
	paintingData = _.map(data.painting.results, res => ({
		id: keygen._(),
		answerTime: moment(res.answerTime).format("DD/MM/YY HH:mm"),
		type: APPROVAL_DATA_TYPES.PAINTER,
		name: res.userCreated.userEmail,
		status: res.status,
		markedAs: res.isCorrect,
		conflictText: res.conflict_text || null,
		shapes: [...res.areas]
	}))


	//supervisor data
	supervisorData = _.map(data.supervisor.appliedShapes, res => ({
		id: keygen._(),
		type: APPROVAL_DATA_TYPES.SUPERVISOR,
		description: res.description,
		topLevel: res.multilevelTypes.topLevel,
		bottomLevel: res.multilevelTypes.bottomLevel,
		isComplex: res.multilevelTypes.isComplex,
		shapes: [res.shape]
	}))


	//snapshot While Supervise data
	snapshotWhileSuperviseData = (data.supervisor.subtaskExistingAreasSnapshot && data.supervisor.subtaskExistingAreasSnapshot.length > 0) ? {
		id: keygen._(),
		type: APPROVAL_DATA_TYPES.SNAPSHOT_WHILE_SUPERVISOR,
		numOfSnapshots: data.supervisor.subtaskExistingAreasSnapshot.length,
		shapes: _.map(data.supervisor.subtaskExistingAreasSnapshot, shape => shape.shape)
	} : null


	dbSnapshotData = data.dbSnapshotsAreas.length > 0 ? {
		id: keygen._(),
		type: APPROVAL_DATA_TYPES.DB_SNAPSHOT,
		numOfSnapshots: data.dbSnapshotsAreas.length,
		shapes: data.dbSnapshotsAreas,

	} : null

	phaseFourDbSnapshotsAreas = _.map(data.phaseFourDbSnapshotsAreas, (dbSnapshotArea) => ({
		id: dbSnapshotArea._id,
		type: APPROVAL_DATA_TYPES.DB_SNAPSHOT_PHASE_FOUR,
		description: dbSnapshotArea.description,
		shapes: [dbSnapshotArea.shape],
	}))

	existingShapesAddedBySubtaskSupervisor = _.map(data.existingShapesAddedBySubtaskSupervisor, (area) => ({
		id: area._id,
		type: APPROVAL_DATA_TYPES.EXISTING_AREA_ADDED_BY_SUBTASK_SUPERVISOR,
		description: area.description,
		shapes: [area.shape],
		topLevel: area.multilevelTypes.topLevel,
		bottomLevel: area.multilevelTypes.bottomLevel,
		isComplex: area.multilevelTypes.isComplex,
	}))

	existingShapesNotAddedBySubtaskSupervisor = _.map(data.existingShapesNotAddedBySubtaskSupervisor, (area) => ({
		id: area._id,
		type: APPROVAL_DATA_TYPES.EXISTING_AREA_NOT_ADDED_BY_SUBTASK_SUPERVISOR,
		description: area.description,
		shapes: [area.shape],
		topLevel: area.multilevelTypes.topLevel,
		bottomLevel: area.multilevelTypes.bottomLevel,
		isComplex: area.multilevelTypes.isComplex,
	}))

	const subtaskBoundingbox = data.painting.shape
	const supervisorComment = data.supervisor.comment || ""
	return {
		subtaskId, subtaskName, supervisorName, superviseTime, supervisorComment, paintingData, supervisorData,
		snapshotWhileSuperviseData, dbSnapshotData, phaseFourDbSnapshotsAreas,
		existingShapesAddedBySubtaskSupervisor, existingShapesNotAddedBySubtaskSupervisor,
		subtaskBoundingbox
	}
}


const generateDataForTableDisplay = (data) => {

	let paintersData = _.map(data.paintingData, painterData => ({
		type: APPROVAL_DATA_TYPES.PAINTER,
		id: painterData.id,
		visible: painterData.status === "problem" || painterData.status === "no_areas" ? -1 : false,
		colorInfo: { fillColor: "transparent", strokeColor: SHAPE_COLORS_CONSTS.black },
		shapes: painterData.shapes

	}))


	let supervisorData = _.map(data.supervisorData, supervisorData => ({
		type: APPROVAL_DATA_TYPES.SUPERVISOR,
		id: supervisorData.id,
		visible: true,
		colorInfo: { fillColor: SHAPE_COLORS_CONSTS.transparent, strokeColor: SHAPE_COLORS_CONSTS.blue },
		shapes: supervisorData.shapes
	}))


	let subtaskExistingAreasSnapshotData = data.snapshotWhileSuperviseData ? {
		type: APPROVAL_DATA_TYPES.SNAPSHOT_WHILE_SUPERVISOR,
		id: data.snapshotWhileSuperviseData.id,
		visible: true,
		colorInfo: { fillColor: SHAPE_COLORS_CONSTS.transparent, strokeColor: SHAPE_COLORS_CONSTS.green },
		shapes: data.snapshotWhileSuperviseData.shapes
	} : null


	let dbSnapshotData = data.dbSnapshotData ? {
		type: APPROVAL_DATA_TYPES.DB_SNAPSHOT,
		id: data.dbSnapshotData.id,
		visible: true,
		colorInfo: { fillColor: SHAPE_COLORS_CONSTS.transparent, strokeColor: SHAPE_COLORS_CONSTS.green },
		shapes: data.dbSnapshotData.shapes
	} : null

	let phaseFourDbSnapshotsAreas = _.map(data.phaseFourDbSnapshotsAreas, phaseFourDbSnapshot => ({
		type: APPROVAL_DATA_TYPES.DB_SNAPSHOT_PHASE_FOUR,
		id: phaseFourDbSnapshot.id,
		visible: true,
		colorInfo: { fillColor: SHAPE_COLORS_CONSTS.transparent, strokeColor: SHAPE_COLORS_CONSTS.green },
		shapes: [phaseFourDbSnapshot.shapes[0]]
	}))

	let existingShapesAddedBySubtaskSupervisor = _.map(data.existingShapesAddedBySubtaskSupervisor, area => ({
		type: APPROVAL_DATA_TYPES.EXISTING_AREA_ADDED_BY_SUBTASK_SUPERVISOR,
		id: area.id,
		visible: true,
		colorInfo: { fillColor: SHAPE_COLORS_CONSTS.transparent, strokeColor: SHAPE_COLORS_CONSTS.black },
		shapes: [area.shapes[0]]
	}))

	let existingShapesNotAddedBySubtaskSupervisor = _.map(data.existingShapesNotAddedBySubtaskSupervisor, area => ({
		type: APPROVAL_DATA_TYPES.EXISTING_AREA_NOT_ADDED_BY_SUBTASK_SUPERVISOR,
		id: area.id,
		visible: true,
		colorInfo: { fillColor: SHAPE_COLORS_CONSTS.transparent, strokeColor: SHAPE_COLORS_CONSTS.green },
		shapes: [area.shapes[0]]
	}))

	return _([...paintersData, ...supervisorData, subtaskExistingAreasSnapshotData, ...phaseFourDbSnapshotsAreas, ...existingShapesAddedBySubtaskSupervisor, ...existingShapesNotAddedBySubtaskSupervisor, dbSnapshotData]).filter().keyBy(item => item.id).value()
}


const generateQuesryParamsFromObject = (object) => {
	if (!object) return null

	let quesryString = ""
	for (let key in object) {
		if (typeof object[key] === "string" && object[key].length === 0) {
			continue;
		}
		quesryString += `${key}=${object[key]}&`
	}
	return quesryString.slice(0, -1)
}