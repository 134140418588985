
export const biggestBoundingBox = (arr) => {
	if (!Array.isArray(arr))
		throw new Error("expected array, got: " + typeof arr)

	return arr.reduce((prevValue, currentValue) => {
		let newValue = prevValue;

		if (currentValue.sw.lat && prevValue.sw.lat && currentValue.sw.lat < prevValue.sw.lat)
			newValue.sw.lat = currentValue.sw.lat

		if (currentValue.sw.lng && prevValue.sw.lng && currentValue.sw.lng < prevValue.sw.lng)
			newValue.sw.lng = currentValue.sw.lng

		if (currentValue.ne.lat && prevValue.ne.lat && currentValue.ne.lat > prevValue.ne.lat)
			newValue.ne.lat = currentValue.ne.lat

		if (currentValue.ne.lng && prevValue.ne.lng && currentValue.ne.lng > prevValue.ne.lng)
			newValue.ne.lng = currentValue.ne.lng
		return newValue

	}, { sw: { lat: 180, lng: 180 }, ne: { lat: -180, lng: -180 } })

}


