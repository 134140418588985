import React, { Component } from "react";
import { Prompt } from "react-router";

import { connect } from "react-redux";
import { Popover } from "react-bootstrap";

import { fetchReadyForSupervised, onDoneEdit, resetStore } from "../../redux/supervise/superviseAction";

import SubtaskInfoTitle from "./components/SubtaskInfoTitle";
import TableContainerV2 from "./containers/SupervisorTable";
import MapContainer from "./containers/mapContainer";
import NoContentComponent from "../hoc/NoContentComponent";
import { objectToString } from "../utils/ObjectToString";
import PreviewSubtaskSummary from "../previewPage/utilsComponents/previewSubtaskSummary/PreviewSubtaskSummary";
import "./superviseStyle.css";



class Supervise extends Component {

	componentDidMount() {
		// fetch a subtask to Supervisor
		this.props.fetchReadyForSupervised();
	}


	componentWillUnmount() {
		this.props.resetStore()
	}


	fetchSupervisorSubtask = () => {
		// fetch a subtask to Supervisor
		this.props.fetchReadyForSupervised();
	}

	onSkip = () => {
		const { onDoneEdit, fetchReadyForSupervised } = this.props
		onDoneEdit()
		fetchReadyForSupervised();
	}


	popover = () => {
		const { subtask } = this.props
		return <Popover id="popover-positioned-bottom" bsSize="small" title={"name: " + subtask.name}>
			{objectToString(subtask)}
		</Popover>
	}


	generateApproverSupervisorObjectsFromSendBack = (subtask = {}, isSendBack = false) => {
		if (isSendBack)
			return ({
				approverAnswer: {
					comment: subtask.approverSendBack.approverComment,
					approvedTime: subtask.approverSendBack.sendBackTime,
					userApproved: subtask.approverSendBack.userApproved,
				},
				supervisorAnswer: {
					userSupervised: subtask.approverSendBack.previousUserSupervised,
					superviseTime: subtask.approverSendBack.previousSuperviseTime
				}
			})

		return null

	}


	render() {
		const { subtask = {}, leftToSupervise, isHaveUnsavedChanges, isSendBack, history, showLoader } = this.props;
		return (
			<div style={{ height: "100%" }}>

				<Prompt
					when={isHaveUnsavedChanges}
					message={"You have unsaved changes, Leave page?"}
				/>

				{/* subtask name headline */}
				{!!subtask && <SubtaskInfoTitle
					subtask={subtask}
					isSendBack={isSendBack}
					sendBack={subtask.approverSendBack}
					leftToSupervise={leftToSupervise}
					onSkip={this.onSkip}
					popover={this.popover} />}


				{!subtask && !showLoader && <NoContentComponent title="NO SUBTASKS LEFT FOR SUPERVISE" text="no subtask left to supervise, good for you!" refreshAction={this.fetchSupervisorSubtask} />}
				{subtask &&

					<div className="supervise_manager">

						<div className='supervise_table_container' style={{ "textAlignLast": "center", overflowY: "auto" }}>
							<PreviewSubtaskSummary approverAndSupervisorSummary={this.generateApproverSupervisorObjectsFromSendBack(subtask, isSendBack)} />

							<TableContainerV2 />
						</div>
						{/* <div className='supervise_table_container' style={{ "textAlignLast": "center", overflowY: "auto" }}><TableContainer /></div> */}

						<div className='supervise_map_container'><MapContainer /></div>

					</div>}

			</div >

		)
	}

}

let mapStateToProps = ({ supervisorStore, loaderStore }) => ({
	showLoader: loaderStore.showLoader,
	asyncStatus: supervisorStore.async_status,
	subtask: supervisorStore.subtask,
	isSendBack: supervisorStore.isSendBack,
	leftToSupervise: supervisorStore.numWaitingToSupervise,
	isHaveUnsavedChanges: supervisorStore.supervisorResultObject.correctArr.length > 0 || supervisorStore.supervisorResultObject.appliedShapes.length > 0
})

let mapDispatchToProps = (dispatch) => ({
	fetchReadyForSupervised: () => dispatch(fetchReadyForSupervised()),
	resetStore: () => dispatch(resetStore()),
	onDoneEdit: () => dispatch(onDoneEdit()),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Supervise);
