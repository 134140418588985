import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
	deleteTask,
	// getSubtasksByParentID,
	postTask,
	cancelAddEdit,
	onFocusShape,
	onAddShape,
	onDoneFocusShape,
	// removeSubTasks
} from "../../../redux/tasks/tasksActions";

import { showDialog, closeDialog } from "../../../redux/dialog/dialogActions";

import { MAP_STATE } from "./../../../redux/reduxConsts";
import ListComponent from "./../components/ListComponent";
import AddEditComponent from "./../components/AddEditComponent";
import { ShapeUtils } from "../../../common/sharedGlobal";


class ListContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			tasks: []
		}
	}


	static getDerivedStateFromProps(nextProps, prevState) {
		let state = prevState;


		if (nextProps.task !== prevState.tasks) {
			state = {
				...state,
				tasks: nextProps.tasks
			}
		}

		if (nextProps.mapState !== prevState.mapState) {
			state = {
				...state,
				mapState: nextProps.mapState
			}
		}
		return state;


	}



	onAddHandler = () => {

		this.props.onAddShape()
	}


	onDelete = (_id, name) => {

		this.props.showDialog({
			level: "info",
			title: "Delete Task",
			txt: `Are you sure you want to delete "${name}"?`,
			confirm: [this.props.onDeleteTask, _id], cancel: this.props.closeDialog
		})

	}


	onDoneAddShape = (shapeInfo) => {

		// get the bounding box from the tmp shape in the store
		const { shape } = this.props.tmpShape;

		// check if the tmp shape have bounding box (meaning the use draw a shape on the map)
		if (shape && shape.boundingBox && shapeInfo.name) {
			// the tmp shape have bounding box, meaning the user draw a shape in the map OR the user is on edit mode and already have bounding box

			// rearrange the shape
			let shapeToSend = Object.assign({}, { ...shapeInfo, shape });

			// send the new shape to redux to handle
			this.props.onDoneAddShape(shapeToSend);
		}
		else {
			// the tmp shape does not have bounding box meaning the user did not draw a shape in the map
			alert("did you forgot to draw a shape or add require info ??")

		}
	}

	// hideSubtaskFromMap = (subtask) => {
	// 	this.props.removeSubTasks({ parentId: subtask["_id"] })
	// }


	onShapeFocus = ({ shape }) => {
		switch (shape.type) {
			case "Rectangle": {
				this.props.onFocusShape(ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(shape.boundingBox))
				return

			}
			case "DiagonalRectangle": {
				this.props.onFocusShape(ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(ShapeUtils.POLYGON.convertPointsToBoundingBox(shape.points)))
				return
			}
		}
	}



	render() {
		const { tasks = [], mapState = MAP_STATE.READ } = this.state;
		const { accessToken } = this.props.user || null
		const { tmpShape } = this.props;

		return (
			mapState === MAP_STATE.READ ?
				// render the list component
				< ListComponent
					tasks={tasks}
					addItem={this.onAddHandler}
					deleteItem={({ _id, name }) => this.onDelete(_id, name)}
					// deleteItem={({ _id }) => this.props.onDeleteTask(_id, accessToken)}
					focusItem={this.onShapeFocus}
				// displaySubtasks={({ _id }) => this.props.getSubtasksByParentIdAsync(_id, accessToken)}
				// removeSubTasks={this.hideSubtaskFromMap}
				/>
				:
				// render the add edit component
				<AddEditComponent
					onDoneAddEdit={this.onDoneAddShape}
					data={tmpShape}
					onCancel={this.props.cancelAddEdit} />
		);
	}

}

let mapStateToProps = ({ tasksStore, authStore }) => {
	return {
		tasks: tasksStore.tasks,
		mapState: tasksStore.mapState,
		tmpShape: tasksStore.tmpShape,
		user: authStore.user || {},
		asyncStatus: tasksStore.asyncStatus
	}
}

let mapDispatchToProps = (dispatch) => {
	return {
		// async function
		showDialog: (...params) => dispatch(showDialog(...params)),
		closeDialog: () => dispatch(closeDialog()),
		onDeleteTask: (id) => dispatch(deleteTask(id)),
		onDoneAddShape: (task) => dispatch(postTask(task)),
		onFocusShape: bindActionCreators(onFocusShape, dispatch),
		onAddShape: bindActionCreators(onAddShape, dispatch),
		onDoneFocusShape: bindActionCreators(onDoneFocusShape, dispatch),
		cancelAddEdit: bindActionCreators(cancelAddEdit, dispatch),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ListContainer);
