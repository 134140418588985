
import React from "react"
import { Map } from "../../../../common/components";
import _ from "lodash"
import { SHAPE_COLORS_CONSTS } from "../../../genericTableComponents/shapeColor/shapeColors"
import { ShapeUtils } from "../../../../common/sharedGlobal";


export class ApproveStageMapContainer extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			zoom: 16,
			drawingModes: [null],
			currentlyAddedDiagonalRectanglePoints: [],
		}
	}






	static getDerivedStateFromProps(props, state) {
		let newState = { ...state }


		// In a progress of adding a new diagonal rectangle shape
		if (state.currentlyAddedDiagonalRectanglePoints.length > 0) {
			return {
				...newState,
				drawingModes: ["marker", null]
			}
		}


		// In Editing section and an item is in active edit
		if (props.isEditing && props.itemIdCurrentlyEditingBB) {
			let itemInEditType = _.find(props.items, item => item.id === props.itemIdCurrentlyEditingBB)
			itemInEditType = itemInEditType && itemInEditType.shapes[0].type

			switch (itemInEditType) {
				case "Rectangle": {
					return {
						...newState,
						drawingModes: [null, "rectangle"]
					}
				}

				case "DiagonalRectangle": {
					return {
						...newState,
						drawingModes: [null, "marker"]
					}
				}
			}
		}


		// in Editing section and no item is in active edit
		if (props.isEditing && !props.itemIdCurrentlyEditingBB) {
			return {
				...newState,
				drawingModes: ["rectangle", "marker", null]
			}
		}


		// inot in edit mode
		if (!props.isEditing) {
			return {
				...newState,
				drawingModes: [null]
			}
		}

		return newState
	}



	componentDidMount() {
		window.addEventListener("keydown", this.onBackspacePressed);
		window.addEventListener("keydown", this.onDownPressed);
		window.addEventListener("keydown", this.onUpPressed);
		window.addEventListener("keypress", this.onSpacePress);
		window.addEventListener("keyup", this.onSpaceUp);
	}


	componentWillUnmount() {
		window.removeEventListener("keydown", this.onBackspacePressed)
		window.removeEventListener("keydown", this.onDownPressed)
		window.removeEventListener("keydown", this.onUpPressed)
		window.removeEventListener("keypress", this.onSpacePress)
		window.removeEventListener("keyup", this.onSpaceUp)
	}


	onSpacePress = (event) => {
		const { isAnyTextInputFocused, isEditing } = this.props
		if (isAnyTextInputFocused) return null

		if (isEditing && (event.isComposing || event.keyCode === 32)) {
			this.setState({ drawingModes: [null, "rectangle"] })
		}
	}

	onSpaceUp = (event) => {
		const { isAnyTextInputFocused, isEditing } = this.props
		if (isAnyTextInputFocused) return null

		if (isEditing && (event.isComposing || event.keyCode === 32)) {
			this.setState({ drawingModes: ["rectangle", null] })
		}
	}


	onBackspacePressed = event => {
		const { onKeyboardBackspacePressed, isAnyTextInputFocused } = this.props
		if (isAnyTextInputFocused) return null
		if (event.isComposing || (event.keyCode === 8 && event.ctrlKey)) {
			onKeyboardBackspacePressed && onKeyboardBackspacePressed()
		}
	}


	onDownPressed = event => {
		const { onKeyboardDownPressed, isAnyTextInputFocused } = this.props
		if (isAnyTextInputFocused) return null
		if (event.isComposing || event.keyCode === 40) {
			event.preventDefault()
			onKeyboardDownPressed && onKeyboardDownPressed()
		}
	}


	onUpPressed = event => {
		const { onKeyboardUpPressed, isAnyTextInputFocused } = this.props
		if (isAnyTextInputFocused) return null

		if (event.isComposing || event.keyCode === 38) {
			event.preventDefault()
			onKeyboardUpPressed && onKeyboardUpPressed()
		}
	}



	onMarkerOfCurrentlyAddedDiagonalRectangleRightClick = (pointOrderToDelete) => {
		const { currentlyAddedDiagonalRectanglePoints } = this.state

		let pointsArray = [...currentlyAddedDiagonalRectanglePoints]
		pointsArray[pointOrderToDelete] = null
		pointsArray = _.filter(pointsArray)

		this.setState({ currentlyAddedDiagonalRectanglePoints: pointsArray })
	}


	editCurrentlyAddedDiagonalRectanglePointOfTemp = (pointOrder, newPoint) => {
		const { currentlyAddedDiagonalRectanglePoints } = this.state

		let pointsArray = [...currentlyAddedDiagonalRectanglePoints]
		pointsArray[pointOrder] = { ...newPoint }

		this.setState({ currentlyAddedDiagonalRectanglePoints: pointsArray })
	}


	prepareMapShapes = () => {
		const { items, subtaskShape, onShapeClicked, clickedItemId } = this.props
		const { currentlyAddedDiagonalRectanglePoints } = this.state



		const mapShapes = []

		// push shape for currently added shape
		if (currentlyAddedDiagonalRectanglePoints.length > 0) {
			mapShapes.push({
				type: "DiagonalRectangle",
				points: currentlyAddedDiagonalRectanglePoints,
				isEditable: true,
				onRightClick: (pointOrderToDelete) => this.onMarkerOfCurrentlyAddedDiagonalRectangleRightClick(pointOrderToDelete),
				onDiagonalRectanglePointDrag: (pointOrder, newPoint) => this.editCurrentlyAddedDiagonalRectanglePointOfTemp(pointOrder, newPoint),
			})
		}



		// Generating a shape for subtask bounding box
		mapShapes.push({
			...subtaskShape,
			zIndex: 1,
			styles: {
				fillColor: "transparent",
				visible: true,
				strokeColor: "red"
			}

		})


		_.forEach(items, item => {
			item && item.shapes && item.shapes.length > 0 && _.forEach(item.shapes, shape => {

				switch (shape.type) {
					case "Rectangle": {

						mapShapes.push({
							...shape,
							isEditable: !!item.isEditable,
							zIndex: item.type === "Supervisor answer" ? 999 : 0,
							onClick: () => {
								onShapeClicked && onShapeClicked(item)
							},
							styles: {
								fillColor: item.isDeleted ? SHAPE_COLORS_CONSTS.red : item.colorInfo.fillColor,
								visible: item.visible,
								strokeColor: item.isDeleted ? SHAPE_COLORS_CONSTS.red : item.colorInfo.strokeColor,
								strokeWeight: clickedItemId === item.id ? 5 : 2,
								fillOpacity: item.isDeleted ? 0.1 : 0.3
							}

						})
						break;
					}



					case "DiagonalRectangle": {

						mapShapes.push({
							...shape,
							isEditable: !!item.isEditable,
							zIndex: item.type === "Supervisor answer" ? 999 : 0,
							styles: {
								fillColor: item.isDeleted ? SHAPE_COLORS_CONSTS.red : item.colorInfo.fillColor,
								visible: item.visible,
								strokeColor: item.isDeleted ? SHAPE_COLORS_CONSTS.red : item.colorInfo.strokeColor,
								strokeWeight: clickedItemId === item.id ? 5 : 2,
								fillOpacity: item.isDeleted ? 0.1 : 0.3
							},

							onClick: () => {
								onShapeClicked && onShapeClicked(item)
							},
							onRightClick: (pointOrderToDelete) => this.props.onDiagonalRectangleMarkerRightClick(pointOrderToDelete),
							onDiagonalRectanglePointDrag: (pointOrder, newPoint) => this.props.onEditDiagonalRectanglePoint(pointOrder, newPoint),

						})
						break;
					}


				}
			})
		})


		// Convert Rectangles bounding box to ne sw type
		_.forEach(mapShapes, shape => {
			if (shape.type === "Rectangle") {
				shape.boundingBox = ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(shape.boundingBox)
			}
		})

		return mapShapes
	}



	static coordinatesParse = ({
		cornersToMinMax: ({ sw, ne }) => ({
			minLat: parseFloat(sw.lat), minLon: parseFloat(sw.lng), maxLat: parseFloat(ne.lat), maxLon: parseFloat(ne.lng)
		}),
		minMaxToCorners: ({ minLon, minLat, maxLon, maxLat }) => ({
			sw: {
				lat: minLat, lng: minLon
			},
			ne: {
				lat: maxLat, lng: maxLon
			}
		}),
		getCenterFromCorners: (bb) => {
			if (!bb.sw || !bb.ne)
				return null
			const generateCenter = (max, min) => {
				let subtract = max - min;
				return parseFloat((subtract / 2) + min)
			}


			return { lat: generateCenter(bb.ne.lat, bb.sw.lat), lng: generateCenter(bb.ne.lng, bb.sw.lng) }
		}
	})




	generateDrawerObject = () => {
		const { drawingModes } = this.state;

		return {
			isVisible: true,
			position: 2,
			drawingModes: drawingModes,
			onMarkerDraw: this.onDiagonalRectanglePointAdd,
			onRectangleDraw: this.onRectangleDraw,
		}

	}



	onDiagonalRectanglePointAdd = (point) => {
		const { itemIdCurrentlyEditingBB, addDiagonalRectanglePointToExistingShape, onShapeDraw } = this.props
		if (itemIdCurrentlyEditingBB) {
			addDiagonalRectanglePointToExistingShape(point)
			return
		}


		const { currentlyAddedDiagonalRectanglePoints } = this.state

		if (currentlyAddedDiagonalRectanglePoints.length === 2) {
			onShapeDraw({ type: "DiagonalRectangle", points: [...currentlyAddedDiagonalRectanglePoints, point] })

			// reset points array
			this.setState({ currentlyAddedDiagonalRectanglePoints: [] })
		} else {
			this.setState(pState => ({ currentlyAddedDiagonalRectanglePoints: [...pState.currentlyAddedDiagonalRectanglePoints, point] }))
		}
	}



	onRectangleDraw = ({ minLat, minLon, maxLat, maxLon }) => {
		//TODO : implement in map/V3 the ability to set the returned type from drawing corners OR minMax
		this.props.onShapeDraw({ type: "Rectangle", boundingBox: { minLat, minLon, maxLat, maxLon } })
	}



	render() {
		const { zoom } = this.state;
		const { center } = this.props
		const mapItems = this.prepareMapShapes()
		const drawer = this.generateDrawerObject()


		return <div style={{ width: "100%", height: "100%", margin: "0 auto" }}>
			<Map
				defaultCenter={center}
				center={center}
				zoom={zoom}
				drawer={drawer}
				listToRender={mapItems}
			/>
		</div>
	}
}