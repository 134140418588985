const getApproveStoreFromState = (state) => state.approverStore
export const getApproveStoreDataState = (state) => getApproveStoreFromState(state).data

export const getApproverSendBack = (state) => getApproveStoreFromState(state).data.approverSendBack


export const getTableItemsSelections = (state) => getApproveStoreFromState(state).tableItemsSelections
export const getApproveCommentBoxValue = (state) => getApproveStoreFromState(state).approveCommentBoxValue

export const getApprovalSubtaskBoundingBoxFromStore = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).subtaskBoundingbox
export const getPaintersDataFromApprovalStore = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).paintingData
export const getSupervisorDataFromApprovalStore = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).supervisorData
export const getDbSnapshotDataFromApprovalStore = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).dbSnapshotData
export const getDbSnapshotPhaseFOurDataFromApprovalStore = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).phaseFourDbSnapshotsAreas
export const getSnapshotWhileSupervisorDataFromApprovalStore = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).snapshotWhileSuperviseData
export const getExistingShapesAddedBySubtaskSupervisor = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).existingShapesAddedBySubtaskSupervisor
export const getExistingShapesNotAddedBySubtaskSupervisor = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).existingShapesNotAddedBySubtaskSupervisor

export const getApproverAnswersFromState = (state) => getApproveStoreFromState(state).approverAnswers
export const getApproverScore = (state) => getApproverAnswersFromState(state).score

export const getPhaseOneAnswerFromState = (state) => getApproverScore(state).shapes

export const getSupervisorNameFromState = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).supervisorName
export const getSuperviseTimeFromState = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).superviseTime
export const getSubtaskNameFromState = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).subtaskName
export const getSupervisorCommentFromState = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).supervisorComment
export const getRemainingSubtasksToApprove = (state) => getApproveStoreDataState(state) && getApproveStoreDataState(state).remainingSubtasks && getApproveStoreDataState(state).remainingSubtasks.ready_to_approve


export const getApproverAnswerForShapes = (state) => getApproverScore(state) && getApproverScore(state).shapes
export const getApproverAnswerForTags = (state) => getApproverScore(state) && getApproverScore(state).tags
export const getApproverAnswerForGrading = (state) => getApproverScore(state) && getApproverScore(state).grading

export const getApproverFilter = (state) => getApproveStoreFromState(state).filter

export const getCurrentUsername = (state) => {
	return (state.authStore && state.authStore.user && state.authStore.user.profile && state.authStore.user.profile.name) || ""
}
