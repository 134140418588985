
import React from "react"
import { Map } from "../../../common/components";
import _ from "lodash"
import { SHAPE_COLORS_CONSTS } from "../../genericTableComponents/shapeColor/shapeColors"


export class ApprovePhaseMapContainer extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			zoom: 16,
			drawingModes: [null]
		}
	}


	componentDidMount() {
		window.addEventListener("keydown", this.onBackspacePressed);
		window.addEventListener("keydown", this.onDownPressed);
		window.addEventListener("keydown", this.onUpPressed);
		window.addEventListener("keypress", this.onSpacePress);
		window.addEventListener("keyup", this.onSpaceUp);
	}


	componentWillUnmount() {
		window.removeEventListener("keydown", this.onBackspacePressed)
		window.removeEventListener("keydown", this.onDownPressed)
		window.removeEventListener("keydown", this.onUpPressed)
		window.removeEventListener("keypress", this.onSpacePress)
		window.removeEventListener("keyup", this.onSpaceUp)
	}


	onSpacePress = (event) => {
		if (this.props.isEditing && (event.isComposing || event.keyCode === 32)) {
			this.setState({ drawingModes: [null, "rectangle"] })
		}
	}

	onSpaceUp = (event) => {
		if (this.props.isEditing && (event.isComposing || event.keyCode === 32)) {
			this.setState({ drawingModes: ["rectangle", null] })
		}
	}


	onBackspacePressed = event => {
		const { onKeyboardBackspacePressed } = this.props
		if (event.isComposing || (event.keyCode === 8 && event.ctrlKey)) {
			onKeyboardBackspacePressed && onKeyboardBackspacePressed()
		}
	}


	onDownPressed = event => {
		const { onKeyboardDownPressed } = this.props
		if (event.isComposing || event.keyCode === 40) {
			event.preventDefault()
			onKeyboardDownPressed && onKeyboardDownPressed()
		}
	}


	onUpPressed = event => {
		const { onKeyboardUpPressed } = this.props
		if (event.isComposing || event.keyCode === 38) {
			event.preventDefault()
			onKeyboardUpPressed && onKeyboardUpPressed()
		}
	}


	prepareMapShapes = () => {
		const { items, subtaskBoundingBox, onRectangleClick, clickedItemId } = this.props
		const mapShapes = []
		// Generating a shape for subtask bounding box
		mapShapes.push({
			boundingBox: subtaskBoundingBox,
			type: "Rectangle",
			zIndex: 1,
			styles: {
				fillColor: "transparent",
				visible: true,
				strokeColor: "red"
			}

		})


		_.forEach(items, item => {
			item && item.shapes && item.shapes.length > 0 && _.forEach(item.shapes, shape => {

				mapShapes.push({
					isEditable: item.isEditable,
					boundingBox: shape,
					type: "Rectangle",
					zIndex: item.type === "Supervisor answer" ? 999 : 0,
					onClick: () => {
						onRectangleClick && onRectangleClick(item)
					},
					styles: {
						fillColor: item.isDeleted ? SHAPE_COLORS_CONSTS.red : item.colorInfo.fillColor,
						visible: item.visible,
						strokeColor: item.isDeleted ? SHAPE_COLORS_CONSTS.red : item.colorInfo.strokeColor,
						strokeWeight: clickedItemId === item.id ? 5 : 2,
					}

				})
			})
		})

		return mapShapes
	}




	static coordinatesParse = ({
		cornersToMinMax: ({ sw, ne }) => ({
			minLat: parseFloat(sw.lat), minLon: parseFloat(sw.lng), maxLat: parseFloat(ne.lat), maxLon: parseFloat(ne.lng)
		}),
		minMaxToCorners: ({ minLon, minLat, maxLon, maxLat }) => ({
			sw: {
				lat: minLat, lng: minLon
			},
			ne: {
				lat: maxLat, lng: maxLon
			}
		}),
		getCenterFromCorners: (bb) => {
			if (!bb.sw || !bb.ne)
				return null
			const generateCenter = (max, min) => {
				let subtract = max - min;
				return parseFloat((subtract / 2) + min)
			}


			return { lat: generateCenter(bb.ne.lat, bb.sw.lat), lng: generateCenter(bb.ne.lng, bb.sw.lng) }
		}
	})


	componentDidUpdate(prevProps) {
		// Typical usage (don't forget to compare props):
		if (this.props.isEditing !== prevProps.isEditing) {
			if (this.props.isEditing) {
				this.setState({ drawingModes: ["rectangle", null] })
			}
			else {
				this.setState({ drawingModes: [null] })
			}
		}
	}

	render() {
		const { zoom, drawingModes } = this.state;
		const { center } = this.props;
		return <div style={{ width: "100%", height: "100%", margin: "0 auto" }}>
			<Map
				defaultCenter={center}
				center={center}
				zoom={zoom}
				drawer={{
					isVisible: true,
					position: 2,
					drawingModes: drawingModes,
					onRectangleDraw: ({ minLat, minLon, maxLat, maxLon }) => {
						const boundingBox = {
							ne: { lat: maxLat, lng: maxLon },
							sw: { lat: minLat, lng: minLon }
						}
						this.props.onShapeDraw({ boundingBox })

					},
				}}
				listToRender={this.prepareMapShapes()}
			/>
		</div>
	}
}