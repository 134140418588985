
const { PAINT } = require("../reduxConsts").ACTION_TYPES



export const cleanError = () => ({ type: PAINT.CLEAN_ERROR })






/* * * * * * * * * * * * * * * * * async actions * * * * * * * * * * * * * * */
/**@param {String} req async start function*/
export const async_start = () => ({ type: PAINT.ASYNC_START })

/**@param {Object} data async success function */
export const async_success = () => ({ type: PAINT.ASYNC_SUCCESS })

/**@param {String} error async error function */
export const async_error = error => ({ type: PAINT.ASYNC_ERROR, value: error })
/* * * * * * * * * * * * * * * * * async actions * * * * * * * * * * * * * * */


/* * * * * * * * * * * * * * * * *SAGA ACTIONS* * * * * * * * * * * * * * */

// TRIGGER FETCH SUBTASK SAGA
export const get_random_subtask = () => ({ type: PAINT.FETCH_SUB_TASK })

// WILL LOAD THE SUBTASK DATA AFTER FETCH
export const load_sub_task_for_paint = sub_task => ({ type: PAINT.LOAD_SUB_TASK_FOR_ANSWER, value: sub_task })

export const clean_subtask_from_store = () => ({ type: PAINT.CLEAN_SUBTASK })

// WILL TRIGGER POST ANSWER TO SUBTASK SAGA
export const post_subtask_answer = (answer) => ({ type: PAINT.POST_ANSWER, value: { answer } })

/* * * * * * * * * * * * * * * * *SAGA ACTIONS* * * * * * * * * * * * * * */


/* * * * * * * * * * * * * * * * * * *ANSWER ACTIONS * * * * * * * * * * * * * * */
export const resetStore = () => ({ type: PAINT.RESET_STORE })
export const setStatus = status => ({ type: PAINT.SET_ANSWER_STATUS, value: status })
export const addAnswerShape = shape => ({ type: PAINT.ADD_ANSWER_AREA, value: shape })
export const replaceAnswerAreasArray = areasArray => ({ type: PAINT.REPLACE_ANSWER_OBJECT_AREAS_ARRAY, value: areasArray })
export const addConflictText = text => ({ type: PAINT.SET_ANSWER_CONFLICT_TEXT, value: text })
export const setError = (e) => ({ type: PAINT.SET_ERROR, value: e })
export const cleanAnswerObject = () => ({ type: PAINT.CLEAN_ANSWER })
export const focusMap = (bb) => ({ type: PAINT.MAP_FOCUS, value: bb })
export const clearFocus = () => ({ type: PAINT.CLEAR_FOCUS })
export const shapeClicked = (s) => ({ type: PAINT.SHAPE_CLICKED, value: s })
export const onShapeEdit = (s) => ({ type: PAINT.ON_EDIT_SHAPE, value: s })
export const onDoneEdit = () => ({ type: PAINT.ON_DONE_EDIT })
export const onDeleteShape = (id) => ({ type: PAINT.ON_DELETE_SHAPE, value: id })
// export const resetEditShapeID = () => ({ type: PAINT.RESET_EDIT_SHAPE_ID })

// export const setSubtaskID = id => ({ type: PAINT.SET_ANSWER_SUBTASK_ID, value: id })
export const removeAnswerShapes = () => ({ type: PAINT.REMOVE_ANSWER_AREAS })
/* * * * * * * * * * * * * * * * * * *ANSWER ACTIONS * * * * * * * * * * * * * * */