import React from "react"
import "./shapeVisibility.css"
import ControlledInputPure from "../../hoc/ControlledInputPure"
import { Glyphicon } from "react-bootstrap"


export class GtShapeVisibility extends React.Component {
	componentDidMount() {
		const { bindKeyListenerToItem } = this.props
		if (bindKeyListenerToItem) {
			window.addEventListener("keydown", this.bindKeyToAction)
		}
	}


	bindKeyToAction = (event) => {
		const { item, isVisible, isAnyTextInputFocused } = this.props
		if (isAnyTextInputFocused) return null

		const { triggerKey, action } = this.props.bindKeyListenerToItem
		if (event.isComposing || event.keyCode === `${triggerKey}`.charCodeAt(0)) {
			action && action(item.id, isVisible === -1 ? -1 : !isVisible)
		}
	}


	onVisibilityChange = (element) => {
		const { changeVisibility, item } = this.props
		const isVisible = element.target.checked
		changeVisibility(item.id, isVisible)
	}


	componentWillUnmount() {
		window.removeEventListener("keydown", this.bindKeyToAction)
	}

	render() {
		const { isVisible } = this.props
		return (<div className="GT_ShapeVisibility">
			{isVisible === -1 && <div className="disabled"><Glyphicon glyph="ban-circle" size="lg" /></div>}
			{isVisible !== -1 && <ControlledInputPure
				required={false}
				height="20px"
				width="20px"
				type="checkbox"
				checked={isVisible}
				onChangeValue={this.onVisibilityChange}
				controlledField="Display" />}
		</div>)
	}
}
