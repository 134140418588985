import React from "react";
import { MenuItem, DropdownButton } from "react-bootstrap"
import propTypes from "prop-types";
import ControlledInputPure from "../../../hoc/ControlledInputPure"


const MultilevelPicker = ({ isComplex, top = "Types", bottom = "Types", topHandler, bottomHandler, complexHandler, defaultType = "Types" }) => {

	const TYPES = ["Types", "Car", "Pedestrian", "Train", "Boat"]


	const onIsComplexChange = (isChecked) => {
		topHandler(null)
		bottomHandler(null)
		complexHandler(isChecked)
	}

	const dropDownMultilevelType = (title) =>
		<DropdownButton
			disabled={isComplex}
			id="ApproverPhaseFourTypeDropdown"
			bsSize="xs"
			title={title === "Top" ? top : bottom}
		>
			{TYPES.map((t, i) =>
				<MenuItem
					disabled={isComplex || t === "Types" || t === defaultType}
					active={title === "Top" ? t === top : t === bottom}
					eventKey={i}
					onSelect={() => { title === "Top" ? topHandler(t) : bottomHandler(t) }}
					key={`${title}${i}`}
				>{t}</MenuItem>
			)
			}
		</DropdownButton >





	const style = { margin: "5px 0", textAlignLast: "left" };

	return (
		<div style={{ width: "100%", height: "100%" }}>

			<div style={style}>
				<small style={{ margin: "0 3px" }}>Top: </small>
				{dropDownMultilevelType("Top")}
			</div>

			<div style={style}>
				<small style={{ margin: "0 3px" }}>Bottom: </small>
				{dropDownMultilevelType("Bottom")}
			</div>

			<div style={{ display: "flex", alignItems: "center" }}>
				<ControlledInputPure
					// disabled={ }
					required={false}
					height={"25"}
					width={"25"}
					type="checkbox"
					checked={isComplex}
					onChangeValue={(e) => { onIsComplexChange(e.target.checked) }}
					controlledField=">2 multilevel" />
				<small style={{ margin: "0 3px" }}> Is complex multilevel</small>

			</div>


		</div>

	)



}


MultilevelPicker.propTypes = {
	top: propTypes.oneOf(["Types", "Car", "Pedestrian", "Train", "Boat"]),
	bottom: propTypes.oneOf(["Types", "Car", "Pedestrian", "Train", "Boat"]),
	topHandler: propTypes.func.isRequired,
	isComplex: propTypes.bool,
	complexHandler: propTypes.func.isRequired,
	bottomHandler: propTypes.func.isRequired


}


export default MultilevelPicker



