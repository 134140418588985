import { put, call, takeLatest, all, spawn, select } from "redux-saga/effects";
import { ACTION_TYPES, TIME_INTERVALS } from "../reduxConsts";
import { SafeSaga } from "../../common/modules"


import {
	clearReport,
	setReportResponse,
	async_start, async_success

} from "../report/reportActions";

import { getUserGroups } from "../../utils/utils";

const reportUrls = {
	getReport: "api/report"
}

function* fetchReport(network, value) {

	const state = yield select()
	const reportStore = state.reportStore
	const body = reportStore.interval
	// dispatch async start action
	yield put(async_start());
	const reportResponse = yield call(
		network.sendPost,
		reportUrls.getReport,
		body
	);


	if (!reportResponse) {
		yield put(clearReport())
	}


	else {
		yield put(setReportResponse({
			report: [...reportResponse.paintedAndSupervisedCount || []],
			sentBackWaiting: [...reportResponse.sentBackWaiting || []],
			waitingReapprove: [...reportResponse.waitingReapprove || []],
		}));
	}

	return yield put(async_success());
}

function* getReportsSortedByCustomInterval(network) {
	yield takeLatest(ACTION_TYPES.REPORT.SET_REPORT_CUSTOM_INTERVAL, SafeSaga(function* ({ value }) {
		yield call(fetchReport, network, value)
	}))
}


function* getReportsSortedByInterval(network) {
	yield takeLatest(ACTION_TYPES.REPORT.SET_REPORT_INTERVAL_TYPE, SafeSaga(function* ({ value }) {
		if (value !== TIME_INTERVALS.CUSTOM.system) {
			yield call(fetchReport, network, value)
		}
	}))
}


function* get_report(network) {
	yield takeLatest(ACTION_TYPES.REPORT.FETCH_REPORT, SafeSaga(function* ({ value }) {
		yield call(fetchReport, network, value)
	}))
}



export function* reportSaga(network) {
	yield all([
		spawn(getReportsSortedByCustomInterval, network),
		spawn(getReportsSortedByInterval, network),
		spawn(get_report, network),
	])
}
