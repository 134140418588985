import React, { Component } from "react";
import { connect } from "react-redux";
import isequal from "lodash.isequal";

import { Map } from "../../../common/components";

import {
	fetchShapeWithBoundingBox,
	clearFocus,
	onShapeDraw,
	onShapeClick,
	editDiagonalRectanglePoint,
	removePointOfDiagonalRectangleInEdit,
	removeDiagonalRectangleFromCurrentlyAddedShape,
	addDiagonalRectangleToCurrentlyAddedShape,
	updatePointOfDiagonalRectangleCurrentlyAddedShape,
} from "../../../redux/supervise/superviseAction";


import COLORS from "../colorConsts";
import { ShapeUtils } from "../../../common/sharedGlobal";



class MapContainer extends Component {
	constructor(props) {
		super(props)

		this.state = {
			defaultCenter: null,
			currentCenter: null,
			zoom: 16,
			drawerToolModes: ["rectangle", "marker", null],

			colorsConsts: {
				inRadiusShapes: {
					strokeColor: COLORS.green, fillColor: COLORS.transparent, zIndex: -2, opacity: 0.5
				},
				subtaskShape: {
					strokeColor: COLORS.red, fillColor: COLORS.transparent, zIndex: -1
				},
				appliedShapes: {
					strokeColor: COLORS.blue, fillColor: COLORS.transparent, zIndex: 999
				},

				approverSuggestions: {
					strokeColor: COLORS.black, fillColor: COLORS.transparent, zIndex: 998
				},

			},
		}

	}



	componentDidMount() {
		const { subtask } = this.props;
		setTimeout(() => {
			// calculate the full bounding box if painter answer includes bounding boxes
			const shapesInMapboundingBoxes = [subtask.boundingBox]

			subtask.results.map(({ areas = [] }) => areas.map(area => shapesInMapboundingBoxes.push(area.boundingBox)))
			const boundsToFocus = ShapeUtils.RECTANGLE.biggestBoundingBox(shapesInMapboundingBoxes);
			this.setState({
				currentCenter: ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(boundsToFocus)
			})
		}, 1);

		window.addEventListener("keypress", this.onSpacePress);
		window.addEventListener("keyup", this.onSpaceUp);
	}




	onSpacePress = (event) => {
		if (this.props.isEditing && (event.isComposing || event.keyCode === 32)) {
			this.setState({ drawerToolModes: [null, "rectangle"] })
		}
	}


	onSpaceUp = (event) => {
		if (this.props.isEditing && (event.isComposing || event.keyCode === 32)) {
			this.setState({ drawerToolModes: ["rectangle", null] })
		}
	}


	static getDerivedStateFromProps(props, state) {
		let newState = state

		if (props.focus) {
			const minMaxFocus = { minLat: parseFloat(props.focus.sw.lat), minLon: parseFloat(props.focus.sw.lng), maxLat: parseFloat(props.focus.ne.lat), maxLon: parseFloat(props.focus.ne.lng) }
			newState = {
				...newState,
				currentCenter: isequal(state.currentCenter, minMaxFocus) ? state.currentCenter : minMaxFocus
			}
		}



		newState = {
			...newState,
			drawerToolModes: ["rectangle", "marker", null]
		}

		if (props.currentlyAddedDiagonalRectanglePoints.length > 0) {
			newState = {
				...newState,

				drawerToolModes: ["marker", null]
			}
		}


		if (props.editShapeID) {
			const editedShape = props.supervisorResultObject.appliedShapes.find(s => s.id === props.editShapeID)
			switch (editedShape.shape.type) {
				case "Rectangle": {
					newState = {
						...newState,
						drawerToolModes: [null]
						// drawerToolModes: ["rectangle", null]
					}
					break;
				}

				case "DiagonalRectangle": {
					newState = {
						...newState,
						drawerToolModes: [null]
						// drawerToolModes: ["marker", null]

					}
					break;
				}
			}
		}

		return newState
	}



	editDiagonalRectanglePointOfTemp = (pointOrder, newPoint) => {
		const { editDiagonalRectanglePoint } = this.props
		editDiagonalRectanglePoint(pointOrder, newPoint)
	}


	onInAddOfDiagonalRectanglePointEdit = (pointOrder, newPoint) => {
		const { updatePointOfDiagonalRectangleCurrentlyAddedShape } = this.props
		updatePointOfDiagonalRectangleCurrentlyAddedShape(pointOrder, newPoint)
	}


	// Delete a point from a  Diagonal Rectangle that is beeing added
	onInAddOfDiagonalRectangleMarkerRightClick = (pointOrderToDelete) => {
		const { removeDiagonalRectangleFromCurrentlyAddedShape } = this.props
		removeDiagonalRectangleFromCurrentlyAddedShape(pointOrderToDelete)
	}




	onMarkerRightClick = (pointOrderToDelete) => {
		const { removePointOfDiagonalRectangleInEdit } = this.props
		removePointOfDiagonalRectangleInEdit(pointOrderToDelete)
	}



	prepareDrawList = () => {
		// init the array of shapes to present in map 
		let returnDrawList = [];

		const { supervisorResultObject, subtask, shapesInSubtaskRadius, resultsColorDisplayHandler, currentlyAddedDiagonalRectanglePoints,
			editShapeID, displayShapesInSubtaskRadius, displayApproverSendBackShapes, approverSuggestionColorDisplayHandler } = this.props
		const { colorsConsts } = this.state


		// add applied shapes, while approving the admin will draw shapes to be applied
		supervisorResultObject.appliedShapes.map(s => {

			switch (s.shape.type) {
				case "Rectangle": {
					returnDrawList.push(
						{
							...s.shape,
							styles: colorsConsts.appliedShapes,
							onClick: () => { this.props.onShapeClick(s.id) },
							isEditable: s.id === editShapeID
						}
					);
					break
				}

				case "DiagonalRectangle": {
					returnDrawList.push(
						{
							...s.shape,
							styles: colorsConsts.appliedShapes,
							isEditable: s.id === editShapeID,
							onClick: () => { this.props.onShapeClick(s.id) },
							onRightClick: (pointOrderToDelete) => this.onMarkerRightClick(pointOrderToDelete),
							onDiagonalRectanglePointDrag: (pointOrder, newPoint) => this.editDiagonalRectanglePointOfTemp(pointOrder, newPoint),
						}
					);
					break
				}

				default:
					return null
			}


		})


		// Create a shape for currently added Diagonal Rectangle - if exists
		if (currentlyAddedDiagonalRectanglePoints.length > 0) {
			returnDrawList.push({
				type: "DiagonalRectangle",
				styles: colorsConsts.appliedShapes,
				isEditable: true,
				points: [...currentlyAddedDiagonalRectanglePoints],
				onRightClick: (pointOrderToDelete) => this.onInAddOfDiagonalRectangleMarkerRightClick(pointOrderToDelete),
				onDiagonalRectanglePointDrag: (pointOrder, newPoint) => this.onInAddOfDiagonalRectanglePointEdit(pointOrder, newPoint),
				// onDiagonalRectanglePointDragEnd: (pointOrder, newPoint) => this.onDiagonalRectanglePointDragEnd(pointOrder, newPoint),
			})
		}

		// add the subtask boundingBox with color settings to the draw list to be sent to the map component
		returnDrawList.push({ boundingBox: { ...subtask.boundingBox }, type: "Rectangle", styles: { ...colorsConsts.subtaskShape, visible: true } })


		// add each answer shape if any to the draw list to be sent to the map component
		shapesInSubtaskRadius.length > 0 && shapesInSubtaskRadius.map((drawArea) => {
			return returnDrawList.push(
				{
					...drawArea.shape,
					styles: { ...colorsConsts.inRadiusShapes, visible: displayShapesInSubtaskRadius }
				}
			)
		})



		// add approverSendBack items TODO:
		subtask.approverSendBack && subtask.approverSendBack.approverSuggestions && subtask.approverSendBack.approverSuggestions.map((drawArea) => {
			return returnDrawList.push(
				{
					boundingBox: drawArea.boundingBox,
					type: "Rectangle",
					styles: { ...approverSuggestionColorDisplayHandler, visible: displayApproverSendBackShapes }
				}
			)
		})


		subtask.results.map(({ _id, areas }) => {

			const resultStyles = resultsColorDisplayHandler.filter(r => r.id === _id)[0]

			return areas.map(a => {
				return returnDrawList.push(
					{
						boundingBox: a.boundingBox,
						type: "Rectangle",
						styles: { fillColor: resultStyles.colorInfo.fillColor === "default" ? COLORS.transparent : resultStyles.colorInfo.fillColor, visible: resultStyles.visible, strokeColor: "#000000" }
					})

			})
		})

		return returnDrawList

	}



	onDiagonalRectanglePointAdd = (point) => {
		const { addDiagonalRectangleToCurrentlyAddedShape, onShapeDraw, currentlyAddedDiagonalRectanglePoints } = this.props
		addDiagonalRectangleToCurrentlyAddedShape(point)
		if (currentlyAddedDiagonalRectanglePoints.length === 2) {
			onShapeDraw({ type: "DiagonalRectangle", points: [...currentlyAddedDiagonalRectanglePoints, point] })
		}

	}



	generateDrawerObject = () => {
		const { drawerToolModes } = this.state;
		return {
			isVisible: true,
			position: 2,
			drawingModes: drawerToolModes,
			onMarkerDraw: this.onDiagonalRectanglePointAdd,
			onRectangleDraw: ({ minLat, minLon, maxLat, maxLon }) => {
				//TODO : implement in map/V3 the ability to set the returned type from drawing corners OR minMax
				const boundingBox = {
					ne: { lat: maxLat, lng: maxLon },
					sw: { lat: minLat, lng: minLon }
				}
				this.props.onShapeDraw({ type: "Rectangle", boundingBox });
			},
		}

	}


	componentWillUnmount() {
		this.props.clearFocus()
		window.removeEventListener("keypress", this.onSpacePress)
		window.removeEventListener("keyup", this.onSpaceUp)
	}


	render() {
		const { zoom, currentCenter } = this.state;
		const { subtask } = this.props;
		const mapItems = this.prepareDrawList()
		const drawer = this.generateDrawerObject()
		return (
			<div style={{ width: "100%", height: "100%", margin: "0 auto" }}>
				{subtask &&
					<Map
						center={currentCenter}
						zoom={zoom}
						drawer={drawer}
						listToRender={mapItems}
					/>
				}
			</div>
		);
	}
}


const mapStateToProps = ({ supervisorStore }) => ({
	focus: supervisorStore.focus,
	subtask: supervisorStore.subtask,
	supervisorResultObject: supervisorStore.supervisorResultObject,
	shapesInSubtaskRadius: supervisorStore.shapesInSubtaskRadius,
	resultsColorDisplayHandler: supervisorStore.resultsColorDisplayHandler,
	editShapeID: supervisorStore.editShapeID,
	currentlyAddedDiagonalRectanglePoints: supervisorStore.currentlyAddedDiagonalRectanglePoints,
	displayShapesInSubtaskRadius: supervisorStore.displayShapesInSubtaskRadius,
	displayApproverSendBackShapes: supervisorStore.displayApproverSendBackShapes,
	approverSuggestionColorDisplayHandler: supervisorStore.approverSuggestionColorDisplayHandler,
})


const mapDispatchToProps = (dispatch) => ({
	fetchShapeWithBoundingBox: (boundingBox) => dispatch(fetchShapeWithBoundingBox(boundingBox)),
	clearFocus: () => dispatch(clearFocus()),
	onShapeDraw: (bb) => dispatch(onShapeDraw(bb)),
	onShapeClick: (id) => dispatch(onShapeClick(id)),
	editDiagonalRectanglePoint: (pointOrder, newPoint) => dispatch(editDiagonalRectanglePoint(pointOrder, newPoint)),
	removePointOfDiagonalRectangleInEdit: (pointOrderToDelete) => dispatch(removePointOfDiagonalRectangleInEdit(pointOrderToDelete)),
	addDiagonalRectangleToCurrentlyAddedShape: (point) => dispatch(addDiagonalRectangleToCurrentlyAddedShape(point)),
	removeDiagonalRectangleFromCurrentlyAddedShape: (pointOrderToDelete) => dispatch(removeDiagonalRectangleFromCurrentlyAddedShape(pointOrderToDelete)),
	updatePointOfDiagonalRectangleCurrentlyAddedShape: (pointOrder, newPoint) => dispatch(updatePointOfDiagonalRectangleCurrentlyAddedShape(pointOrder, newPoint)),
})


export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MapContainer);
