// import saga helpers
import { put, takeLatest, call, spawn, all } from "redux-saga/effects";
import { updateTaskList, resetAddEditState, updateMapState, addSubtasksToTask } from "./../tasks/tasksActions";

import { SafeSaga } from "../../common/modules"

// import action type
import { ACTION_TYPES, MAP_STATE } from "../reduxConsts";
import { ShapeUtils } from "../../common/sharedGlobal";



const TasksUrls = {
	getTasks: "api/tasks",
	deleteTask: "api/tasks",
	postTask: "api/tasks/upsert",
	// getSubtasks: "api/subtasks/parentid/",
}

//ACTION_TYPES.TASK.ASYNC_GET_TASKS
function* getTasks(network) {

	yield takeLatest(ACTION_TYPES.TASK.ASYNC_GET_TASKS, function* () {


		// make the api call
		const response = yield call(network.sendGet, TasksUrls.getTasks);

		// if all ok then dispatch success
		yield put(updateTaskList(response.data));

	})

}

//ACTION_TYPES.TASK.ASYNC_ADD_TASK
function* postTask(network) {

	yield takeLatest(ACTION_TYPES.TASK.ASYNC_ADD_TASK, SafeSaga(function* ({ value }) {

		// get th data from the action.value
		const { task } = value;

		//convert shape bounding box from new sw to min max format 
		const tempTask = _.cloneDeep(task)
		tempTask.shape.boundingBox = ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(tempTask.shape.boundingBox)

		// make the api call
		const response = yield call(network.sendPost, TasksUrls.postTask, tempTask);



		if (response !== undefined) {	// dispatch a  success action with the new data
			yield put(updateTaskList(response.data));

			// dispatch map state change to the store to tell the ui to change
			yield put(updateMapState(MAP_STATE.READ));

			// dispatch a reset temp shape 
			yield put(resetAddEditState());
		}


	}))

}

//ACTION_TYPES.TASK.ASYNC_GET_SUBTASKS
// function* getSubtasks(network) {

// 	yield takeLatest(ACTION_TYPES.TASK.ASYNC_GET_SUBTASKS, SafeSaga(function* ({ value }) {

// 		// get th data from the action.value
// 		const { id } = value;
// 		const response = yield call(network.sendGet, TasksUrls.getSubtasks + id, id);

// 		if (response !== undefined)
// 			// dispatch a  success action with the new data
// 			yield put(addSubtasksToTask(response.data, id));
// 	}))

// }

//ACTION_TYPES.TASK.ASYNC_DELETE_TASK
function* deleteTask(network) {

	yield takeLatest(ACTION_TYPES.TASK.ASYNC_DELETE_TASK, SafeSaga(function* ({ value }) {

		const { id } = value;


		const response = yield call(network.sendDelete, TasksUrls.deleteTask, { id });

		if (response !== undefined)
			yield put(updateTaskList(response.data));
	}))

}




export function* tasksSaga(network) {
	yield all([
		spawn(getTasks, network),
		spawn(postTask, network),
		// spawn(getSubtasks, network),
		spawn(deleteTask, network),
	])
}



