// import saga helpers
import { put, call, takeLatest, spawn, all, } from "redux-saga/effects";
import { SafeSaga } from "../../common/modules"


// import action type
import { ACTION_TYPES } from "../reduxConsts";



import {
	clean_subtask_from_store,
	load_sub_task_for_paint,
	async_start,
	async_success,
	cleanAnswerObject,
	resetStore
} from "../paint/paintAction";

import { ShapeUtils } from "../../common/sharedGlobal";


const PaintUrls = {
	getRandomSubtask: "api/subtasks/painter/random",
	postSubtaskAnswer: "api/subtasks/painter/result",
}

const isResponseHaveSubtask = (res) => {
	const objectKeys = Object.keys(res);
	return objectKeys.length > 0 && objectKeys.indexOf("painting") > -1

}

function* getRandomSubtask(network) {

	yield takeLatest(ACTION_TYPES.PAINT.FETCH_SUB_TASK, SafeSaga(function* () {

		yield put(cleanAnswerObject());

		// dispatch async start action
		yield put(async_start());

		// make the api call
		const response = yield call(network.sendGet, PaintUrls.getRandomSubtask);

		let { data = {} } = response

		if (data && isResponseHaveSubtask(data)) {

			// clean last subtask data
			yield put(clean_subtask_from_store())

			// check if the response have painting object
			// if not empty then load the new subtask
			yield put(load_sub_task_for_paint(data))
		}
		// call success
		yield put(async_success())


	}))

}

function* postSubtaskAnswer(network) {

	yield takeLatest(ACTION_TYPES.PAINT.POST_ANSWER, SafeSaga(function* ({ value }) {





		// get the access token from the action value
		const { answer } = value;
		// clean answer obj
		let answerAfterReshape = _.cloneDeep(answer)
		answerAfterReshape.subtaskId = answerAfterReshape.subtask_id
		if (answerAfterReshape.areas) {
			answerAfterReshape.areas = answerAfterReshape.areas.map(area => ({
				...area.shape,
				boundingBox: ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(area.shape.boundingBox)
			}))
		}

		// dispatch async start action
		yield put(async_start());

		// make the api call
		const postSubtaskRes = yield call(network.sendPost, PaintUrls.postSubtaskAnswer, answerAfterReshape);


		let { data = {} } = postSubtaskRes

		// clean last subtask data
		yield put(resetStore())
		// yield put(clean_subtask_from_store())

		// check if the response have painting object
		if (isResponseHaveSubtask(data))
			// if not empty then load the new subtask
			yield put(load_sub_task_for_paint(data))
		// call success
		yield put(async_success())


	})
	)
}





export function* paintSaga(network) {
	yield all([
		spawn(postSubtaskAnswer, network),
		spawn(getRandomSubtask, network)
	])
}
