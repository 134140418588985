import React from "react";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { getApproveCommentBoxValue } from "../../../../redux/approve/selectors";
import { updateApproveCommentBoxValue } from "../../../../redux/approve/approverActions";
import "./approveCommentBox.css"

class ApproveCommentBox extends React.Component {

	onValueChange = (e) => {
		const { updateApproveCommentBoxValueAction } = this.props
		const value = e.target.value
		updateApproveCommentBoxValueAction(value)
	}

	render() {
		const { approveCommentBoxValue, onTextInputFocus, onTextInputBlur } = this.props
		return (<div className="ApproveCommentBox">
			<span>Approver's Comment </span>
			<textarea onFocus={onTextInputFocus} onBlur={onTextInputBlur} onChange={this.onValueChange} value={approveCommentBoxValue} />
		</div>)
	}
}


const mapStateToProps = (state) => ({
	approveCommentBoxValue: getApproveCommentBoxValue(state)
})


const mapActionToProps = (dispatch) => ({
	updateApproveCommentBoxValueAction: bindActionCreators(updateApproveCommentBoxValue, dispatch)
})




export default connect(mapStateToProps, mapActionToProps)(ApproveCommentBox)