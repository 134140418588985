const { ACTION_TYPES, ASYNC_STATUS, TIME_INTERVALS: leaderBoardTimeInterval, LEADERBOARD_SORT_BY, TIME_INTERVALS } = require("../reduxConsts")

// initial state for tasks store
const leaderBoard_initialStore = {
	async_status: null, // for async calls
	interval: {
		type: "interval",
		data: {
			interval: leaderBoardTimeInterval.TODAY.system
		}
	},
	leaderBoard: [],
	sortBy: LEADERBOARD_SORT_BY.TOTAL_SCORE
}


export function leaderBoardStore(state = leaderBoard_initialStore, action) {

	const { type, value } = action;

	switch (type) {

		/******************* ASYNC ACTIONS ******************* */
		case ACTION_TYPES.LEADER_BOARD.RESET_STORE: {
			return leaderBoard_initialStore
		}
		case ACTION_TYPES.LEADER_BOARD.ASYNC_START: {
			return {
				...state,
				async_status: ASYNC_STATUS.START
			}
		}

		case ACTION_TYPES.LEADER_BOARD.ASYNC_SUCCESS: {
			return {
				...state,
				async_status: ASYNC_STATUS.SUCCESS

			}

		}

		case ACTION_TYPES.LEADER_BOARD.ASYNC_ERROR: {
			return {
				...state,
				async_status: ASYNC_STATUS.ERROR,
				errors: value
			}

		}

		case ACTION_TYPES.LEADER_BOARD.SET_SORT_BY: {
			return {
				...state,
				sortBy: value
			}

		}
		/******************************************** */

		/**************** SAGA ACTIONS **************** */


		// case ACTION_TYPES.LEADER_BOARD.GET_LEADERBOARD: {
		// 	return {
		// 		...state,
		// 		leaderBoard: value
		// 	}
		// }
		/************************************* */

		/*******ANSWER OBJECT CASE *********/

		case ACTION_TYPES.LEADER_BOARD.SET_TIME_INTERVAL: {

			const { type, data } = value
			return {
				...state,
				interval: { type, data }
			}
		}
		case ACTION_TYPES.LEADER_BOARD.SET_CUSTOM_LEADERBOARD_TIME_INTERVAL: {
			const { from, to } = value
			return {
				...state,
				interval: {
					type: TIME_INTERVALS.CUSTOM.system,
					data: {
						from,
						to
					}
				}
			}
		}

		case ACTION_TYPES.LEADER_BOARD.SET_CUSTOMTIME_FROM: {

			const { time } = value
			const { interval } = state

			return {
				...state,
				interval: {
					...interval,
					data: {
						from: time,
						to: interval.data.to
					}
				}
			}
		}
		case ACTION_TYPES.LEADER_BOARD.SET_CUSTOMTIME_TO: {

			const { time } = value
			const { interval } = state
			return {
				...state,
				interval: {
					...interval,
					data: {
						from: interval.data.from,
						to: time
					}
				}
			}
		}


		case ACTION_TYPES.LEADER_BOARD.SET_LEADERBOARD: {
			return {
				...state,
				leaderBoard: value
			}
		}


		default: {
			return state
		}
	}
}
