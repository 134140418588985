/* eslint eqeqeq : 0 */

import React from "react";
import Input from "../../../hoc/controlledInput"
import { Button, DropdownButton, MenuItem, OverlayTrigger, Tooltip, Glyphicon } from "react-bootstrap";

const constHelpers = {
	byWho: {
		me: "me",
		all: "all"
	},
	correctness: {
		wrong: "wrong",
		all: "all"
	},
	byRole: {
		painter: "painter",
		supervisor: "supervisor",
		approver: "approver",
	},
	buttonDisplayType: {
		painterMe: "painterMe",
		painterAll: "painterAll",
		superviseMe: "superviseMe",
		superviseAll: "superviseAll",
		approverMe: "approverMe",
		approverAll: "approverAll",
	},
	toolTipText: {
		painter: "Present Painters mistakes answered by 'me'(as painter) or any painter who his answer marked as wrong(by Supervisor)",
		supervisor: "Present Supervisors mistakes answered by 'me'(as Supervisor) or any Supervisor who his answer marked as wrong(by Approver)",
		approver: "Present all approved subtasks, by 'me' as approver or any approved subtask",
	}
}

const buttonsStyle = {
	"margin": "0 3px"
}

const HistoryFilter = (props) => {


	const { filterObject, setFilter, isUserApprover, setFreeSearchFilter } = props;

	const { name, limit } = filterObject;


	const handleLimitChange = (number) => {
		setFilter({
			...filterObject,
			limit: number
		})
	}

	const handleOnInputChange = ({ target }) => {
		setFreeSearchFilter({ text: target.value })
	}

	const getButtonStyle = (buttonType) => {
		const infoType = "info"
		const defaultType = "default"

		const { byRole, byWho } = props.filterObject;

		// painter me
		if (byRole == constHelpers.byRole.painter && byWho == constHelpers.byWho.me && buttonType === constHelpers.buttonDisplayType.painterMe)
			return infoType

		// painter all
		if (byRole == constHelpers.byRole.painter && byWho == constHelpers.byWho.all && buttonType === constHelpers.buttonDisplayType.painterAll)
			return infoType

		// supervisor me
		if (byRole == constHelpers.byRole.supervisor && byWho == constHelpers.byWho.me && buttonType === constHelpers.buttonDisplayType.superviseMe)
			return infoType

		// supervisor all
		if (byRole == constHelpers.byRole.supervisor && byWho == constHelpers.byWho.all && buttonType === constHelpers.buttonDisplayType.superviseAll)
			return infoType


		// approver me
		if (byRole == constHelpers.byRole.approver && byWho == constHelpers.byWho.me && buttonType === constHelpers.buttonDisplayType.approverMe)
			return infoType

		// approver me
		if (byRole == constHelpers.byRole.approver && byWho == constHelpers.byWho.all && buttonType === constHelpers.buttonDisplayType.approverAll)
			return infoType

		return defaultType




	}


	const generateInfoToolTipButton = (infoText) => <OverlayTrigger placement="right" overlay={<Tooltip id="tooltip"><strong>{infoText}</strong></Tooltip>}>
		{<Glyphicon style={{ color: "blue" }} glyph="question-sign" />}
	</OverlayTrigger>

	const setFiltersObject = {
		paintingMistakes: {
			// byWho=me correctness=wrong byRole=painter
			byMe: () => {
				setFilter({
					...filterObject,
					byWho: constHelpers.byWho.me,
					correctness: constHelpers.correctness.wrong,
					byRole: constHelpers.byRole.painter,
					limit
				})
			},
			// byWho=all correctness=wrong byRole=painter
			byAll: () => {
				setFilter({
					...filterObject,
					byWho: constHelpers.byWho.all,
					correctness: constHelpers.correctness.wrong,
					byRole: constHelpers.byRole.painter,
					limit
				})
			}
		},
		supervisorMistakes: {
			// byWho=me correctness=wrong byRole=supervisor
			byMe: () => {
				setFilter({
					...filterObject,
					byWho: constHelpers.byWho.me,
					correctness: constHelpers.correctness.wrong,
					byRole: constHelpers.byRole.supervisor,
					limit
				})
			},
			// byWho=all correctness=wrong byRole=supervisor
			byAll: () => {
				setFilter({
					...filterObject,
					byWho: constHelpers.byWho.all,
					correctness: constHelpers.correctness.wrong,
					byRole: constHelpers.byRole.supervisor,
					limit
				})
			}
		},
		approver: {
			// byWho=me correctness=all byRole=approver
			byMe: () => {
				setFilter({
					...filterObject,
					byWho: constHelpers.byWho.me,
					correctness: constHelpers.correctness.all,
					byRole: constHelpers.byRole.approver,
					limit
				})
			},
			// byWho=all correctness=all byRole=approver
			byAll: () => {
				setFilter({
					...filterObject,
					byWho: constHelpers.byWho.all,
					correctness: constHelpers.correctness.all,
					byRole: constHelpers.byRole.approver,
					limit
				})
			}
		},
	}




	return (
		<div style={{ width: "100%", fontSize: "17px", }}>

			<div style={{ width: "100%", alignItems: "baseline" }}>

				{/* free search input */}
				<div style={{ display: "flex", alignItems: "baseline" }}>
					<div style={{ width: "100%" }}>
						<Input
							value={name.value}
							size="xl"
							placeholder="Task name, Supervisor, Approver, Comment . .  "
							type="text"
							required={false}
							controlledField="subtaskName"
							onChangeValue={handleOnInputChange} />
					</div>
				</div>



				{!name.use && <div>
					<div style={{ display: "flex", alignItems: "center", padding: "5px" }}>
						<label>Painters mistakes</label>
						<Button style={buttonsStyle} bsSize="xsmall" bsStyle={getButtonStyle(constHelpers.buttonDisplayType.painterMe)} onClick={setFiltersObject.paintingMistakes.byMe} >Me</Button>
						<Button style={buttonsStyle} bsSize="xsmall" bsStyle={getButtonStyle(constHelpers.buttonDisplayType.painterAll)} onClick={setFiltersObject.paintingMistakes.byAll} >All</Button>
						{generateInfoToolTipButton(constHelpers.toolTipText.painter)}
					</div>
					<div style={{ display: "flex", alignItems: "center", padding: "5px" }}>
						<label>Supervisors mistakes</label>
						<Button style={buttonsStyle} bsSize="xsmall" bsStyle={getButtonStyle(constHelpers.buttonDisplayType.superviseMe)} onClick={setFiltersObject.supervisorMistakes.byMe} >Me</Button>
						<Button style={buttonsStyle} bsSize="xsmall" bsStyle={getButtonStyle(constHelpers.buttonDisplayType.superviseAll)} onClick={setFiltersObject.supervisorMistakes.byAll} >All</Button>
						{generateInfoToolTipButton(constHelpers.toolTipText.supervisor)}
					</div>
					{isUserApprover && <div style={{ display: "flex", alignItems: "center", padding: "5px" }}>
						<label>Approved Tasks</label>
						<Button style={buttonsStyle} bsSize="xsmall" bsStyle={getButtonStyle(constHelpers.buttonDisplayType.approverMe)} onClick={setFiltersObject.approver.byMe} >Me</Button>
						<Button style={buttonsStyle} bsSize="xsmall" bsStyle={getButtonStyle(constHelpers.buttonDisplayType.approverAll)} onClick={setFiltersObject.approver.byAll} >All</Button>
						{generateInfoToolTipButton(constHelpers.toolTipText.approver)}
					</div>}


				</div>}

				{/* Limit dropdown */}
				<div style={{ alignItems: "baseline", padding: "5px" }}>
					<span> Limit: </span>
					<DropdownButton
						id="limitResultDrowdown"
						bsStyle="default"
						bsSize="xsmall"
						title={<span>{limit}</span>}>

						<MenuItem eventKey="1" active={limit === 10} onClick={() => handleLimitChange(10)}>10</MenuItem>
						<MenuItem eventKey="5" active={limit === 25} onClick={() => handleLimitChange(25)}>25</MenuItem>
						<MenuItem eventKey="2" active={limit === 50} onClick={() => handleLimitChange(50)}>50</MenuItem>
						<MenuItem eventKey="3" active={limit === 100} onClick={() => handleLimitChange(100)}>100</MenuItem>
						<MenuItem eventKey="4" active={limit === 200} onClick={() => handleLimitChange(200)}>200</MenuItem>

					</DropdownButton>
				</div>

			</div>

		</div >)

}



export default HistoryFilter