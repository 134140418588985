
import React from "react";

const Spinner = ({ spinning, text = "THINKING" }) => {

	const prentstyle = {
		"position": "absolute",
		"top": "0",
		"left": "0",
		"zIndex": "9999",
		"backgroundColor": "#8c8c8c70",
		"width": "100%",
		"height": "100%"
	}

	const childstyle = {
		position: "fixed",
		top: "50%",
		left: "50%",
		margin: "0 auto",
	}



	return (
		<div style={{ "display": spinning ? null : "none", ...prentstyle }} >


			<div style={{ ...childstyle }} >
				{text}
			</div>

		</div>
	)
}

export default Spinner;