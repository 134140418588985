// import saga helpers
import { put, takeLatest, spawn, all } from "redux-saga/effects";


// import action type
import { ACTION_TYPES } from "../reduxConsts";

import * as taskActions from "../tasks/tasksActions";


export const runTaskProcessDevSaga = () => ({ type: ACTION_TYPES.DEV.DEV_RUN_TASK_PROCESS });



function* fetchCompletedSubtasks() {
	yield takeLatest(ACTION_TYPES.DEV.DEV_RUN_TASK_PROCESS, function* () {
		const taskPros = {
			"name": "scriptTask",
			"reqRepetition": 3,
			"subTaskAreaSize": 200,
			"subTasksOffset": 100,
			"boundingBox": {
				"sw": { "lat": 31.886835420442235, "lng": 34.923964376759976 },
				"ne": { "lat": 31.91292213554817, "lng": 34.95228850395724 }
			}
		}



		yield put(taskActions.postTask(taskPros))

	})

}




export function* devSaga(network) {
	yield all([
		spawn(fetchCompletedSubtasks, network),
	])
}

