
import { ProgressBar, Badge, Label } from "react-bootstrap";
import React from "react";
import List from "./../../hoc/List";
import searchList from "./../../hoc/searchList";


/**
 * @param {Object} props
 * 	@param {Function} addItem  handler for add item event
 * 	@param {Function} deleteItem  handler for delete item event
 * 	@param {Function} editItem  handler for edit item event
 * 	@param {Function} focusItem  handler for focus item event
 */
const ListComponent = props => {
	const { tasks = [], addItem, deleteItem, focusItem } = props;

	// if on dev mode then add the show/remove subtasks buttons
	// const isDevelop = process.env.NODE_ENV === "development";

	const toPercentage = (num) => {
		let number
		if (typeof num === "string")
			number = Number(num)
		else if (Number.isNaN(num))
			number = 0
		else
			number = num

		return Math.round(number.toFixed(2) * 100)


	}

	const progressBarCustomComponent = ({ numSubTasks, approved, painted, _id }, index) => {
		return <div style={{ backgroundColor: "lightGray", padding: "10px", margin: "8px 0px 8px 0px" }} key={_id}>
			<div style={{ marginBottom: "5px" }}>
				<small><Badge>Painted / Total: ( {painted + " of " + numSubTasks} )</Badge></small>
			</div>
			<ProgressBar striped bsStyle='info' label={`${toPercentage(painted / numSubTasks)} %`} now={painted} min={0} max={numSubTasks} />

			<div style={{ marginBottom: "5px" }}>
				<small><Badge>Supervised / Painted: ( {approved + " of " + painted} )</Badge></small>
			</div>
			<ProgressBar striped bsStyle='success' label={`${toPercentage(approved / painted)} %`} now={approved} min={0} max={painted} />

		</div>
	}


	// const switchButtonCustomComponent = ({ _id, withSubtasks = false }, index) => {

	// 	const handleOnChange = (checked) => {

	// 		if (checked)
	// 			// here the switch was false and the user want to tur it on
	// 			displaySubtasks({ _id })

	// 		else
	// 			// here the switch was true and the user want to turn it off
	// 			removeSubTasks({ _id })
	// 	}

	// 	return (
	// 		<div key={index} style={{ "display": "flex", "alignItems": "center", "margin": "6px 0px", "width": " 100%" }}>
	// 			<div>
	// 				<Label bsStyle="primary">Subtasks :</Label>
	// 			</div>
	// 			<span style={{ "display": "flex", "alignItems": "center", "paddingLeft": "8px", }}>
	// 				<Switch height={15} width={35} onChange={handleOnChange} checked={withSubtasks} />
	// 			</span>
	// 		</div >
	// 	)

	// }




	const createCustomButtons = () => {
		let customObject = [];
		customObject.push({ handler: focusItem, name: "", icon: "map-marker" });

		return customObject;
	}


	return (
		<List
			list={tasks}
			fieldToDisplay={["subTaskAreaSize", "name", "reqRepetition", "subTasksOffset", "userCreated", "numSubTasks"]}
			onAdd={addItem ? addItem : null}
			// onEdit={editItem ? editItem : null}
			onDelete={deleteItem ? deleteItem : null}
			customButton={focusItem ? createCustomButtons() : null}
			CustomComponent={[progressBarCustomComponent]}

		/>
	)

}



//export the component with the search component and tell the searchList where the list is staying
export default searchList(ListComponent, "tasks");


