
import React from "react";
import { Button } from "react-bootstrap";

export default ({ isSendBack, onDoneEdit, onDone, onDoneDisable,
	onDoneMouseEnter, onDoneMouseLeave, style, onSetIsRequestApproveChanged, isRequestApprove }) => {

	const onDoneClicked = () => {
		if (!onDoneDisable) {
			onDoneEdit()
			onDone()
		}
	}


	const onSetIsRequestApproveClick = (e) => {
		onSetIsRequestApproveChanged(e.target.checked)
	}


	return (
		<div style={{
			borderTop: "#2ec1dc57 solid 0.5px",
			borderBottom: "#8ccd977d solid 0.5px",
			borderRadius: "12px",
			display: "flex",
			justifyContent: "space-between",
			margin: "10px 0",
			padding: "9px 0px 9px 10px", ...style
		}} >



			<div style={{ margin: "0 2px" }}>

				<div
					onMouseEnter={onDoneMouseEnter}
					onMouseLeave={onDoneMouseLeave}>
					<Button style={{
						cursor: onDoneDisable ? "not-allowed" : "pointer",
						opacity: onDoneDisable ? 0.5 : 1

					}} key="realBtn" bsStyle="success" onClick={onDoneClicked} >Done</Button>
				</div>
			</div>


			{!isSendBack && <div style={{
				display: "flex", justifyContent: "space-between", alignItems: "center", width: "140px", margin: "0 10px"
			}}>
				<label style={{ margin: 0 }} htmlFor="requestApprove">Request approval</label>
				<input type="checkbox" id="requestApprove" onChange={onSetIsRequestApproveClick} value={isRequestApprove} />
			</div>}
		</div >
	)
}






