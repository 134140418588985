import React from "react";
import ApproverGrading from "../../previewPage/utilsComponents/approverGrading/approverGrading";
import Comment from "../../previewPage/utilsComponents/comment/Comment";
import { Glyphicon } from "react-bootstrap";
import { timeFormatsConst, formatTimePretty } from "../../../utils/utils";


export default ({ approver }) => {
	const appliedShapesStatus = !Array.isArray(approver.appliedShapes) ? "Areas approved" : `Areas added: ${approver.appliedShapes.length}`

	return <div>
		<div>
			<Glyphicon glyph="user" />   {approver.userApproved.userEmail}
		</div>
		{/* if type if undefined then dont show applied shapes */}
		{typeof approver.appliedShapes !== "undefined" && <div><Glyphicon glyph="tags" />   {appliedShapesStatus}</div>}
		{/* {approver.appliedShapes && approver.appliedShapes.length > 0 && <div><Glyphicon glyph="tags" />   Areas added: {approver.appliedShapes.length}</div>} */}
		{approver.comment && <div><Comment comment={approver.comment} /></div>}
		<Glyphicon glyph="time" />   {formatTimePretty(approver.approvedTime, timeFormatsConst.epochTime, timeFormatsConst.localTimeGmtPreviewFormats)}
		{!!approver.score && < ApproverGrading data={approver} />}
		{approver.answerType === "Done" && <span>Type: Done</span>}
		{approver.answerType === "SendBack" && <span>Type: Send Back</span>}
	</div>
}