import React from "react";
import { SomethingWentWrong } from "../../../common/components"
import * as Sentry from "@sentry/browser";

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
			error: null
		};
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true, error };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log("* * * * * * Error * * * * * *");
		console.log("errorInfo", errorInfo);
		console.log("error", error);
		console.log("* * * * * * Error * * * * * *");
		Sentry.withScope((scope) => {
			console.log({ scope });
			scope.setExtras(errorInfo);
			Sentry.captureException(error);
		});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return <SomethingWentWrong rootPath="/" error={this.state.error} />;
		}

		return this.props.children;
	}
}


