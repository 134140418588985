import React from "react";
import propTypes from "prop-types";
import { ListGroupItem, Button, Glyphicon, Label } from "react-bootstrap"
import startCase from "lodash/startCase"
import has from "lodash/has"



/**
 * @package ['react-bootstrap','lodash/startCase']
 * @param {Object} props: the props object can contain varies objects
 * 		@requires list @type {Array} list of object to represent 
 * 		@requires fieldToDisplay @type {Array}  a array of strings, will indicate which field in each object to display
 * 		@type {Function} [onEdit] delegate function to fire when the user wants to edit specific item in the list
 * 		@type {Function} [onAdd] delegate function to fire when the user wants to add item to the list
 * 		@type {Function} [onDelete] delegate function to fire when the user wants to delete specific item from the list
 * 		@type {Function} [onItemClick] delegate function to fire when the user clicks one of the items
 * 		@type {Object} [itemStyle] if the user wants to control the item style
 * 		@type {Function} [customButtons] if the user wants to control an item with custom event
 * 		@type {Array} [CustomComponent] if the user want to display custom component in each list row, array of functions that gets one item and return html
 * @description this function will generate a generic list component
 *  @todo add a search hoc (the current one is not that HOC)
 */

const List = props => {

	// distract all the main data we need
	const {
		onEdit = null,
		onAdd = null,
		onDelete = null,
		list = [],
		itemStyle = null,
		fieldToDisplay = [],
		customButton = [],
		CustomComponent = [],
		onExport,
		itemHighligh = null
	} = props;

	// get all the item relevant fields 
	const itemProps = {
		onEdit,
		onDelete,
		itemStyle,
		fieldToDisplay,
		customButton,
		itemHighligh
	}



	/**
	 * @param {Object} props 
	 * this is a list item component, responsible for display single row from the list
	 */
	const ListRow = ({ ...props }) => {


		// TODO name is a field shouldnt be here!!!!!!
		const addCustomFields = arr => arr.map((f, i) => {
			return <Button
				style={{ margin: "0 5px" }}
				key={i}
				bsSize='sm'
				bsStyle="primary"
				onClick={() => f.handler(item)}>

				{f.icon && <Glyphicon glyph={f.icon} />}
				{has(f, "name") ? f.name : "field"}

			</Button>
		})


		// get the fields from the props sent
		const {
			onEdit = null,
			onDelete = null,
			itemStyle = null,
			item = {},
			fieldToDisplay = [],
			customButton = [],
			index,
			CustomComponent,
			itemHighligh = null
		} = props;


		let isActive = itemHighligh && itemHighligh(item)

		/**
	 * @param {String} field the field in the item to be displayed
	 * @param {Number} index index for the key
	 * function to print the fields that the user asked for
	 */
		const displayField = (field, index) =>
			item[field] ?
				<div key={index} style={{ display: "flex", alignItems: "center", margin: " 6px 0px 6px 0px", width: "100%" }}  >
					<div style={{ width: "fit-content" }} >

						<Label bsStyle="primary" style={{ margin: "0px 10px 0px 0px" }}>
							{startCase(field)}:
						</Label>
					</div>
					<div style={{ display: "flex", alignItems: "center", width: "fit-content", "overflow": "hidden", "whiteSpace": "nowrap" }} >
						{
							typeof item[field] === "object" ?
								// TODO: patch a better idea of displaying nested fields
								Object.values(item[field])[1] :
								item[field]
						}


					</div>
				</div> :
				""


		const setItemStyle = (sentItemStyle, active) => {

			const isActiveStyle = {
				backgroundColor: "#a5a3a3c7",
				borderLeft: " #000000a1 solid 4px"
			}

			let newItemStyle = sentItemStyle || { justifyContent: "space-between" };

			return active ? { ...newItemStyle, ...isActiveStyle } : newItemStyle;


		}










		// return the item ROW with proper data and event handlers
		return (
			// return the component that holds the specific item to display
			<ListGroupItem
				className="listItem"
				key={index}
				style={setItemStyle(itemStyle, isActive)} >
				{/* style={itemStyle ? itemStyle : { display: "flex", justifyContent: "space-between" }} > */}

				{/* print the fields the user asked in the fieldToDisplay Object  */}
				<div className='item_fields_div'>
					{fieldToDisplay.map(displayField)}
					{
						CustomComponent ?
							CustomComponent.map((c, i) => c(item, i)) :
							null}
				</div>

				{/* add the handlers fir each item + the custom ones the user asked with the  customButton object*/}
				<div className='item_action_div'>
					{onEdit ? <Button bsSize='small' onClick={() => onEdit(item)} bsStyle="primary" ><Glyphicon glyph="pencil" /></Button> : null}
					{onDelete ? <Button bsSize='small' onClick={() => onDelete(item)} bsStyle="danger"><Glyphicon glyph="trash" /></Button> : null}
					{customButton.length > 0 ? addCustomFields(customButton) : null}
				</div>


			</ListGroupItem>


		)
	}



	// return the full list with all items and handlers
	return (
		<div className='list_div' style={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<div className='list' style={{ flexGrow: "60", overflowY: "scroll" }}>
				{list.map((item, index) => ListRow({ ...itemProps, item, index, CustomComponent }))}
			</div>
			<div className="list_actions" style={{ flexGrow: "1", padding: " 9px 0px 0px 0px" }}>
				{
					onAdd &&
					<div className='list_add'>
						<Button bsStyle='success' style={{ width: "100%" }} onClick={onAdd}><Glyphicon glyph='plus' /></Button>
					</div>
				}
				{
					onExport &&
					<div className='list_export'>
						<Button bsStyle='info' style={{ width: "100%" }} onClick={onExport}><Glyphicon glyph='export' /></Button>
					</div>
				}
			</div>
		</div >)
}

List.propTypes = {
	// required
	fieldToDisplay: propTypes.arrayOf(propTypes.string).isRequired, // will indicate which field in each object to display
	list: propTypes.array.isRequired, // the list to preform our search on
	// optional
	onEdit: propTypes.func, // function to handle on edit
	onAdd: propTypes.func, // function to handle on add
	onExport: propTypes.func, // function to handle on Export
	onDelete: propTypes.func, // function to handle on delete
	itemStyle: propTypes.object, // object with style to append on each row list
	customButton: propTypes.arrayOf(propTypes.shape({ handler: propTypes.func, name: propTypes.string })),
	CustomComponent: propTypes.arrayOf(propTypes.func)


}


export default List;

