// leaderboard container 

import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import Spinner from "./../../hoc/Spinner";
import { set_interval, get_leaderboard, sort_by, set_custom_time_from, set_custom_time_to, setCustomIntervalForLeaderboard } from "./../../../redux/leaderBoard/leaderBoardAction";
import { TIME_INTERVALS } from "./../../../redux/reduxConsts";
import { toPercentage } from "./../../../utils/utils";
import LeaderBoardHeader from "./../components/LeaderBoardHeader";
import "./leaderBoard.css"
import SupervisorsLeaderboardTable from "../components/supervisorsLeaderboardTable";
import PaintersLeaderboardTable from "../components/paintersLeaderboardTable";
import { SortPicker } from "../../utils/Components/sortPicker/sortPicker";
import { Button } from "react-bootstrap";
import { DatesRangePicker } from "../../utils/Components/datesRangePicker/datesRangePicker";
import EmptyLeaderBoard from "../components/EmptyLeaderBoard";

class LeaderBoard extends Component {

	constructor(props) {
		super(props)
		this.state = {
			painterSortSelection: "SCORE",
			supervisorSortSelection: "SCORE",
		}
	}



	componentDidMount() {
		const { interval } = this.props;

		this.props.get_leaderboard(interval.data.interval);
	}



	setIntervalAction = (intervalActionString) => {

		if (intervalActionString === "CUSTOM") {
			this.props.set_interval({
				type: TIME_INTERVALS.CUSTOM.system,
				data: {
					from: moment().toJSON(),
					to: moment().toJSON()
				}
			});
		}

		else {

			//  set the intrval store data
			this.props.set_interval({
				type: "interval",
				data: {
					interval: intervalActionString
				}
			});

			this.props.get_leaderboard();
		}

	}


	addAccurateAndQuickPercentage = (stats) => stats.map(userState => ({
		...userState,
		accurate: toPercentage((userState.totalRight / (userState.totalRight + userState.totalWrong))) || 0
	})
	)



	setPaintersTableSortBy = (sortBy) => {
		this.setState({ painterSortSelection: sortBy })
	}

	setSupervisorTableSortBy = (sortBy) => {
		this.setState({ supervisorSortSelection: sortBy })
	}



	render() {

		const { asyncStatus, interval, stats = [], setCustomIntervalForLeaderboard } = this.props;
		const { painterSortSelection, supervisorSortSelection } = this.state;
		const currentIntervalType = interval.data.interval || TIME_INTERVALS.CUSTOM.system
		return (
			<div className="leaderboardPage">

				<Spinner spinning={asyncStatus === "START"} text="Fetching LeaderBoard" />


				<LeaderBoardHeader mainLabel="Leaderboard" intervalTitle={interval} />


				<div style={{ padding: "10px" }}>
					<SortPicker
						label="Show:"
						currentSelectedSortOption={currentIntervalType}
						sortGroups={[
							[{ label: "Today", value: "TODAY" }, { label: "Yesterday", value: "YESTERDAY" }, { label: "This Week", value: "WEEK" }, { label: "Last 7 Days", value: "LAST_7_DAYS" }, { label: "This Month", value: "MONTH" }, { label: "Last 30 Days", value: "LAST_30_DAYS" }],
						]}
						onSelect={(sortByTimeTerm) => this.setIntervalAction(sortByTimeTerm)}>

						<div style={{ display: "flex" }}>
							<Button
								bsSize="sm"
								bsStyle={currentIntervalType === TIME_INTERVALS.CUSTOM.system ? "success" : "default"}
								onClick={() => this.setIntervalAction(TIME_INTERVALS.CUSTOM.system)}>
								Custom
							</Button>
							{currentIntervalType === TIME_INTERVALS.CUSTOM.system &&
								<span style={{
									position: "relative",
									bottom: "17px"
								}}>
									<DatesRangePicker
										onDone={(startTime, endTime) => setCustomIntervalForLeaderboard(startTime, endTime)}
									/>
								</span>
							}
						</div>
					</SortPicker>
				</div>


				{stats.length > 0 ?
					<div className="tablesWrapper">
						<div className="paintersTableWrapper">
							<div style={{ padding: "10px" }}>
								<h3>Painters</h3>
								<SortPicker
									sortGroups={
										[[{ label: "Score", value: "SCORE" }, { label: "Total Painted", value: "TOTAL_PAINTED" }, { label: "Total Right", value: "TOTAL_RIGHT" }, { label: "Total Wrong", value: "TOTAL_WRONG" }]]
									}
									label="Sort By:"
									currentSelectedSortOption={painterSortSelection}
									onSelect={(sortBy) => this.setPaintersTableSortBy(sortBy)} />
							</div>
							<PaintersLeaderboardTable stats={this.addAccurateAndQuickPercentage(stats)} sortBy={painterSortSelection} />
						</div>



						<div className="supervisorsTableWrapper">
							<div style={{ padding: "10px" }}>

								<h3>Supervisors</h3>
								<SortPicker
									sortGroups={
										[[{ label: "Score", value: "SCORE" }, { label: "Total Supervised", value: "TOTAL_SUPERVISED" }, { label: "Total Supervised Perfectly", value: "TOTAL_SUPERVISED_PERFECTLY" }, { label: "Total Sendbacks", value: "TOTAL_SENDBACKS" }]]
									}
									label="Sort By:"
									currentSelectedSortOption={supervisorSortSelection}
									onSelect={(sortBy) => this.setSupervisorTableSortBy(sortBy)} />
							</div>
							<SupervisorsLeaderboardTable stats={this.addAccurateAndQuickPercentage(stats)} sortBy={supervisorSortSelection} />
						</div>

					</div>
					: <EmptyLeaderBoard />}


			</div >

		)
	}

}

let mapStateToProps = ({ leaderBoardStore, authStore }) => {
	return {
		asyncStatus: leaderBoardStore.async_status,
		interval: leaderBoardStore.interval,
		stats: leaderBoardStore.leaderBoard,
		user: authStore.user
	}
}

let mapDispatchToProps = (dispatch) => {
	return {
		get_leaderboard: (interval) => dispatch(get_leaderboard(interval)),
		set_interval: ({ type, data }) => dispatch(set_interval({ type, data })),
		sortByAction: (interval) => dispatch(sort_by(interval)),
		set_custom_time_from: (time) => dispatch(set_custom_time_from(time)),
		set_custom_time_to: (time) => dispatch(set_custom_time_to(time)),
		setCustomIntervalForLeaderboard: (from, to) => dispatch(setCustomIntervalForLeaderboard(from, to)),
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(LeaderBoard);
