export const ACTION_TYPES = {
	AREAS: {
		SET_IS_MAP_AUTOLOAD_ACTIVE: "SET_IS_MAP_AUTOLOAD_ACTIVE"
	},
	REPORT: {
		FETCH_REPORT: "REPORT_FETCH_REPORT",
		SET_REPORT_SORT_BY: "SET_REPORT_SORT_BY",
		SET_REPORT_RESPONSE: "REPORT_SET_REPORT_RESPONSE",
		CLEAR_REPORT_RESPONSE: "REPORT_CLEAR_REPORT_RESPONSE",
		ASYNC_START: "REPORT_ASYNC_START",
		ASYNC_SUCCESS: "REPORT_ASYNC_SUCCESS",
		ASYNC_ERROR: "REPORT_ASYNC_ERROR",
		SET_REPORT_INTERVAL_TYPE: "SET_REPORT_INTERVAL_TYPE",
		SET_REPORT_CUSTOM_INTERVAL: "SET_REPORT_CUSTOM_INTERVAL",

	},

	SHAPE: {
		ADD_DIAGONAL_RECTANGLE_POINT: "ADD_DIAGONAL_RECTANGLE_POINT",
		EDIT_DIAGONAL_RECTANGLE_POINT: "EDIT_DIAGONAL_RECTANGLE_POINT",
		UPDATE_CURRENT_ADD_EDIT_SHAPE: "UPDATE_CURRENT_ADD_EDIT_SHAPE",
		DELETE_DIAGONAL_RECTANGLE_POINT_IN_EDIT: "DELETE_DIAGONAL_RECTANGLE_POINT_IN_EDIT",


		DELETE_AREA_FROM_LIST_BY_ID: "SHAPE_DELETE_AREA_FROM_LIST_BY_ID",
		ASK_IN_BOX_AREAS: "SHAPE_ASK_IN_BOX_AREAS",
		DELETE_MULTIPLE_AREAS: "SHAPE_DELETE_MULTIPLE_AREAS",
		MULTIPLE_DELETE: "SHAPE_MULTIPLE_DELETE",
		RESET_STATE: "SHAPE_RESET_STATE",
		RESET_AREAS: "SHAPE_CLEAN_SHAPES_ARRAY",
		EXPORT: "SHAPE_EXPORT",
		ON_ADD_AREA: "SHAPE_ON_ADD_SHAPE",
		ON_DONE_ADD_SHAPE: "SHAPE_ON_DONE_ADD_SHAPE",
		ON_EDIT_AREA: "SHAPE_ON_EDIT_SHAPE",
		ON_DONE_EDIT_SHAPE: "SHAPE_ON_DONE_EDIT_SHAPE",
		ON_DELETE_SHAPE: "SHAPE_ON_DELETE_SHAPE",
		ON_FOCUS_AREA: "SHAPE_ON_FOCUS_SHAPE",
		RESET_FOCUS: "SHAPE_RESET_FOCUS",
		ON_FOCUS_DONE: "SHAPE_ON_FOCUS_DONE",
		ASYNC_START: "SHAPE_ASYNC_START",
		ASYNC_SUCCESS: "SHAPE_ASYNC_SUCCESS",
		ASYNC_ERROR: "SHAPE_ASYNC_ERROR",
		RESET_ASYNC: "SHAPE_RESET_ASYNC",
		SET_MAP_STATE: "SHAPE_SET_MAP_STATE",
		RESET_ADD_EDIT_SHAPE: "SHAPE_RESET_ADD_EDIT_SHAPE",
		ON_CANCEL_ADD_EDIT: "SHAPE_ON_CANCEL_ADD_EDIT",
		ADD_BOUNDS_TO_TEMP: "SHAPE_ADD_BOUNDS_TO_TEMP",
		ADD_NAME_TO_TEMP: "SHAPE_ADD_NAME_TO_TEMP",
		UPDATE_SHAPE_PROP: "SHAPE_UPDATE_SHAPE_PROP",
		UPDATE_SHAPE: "SHAPE_UPDATE_SHAPE",
		CLEAN_ERROR: "SHAPE_CLEAN_ERROR",
		AREA_CLICKED: "SHAPE_CLICKED",




		SET_AREAS_LIST: "SET_AREAS_LIST",
		UPDATE_AREA_ITEM_BY_ID: "UPDATE_AREA_ITEM_BY_ID",
		GET_AREAS: "GET_AREAS",
		DELETE_AREA: "DELETE_AREA",
		ADD_EDIT_AREA: "ADD_EDIT_AREA",
		SET_AREA_DISABLE: "SET_AREA_DISABLE",
		LOAD_SHAPES_THAT_FIT_MAP_BOUNDS: "LOAD_SHAPES_THAT_FIT_MAP_BOUNDS"
	},
	TASK: {
		ASYNC_GET_TASKS: "TASK_ASYNC_GET_TASKS",
		ASYNC_ADD_TASK: "TASK_ASYNC_ADD_TASK",
		// ASYNC_GET_SUBTASKS: "TASK_ASYNC_GET_SUBTASKS",
		ASYNC_DELETE_TASK: "TASK_ASYNC_DELETE_TASK",
		UPDATE_TASKS_LIST: "TASK_UPDATE_TASKS_LIST",
		SET_MAP_STATE: "TASK_SET_MAP_STATE",
		RESET_TMP: "TASK_RESET_TMP",
		ADD_SUBTASK_TO_TASK: "TASK_ADD_SUBTASK_TO_TASK",
		ON_FOCUS_SHAPE: "TASK_ON_FOCUS_SHAPE",
		ADD_BOUNDS_TO_TEMP: "TASK_ADD_BOUNDS_TO_TEMP",
		ON_CANCEL_ADD_EDIT: "TASK_ON_CANCEL_ADD_EDIT",
		ON_ADD_SHAPE: "TASK_ON_ADD_SHAPE",
		ON_FOCUS_DONE: "TASK_ON_FOCUS_DONE",
		// REMOVE_SUBTASKS_BY_PARENT_ID: "TASK_REMOVE_SUBTASKS_BY_PARENT_ID",
		RESET_STATE: "TASK_RESET_STATE",
		RESET_FOCUS: "TASK_RESET_FOCUS",
		CLEAN_ERROR: "CLEAN_ERROR",
		ASYNC_START: "TASK_ASYNC_START"
	},
	PAINT: {

		//SUBTASK UI CONTROLLER 
		CLEAN_SUBTASK: "PAINT_CLEAN_SUBTASK",
		LOAD_SUB_TASK_FOR_ANSWER: "PAINT_LOAD_SUB_TASK_FOR_ANSWER",

		//SAGA ASYNC ACTIONS
		POST_ANSWER: "PAINT_POST_SUBTASK_ANSWER",
		FETCH_SUB_TASK: "PAINT_FETCH_SUBTASK",

		// ASYNC ACTIONS	
		ASYNC_START: "PAINT_ASYNC_START",
		ASYNC_SUCCESS: "PAINT_ASYNC_SUCCESS",
		ASYNC_ERROR: "PAINT_ASYNC_ERROR",
		CLEAN_ERROR: "PAINT_CLEAN_ERROR",

		// ANSWER CONTROLLERS
		SET_ANSWER_SUBTASK_ID: "PAINT_SET_ANSWER_SUBTASK_ID",

		SET_ANSWER_STATUS: "PAINT_SET_STATUS",

		ADD_ANSWER_AREA: "PAINT_ADD_AREA",
		REPLACE_ANSWER_OBJECT_AREAS_ARRAY: "PAINT_REPLACE_ANSWER_OBJECT_AREAS_ARRAY",
		REMOVE_ANSWER_AREAS: "PAINT_REMOVE_AREAS",

		SET_ANSWER_CONFLICT_TEXT: "PAINT_SET_CONFLICT_TEXT",

		CLEAN_ANSWER: "PAINT_CLEAN_ANSWER",

		SET_ERROR: "PAINT_SET_ERROR",
		RESET_STORE: "PAINT_RESET_STORE",
		MAP_FOCUS: "PAINT_MAP_FOCUS",
		CLEAR_FOCUS: "PAINT_CLEAR_FOCUS",
		SHAPE_CLICKED: "PAINT_SHAPE_CLICKED",
		ON_EDIT_SHAPE: "PAINT_ON_EDIT_SHAPE",
		ON_DONE_EDIT: "PAINT_ON_DONE_EDIT",
		ON_DELETE_SHAPE: "PAINT_ON_DELETE_SHAPE",

	},
	SUPERVISOR: {
		ADD_DIAGONAL_RECTANGLE_POINT_TO_CURRENTLY_ADDED_SHAPE: "SUPERVISOR_ADD_DIAGONAL_RECTANGLE_POINT_TO_CURRENTLY_ADDED_SHAPE",
		REMOVE_DIAGONAL_RECTANGLE_POINT_FROM_CURRENTLY_ADDED_SHAPE: "SUPERVISOR_REMOVE_DIAGONAL_RECTANGLE_POINT_FROM_CURRENTLY_ADDED_SHAPE",
		EDIT_DIAGONAL_RECTANGLE_POINT_OF_CURRENTLY_ADDED_SHAPE: "SUPERVISOR_EDIT_DIAGONAL_RECTANGLE_POINT_OF_CURRENTLY_ADDED_SHAPE",
		RESET_STORE: "SUPERVISOR_RESET_STORE",
		SET_SEND_BACK_BOOL: "SUPERVISOR_SET_SEND_BACK_BOOL",
		SET_IS_COMPLEX_TYPE: "SUPERVISOR_SET_IS_COMPLEX_TYPE",
		SET_BOTTOM_LEVEL_TYPE: "SUPERVISOR_SET_BOTTOM_LEVEL_TYPE",
		EDIT_APPLIED_SHAPE_BY_ID: "SUPERVISOR_EDIT_APPLIED_SHAPE_BY_ID",
		SET_TOP_LEVEL_TYPE: "SUPERVISOR_SET_TOP_LEVEL_TYPE",
		RETRY_APPLIED_SHAPE_SUGGESTIONS: "SUPERVISOR_RETRY_APPLIED_SHAPE_SUGGESTIONS",
		SET_APPROVER_SENDBACK_DISPLAY: "SET_APPROVER_SENDBACK_DISPLAY",
		SET_AREAS_IN_RADIUS_DISPLAY: "SUPERVISOR_SET_AREAS_IN_RADIUS_DISPLAY",
		ADD_SUGGESTION_TO_SPECIFIC_APPLIED_SHAPE: "SUPERVISOR_ADD_SUGGESTION_TO_SPECIFIC_APPLIED_SHAPE",
		FETCHING_SUGGESTIONS: "SUPERVISOR_FETCHING_SUGGESTIONS",
		ON_SHAPE_DRAW: "SUPERVISOR_ON_SHAPE_DRAW",
		FETCH_NEARBY: "SUPERVISOR_FETCH_NEARBY",
		ADD_APPLIED_SHAPE_DESCRIPTION: "SUPERVISOR_ADD_APPLIED_SHAPE_DESCRIPTION",
		ON_DELETE_SHAPE: "SUPERVISOR_ON_DELETE_SHAPE",
		ON_DONE_EDIT: "SUPERVISOR_ON_DONE_EDIT",
		SHAPE_CLICKED: "SUPERVISOR_SHAPE_CLICKED",
		SET_USER_RESULTS_COLOR: "SUPERVISOR_SET_USER_RESULTS_COLOR",
		SET_APPROVER_SUGGESTION_COLOR: "SET_APPROVER_SUGGESTION_COLOR",
		SET_USER_RESULTS_DISPLAY: "SUPERVISOR_SET_USER_RESULTS_DISPLAY",
		SET_EDIT_SHAPE_ID: "SUPERVISOR_SET_EDIT_SHAPE_ID",
		REPLACE_APPLIED_SHAPES_ARRAY: "SUPERVISOR_REPLACE_APPLIED_SHAPES_ARRAY",
		ADD_SUGGESTED_AREAS: "SUPERVISOR_ADD_SUGGESTED_AREAS",
		GET_APPLIED_SHAPE_SUGGESTIONS: "SUPERVISOR_GET_APPLIED_SHAPE_SUGGESTIONS",
		SET_IS_REQUEST_APPROVE: "SET_IS_REQUEST_APPROVE",
		EDIT_DIAGONAL_RECTANGLE_POINT: "SUPERVISOR_EDIT_DIAGONAL_RECTANGLE_POINT",
		DELETE_DIAGONAL_RECTANGLE_POINT_IN_EDIT: "SUPERVISOR_DELETE_DIAGONAL_RECTANGLE_POINT_IN_EDIT",
		// MAP CONTROLLER
		ADD_SHAPE_TO_MAP: "SUPERVISOR_ADD_SHAPE_TO_MAP",

		// CORRECT ARRAY CONTROLLER
		ADD_CORRECT_RESULT: "SUPERVISOR_ADD_CORRECT_RESULT",
		REMOVE_CORRECT_RESULT: "SUPERVISOR_REMOVE_CORRECT_RESULT",


		// SUBTASK CONTROLLER
		SET_SUBTASK: "SUPERVISOR_SET_SUBTASK",
		CLEAN_SUBTASK: "SUPERVISOR_CLEAN_SUBTASK",

		// SET COLOR 

		// SAGA ASYNC ACTIONS
		POST_SUPERVISED: "SUPERVISOR_POST_SUPERVISED",
		POST_SUPERVISEDV2: "SUPERVISOR_POST_SUPERVISEDV2",
		GET_READY_FOR_SUPERVISE: "SUPERVISOR_GET_READY_FOR_SUPERVISE",

		GET_SHAPES_WITH_RADIUS: "SUPERVISOR_GET_SHAPES_WITH_RADIUS",

		ADD_SHAPES_IN_RADIUS: "SUPERVISOR_ADD_SHAPES_IN_RADIUS",


		ADD_APPLIED_SHAPE: "SUPERVISOR_ADD_APPLIED_SHAPE",
		REMOVE_APPLIED_SHAPES: "SUPERVISOR_REMOVE_APPLIED_SHAPES",
		SET_SUPERVISOR_COMMENT: "SUPERVISOR_SET_SUPERVISOR_COMMENT",
		// SET_SHAPE_PREFIX: "SUPERVISOR_SET_SHAPE_PREFIX",

		SET_NUM_READY_TO_SUPERVISE: "SUPERVISOR_SET_NUM_READY_TO_SUPERVISOR",

		MAP_FOCUS: "SUPERVISOR_MAP_FOCUS",
		CLEAR_FOCUS: "SUPERVISOR_CLEAR_FOCUS",



		ASYNC_START: "ASYNC_START",
		ASYNC_SUCCESS: "ASYNC_SUCCESS",
		ASYNC_ERROR: "ASYNC_ERROR",
		CLEAN_ERROR: "CLEAN_ERROR",
	},
	APPROVER: {
		ADD_DIAGONAL_RECTANGLE_POINT_TO_CURRENTLY_ADDED_SHAPE: "APPROVE_ADD_DIAGONAL_RECTANGLE_POINT_TO_CURRENTLY_ADDED_SHAPE",
		REMOVE_DIAGONAL_RECTANGLE_POINT_FROM_CURRENTLY_ADDED_SHAPE: "APPROVE_REMOVE_DIAGONAL_RECTANGLE_POINT_FROM_CURRENTLY_ADDED_SHAPE",
		EDIT_DIAGONAL_RECTANGLE_POINT_OF_CURRENTLY_ADDED_SHAPE: "APPROVE_EDIT_DIAGONAL_RECTANGLE_POINT_OF_CURRENTLY_ADDED_SHAPE",
		FETCH_SUB_TASK_FOR_APPROVAL: "FETCH_SUB_TASK_FOR_APPROVAL",
		CLEAR_FILTERS_AND_FETCH_SUB_TASK_FOR_APPROVAL: "CLEAR_FILTERS_AND_FETCH_SUB_TASK_FOR_APPROVAL",
		STORE_SUB_TASK_FOR_APPROVAL: "STORE_SUB_TASK_FOR_APPROVAL",
		SET_DATA_FOR_APPROVER_TABLE: "SET_DATA_FOR_APPROVER_TABLE",
		SET_COLOR_FOR_APPROVER_TABLE_ITEM: "SET_COLOR_FOR_APPROVER_TABLE_ITEM",
		SET_VISIBILITY_FOR_APPROVER_TABLE_ITEM: "SET_VISIBILITY_FOR_APPROVER_TABLE_ITEM",
		SET_APPROVE_COMMENT_BOX_VALUE: "SET_APPROVE_COMMENT_BOX_VALUE",
		PHASE_CHANGED: "PHASE_CHANGED",
		RESET_APPROVER_STATE: "RESET_APPROVER_STATE",
		SET_PHASE_ANSWER: "SET_PHASE_ANSWER",
		SUBMIT_APPROVER_ANSWERS: "SUBMIT_APPROVER_ANSWERS",
		SET_IS_ALL_VISIBLE: "SET_IS_ALL_VISIBLE",
		SET_APPROVER_SUBTASK_API_TYPE: "SET_APPROVER_SUBTASK_API_TYPE",
		RESET_APPROVE_TASK: "RESET_APPROVE_TASK",
		DELETE_DBSNAPSHOT_AREA: "DELETE_DBSNAPSHOT_AREA",
		CONFIRM_DELETE_DBSNAPSHOT_AREA: "CONFIRM_DELETE_DBSNAPSHOT_AREA",
		APPROVE_DONE_EDIT_MODE: "APPROVE_DONE_EDIT_MODE",
		APPROVE_DONE_SEND_BACK: "APPROVE_DONE_SEND_BACK",
		SET_APPROVER_FILTER: "SET_APPROVER_FILTER",
		RESET_APPROVER_FILTER: "RESET_APPROVER_FILTER",
		APPROVE_DONE: "APPROVE_DONE",
	},
	LEADER_BOARD: {
		RESET_STORE: "LEADERBOARD_RESET_STORE",
		SET_CUSTOMTIME_FROM: "LEADERBOARDSET_CUSTOMTIME_FROM",
		SET_CUSTOMTIME_TO: "LEADERBOARD_SET_CUSTOMTIME_TO",
		SET_TIME_INTERVAL: "LEADERBOARD_SET_TIME_INTERVAL",
		GET_LEADERBOARD: "LEADERBOARD_GET_LEADERBOARD",
		SET_SORT_BY: "LEADERBOARD_SET_SORT_BY",
		SET_LEADERBOARD: "LEADERBOARD_SET_LEADERBOARD",
		ASYNC_START: "ASYNC_START",
		ASYNC_SUCCESS: "ASYNC_SUCCESS",
		ASYNC_ERROR: "ASYNC_ERROR",
		SET_CUSTOM_LEADERBOARD_TIME_INTERVAL: "SET_CUSTOM_LEADERBOARD_TIME_INTERVAL",
	},
	DIALOG: {
		SHOW_DIALOG: "SHOW_DIALOG",
		CLOSE_DIALOG: "CLOSE_DIALOG"
	},
	PREVIEW: {
		FILTER_CHANGED: "FILTER_CHANGED",
		FETCH_COMPLETE_SUBTASK_LIST: "FETCH_COMPLETE_SUBTASK_LIST",
		GET_SHAPES_WITH_RADIUS_FOR_PREVIEW: "GET_SHAPES_WITH_RADIUS_FOR_PREVIEW",
		SET_INBOX_RESPONSE_FOR_PREVIEW: "SET_INBOX_RESPONSE_FOR_PREVIEW",
		SET_FILTERS: "SET_FILTERS",
		SET_FULL_FILTERS: "SET_FULL_FILTERS",
		SET_FREE_SEARCH_FILTER: "SET_FREE_SEARCH_FILTER",
		// ADVANCED_FILTER_CLICK: "ADVANCED_FILTER_CLICK",
		SET_COMPLETE_SUBTASK_LIST: "SET_COMPLETE_SUBTASK_LIST",
		CLEAN_COMPLETE_SUBTASK_LIST: "CLEAN_COMPLETE_SUBTASK_LIST",

		FETCH_SUBTASK_TO_PREVIEW: "FETCH_SUBTASK_TO_PREVIEW",

		SET_SUBTASK_TO_PREVIEW: "SET_SUBTASK_TO_PREVIEW",

		GET_IN_BOX: "GET_IN_BOX",

		ADD_SHAPE: "ADD_SHAPE",

		ASYNC_START: "ASYNC_START",
		ASYNC_SUCCESS: "ASYNC_SUCCESS",
		ASYNC_ERROR: "ASYNC_ERROR",

	},
	LOADER: {
		SHOW_LOADER: "SHOW_LOADER",
		HIDE_LOADER: "HIDE_LOADER",
	},
	DEV: {
		"DEV_RUN_TASK_PROCESS": "DEV_RUN_TASK_PROCESS"
	}
}

export const REPORT_SORT_BY = {
	PAINT_COUNT: "paintedCount",
	SUPERVISE_COUNT: "superviseCount",
	APPROVED_COUNT: "approvedCount",
	DATE: "timeFrame",
	NAME: "user",
}

export const LEADERBOARD_SORT_BY = {
	TOTAL_ANSWERED: "totalAnswered",
	TOTAL_RIGHT: "totalRight",
	TOTAL_WRONG: "totalWrong",
	TOTAL_SCORE: "totalScore"
}

export const ASYNC_STATUS = {
	START: "START",
	SUCCESS: "SUCCESS",
	ERROR: "ERROR"
}

export const MAP_STATE = {
	READ: "READ",
	ADD: "ADD",
	EDIT: "EDIT"
}

export const groups = {
	painter: "areas-blacklist-manager/painters",
	admin: "areas-blacklist-manager/administrators"
}

export const TIME_INTERVALS = {
	TODAY: {
		pretty: "Today",
		system: "TODAY",
		presentUI: true
	},
	YESTERDAY: {
		pretty: "Yesterday",
		system: "YESTERDAY",
		presentUI: true
	},
	WEEK: {
		pretty: "This Week",
		system: "WEEK",
		presentUI: true
	},
	LAST_7_DAYS: {
		pretty: "Last 7 Days",
		system: "LAST_7_DAYS",
		presentUI: true
	},
	MONTH: {
		pretty: "This Month",
		system: "MONTH",
		presentUI: true
	},

	LAST_30_DAYS: {
		pretty: "Last 30 Days",
		system: "LAST_30_DAYS",
		presentUI: true
	},
	// ALL_TIME: {
	// 	pretty: "All Time",
	// 	system: "ALL_TIME",
	// 	presentUI: false
	// },
	CUSTOM: {
		pretty: "Custom time",
		system: "CUSTOM",
		presentUI: false
	}
}

export const leaderBoardSortBy = {
	TOTAL_SCORE: {
		system: "totalScore",
		pretty: "Score"
	},
	TOTAL_ANSWERED: {
		system: "totalAnswered",
		pretty: "Answered"
	},
	TOTAL_RIGHT: {
		system: "totalRight",
		pretty: "Right"
	},
	TOTAL_WRONG: {
		system: "totalWrong",
		pretty: "Wrong"
	},
	SPACER: {
		system: null,
		pretty: null
	},
	TOTAL_SUPERVISED: {
		system: "totalSupervised",
		pretty: "Total Supervised"
	},
	SUPERVISOR_SCORE: {
		system: "supervisorScore",
		pretty: "Supervisor Score"
	}
}


export const tableFilter = {
	// return full intervals options
	byDay: ({
		YESTERDAY: "YESTERDAY",
		TODAY: "TODAY",
		WEEK: "WEEK",
		LAST_7_DAYS: "LAST_7_DAYS",
		MONTH: "MONTH",
		LAST_30_DAYS: "LAST_30_DAYS",
		ALL_TIME: "ALL_TIME"
	}),
	//filter by user
	byUser: ({
		me: "me",
		all: "all"
	}),
	// filter by correctness
	byCorrectness: ({
		wrong: "wrong",
		all: "all"
	}),

}
