import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { shapeClicked, resetFocus, addDiagonalRectanglePoint, editDiagonalRectanglePoint, updateCurrentAddEditShape, removePointOfDiagonalRectangleInEdit } from "./../../../redux/shapes/shapeActions";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import { Map } from "./../../../common/components";
import { ShapeUtils } from "../../../common/sharedGlobal";
import { getNearbyGeocode } from "../../../redux/saga/utils";


const mapStates = {
	READ: "READ",
	ADD: "ADD",
	EDIT: "EDIT"
}

class MapContainer extends Component {

	constructor(props) {
		super(props);
		this.state = {
			zoom: 10,
			defaultCenter: { lat: 32, lng: 35 },
			center: { lat: 32, lng: 35 },
			mapState: "READ"
		}

	}


	static getDerivedStateFromProps(props, state) {

		let newState = { ...state };
		// set a center object if overlay focus in not null
		let derivedCenterFromFocus = !isEmpty(props.focusOverlay) ? props.focusOverlay : null


		//  if user asked to focus (also in add edit)
		if (derivedCenterFromFocus !== null && !isEqual(derivedCenterFromFocus, state.center)) {
			if (
				isEmpty(state.center) ||
				(!isEmpty(state.center) && !isEmpty(derivedCenterFromFocus))) {
				newState = {
					...newState,
					center: derivedCenterFromFocus
				}
			}
		}
		if (derivedCenterFromFocus === null) {
			newState = {
				...newState,
				center: null
			}
		}



		//  if map state changed
		if (props.mapState !== state.mapState)
			newState = {
				...newState,
				focusOverlay: null,
				mapState: props.mapState
			}


		return newState
	}


	componentDidUpdate(prevProps, prevState) {
		if ((this.props.mapState === mapStates.ADD || this.props.mapState === mapStates.EDIT) && !isEmpty(this.props.addEditShape) && !isEmpty(prevProps.addEditShape) && (this.props.addEditShape.shape.points.length !== prevProps.addEditShape.shape.points.length)) {
			this.getDescriptionFromGoogleForShape(this.props.addEditShape, this.props.updateCurrentAddEditShape)
		}
	}



	onShapeClicked = (shapeId) => {
		this.props.shapeClicked(shapeId)
	}



	getDescriptionFromGoogleForShape = (item, callback) => {
		if (item.shape.points.length === 3) {
			// convert points to a rectangle boundingBox
			const centerOfShape = ShapeUtils.getCenter(item)

			centerOfShape && getNearbyGeocode(centerOfShape).then(res => {
				const description = res && res[0] && res[0].formatted_address || ""
				callback({ description })
			})
		}

	}


	onDiagonalRectanglePointAdd = ({ lat, lng }) => {
		const { addDiagonalRectanglePoint } = this.props
		addDiagonalRectanglePoint({ lat, lng })

	}



	editDiagonalRectanglePointOfTemp = (pointOrder, newPoint) => {
		const { editDiagonalRectanglePoint } = this.props
		editDiagonalRectanglePoint(pointOrder, newPoint)
	}



	onDiagonalRectanglePointDragEnd = (pointOrder, newPoint) => {
		const { addEditShape, updateCurrentAddEditShape } = this.props
		this.getDescriptionFromGoogleForShape(addEditShape, updateCurrentAddEditShape)
	}


	// Generate an array of shape objects for the map
	reshapeAreasForMap = (shapeList, addEditShape) => {
		let shapesForMapArray = shapeList.map(s => ({
			id: s._id,
			...s.shape,
			onClick: e => this.onShapeClicked(s._id),
		}))

		if (!isEmpty(addEditShape)) {
			// add the temp shape as editable

			shapesForMapArray = [
				...shapesForMapArray,
				{
					id: addEditShape._id,
					...addEditShape.shape,
					isEditable: true,
					onRightClick: (pointOrderToDelete) => this.onMarkerRightClick(pointOrderToDelete),
					onDiagonalRectanglePointDrag: (pointOrder, newPoint) => this.editDiagonalRectanglePointOfTemp(pointOrder, newPoint),
					onDiagonalRectanglePointDragEnd: (pointOrder, newPoint) => this.onDiagonalRectanglePointDragEnd(pointOrder, newPoint),
				}
			]

		}

		return shapesForMapArray
	}



	onMarkerRightClick = (pointOrderToDelete) => {
		const { removePointOfDiagonalRectangleInEdit } = this.props
		removePointOfDiagonalRectangleInEdit(pointOrderToDelete)
	}


	generateDrawerObject = () => {
		const { mapState, addEditShape } = this.props;
		return {
			position: 2,
			drawingModes: (mapState === mapStates.ADD || mapState === mapStates.EDIT) && addEditShape.shape.points.length < 3 ? ["marker", null] : [null],
			onMarkerDraw: this.onDiagonalRectanglePointAdd,
		}
	}


	onDragStartHandler = () => {
		// reset focus
		this.props.resetFocus()
	}


	onBoundsChanged = (mapBounds) => {
		const { onBoundsChanged } = this.props
		onBoundsChanged(mapBounds)
	}


	render() {
		const { zoom, defaultCenter, center } = this.state
		const { addEditShape, shapeList } = this.props
		const listToRender = this.reshapeAreasForMap(shapeList, addEditShape)
		const drawer = this.generateDrawerObject()
		return (
			<div style={{ height: "100%", width: "100%" }} >
				<Map
					defaultCenter={defaultCenter}
					showSearchBox={true}
					onDragStart={this.onDragStartHandler}
					listToRender={listToRender}
					center={center || defaultCenter}
					zoom={zoom}
					drawer={drawer}
					itemInEdit={addEditShape}
					onBoundsChanged={this.onBoundsChanged}
				/>
			</div>
		)
	}
}



const mapStateToProps = ({ shapesStore }) => ({
	shapeList: shapesStore.shapeList,
	mapState: shapesStore.mapState,
	focusOverlay: shapesStore.focus ? shapesStore.focus : null,
	addEditShape: shapesStore.addEditShape
})


const mapDispatchToProps = (dispatch) => ({
	resetFocus: bindActionCreators(resetFocus, dispatch),
	shapeClicked: bindActionCreators(shapeClicked, dispatch),
	addDiagonalRectanglePoint: bindActionCreators(addDiagonalRectanglePoint, dispatch),
	editDiagonalRectanglePoint: bindActionCreators(editDiagonalRectanglePoint, dispatch),
	updateCurrentAddEditShape: bindActionCreators(updateCurrentAddEditShape, dispatch),
	removePointOfDiagonalRectangleInEdit: bindActionCreators(removePointOfDiagonalRectangleInEdit, dispatch),

})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(MapContainer);
