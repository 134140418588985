const { PREVIEW } = require("../reduxConsts").ACTION_TYPES





export const fetchUserCompletedSubtasks = (userid, options) => ({ type: PREVIEW.FETCH_COMPLETE_SUBTASK_LIST, value: { userid, ...options } });

export const setSubtasksList = (list) => ({ type: PREVIEW.SET_COMPLETE_SUBTASK_LIST, value: list });

export const cleanSubtasksList = () => ({ type: PREVIEW.CLEAN_COMPLETE_SUBTASK_LIST });

export const fetchInBox = (subtask) => ({ type: PREVIEW.GET_IN_BOX, value: subtask });

export const addShapes = (shapes) => ({ type: PREVIEW.ADD_SHAPES, value: shapes });

// export const advancedClick = (bool) => ({ type: PREVIEW.ADVANCED_FILTER_CLICK, value: bool });

export const setFilter = ({ key, value }) => ({ type: PREVIEW.SET_FILTERS, value: ({ key, value }) })
export const setFullFilter = (fullFilterObj) => ({ type: PREVIEW.SET_FULL_FILTERS, value: fullFilterObj })
export const setFreeSearchFilter = ({ text }) => ({ type: PREVIEW.SET_FREE_SEARCH_FILTER, value: ({ text }) })

export const fetchShapeWithBoundingBox = (boundingBox) => ({ type: PREVIEW.GET_SHAPES_WITH_RADIUS_FOR_PREVIEW, value: { boundingBox } })

export const setInBoxResponseForPreview = (response) => ({ type: PREVIEW.SET_INBOX_RESPONSE_FOR_PREVIEW, value: response });

export const filterChanged = (bool) => ({ type: PREVIEW.FILTER_CHANGED, value: { isChanged: bool } })


/* * * * * * * * * * * * * * * * * async actions * * * * * * * * * * * * * * */
/**@param {String} req async start function*/
export const async_start = () => ({ type: PREVIEW.ASYNC_START })

/**@param {Object} data async success function */
export const async_success = () => ({ type: PREVIEW.ASYNC_SUCCESS })

/**@param {String} error async error function */
export const async_error = error => ({ type: PREVIEW.ASYNC_ERROR, value: error })
/* * * * * * * * * * * * * * * * * async actions * * * * * * * * * * * * * * */