import React from "react"
import "./shapeColor.css"
import { MenuItem, DropdownButton } from "react-bootstrap"
import { SHAPE_COLORS } from "./shapeColors"
import _ from "lodash"


export class GtShapeColor extends React.Component {

	generateDivColor = (fillStrokeCombo) => <div
		style={{
			height: "10px",
			backgroundColor: fillStrokeCombo.fillColor,
			border: `2px solid ${fillStrokeCombo.strokeColor}`
		}} />


	renderColorOptions = () => {
		const { onColorSelect } = this.props
		return _.map(SHAPE_COLORS, (fillStrokeCombo, i) => {
			const { fillColor, strokeColor } = fillStrokeCombo
			return <MenuItem
				key={i}
				bsSize="xs"
				title={fillStrokeCombo}
				onClick={() => onColorSelect(fillColor, strokeColor)}>

				{this.generateDivColor(fillStrokeCombo)}

			</MenuItem >
		})
	}

	render() {
		const { fillStrokeCombo, isDisabled } = this.props
		const { fillColor, strokeColor } = fillStrokeCombo
		return (<div className="GT_ShapeColor">
			<DropdownButton
				className="ColorButton"
				pullRight
				disabled={isDisabled}
				title={<div style={{
					height: "10px",
					width: "50px",
					backgroundColor: fillColor,
					border: `2px solid ${strokeColor}`
				}}></div>}
				bsSize="xs"
				id="color_dropdown">
				{this.renderColorOptions()}
			</DropdownButton>
		</div>)
	}
}
