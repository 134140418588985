
import React, { Component } from "react";

import propTypes from "prop-types";

export class ControlledInput extends Component {


	addRequiredSign = (isRequired) => isRequired ? <span style={{ color: "red" }} >  * </span> : null

	getSize = (type, size) => {
		const types = {
			"checkbox": this.getCheckboxSize(size),
			"text": this.getInputSize(size),
			"number": this.getInputSize(size)
		};
		return types[type];

	}

	getCheckboxSize = (s) => {
		switch (s) {

			case "s": {
				return { width: "10px", height: "10px" }
			}
			case "m": {
				return { width: "15px", height: "15px" }
			}
			case "l": {
				return { width: "20px", height: "20px" }
			}
			default: {
				return { width: "10px", height: "10px" }
			}

		}
	}
	getInputSize = (s) => {
		switch (s) {

			case "xs": {
				return { width: "15%" }
			}
			case "s": {
				return { width: "30%" }
			}
			case "m": {
				return { width: "70%" }
			}
			case "l": {
				return { width: "100%" }
			}
			default: {
				return { width: "30%" }
			}

		}
	}


	render() {

		let {
			// get the place holder for the input
			placeholder = "",
			// get the event handler
			onChangeValue,
			// get the value if any
			value, // not in use(!?)
			// get the field name to display
			label,
			// get the field controlled in the Object (to throw the handler)
			controlledField,
			// the type of the input 
			type = "text",
			// is that field is mandatory 
			required,
			// explanation on the input type and value
			info,
			// if checkbox is the type then if the defaultChecked is true the checkbox will render as checked
			checked = false,
			// get the size the user sent
			size = "s",
			// display the label and input in same line
			displayFlex = false,
			// Should auto focus or not
			autoFocus = false
		} = this.props;


		let inputStyle = {
			...this.getSize(type, size)
		}

		return (
			<div className="form-group" >

				<div style={{ display: displayFlex ? "flex" : null }}>

					<div>

						{/* add required sign if needed */}
						{this.addRequiredSign.bind(this)(required)}

						{/* print the label only if exist */}
						{label && <span>{label}: </span>}

					</div>

					<div>

						<input
							onClick={(e) => { e.stopPropagation() }}
							type={type}
							className="form-input"
							onChange={(element) => { onChangeValue(element, controlledField) }}
							placeholder={placeholder}
							value={value}
							min={type === "number" ? 1 : undefined}
							checked={checked ? checked : false}
							style={inputStyle}
							autoFocus={autoFocus}
						/>
					</div>
				</div>

				<small>{info}</small>
			</div>
		);
	}

}

ControlledInput.propTypes = {
	// get the place holder for the input
	placeholder: propTypes.string,
	// get the event handler
	onChangeValue: propTypes.func.isRequired,
	// get the value if any
	// value,
	// get the field name to display
	label: propTypes.string,
	// get the field controlled in the Object (to throw the handler)
	controlledField: propTypes.string.isRequired,
	// the type of the input 
	type: propTypes.oneOf(["text", "checkbox", "number"]).isRequired,
	// is that field is mandatory 
	required: propTypes.bool.isRequired,
	// explanation on the input type and value
	// info
	size: propTypes.oneOf(["xs", "s", "m", "l"]),

	displayFlex: propTypes.bool
}

export default ControlledInput


