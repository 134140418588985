import React, { useState } from "react"
import { Button } from "react-bootstrap";
import { TimeUtils } from "../../../../common/modules";
import DateTimePicker from "../../../leaderBoardPage/components/DateTimePicker"


export const DatesRangePicker = ({ onDone, startTime, endTime, onStartTimeChange, onEndTimeChange, validate }) => {
	const [startDate, setStartDate] = useState(startTime ? new Date(parseInt(startTime)) : new Date());
	const [endDate, setEndDate] = useState(endTime ? new Date(parseInt(endTime)) : new Date());


	const onStartTimeSelect = (date) => {
		setStartDate(date)
		onStartTimeChange && onStartTimeChange(date)

	}


	const onEndTimeSelect = (date) => {
		setEndDate(date)
		onEndTimeChange && onEndTimeChange(date)
	}


	const onCanDone = () => {
		onDone(startDate, endDate)
	}


	const isSendButtonDisabled = () => {
		// should be disabled if
		// 1) start date is AFTER end date
		// 2) start date is same as end date AND start time is AFTER end time

		const isDayOK = TimeUtils.isEndAfterStartDay({ start: startDate, end: endDate })
		const isSameDay = TimeUtils.isSameDay({ start: startDate, end: endDate })
		const isSameMinute = TimeUtils.isSameMinute({ start: startDate, end: endDate })
		const isTimeOK = TimeUtils.isStartBeforeEndMinute({ start: startDate, end: endDate })

		// case where end day is after start
		if (isDayOK) {
			// setDisabledButtonErrorMsg("")
			validate && validate(true)
			return ({ isDisabled: false, msg: "" })
		}
		// check if same day
		else {
			// if same day check time
			if (isSameDay) {
				// if time ok and same day then OK
				if (isTimeOK) {
					validate && validate(true)
					return ({ isDisabled: false, msg: "" })
				}
				else {
					if (!isSameMinute) {
						validate && validate(false)
						return ({ isDisabled: true, msg: "Start time is after or same as end time" })
					}
					else {
						validate && validate(true)
						return ({ isDisabled: false, msg: "" })
					}
				}
			}
			else {
				validate && validate(false)
				return ({ isDisabled: true, msg: "Start day is after end day" })
			}
		}
	}


	const { isDisabled, msg } = isSendButtonDisabled();

	return <div className="DatesRangePicker">

		<div style={{ display: "flex", padding: "0 20px" }}>
			<div style={{ margin: "0 5px" }}>
				<small>Start</small>
				<DateTimePicker
					title={"Start data"}
					withTime={true}
					withFutureDates={true}
					returnedFormat={"dd/MM/yyyy HH:mm"}
					onChange={onStartTimeSelect}
				/>
			</div>

			<div style={{ margin: "0 5px" }}>
				<small>End</small>
				<DateTimePicker
					title={"End date"}
					withTime={true}
					withFutureDates={true}
					returnedFormat={"dd/MM/yyyy HH:mm"}
					onChange={onEndTimeSelect}
				/>
			</div>

			{isDisabled ? <div style={{ alignSelf: "flex-end", color: "red" }}><small>*{msg}</small></div> : <div style={{ alignSelf: "flex-end" }}>
				<Button bsSize="xs" onClick={onCanDone} disabled={isDisabled}>Go</Button>
			</div>}

		</div>
	</div>
}