
import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { answer } from "../paintConsts"
// import ResultAnswer from "./ResultAnswer"
import Option from "./Option"
import AddedShapeDisplay from "./AddedShapeDisplay"

// import "./../style.css";

class AnswerOptions extends Component {

	constructor(props) {
		super(props)


		this.state = {
			checkBoxes: [
				{
					required: false,
					controlledField: answer.has_areas,
					label: "Multilevel",
					type: "checkbox",
					info: "Check here if you found areas",
					checked: false,
					fontSize: "20px",
					border: "green",
					glyph: "plus"
				},
				{
					required: false,
					controlledField: answer.no_areas,
					label: "No Multilevel",
					type: "checkbox",
					info: "Check here if this task has no areas to mark",
					checked: false,
					fontSize: "20px",
					border: "red",
					glyph: "remove"
				},
				{
					required: false,
					controlledField: answer.conflict,
					label: "Other",
					type: "checkbox",
					info: "Check here if you have a problem",
					checked: false,
					border: "purple",
					fontSize: "20px",
					glyph: "question-sign"
				}
			],
			resetOnChange: null
		}


		this.nameChange = this.nameChange.bind(this)
	}


	static getDerivedStateFromProps(nextProps, prevState) {

		let newState = {};


		if (!nextProps.answerObject.status) {
			newState = {
				...prevState,
				checkBoxes: prevState.checkBoxes.map(c => {
					return { ...c, checked: false }
				})
			}

		}

		if (prevState.resetOnChange !== nextProps.resetOnChange) {
			// here we reset all cehckboxes to be false values
			newState = {
				...prevState,
				resetOnChange: nextProps.resetOnChange,
				checkBoxes: prevState.checkBoxes.map(c => {
					return { ...c, checked: false }
				})
			}
		}

		else {
			newState = prevState;
			// if status is null meaning we are at the start of new painting task
			if (nextProps.answerObject.status === undefined)
				// nothing to change
				return newState;


			else {

				newState.checkBoxes =
					newState.checkBoxes.map(checkbox => {
						if (checkbox.controlledField === nextProps.answerObject.status)
							return { ...checkbox, checked: true }
						else
							return { ...checkbox, checked: false }
					})
			}
		}



		//console.log(nextProps, prevState);
		return newState
	}



	nameChange(answerType) {
		return (e) => {
			const { status } = this.props.answerObject;

			if (answerType === answer.conflict_text)
				this.props.addConflictText(e.target.value ? e.target.value : null)
			else
				this.props.setStatus(status === answerType ? null : answerType)


		}
	}

	// will filter the checked answer and return its controlled field
	getAnswerFromCheckboxArr = () => {

		const { checkBoxes } = this.state;

		let chosenAnswer = checkBoxes.filter(c => c.checked)
		if (chosenAnswer.length === 1)
			return chosenAnswer[0].controlledField
		else
			return null
	}

	resetAllCheckBoxs = () => {
		let { checkBoxes } = this.state;
		let reset = checkBoxes.map(c => {
			return { ...c, checked: false }
		})

		this.setState({ checkBoxes: reset })
	}

	render() {

		const { checkBoxes } = this.state;
		const { answerObject, shapeClickedId, onShapeEdit, editShapeID, onDoneEdit, onDeleteShape } = this.props;

		return (

			<div style={{ display: "grid", gridRowGap: "15px", maxWidth: "100%" }}>

				{checkBoxes.map((c, i) => (
					<div key={i} style={{ fontSize: c.fontSize }}>
						<Option {...c} onChangeValue={this.nameChange} />
					</div>
				))}


				{/* {answerObject.areas.length > 0 && <small>Manage your answer</small>} */}
				{answerObject.areas.length > 0 && <small>Manage your answer<Button style={{ float: "right" }} bsStyle="danger" bsSize="xs" onClick={this.props.clearAnswerShapes} >Clear all</Button></small>}

				{answerObject.areas.length > 0 &&
					<AddedShapeDisplay
						areasArray={answerObject.areas}
						activeShapeID={shapeClickedId}
						onEdit={onShapeEdit}
						editShapeID={editShapeID}
						onDoneEdit={onDoneEdit}
						onDelete={onDeleteShape}
					/>
				}


			</div >
		)
	}

}






export default AnswerOptions;
