import React, { useEffect, useState } from "react"
import { shallowEqual, useSelector, useDispatch } from "react-redux"

import { Button } from "react-bootstrap"
import { TimeUtils } from "../../../../common/modules"
import { DatesRangePicker } from "../../../utils/Components/datesRangePicker/datesRangePicker"
import { SortPicker } from "../../../utils/Components/sortPicker/sortPicker"
import moment from "moment"
import Switch from "react-switch"
import "./approverFilter.css"
import { approverResetFilter, approverSetFilter } from "../../../../redux/approve/approverActions"
import { getApproverFilter, getCurrentUsername } from "../../../../redux/approve/selectors"



export const ApproverFilter = ({ hide, onTextInputFocus, onTextInputBlur }) => {
	const dispatch = useDispatch()
	const currentUsername = useSelector(state => getCurrentUsername(state), shallowEqual)
	const filter = useSelector(state => getApproverFilter(state), shallowEqual)

	const [withPriority, setWithProirity] = useState(false)
	const [approvalRequest, setApprovalRequest] = useState(false)
	const [subtaskName, setSubtaskName] = useState("")
	const [sentBackBy, setSentBackBy] = useState("")
	const [isSentBackByEnabled, setIsSentBackByEnabled] = useState(false)
	const [supervisorName, setSupervisorName] = useState("")
	const [intervalType, setIntervalType] = useState("")
	const [sortFromDate, setSortFromDate] = useState("")
	const [sortToDate, setSortToDate] = useState("")
	const [areCustomDatesValid, setAreCustomDatesValid] = useState(true)


	useEffect(() => {
		setWithProirity(filter && filter.withPriority || false)
		setSubtaskName(filter && filter.subtaskName || "")
		setSentBackBy(filter && filter.sentBackBy || "")
		setIsSentBackByEnabled(filter && filter.sentBackBy ? true : false)
		setSupervisorName(filter && filter.supervisorName || "")
		setSortFromDate(filter && filter.dateFrom || "")
		setSortToDate(filter && filter.dateTo || "")
		setIntervalType(filter && filter.intervalType || "")
		setApprovalRequest(filter && filter.approvalRequest || false)
	}, [filter])


	const onWithPriorityChange = (e) => {
		setWithProirity(e.target.checked)
	}


	const onApprovalRequestChange = (e) => {
		setApprovalRequest(e.target.checked)
	}


	const onSubtaskNameChange = (e) => {
		setSubtaskName(e.target.value)
	}


	const onSentBackByChange = (e) => {
		setSentBackBy(e.target.value)
	}


	const onSupervisorNameChange = (e) => {
		setSupervisorName(e.target.value)
	}


	const setCustomInterval = (startTime, endTime) => {
		setSortFromDate(moment(startTime).valueOf())
		setSortToDate(moment(endTime).valueOf())
	}

	const onIsSentBackByEnabledChange = (isEnabled) => {
		setIsSentBackByEnabled(isEnabled)
		if (!isEnabled) {
			setSentBackBy("")
		}
	}


	const onIntervalChange = (selectedIntervalType) => {
		//Reset interval type if clicked twice
		if (selectedIntervalType === intervalType) {
			setIntervalType("")
			setSortFromDate("")
			setSortToDate("")
			return
		}

		setIntervalType(selectedIntervalType)

		if (selectedIntervalType === "CUSTOM") {
			const startTime = moment().valueOf()
			setSortFromDate(startTime)
			setSortToDate(startTime)
			return
		}
		const { from, to } = TimeUtils.generateTimeWindow(selectedIntervalType, true)
		setSortFromDate(from)
		setSortToDate(to)
	}

	const onStartTimeChange = (startTime) => {
		setSortFromDate(moment(startTime).valueOf())
	}


	const onEndTimeChange = (endTime) => {
		setSortToDate(moment(endTime).valueOf())

	}

	const onFilterClicked = () => {
		let filterData;
		if (subtaskName) {
			filterData = { subtaskName }
		} else {
			filterData = {
				approvalRequest,
				withPriority,
				subtaskName,
				sentBackBy,
				supervisorName,
				intervalType,
				dateFrom: sortFromDate,
				dateTo: sortToDate
			}
		}
		dispatch(approverSetFilter(filterData))
		hide()
	}


	const onResetClicked = () => {
		dispatch(approverResetFilter())
		setIntervalType("")
	}


	return <div className="ApproverFilter">
		<span className="mainTitle">Select filter options</span>
		<div className="filterOptions">

			<div className="section">
				<label htmlFor="subtaskName">Subtask Name</label>
				<input type="text" onFocus={onTextInputFocus} onBlur={onTextInputBlur} id="subtaskName" value={subtaskName} onChange={onSubtaskNameChange} />
			</div>

			<div className="section">
				<label htmlFor="supervisorName">Supervisor Name</label>
				<input disabled={subtaskName} onFocus={onTextInputFocus} onBlur={onTextInputBlur} type="text" id="supervisorName" value={supervisorName} onChange={onSupervisorNameChange} />
			</div>

			<div className="section">
				<label htmlFor="sentBackBy">Sent Back By</label>
				<Switch disabled={!!subtaskName} height={15} width={35} onChange={onIsSentBackByEnabledChange} checked={isSentBackByEnabled} />
				{isSentBackByEnabled && <input disabled={subtaskName} type="text" onFocus={onTextInputFocus} onBlur={onTextInputBlur} id="sentBackBy" value={sentBackBy} onChange={onSentBackByChange} />}
				{isSentBackByEnabled && <Button
					disabled={subtaskName}
					bsSize="xs"
					bsStyle="success"
					onClick={() => setSentBackBy(currentUsername)}>
					Me
				</Button>}
			</div>

			<div className="section">
				<label htmlFor="withPriority">Many Areas</label>
				<input disabled={subtaskName} type="checkbox" id="withPriority" checked={withPriority} onChange={onWithPriorityChange} />
			</div>

			<div className="section">
				<label htmlFor="approvalRequest">Approval Request</label>
				<input disabled={subtaskName} type="checkbox" id="approvalRequest" checked={approvalRequest} onChange={onApprovalRequestChange} />
			</div>

			<div className="sortPickerWrapper">
				<SortPicker
					disabled={subtaskName}
					label="Show:"
					currentSelectedSortOption={intervalType}
					sortGroups={[
						[{ label: "Today", value: "TODAY" }, { label: "Yesterday", value: "YESTERDAY" }, { label: "This Week", value: "WEEK" }, { label: "Last 7 Days", value: "LAST_7_DAYS" }, { label: "This Month", value: "MONTH" }],
					]}
					onSelect={(sortByTimeTerm) => onIntervalChange(sortByTimeTerm)}>

					<div style={{ display: "flex", position: "relative" }}>
						<Button
							disabled={subtaskName}
							bsSize="sm"
							bsStyle={intervalType === "CUSTOM" ? "success" : "default"}
							onClick={() => onIntervalChange("CUSTOM")}>
							Custom
						</Button>
						{intervalType === "CUSTOM" && !subtaskName &&
							<span className="customDatesPickerWrapper" >
								<DatesRangePicker
									validate={setAreCustomDatesValid}
									startTime={sortFromDate}
									endTime={sortFromDate}
									onStartTimeChange={onStartTimeChange}
									onEndTimeChange={onEndTimeChange}
									onDone={(startTime, endTime) => setCustomInterval(startTime, endTime)}
								/>
							</span>
						}
					</div>
				</SortPicker>
			</div>

			<div className="actionBtns">
				<Button
					bsSize="sm"
					bsStyle="success"
					disabled={isSentBackByEnabled && sentBackBy.length === 0 || !areCustomDatesValid}
					onClick={onFilterClicked}>
					Filter
				</Button>
				<Button
					bsSize="sm"
					bsStyle="warning"
					onClick={onResetClicked}>
					Reset
				</Button>
				<Button
					bsSize="sm"
					bsStyle="danger"
					onClick={hide}>
					Cancel
				</Button>
			</div>
		</div>

	</div>
}