import React from "react";
import { Button, Glyphicon } from "react-bootstrap";
import { ShapeUtils } from "../../../../common/sharedGlobal";
import "./listItemActions.css"


export default ({ id, item, onEdit, onFocus, onDelete }) => {
	const boundingBoxForFocus = ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(ShapeUtils.getBoundingBox(item))
	return (
		<div className="itemActionsContainer">
			<Button title="Edit" bsStyle="warning" onClick={() => onEdit(id)}><Glyphicon glyph="pencil" /></Button>
			<Button title="Delete" bsStyle="danger" bsSize="xs" onClick={() => onDelete({ id, item })}><Glyphicon glyph="trash" /></Button>
			<Button title="Focus" bsStyle="primary" bsSize="xs" onClick={() => onFocus(id, boundingBoxForFocus)}><Glyphicon glyph="map-marker" /></Button>
		</div >
	)



}