import React from "react";
import { constHelpers } from "../../components/utils";
import Switch from "react-switch"

export default ({ isApproved, previewDisplayString, updatePreviewDisplayString, }) => {

	const handleOnChange = () => {
		if (previewDisplayString === constHelpers.previewDisplay.approver) {
			updatePreviewDisplayString(constHelpers.previewDisplay.supervisor)
		}
		if (previewDisplayString === constHelpers.previewDisplay.supervisor) {
			updatePreviewDisplayString(constHelpers.previewDisplay.approver)
		}
	}


	if (isApproved)
		return < div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "182px" }}>
			<span>{constHelpers.previewDisplay.supervisor} </span>
			<Switch
				uncheckedIcon={false}
				checkedIcon={false}
				height={15}
				width={35}
				offColor="#ececec"
				onColor="#ececec"
				onHandleColor="#4CA64C"
				offHandleColor="#4CA64C"
				onChange={handleOnChange}
				checked={previewDisplayString === constHelpers.previewDisplay.approver} />
			<span>{constHelpers.previewDisplay.approver} </span>
		</div >
	else return null
}