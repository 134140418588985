import { handleActions } from "redux-actions"
import { ShapeUtils } from "../../common/sharedGlobal";
import { updateShapeList } from "./shapeActions";
const { ACTION_TYPES, MAP_STATE } = require("../reduxConsts")



const initialState = {
	shapeList: [],
	mapState: MAP_STATE.READ,
	addEditShape: null,
	focus: null,
	clickedShapeID: null,
}



const resetState = (state, action) => ({ ...initialState })



const deleteAreaById = (state, action) => {
	const { id } = action.value;
	const shapeList = state.shapeList.filter(shape => shape["_id"] !== id)

	return {
		...state,
		shapeList
	}
}


const setClickedAreaById = (state, action) => {
	const { id } = action.value
	const clickedShapeID = state.clickedShapeID === id ? null : id

	return {
		...state,
		clickedShapeID
	}
}



const resetAddEditShape = (state, action) => ({
	...state,
	addEditShape: {}
})




const updateAddEditShape = (state, action) => {
	const data = action.value
	return {
		...state,
		addEditShape: {
			...state.addEditShape,
			...data
		}
	}
}




const cancelAddEditOfShape = (state, action) => {
	const { shape } = action.value
	let newShapeList = [...state.shapeList];

	if (state.mapState === MAP_STATE.EDIT) {
		const shapeBeforeEdit = shape ? shape : { ...state.addEditShape }
		newShapeList = [...state.shapeList, shapeBeforeEdit]
	}

	return {
		...state,
		mapState: MAP_STATE.READ,
		shapeList: [...newShapeList],
		addEditShape: {},
	}

}



const addArea = (state, action) => ({
	...state,
	mapState: MAP_STATE.ADD,
	addEditShape: {
		multilevelTypes: {},
		description: "",
		shape: {
			type: "DiagonalRectangle",
			points: []
		}
	}
})




const enterEditModeOnAreaEdit = (state, action) => {
	const idOfShapeInEdit = action.value
	const addEditShape = { ...state.shapeList.filter(s => s._id === idOfShapeInEdit)[0] };
	const shapeList = [...state.shapeList.filter(s => s._id !== idOfShapeInEdit)]
	const focus = ShapeUtils.getBoundingBox(addEditShape)
	return {
		...state,
		mapState: MAP_STATE.EDIT,
		addEditShape: { ...addEditShape },
		shapeList: [...shapeList],
		focus
	}
}




const addDiagonalRectanglePointToAreaInEdit = (state, action) => {
	if (state.addEditShape && state.addEditShape.shape.points.length === 3) {
		return { ...state }
	}

	const newPoint = action.value
	let tempPoints = [...state.addEditShape.shape.points, { ...newPoint }]

	const tempShape = {
		...state.addEditShape.shape,
		points: [...tempPoints]
	}
	return {
		...state,
		addEditShape: {
			...state.addEditShape,
			shape: { ...tempShape }
		}
	}
}



const editDiagonalRectanglePointOfAreaInEdit = (state, action) => {
	const { pointOrder, newPoint } = action.value
	const tempPoints = [...state.addEditShape.shape.points]
	tempPoints[pointOrder] = { ...newPoint }

	const tempShape = {
		...state.addEditShape.shape,
		points: [...tempPoints]
	}


	return {
		...state,
		addEditShape: {
			...state.addEditShape,
			shape: { ...tempShape }
		}
	}
}



const focusMapOnBounds = (state, action) => {
	const boundingBox = action.value.boundingBox
	return {
		...state,
		focus: ({
			minLat: boundingBox.sw.lat,
			minLon: boundingBox.sw.lng,

			maxLat: boundingBox.ne.lat,
			maxLon: boundingBox.ne.lng
		})
	}

}



const deleteMultipleAreas = (state, action) => {
	const idsToDeleteArray = action.value;

	// remove all elements with id equal to each id in "array"
	const updatedShapeList = state.shapeList.filter(s => idsToDeleteArray.indexOf(s._id) < 0)

	return {
		...state,
		shapeList: updatedShapeList
	}
}



const resetFocus = (state, action) => ({
	...state,
	focus: null
})



const setMapState = (state, action) => ({
	...state,
	mapState: action.value
})


const updateAreaById = (state, action) => {
	const { data, id } = action.value
	let isItemUpdated = false

	let updatedShapeList = state.shapeList.map(shape => {
		if (shape._id === id) {
			isItemUpdated = true
			return ({ ...shape, ...data })
		}
		return shape;
	})

	if (!isItemUpdated) {
		updatedShapeList = [...updatedShapeList, data]
	}

	return {
		...state,
		shapeList: updatedShapeList
	}
}



const setAreasList = (state, action) => {
	const newShapesList = action.value
	return {
		...state,
		shapeList: newShapesList
	}
}



const resetAreas = (state, action) => {
	return {
		...state,
		shapeList: []
	}
}


const deleteDiagonalRectanglePointInEdit = (state, action) => {
	const pointOrderToDelete = action.value


	let tempPointsArr = [...state.addEditShape.shape.points]

	//remove the point 
	tempPointsArr[pointOrderToDelete] = null
	tempPointsArr = _.filter(tempPointsArr)


	const tempShape = {
		...state.addEditShape.shape,
		points: [...tempPointsArr]
	}


	return {
		...state,
		addEditShape: {
			...state.addEditShape,
			shape: { ...tempShape }
		}
	}

}



export const shapesStore = handleActions({
	[ACTION_TYPES.SHAPE.RESET_STATE]: resetState,
	[ACTION_TYPES.SHAPE.DELETE_AREA_FROM_LIST_BY_ID]: deleteAreaById,
	[ACTION_TYPES.SHAPE.AREA_CLICKED]: setClickedAreaById,
	[ACTION_TYPES.SHAPE.RESET_ADD_EDIT_SHAPE]: resetAddEditShape,
	[ACTION_TYPES.SHAPE.UPDATE_CURRENT_ADD_EDIT_SHAPE]: updateAddEditShape,
	[ACTION_TYPES.SHAPE.ON_CANCEL_ADD_EDIT]: cancelAddEditOfShape,
	[ACTION_TYPES.SHAPE.ON_ADD_AREA]: addArea,
	[ACTION_TYPES.SHAPE.ON_EDIT_AREA]: enterEditModeOnAreaEdit,
	[ACTION_TYPES.SHAPE.ADD_DIAGONAL_RECTANGLE_POINT]: addDiagonalRectanglePointToAreaInEdit,
	[ACTION_TYPES.SHAPE.EDIT_DIAGONAL_RECTANGLE_POINT]: editDiagonalRectanglePointOfAreaInEdit,
	[ACTION_TYPES.SHAPE.ON_FOCUS_AREA]: focusMapOnBounds,
	[ACTION_TYPES.SHAPE.DELETE_MULTIPLE_AREAS]: deleteMultipleAreas,
	[ACTION_TYPES.SHAPE.RESET_FOCUS]: resetFocus,
	[ACTION_TYPES.SHAPE.SET_MAP_STATE]: setMapState,
	[ACTION_TYPES.SHAPE.UPDATE_AREA_ITEM_BY_ID]: updateAreaById,
	[ACTION_TYPES.SHAPE.SET_AREAS_LIST]: setAreasList,
	[ACTION_TYPES.SHAPE.RESET_AREAS]: resetAreas,
	[ACTION_TYPES.SHAPE.DELETE_DIAGONAL_RECTANGLE_POINT_IN_EDIT]: deleteDiagonalRectanglePointInEdit,
}, initialState)



