import React from "react";
import { Glyphicon } from "react-bootstrap";


const RenderCorrectWrong = (bool) => <Glyphicon style={{ color: bool ? "green" : "red" }} glyph={bool ? "ok-circle" : "remove-circle"} />


export default ({ data }) => <div>
	<div>{data.appliedShapes && data.appliedShapes.length > 0 && <span>Added areas: {data.appliedShapes.length}</span>}</div>
	<div>shapes: {RenderCorrectWrong(data.score.shapes)}</div>
	<div>grading: {RenderCorrectWrong(data.score.grading)}</div>
	<div>tags: {RenderCorrectWrong(data.score.tags)}</div>
</div>