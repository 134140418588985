import React from "react";
import { Glyphicon } from "react-bootstrap";
import { constHelpers } from "./utils";



export default ({ subtaskName, backHandler, }) => <div>

	<div style={{ display: "flex", alignItems: "center", cursor: "pointer" }} onClick={backHandler}>

		<Glyphicon glyph="chevron-left" style={{
			cursor: "pointer",
			fontSize: "20px",
			color: constHelpers.HEADLINE_COLOR
		}} />

		<span style={{ color: constHelpers.HEADLINE_COLOR, fontSize: "20px" }}>{subtaskName}</span>
	</div>

</div>