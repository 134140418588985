import { connect } from "react-redux";
import React, { Component } from "react";
import { Modal } from "../../common/components/Modal";

const mapStateToProps = ({ modalStore }) => {
	return {
		...modalStore
	};
};


class ModalContainer extends Component {
	render() {
		const { isModalOpen } = this.props;
		console.log({ isModalOpen });
		return isModalOpen ?
			<Modal {...this.props} /> :
			null
	}
}



export default connect(mapStateToProps)(ModalContainer);


