

const { SHAPE } = require("../reduxConsts").ACTION_TYPES


// this function will reset the tmp shape 



// async operation has started
export const async_start = () => ({ type: SHAPE.ASYNC_START })

// async operation has finished successfully
// export const async_success = () => ({ type: SHAPE.ASYNC_SUCCESS });

// async operation has finished with errorF
// export const async_error = (error) => ({ type: SHAPE.ASYNC_ERROR, value: error })
/******************* SAGA ACTION *************** */
const getShapes = () => ({ type: SHAPE.GET_AREAS })
const deleteShape = (id) => ({ type: SHAPE.DELETE_AREA, value: { id } })
const addEditShape = (data) => ({ type: SHAPE.ADD_EDIT_AREA, value: { area: data } })
const changeDisableArea = (id, bool) => ({ type: SHAPE.SET_AREA_DISABLE, value: { id, bool } })
const sendMultipleDelete = (idArray) => ({ type: SHAPE.MULTIPLE_DELETE, value: idArray })
const askInBoxAreas = (bb) => ({ type: SHAPE.ASK_IN_BOX_AREAS, value: { boundingBox: bb } })

export const sagaActions = { askInBoxAreas, getShapes, deleteShape, addEditShape, changeDisableArea, sendMultipleDelete }
/******************* SAGA ACTION *************** */

/******************* SAGA ACTION *************** */
export const deleteAreaFromListById = (id) => ({ type: SHAPE.DELETE_AREA_FROM_LIST_BY_ID, value: { id } })
export const multipleDeleteSuccess = (array) => ({ type: SHAPE.DELETE_MULTIPLE_AREAS, value: array })
export const shapeClicked = (id) => ({ type: SHAPE.AREA_CLICKED, value: { id } });
export const resetFocus = () => ({ type: SHAPE.RESET_FOCUS })
export const cancelAddEdit = (shape = null) => ({ type: SHAPE.ON_CANCEL_ADD_EDIT, value: { shape } })
export const onFocusShape = (id, boundingBox) => ({ type: SHAPE.ON_FOCUS_AREA, value: { id, boundingBox } })
export const onAddShape = (service) => ({ type: SHAPE.ON_ADD_AREA, value: service });
export const onEditDraw = (id) => ({ type: SHAPE.ON_EDIT_AREA, value: id });
export const exportShapes = () => ({ type: SHAPE.EXPORT });
export const resetState = () => ({ type: SHAPE.RESET_STATE });
export const cleanError = () => ({ type: SHAPE.CLEAN_ERROR });
export const updateShapeList = (shapeList) => ({ type: SHAPE.SET_AREAS_LIST, value: shapeList })
export const updateShapeItemById = ({ id, data }) => ({ type: SHAPE.UPDATE_AREA_ITEM_BY_ID, value: { id, data } })
export const resetTmpShape = () => ({ type: SHAPE.RESET_ADD_EDIT_SHAPE })
export const setMapState = (state) => ({ type: SHAPE.SET_MAP_STATE, value: state })
export const updateShape = ({ isActive, _id }) => ({ type: SHAPE.UPDATE_SHAPE, value: { isActive, _id } })
export const addDiagonalRectanglePoint = ({ lat, lng }) => ({ type: SHAPE.ADD_DIAGONAL_RECTANGLE_POINT, value: { lat, lng } })
export const editDiagonalRectanglePoint = (pointOrder, newPoint) => ({ type: SHAPE.EDIT_DIAGONAL_RECTANGLE_POINT, value: { pointOrder, newPoint } })
export const updateCurrentAddEditShape = (dataToUpdate) => ({ type: SHAPE.UPDATE_CURRENT_ADD_EDIT_SHAPE, value: dataToUpdate })
export const removePointOfDiagonalRectangleInEdit = (pointOrder) => ({ type: SHAPE.DELETE_DIAGONAL_RECTANGLE_POINT_IN_EDIT, value: pointOrder })

export const loadShapesThatFitMapBounds = (bounds) => ({ type: SHAPE.LOAD_SHAPES_THAT_FIT_MAP_BOUNDS, value: bounds })

