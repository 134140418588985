

const { DIALOG } = require("../reduxConsts").ACTION_TYPES


/**level, txt, next, cancel, title  */
export const showDialog = (data) => ({ type: DIALOG.SHOW_DIALOG, value: data })

export const closeDialog = () => ({ type: DIALOG.CLOSE_DIALOG });

