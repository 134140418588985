import React, { Component } from "react";
import { connect } from "react-redux";

import { getTasks, resetState } from "../../redux/tasks/tasksActions";
import ListContainer from "./containers/ListContainer";
import MapContainer from "./containers/MapContainer";
// import Prompt from "./../utils/Prompt";
import { Prompt } from "react-router"
import Spinner from "./../hoc/Spinner";
// import { runQuickTask } from "./../../utils/devRunFullTaskProcess";
import "./tasksStyle.css";

class Manage extends Component {




	componentDidMount() {
		// let { accessToken } = this.props.user;

		this.props.getTasksAsync();

	}

	componentWillUnmount() {
		const { resetState } = this.props
		resetState()
	}


	render() {
		const { mapState } = this.props;

		return (
			<div className='task_manager'>
				{/* TODO: KNOWN ISSUE: firing twice */}
				<Prompt
					when={mapState === "ADD"}
					message={"You have unsaved changes, Leave page?"}
				/>
				<Spinner spinning={this.props.tasksAsyncStatus === "START"} text={"Fetching data"}  ></Spinner>
				<div className='task_list_container'><ListContainer /></div>
				<div className='task_map_container' ><MapContainer /></div>
			</div>

		)
	}

}

let mapStateToProps = ({ tasksStore, authStore }) => {
	return {
		tasksAsyncStatus: tasksStore.asyncStatus,
		user: authStore.user || {},
		mapState: tasksStore.mapState

	}
}

let mapDispatchToProps = (dispatch) => {
	return {
		getTasksAsync: (accessToken) => dispatch(getTasks(accessToken)),
		resetState: () => dispatch(resetState())
	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Manage);
