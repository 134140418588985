const getSubtaskNameFromUrlPath = () => {

	const searchParams = new URLSearchParams(window.location.search);
	return searchParams.get("task")
}


const removeSubtaskNameFromUrl = () => {

	window.history.replaceState({}, "", `${window.location.pathname}`);

	// const searchParams = new URLSearchParams(window.location.search);
	// return searchParams.delete("task")
}



export {
	getSubtaskNameFromUrlPath,
	removeSubtaskNameFromUrl
}