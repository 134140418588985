import React from "react";

import lodash_isempty from "lodash.isempty";
import ApproverSummary from "../../../genericTableComponents/summary/approver";
import { GenericTable } from "../../../../common/components";
import SupervisorSummary from "../../../genericTableComponents/summary/supervisor";


export default ({ approverAndSupervisorSummary }) => {
	const renderSupervisorCell = (supervisor = false) => {

		if (!lodash_isempty(supervisor)) return <SupervisorSummary supervisor={supervisor} />
		else return null
	}

	const renderApproverCell = (approver = false) => {
		if (!lodash_isempty(approver))
			return <ApproverSummary approver={approver} />
		else return null
	}



	if (approverAndSupervisorSummary)
		if (!lodash_isempty(approverAndSupervisorSummary.approverAnswer))
			return <GenericTable data={[approverAndSupervisorSummary]} mapColumnToRenderer={
				[
					{
						column: { title: "Supervisor" },
						cellRenderer: ({ supervisorAnswer }) => renderSupervisorCell(supervisorAnswer)
					},
					{
						column: { title: "Approver" },
						cellRenderer: ({ approverAnswer }) => renderApproverCell(approverAnswer)
					},
				]} />

		else
			return <GenericTable data={[approverAndSupervisorSummary]} mapColumnToRenderer={
				[
					{
						column: { title: "Supervisor" },
						cellRenderer: ({ supervisorAnswer }) => renderSupervisorCell(supervisorAnswer)
					}
				]} />
	else return null

}