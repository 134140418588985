import React from "react"
import "./info.css"
import MultilevelPicker from "./../../manageAreasPage/components/MultilevelPicker/AddMultilevelType"
import * as classNames from "classnames"

export class GtSupervisorInfo extends React.Component {

	render() {
		const { data, isEditable, onMultilevelComplexChange, onMultilevelTopChange, onMultilevelBottomChange, isTextWithLineThrough } = this.props
		if (isEditable) {
			const editableMultilayerClassNames = classNames({
				"errorBg": !data.isComplex && (!data.topLevel || !data.bottomLevel)
			})
			const topLevel = data.topLevel || "Types"
			const bottomLevel = data.bottomLevel || "Types"

			return <div className={editableMultilayerClassNames}>
				<MultilevelPicker
					top={topLevel}
					bottom={bottomLevel}
					isComplex={data.isComplex}
					topHandler={onMultilevelTopChange}
					bottomHandler={onMultilevelBottomChange}
					complexHandler={onMultilevelComplexChange}
				/>
			</div>
		}

		return (<div className="info GT_supervisorInfo" >
			{data.topLevel && <div style={{
				textDecoration: isTextWithLineThrough ? "line-through" : "none"
			}}>{`Top level: ${data.topLevel}`}</div>}
			{data.bottomLevel && <div style={{
				textDecoration: isTextWithLineThrough ? "line-through" : "none"
			}}>{`Bottom level: ${data.bottomLevel}`}</div>}
			{typeof data.isComplex !== "undefined" && data.isComplex && <div style={{
				textDecoration: isTextWithLineThrough ? "line-through" : "none"
			}}>Complex</div>}
		</div>)
	}

}

