import React from "react";
import ListItemRow from "./ListItemRow"
import ListItemRowIsActive from "./ListItemRowIsActive"
import ListItemActions from "./ListItemActions"
import Checkbox from "./../../../hoc/ControlledInputPure"
import * as classnames from "classnames"
import "./listItem.css"


export default ({ item, setShapeActive, onEdit, onFocus, onDelete, active, style, multiPleDeleteOn, multiPleDeleteChecked, isMultipleDeleteSelected }) => {
	const { _id, description, isActive, multilevelTypes } = item
	const containerClassNames = classnames({
		"listItemContainer": true,
		"multipleDeleteActive": isMultipleDeleteSelected,
		"active": active
	})

	return (
		<div className={containerClassNames} style={style} >

			{multiPleDeleteOn && <div className="checkboxWrapper"><Checkbox checked={isMultipleDeleteSelected} size="m" type="checkbox" controlledField="multiPleDeleteCheckbox" required={false} onChangeValue={(e) => {
				multiPleDeleteChecked(_id, e.target.checked)
			}} ></Checkbox></div>}
			<div className="centerWrapper">
				<ListItemRow keyname={"ID"} value={_id} />
				<ListItemRow keyname={"Description"} value={description} />
				{!!multilevelTypes.isComplex && <ListItemRow keyname={"Complex multilevel"} value={""} addColon={false} />}
				{multilevelTypes.topLevel && <ListItemRow keyname={"Multilevel Top"} value={multilevelTypes.topLevel} />}
				{multilevelTypes.bottomLevel && <ListItemRow keyname={"Multilevel Bottom"} value={multilevelTypes.bottomLevel} />}
				<ListItemRowIsActive isActive={isActive} isActiveAction={setShapeActive} id={_id} />
			</div>
			<ListItemActions description={description} item={item} onEdit={onEdit} onFocus={onFocus} onDelete={onDelete} id={_id} />
		</div>
	)

}