const { ACTION_TYPES } = require("../reduxConsts")

// initial state for tasks store
const loader_initial = {
	showLoader: false
}


export function loaderStore(state = loader_initial, action) {

	const { type } = action;

	switch (type) {

		/******************* ASYNC ACTIONS ******************* */
		case ACTION_TYPES.LOADER.SHOW_LOADER: {
			return {
				showLoader: true
			}
		}

		case ACTION_TYPES.LOADER.HIDE_LOADER: {
			return {
				showLoader: false
			}

		}


		default: {
			return state
		}
	}
}
