

import React from "react";
import { Glyphicon, OverlayTrigger, Button } from "react-bootstrap"

export default ({ subtask, leftToSupervise, popover, isSendBack = false, onSkip }) => {

	const reSuperviseMessage = (approver) => "This subtask was sent back by " + approver

	const isSendBackStyle = {
		backgroundColor: "#ff000038",
		borderRadius: "10px",
		textAlign: "center",
		fontWeight: 800
	}

	const goToPreviewSubtask = () => {
		// open the preview in a new window (popup)
		window.open("preview/" + subtask.approverSendBack.previousSubTaskName, "popup", "width=800,height=800")
	}

	return (
		<div>
			<div style={{ marginTop: "1vh", alignItems: "center", color: "#5eb2e1", height: "7%", paddingRight: "20px", paddingLeft: "20px", paddingBottom: "5px", fontSize: "22px", display: "flex" }} >
				{/* page info */}
				<div style={{ flexGrow: "1" }}>

					<h3 style={{ margin: "0" }}>
						Supervise
						<span style={{ color: "gray", padding: "0px 10px 0px 10px" }}> {">"} </span>
						{subtask.name}
					</h3>
				</div>

				{/* info button */}
				<div style={{ flexGrow: "1" }} >
					<OverlayTrigger trigger="click" placement="bottom" overlay={popover()}>
						<Glyphicon style={{ top: "0.3vh" }} glyph="info-sign" />
					</OverlayTrigger>
				</div>


				{leftToSupervise && <div style={{ flexGrow: "80" }}>
					<h3 style={{ margin: "0" }}>( Tasks left: {leftToSupervise} )</h3>
				</div>}
				<div style={{ display: "flex", paddingRight: "5px" }}>
					<Button bsSize="sm" bsStyle="info" onClick={onSkip}>Skip</Button>
				</div>
			</div>


			{isSendBack && <div style={isSendBackStyle}>
				{reSuperviseMessage(subtask.approverSendBack.userApproved.userEmail)}
				<Button onClick={goToPreviewSubtask} bsStyle="link">Show original</Button>
			</div>}

		</div>
	)

}



