import React from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { phaseChanged, setItemColorInApproverTable, setItemVisibilityInApproverTable, setPhaseAnswer, setVisibilityToAll } from "../../../../redux/approve/approverActions"
import {
	getApprovalSubtaskBoundingBoxFromStore, getPaintersDataFromApprovalStore, getSnapshotWhileSupervisorDataFromApprovalStore,
	getSuperviseTimeFromState, getSupervisorCommentFromState, getSupervisorDataFromApprovalStore, getSupervisorNameFromState, getTableItemsSelections,
	getApproverAnswerForShapes, getApproverAnswerForTags, getApproverAnswerForGrading
} from "../../../../redux/approve/selectors"
import { Badge, Button, Glyphicon } from "react-bootstrap"
import { GtPainterInfo } from "../../../genericTableComponents/info/painter"
import { GenericTable } from "../../../../common/components"
import { GtShapeVisibility } from "../../../genericTableComponents/shapeVisibility/shapeVisibility"
import { GtSnapshotWhileSupervisorInfo } from "../../../genericTableComponents/info/snapshotWhileSupervisor"
import { GtShapeColor } from "../../../genericTableComponents/shapeColor/shapeColor"
import { ApproveStageMapContainer } from "../approveStageMapContainer/approveStageMapContainer"

import { SupervisorAnswersTable } from "./../supervisorsAnswerTable/supervisorsAnswerTable"
import ApproveCommentBox from "./../approveCommentBox/approveCommentBox"
import { APPROVAL_DATA_TYPES } from "../../../../redux/saga/approverSaga"
import { QuestionToApprove } from "../questionToApprove/questionToApprove"
import "./../stages.css"
import { ShapeUtils } from "../../../../common/sharedGlobal"



class StageOne extends React.Component {
	state = {
		mapCenterToFocus: null,
		highlightedItemId: null
	}


	componentDidMount = () => {
		this.generateMapCenter()
	}


	componentDidUpdate(prevProps) {
		if (!prevProps.tableItemsSelections && this.props.tableItemsSelections) {
			this.generateMapCenter()
		}
	}


	generateMapCenter = () => {
		const { approvalSubtaskBoundingBox, tableItemsSelections } = this.props
		// Set initial center
		const tableItems = _.filter(tableItemsSelections, item => item.type === APPROVAL_DATA_TYPES.PAINTER || item.type === APPROVAL_DATA_TYPES.SUPERVISOR || item.type === APPROVAL_DATA_TYPES.SNAPSHOT_WHILE_SUPERVISOR)
		const allShapesBB = ShapeUtils.convertArrayOfDiagonalRectanglesAndRectanglesToArrayOfNeSwBoundingBoxes([
			approvalSubtaskBoundingBox,
			..._.map(tableItems).reduce((arr, item) => {
				return [...arr, ...item.shapes]
			}, [])])
		const centerOfAllShapes = ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(ShapeUtils.RECTANGLE.biggestBoundingBox(allShapesBB))
		this.setState({ mapCenterToFocus: centerOfAllShapes })
	}


	generatePaintersTable = () => {
		const { paintersData, snapshotWhileSupervisorData } = this.props
		const tableData = _.filter([...paintersData, snapshotWhileSupervisorData])

		return <GenericTable
			data={tableData} // Array
			mapColumnToRenderer={[
				{
					column: {
						title: "",
						icon: <Glyphicon glyph="eye-open" />
					},
					cellRenderer: (item, index) => {
						const { tableItemsSelections, setItemVisibilityInApproverTableAction, isAnyTextInputFocused } = this.props
						const isVisible = tableItemsSelections && tableItemsSelections[item.id].visible

						return <GtShapeVisibility
							isAnyTextInputFocused={isAnyTextInputFocused}
							bindKeyListenerToItem={{
								triggerKey: index + 1,
								action: (itemId, isVisible) => setItemVisibilityInApproverTableAction(itemId, isVisible)
							}}
							item={item}
							isVisible={isVisible}
							changeVisibility={setItemVisibilityInApproverTableAction} />
					}
				},
				{
					column: {
						title: "Layer",
						icon: ""
					},
					cellRenderer: (item) => <div style={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}>
						<span>{item.type}</span>
						<Badge>{item.name}</Badge>
					</div>
				},
				{
					column: {
						title: "Info",
						icon: ""
					},
					cellRenderer: (item) => {
						switch (item.type) {
							case APPROVAL_DATA_TYPES.PAINTER:
								return <GtPainterInfo data={item} />
							case APPROVAL_DATA_TYPES.SNAPSHOT_WHILE_SUPERVISOR:
								return <GtSnapshotWhileSupervisorInfo data={item} />
							default:
								return null
						}


					}
				},
				{
					column: {
						title: "Color",
						icon: ""
					},
					cellRenderer: (item) => {
						const { tableItemsSelections, setItemColorInApproverTableAction } = this.props
						const onColorSelect = (fill, stroke) => {
							const itemId = item.id
							setItemColorInApproverTableAction(itemId, fill, stroke)
						}

						const isDisabled = tableItemsSelections[item.id].type === "Snapshot while supervisor" || tableItemsSelections[item.id].visible === -1

						return <GtShapeColor
							fillStrokeCombo={tableItemsSelections[item.id].colorInfo}
							onColorSelect={(fill, stroke) => onColorSelect(fill, stroke)}
							isDisabled={isDisabled} />
					}
				}
			]}
		/>
	}


	onApproverQuestionAnswer = (answer) => {
		const { setPhaseAnswerAction } = this.props
		setPhaseAnswerAction(answer)
	}


	onItemFocus = (boundsToFocus, itemId) => this.setState({ mapCenterToFocus: null }, () => {
		this.setState({ mapCenterToFocus: boundsToFocus, highlightedItemId: itemId })
	})


	render() {
		const { mapCenterToFocus, highlightedItemId } = this.state
		const { isAnyTextInputFocused, onTextInputFocus, onTextInputBlur, superviseTime, approvalSubtaskBoundingBox, tableItemsSelections, supervisorData, supervisorComment, setVisibilityToAll, supervisorName,
			approverAnswerForShapes, approverAnswerForTags, approverAnswerForGrading, goToNextStage } = this.props
		if (!tableItemsSelections || !approvalSubtaskBoundingBox) return null

		const mapItems = _.filter(tableItemsSelections, item => item.type === APPROVAL_DATA_TYPES.PAINTER || item.type === APPROVAL_DATA_TYPES.SUPERVISOR || item.type === APPROVAL_DATA_TYPES.SNAPSHOT_WHILE_SUPERVISOR)
		const mainTableVisibilityTypes = [APPROVAL_DATA_TYPES.PAINTER, APPROVAL_DATA_TYPES.SNAPSHOT_WHILE_SUPERVISOR]

		return (<div className="StageOne stage">

			<div className="leftContainer">
				<div className="tableWrapper">

					<div className="tableTitle">Layers</div>
					<div className="showHideContainer">
						<span onClick={() => { setVisibilityToAll(true, mainTableVisibilityTypes) }}>Show All</span>
						<span onClick={() => { setVisibilityToAll(false, mainTableVisibilityTypes) }}>Hide All</span>
					</div>
					<div style={{ overflow: "auto", display: "flex", flex: 1, flexDirection: "column" }}>
						{this.generatePaintersTable()}
					</div>


					<div className="tableTitle">Supervisor's Answer<Badge>{supervisorName}</Badge><Badge>{superviseTime}</Badge></div>
					<div style={{ overflow: "auto", display: "flex", flex: 1, flexDirection: "column" }}>
						<SupervisorAnswersTable
							highlightedItemId={highlightedItemId}
							onFocusClicked={this.onItemFocus}
							tableData={supervisorData} />

					</div>

				</div>



				<div className="bottomWrapper">
					<div className="questionsWrapper">
						<QuestionToApprove
							answer={approverAnswerForShapes}
							question={"Did the supervisor mark areas with a multilevel?"}
							onTrue={() => this.onApproverQuestionAnswer({ shapes: true })}
							onWrong={() => this.onApproverQuestionAnswer({ shapes: false })} />

						<QuestionToApprove
							answer={approverAnswerForGrading}
							question={"Did the supervisor marked the painters answers correctly?"}
							onTrue={() => this.onApproverQuestionAnswer({ grading: true })}
							onWrong={() => this.onApproverQuestionAnswer({ grading: false })} />

						<QuestionToApprove
							answer={approverAnswerForTags}
							question={"Did the supervisor marked the shapes types correctly?"}
							onTrue={() => this.onApproverQuestionAnswer({ tags: true })}
							onWrong={() => this.onApproverQuestionAnswer({ tags: false })} />

					</div>
					<div className="commentsWrapper">
						<ApproveCommentBox onTextInputFocus={onTextInputFocus} onTextInputBlur={onTextInputBlur} />
						<div className="supervisorsAnswerWrapper">
							<span>Supervisor's Comment</span>
							<textarea value={supervisorComment} disabled />
						</div>
					</div>
					<div className="actionBtns">
						<Button bsSize='sm' onClick={goToNextStage} bsStyle="success">Next </Button>
					</div>

				</div>

			</div>

			<div className="mapWrapper">
				<ApproveStageMapContainer
					isAnyTextInputFocused={isAnyTextInputFocused}
					clickedItemId={highlightedItemId}
					center={mapCenterToFocus}
					subtaskShape={approvalSubtaskBoundingBox}
					items={mapItems} />
			</div>

		</div >)
	}
}


const mapStateToProps = (state) => ({
	tableItemsSelections: getTableItemsSelections(state),
	paintersData: getPaintersDataFromApprovalStore(state),
	supervisorData: getSupervisorDataFromApprovalStore(state),
	snapshotWhileSupervisorData: getSnapshotWhileSupervisorDataFromApprovalStore(state),
	approvalSubtaskBoundingBox: getApprovalSubtaskBoundingBoxFromStore(state),
	supervisorComment: getSupervisorCommentFromState(state),
	supervisorName: getSupervisorNameFromState(state),
	superviseTime: getSuperviseTimeFromState(state),
	approverAnswerForShapes: getApproverAnswerForShapes(state),
	approverAnswerForTags: getApproverAnswerForTags(state),
	approverAnswerForGrading: getApproverAnswerForGrading(state),
})

const mapDispatchToProps = (dispatch) => ({
	setItemColorInApproverTableAction: bindActionCreators(setItemColorInApproverTable, dispatch),
	setItemVisibilityInApproverTableAction: bindActionCreators(setItemVisibilityInApproverTable, dispatch),
	phaseChangedAction: bindActionCreators(phaseChanged, dispatch),
	setPhaseAnswerAction: bindActionCreators(setPhaseAnswer, dispatch),
	setVisibilityToAll: bindActionCreators(setVisibilityToAll, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(StageOne)