
const { ACTION_TYPES } = require("../reduxConsts")

// initial state for tasks store
const dialog_initial = {
	level: "",
	txt: "",
	title: "",
	next: [],
	visible: false,
	onConfirm: null,
	onCancel: null
}


export function dialogStore(state = dialog_initial, action) {
	const { type, value } = action;

	switch (type) {

		// applied shapes controller
		case ACTION_TYPES.DIALOG.SHOW_DIALOG: {

			const { level, txt, next, confirm, cancel, title } = value;

			return {
				...state,
				visible: true,
				level,
				txt,
				onCancel: cancel,
				onConfirm: confirm,
				title,
				next: next
			}
		}
		// applied shapes controller
		case ACTION_TYPES.DIALOG.CLOSE_DIALOG: {
			return {
				level: "",
				txt: "",
				title: "",
				visible: false,
				// array of[function,...args, ...,]
				onConfirm: null,
				// array of[function,...args, ...,]
				onCancel: null,
				// array of[buttonText,function,...args, ...,]
				next: []

			}
		}



		default: {
			return state
		}
	}
}