import React from "react";
import NoContentComponent from "../hoc/NoContentComponent";
import { fetchSubTaskToApprove, setApproverSubtaskApiType } from "./../../redux/approve/approverActions"
import { getSubtaskNameFromUrlPath, removeSubtaskNameFromUrl } from "./../utils/urlSearchStringHandler"




const WrapWithSubtaskFetcher = (WrappedComponent) => {

	return class extends React.Component {



		renderNoSubtaskFound = (name) => <NoContentComponent
			refreshAction={this.fetchSubtaskForApprover}
			title={"The subtask '" + name + "' is not ready to approve or exists."}
			text={"Click refresh to fetch random one."}
		/>


		fetchSubtaskForApprover = () => {

			removeSubtaskNameFromUrl()
			this.props.dispatch(setApproverSubtaskApiType("any"))
			this.props.dispatch(fetchSubTaskToApprove())
		}


		componentDidMount = () => {
			const { subtaskName } = this.props;
			const subtaskNameFromUrl = getSubtaskNameFromUrlPath()
			if (subtaskName === null && subtaskNameFromUrl !== null)
				this.props.dispatch(fetchSubTaskToApprove(subtaskNameFromUrl))
			return Promise.resolve()
		}





		render() {

			const { subtaskName } = this.props;


			const subtaskNameFromUrl = getSubtaskNameFromUrlPath()
			if (subtaskName === null && subtaskNameFromUrl)
				return this.renderNoSubtaskFound(subtaskNameFromUrl);
			else {
				return <WrappedComponent {...this.props} />;
			}
		}
	}

}



export default WrapWithSubtaskFetcher