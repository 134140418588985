import React from "react"




export const objectToString = (obj) => {

	const lastRun = (keyvalueArr) => {


		if (!keyvalueArr || keyvalueArr[1] === undefined || keyvalueArr[1] === null || Array.isArray(keyvalueArr[1]))
			return null


		if (typeof keyvalueArr[1] === "string" || typeof keyvalueArr[1] === "number")
			return arrayToSpan(keyvalueArr)


		return arrayToSpan([keyvalueArr[0], iterate(keyvalueArr[1]).map(lastRun)])
	}



	const iterate = (_obj) => {
		return Object.entries(_obj)
	}

	const arrayToSpan = (arr) => {
		return <div key={arr[0]}> <strong>{arr[0]} </strong> : <small>{arr[1]} </small></div>
	}


	let data =
		<div>
			{iterate(obj)
				.map(lastRun)}
		</div>

	return data

}