/* eslint-disable */
// import initAppVariables from "./utils/init";
// initAppVariables();

import React from "react";

import ReactDOM from "react-dom";

import { unregister } from "../registerServiceWorker";
import { SentryUtils } from "../common/modules/";

SentryUtils.initSentry(process.env.REACT_APP_SENTRY_DSN)

require('./checkClientVersion')


import { Provider } from "react-redux";

import "./../index.css";


import AppRoutes from "../components/router/appRoutes";
import RootWrapper from "../components/Errors/ErrorBoundaries/RootWrapper";

import initStore from "../redux";


const store = initStore();

const App = () => <RootWrapper>
    <Provider store={store}>
        <AppRoutes store={store} />
    </Provider>
</RootWrapper>


ReactDOM.render(
    App(),
    document.getElementById("root"));



unregister();
