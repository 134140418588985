// import saga helpers
import { put, call, takeLatest, spawn, all } from "redux-saga/effects";
import downloadJS from "downloadjs";
import { SafeSaga } from "../../common/modules"
import { ShapeUtils } from "../../common/sharedGlobal"

// import action type
import { ACTION_TYPES, MAP_STATE } from "../reduxConsts";
import { has } from "lodash";

import {
	async_start,
	resetTmpShape,
	setMapState,
	updateShapeList,
	updateShapeItemById,
	updateShape,
	multipleDeleteSuccess,
	resetFocus,
	deleteAreaFromListById
} from "../shapes/shapeActions";

import { showDialog } from "../dialog/dialogActions";



const AreasUrls = {
	getAreas: "api/areas",
	deleteArea: "api/areas",
	addEditArea: "api/v2/areas",
	disableArea: "api/areas/activate",
	exportShapes: "api/areas/export",
	multipleDelete: "api/areas/delete/batch",
	inBoxV2: "api/v2/areas/inbox",
	inBox: "api/areas/inbox",
}

const responseHandlers = {
	deleteMultipleAreas: (response) => {
		const { message, data } = response;
		return ({
			deletedIdArray: data.parameterToDelete,
			message
		})
	}
}


function* deleteArea(network) {

	yield takeLatest(ACTION_TYPES.SHAPE.DELETE_AREA, SafeSaga(function* ({ value }) {

		const { id } = value;


		yield put(async_start())

		// make the api call
		const deleteAreaRes = yield call(network.sendDelete, `${AreasUrls.deleteArea}/${id}`);
		console.log({ deleteAreaRes });
		if (deleteAreaRes.data.parameterToDelete === id)
			yield put(deleteAreaFromListById(id))
		else
			yield put(showDialog({
				level: "error", txt: `The deleted id returned from the server is different or null from the asked area : asked:${id} from server:${deleteAreaRes.data.parameterToDelete} `, title: "delete error"
			}))
		// yield put(deleteAreaFromListById(deleteAreaRes.data.parameterToDelete))
	}))
}



function* getAreas(network) {
	yield takeLatest(ACTION_TYPES.SHAPE.GET_AREAS, SafeSaga(function* () {

		// dispatch async start
		yield put(async_start())

		// const areasArray = yield call(network.sendGet, AreasUrls.getAreas); // TODO:
		const areasArray = AREAS_DUMMY_DATA

		if (areasArray) {
			// yield put(async_success())
			// TODO: remove the mapping, for migration


			// TODO: convert with shape utils - OLD type shapes to new type
			const areas = areasArray.areas.map(a => ({ ...a, boundingBox: a.boundingBox || a.position }))
			yield put(updateShapeList(areas));
		}
	}
	)
	)

}


function* getAreasThatFitMapBounds(network) {
	yield takeLatest(ACTION_TYPES.SHAPE.LOAD_SHAPES_THAT_FIT_MAP_BOUNDS, SafeSaga(function* (action) {
		const boundingBox = action.value

		const minMaxBoundingBox = ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(boundingBox)

		// dispatch async start
		// yield put(async_start())

		const areas = yield call(network.sendPost, AreasUrls.inBoxV2, { shape: minMaxBoundingBox }); //TODO:
		// const areas = AREAS_DUMMY_DATA
		if (areas) {
			// yield put(async_success())
			// TODO: remove the mapping, for migration

			yield put(updateShapeList(areas.areas));
		}

	}))

}



// TODO: This needs refactor due to changes in area structure. Rectangles-=> diagonal rectangles.
// Area doesnt have a Bounding box anymore
function* addEditArea(network) {

	yield takeLatest(ACTION_TYPES.SHAPE.ADD_EDIT_AREA, SafeSaga(function* ({ value }) {
		const { area } = value;
		yield put(async_start())

		// make the api call
		const editedArea = yield call(network.sendPut, AreasUrls.addEditArea, area);

		// const updateList = yield call(areasApi.addEditArea, shape, access_token);

		if (editedArea) {
			const reshapedEditedShape = { ...editedArea }
			yield put(updateShapeItemById({ id: reshapedEditedShape._id, data: reshapedEditedShape }));
			// yield put(updateShapeList({ areas: areasArray }))
			yield put(setMapState(MAP_STATE.READ))
			yield put(resetTmpShape())
		}
		//	yield put(resetFocus())
	}
	)
	)

}



function* disableArea(network) {
	yield takeLatest(ACTION_TYPES.SHAPE.SET_AREA_DISABLE, SafeSaga(function* ({ value }) {
		const { id, bool } = value;

		// dispatch async start
		yield put(async_start())

		const response = yield call(network.sendPost, AreasUrls.disableArea, { shape_id: id, isActive: bool });
		if (has(response, "id")) {
			const { isActive, id } = response;
			yield put(updateShapeItemById({ id, data: { isActive } }));
		}
	}))
}



function* exportAreas(network) {
	yield takeLatest(ACTION_TYPES.SHAPE.EXPORT, SafeSaga(function* ({ value }) {


		// dispatch async start
		yield put(async_start())

		const exportedShapes = yield call(network.sendGet, AreasUrls.exportShapes);
		if (exportedShapes)

			yield downloadJS(JSON.stringify(exportedShapes), "exportedShapes.json", "application/json")

	}
	)
	)
}

function* multipleDelete(network) {
	yield takeLatest(ACTION_TYPES.SHAPE.MULTIPLE_DELETE, SafeSaga(function* ({ value }) {

		const idArray = value;
		// dispatch async start
		yield put(async_start())

		const multipleDeleteRes = yield call(network.sendPost, AreasUrls.multipleDelete, { "array": idArray });




		const { deletedIdArray, message } = responseHandlers.deleteMultipleAreas(multipleDeleteRes.response)


		// if (multipleDeleteRes) {
		// 	const response = {
		// 		status: multipleDeleteRes.response.ok > 0 ? "success" : "fail",
		// 		deleted: multipleDeleteRes.response.deletedCount,
		// 		numRequestForDelete: multipleDeleteRes.response.n
		// 	}

		yield put(multipleDeleteSuccess(deletedIdArray))
		yield put(showDialog({
			level: "info", txt: message, title: "delete",
			// level: "info", txt: `Operation status: ${response.status}, deleted: ${response.deleted}/${response.numRequestForDelete}`, title: "delete"
		}))
	}



	))
}

function* askInBoxAreas(network) {
	yield takeLatest(ACTION_TYPES.SHAPE.ASK_IN_BOX_AREAS, SafeSaga(function* ({ value }) {

		const { boundingBox } = value;
		// dispatch async start
		yield put(async_start())

		const inBoxRes = yield call(network.sendPost, AreasUrls.inBox, { "shape": boundingBox });
		if (inBoxRes) {
			yield put(updateShapeList({ areas: inBoxRes.areas.map(a => ({ ...a, boundingBox: a.boundingBox || a.position })) }));
		}



	}
	)
	)
}

export function* areasSaga(network) {
	yield all([
		spawn(askInBoxAreas, network),
		spawn(addEditArea, network),
		spawn(deleteArea, network),
		spawn(getAreas, network),
		spawn(getAreasThatFitMapBounds, network),
		spawn(disableArea, network),
		spawn(exportAreas, network),
		spawn(multipleDelete, network)
	])
}

const AREAS_DUMMY_DATA = [
	// {
	// 	"_id": "6069931f8f17a6590a9d3a1c",
	// 	"isActive": true,
	// 	"description": "Brussels-tunnel 1, Thu Apr 02 2020 10:06:12 GMT+0000 (UTC)",
	// 	"createdFrom": {
	// 		"type": "unknown"
	// 	},
	// 	"multilevelTypes": {
	// 		"isComplex": true,
	// 		"bottomLevel": null,
	// 		"topLevel": null
	// 	},
	// 	"userCreated": {
	// 		"userId": "unknown",
	// 		"userEmail": "unknown"
	// 	},
	// 	"shape": {
	// 		"type": "DiagonalRectangle",
	// 		"points": [
	// 			{
	// 				"lat": 50.853355326607065,
	// 				"lng": 4.365417384991188
	// 			},
	// 			{
	// 				"lat": 50.853416285307674,
	// 				"lng": 4.365417384991188
	// 			},
	// 			{
	// 				"lat": 50.853416285307674,
	// 				"lng": 4.365504556784172
	// 			}
	// 		],
	// 	},
	// },
	/* 2 createdAt:4/4/2021, 1:20:13 PM*/
	{
		"_id": "606992ddc5ca3a6cad55753d",
		"createdFrom": {
			"data": {
				"subtask_id": "60696855c5ca3a6cad55619b"
			},
			"type": "supervisorAnswer"
		},
		"isActive": true,
		"shape": {
			"type": "DiagonalRectangle",
			"points": [
				{
					"lat": 32.075847802685466,
					"lng": 34.867705517569206
				},
				{
					"lat": 32.07622053434851,
					"lng": 34.867705517569206
				},
				{
					"lat": 32.07622053434851,
					"lng": 34.86827414588036
				}
			],
		},
		"description": "Moshe David Meisner St 40, Petah Tikva, Israel_1617531613",
		"multilevelTypes": {
			"topLevel": "Car",
			"bottomLevel": "Pedestrian"
		},
		"__v": 0,
		"userCreated": {
			"userId": "auth0|6045001b85c4da0069f78750",
			"userEmail": "itayt@eyenet-mobile.com"
		}
	},
	/* 3 createdAt:4/4/2021, 1:20:13 PM*/
	{
		"_id": "606992ddc5ca3a6cad55753c",
		"createdFrom": {
			"data": {
				"subtask_id": "60696855c5ca3a6cad55619b"
			},
			"type": "supervisorAnswer"
		},
		"isActive": true,
		"shape": {
			"type": "DiagonalRectangle",
			"points": [
				{
					"lat": 32.076620537369585,
					"lng": 34.86655753211083
				},
				{
					"lat": 32.07691144755833,
					"lng": 34.86655753211083
				},
				{
					"lat": 32.07691144755833,
					"lng": 34.86717980460229
				}
			],

		},
		"description": "Ha-Vered St 6, Petah Tikva, Israel_1617531613",
		"multilevelTypes": {
			"isComplex": true
		},
		"__v": 0,
		"userCreated": {
			"userId": "auth0|6045001b85c4da0069f78750",
			"userEmail": "itayt@eyenet-mobile.com"
		}
	},
	// /* 4 createdAt:4/4/2021, 1:19:55 PM*/
	// {
	// 	"_id": "606992cbc5ca3a6cad55753a",
	// 	"createdFrom": {
	// 		"type": "unknown"
	// 	},
	// 	"isActive": true,
	// 	"description": "Unnamed Road, Israel",
	// 	"multilevelTypes": {
	// 		"topLevel": "Car",
	// 		"bottomLevel": "Boat",
	// 		"isComplex": false
	// 	},
	// 	"shape": {
	// 		"type": "DiagonalRectangle",
	// 		"points": [
	// 			{
	// 				"lat": 31.991658295857118,
	// 				"lng": 34.785167019832706
	// 			},
	// 			{
	// 				"lat": 31.99500681269436,
	// 				"lng": 34.785167019832706
	// 			},
	// 			{
	// 				"lat": 31.99500681269436,
	// 				"lng": 34.79005936907587
	// 			}
	// 		],

	// 	},
	// 	"__v": 0,
	// 	"userCreated": {
	// 		"userId": "auth0|6045001b85c4da0069f78750",
	// 		"userEmail": "itayt@eyenet-mobile.com"
	// 	}
	// },
	// /* 5 createdAt:4/4/2021, 1:19:50 PM*/
	// {
	// 	"_id": "606992c6c5ca3a6cad557539",
	// 	"createdFrom": {
	// 		"type": "unknown"
	// 	},
	// 	"isActive": true,
	// 	"description": "Simha Erlich St 34, Holon, Israel",
	// 	"multilevelTypes": {
	// 		"isComplex": true
	// 	},
	// 	"shape": {
	// 		"type": "DiagonalRectangle",
	// 		"points": [
	// 			{
	// 				"lat": 31.995370774549343,
	// 				"lng": 34.779416363704776
	// 			},
	// 			{
	// 				"lat": 31.998355207275225,
	// 				"lng": 34.779416363704776
	// 			},
	// 			{
	// 				"lat": 31.998355207275225,
	// 				"lng": 34.78387955950556
	// 			}
	// 		],
	// 	},
	// 	"__v": 0,
	// 	"userCreated": {
	// 		"userId": "auth0|6045001b85c4da0069f78750",
	// 		"userEmail": "itayt@eyenet-mobile.com"
	// 	}
	// }
]
