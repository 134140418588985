import initAppVariables from "./utils/init";
import initApp from "./common/modules/AppInitWithSwaggerAuth"
import { auth0module } from "./common/components/Authentication"
// import axios from "axios"

// load env vars
initAppVariables();

const runClientApp = () => {
	require("./appInit/initApp")
}
// "ui","preview","aaa3"
const shouldPreviewSpecific = (urlArray) => {
	const last = urlArray.pop()
	const beforeLast = urlArray.pop()
	const beforeBeforeLast = urlArray.pop()

	const screensArr = [
		"areas-manager",
		"task-manager",
		"paint",
		"supervise",
		"approver",
		"preview",
		"leaderboard",
		"report",
	]

	// if last value is one of the screens then redirect to the screen
	if (screensArr.indexOf(last) > -1)
		return "?redirect=" + last

	else if (beforeLast === "preview" && last !== "")
		return "?redirect=preview?task=" + last
	else if (beforeLast === "approver" && last !== "")
		return "?redirect=approver?task=" + last


	else return false


}



// it will check if the url contain "swaggerlogin" OR "swaggercallback" and start the auth process to api-docs or init the react app
initApp({
	runClientApp: runClientApp,
	catchSwaggerLogin: false,
	serviceUrlPath: "/areas-blacklist-manager",
	auth0module,
	uiPath: "/ui",
	urlPathToQueryString: (urlArray) => {
		// debugger
		const isPreviewSpecific = shouldPreviewSpecific(urlArray);
		const lastElem = urlArray.pop();
		if (isPreviewSpecific)
			return isPreviewSpecific
		else
			return ""

	}
})

