import React from "react";
import { connect } from "react-redux";
import { Header } from "./../../common/components/Header";
import { Versioning } from "./../../common/components/Versioning";
import { auth0module } from "./../../common/components/Authentication";

function HeaderContainer({ user }) {

	const signout = () => {
		const logoutRedirect = window.location.origin + process.env.REACT_APP_AUTH0_LOGOUT_REDIRECT_URI;
		auth0module.signOut(logoutRedirect)
	}


	const userProfile = user ? {
		name: user.profile.name,
		nickname: user.profile.nickname,
		roles: user.roles,
		picture: user.profile.picture,
		token: user.accessToken,
		sessionExpirationTimestamp: user.expiresAt
	} : null

	return (
		<Header
			logoSrc="https://eyenet-mobile.com/wp-content/uploads/2019/07/logo.png"
			userProfile={userProfile}
			signout={signout}>
			<Versioning versionApiPath="/areas-blacklist-manager/api/health" />
		</Header>
	)

}

const mapStateToProps = function ({ authStore }) {
	return {
		...authStore
	}
}

export default connect(mapStateToProps)(HeaderContainer);