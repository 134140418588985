import React from "react"
import { connect } from "react-redux";
import Switch from "react-switch"
import { bindActionCreators } from "redux"
import * as LocalStorageService from "../../../../common/modules/localStorageService"
import { setIsAutoLoadShapesActive } from "../../../../redux/areas/areasActions"
import "./areasSettingsModal.css"


class AreasSettingsModal extends React.Component {
	onIsMapAutoLoadChange = (isActive) => {
		const { setIsAutoLoadShapesActive } = this.props
		LocalStorageService.set("isAreasMapAutoLoad", isActive)
		setIsAutoLoadShapesActive(isActive)
	}


	render() {
		const { isMapAutoLoadActive } = this.props
		return <div className="AreasSettingsModal">
			<div className="section">
				<span className="sectionLabel">Map auto loads areas</span>
				<Switch height={15} width={35} onChange={this.onIsMapAutoLoadChange} checked={isMapAutoLoadActive} />
			</div>
		</div>
	}
}

const mapStateToProps = ({ areasStore }) => {
	return {
		isMapAutoLoadActive: areasStore.isMapAutoLoadActive
	}
}

const mapDispatchToProps = (dispatch) => ({
	setIsAutoLoadShapesActive: bindActionCreators(setIsAutoLoadShapesActive, dispatch),
})

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(AreasSettingsModal);