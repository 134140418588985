import { handleActions } from "redux-actions"
import { ACTION_TYPES } from "../reduxConsts"
import _ from "lodash"


const initialState = null

const setFilter = (state, action) => {
	const filterData = action.value
	return { ...filterData }
}


const resetFilter = (state, action) => {
	return initialState
}

export const approverFilterReducer = handleActions({
	[ACTION_TYPES.APPROVER.SET_APPROVER_FILTER]: setFilter,
	[ACTION_TYPES.APPROVER.RESET_APPROVER_FILTER]: resetFilter,

}, initialState)