
import React, { Component } from "react";
import ControlledInput from "./../../hoc/controlledInput"
import { isEmpty, has } from "lodash"
import { Button } from "react-bootstrap"
import { connect } from "react-redux"
import MultilevelPicker from "./MultilevelPicker/AddMultilevelType";
import "./AddEditComponent.css"
import { updateCurrentAddEditShape } from "../../../redux/shapes/shapeActions";
import { bindActionCreators } from "redux";

class AddEditComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			isEdit: false,
			inputStyle: { margin: "1px" },
			selectedLocationBoundingBoxCenter: null,
			topLevel: null,
			bottomLevel: null,
			isComplex: false,
			addEditShape: null
		}
	}


	componentDidMount() {
		const { tempShapeInEdit } = this.props
		this.setState({ addEditShape: tempShapeInEdit })
	}


	onDescriptionChange = (val, field) => {
		const description = val.target.value
		this.onUpdateCurrentAddEditShape({ description })
	}


	setDescriptionFromGoogleSuggestion = (description) => {
		this.onUpdateCurrentAddEditShape({ description })
	}


	onDone = () => {
		const { onDoneAddEdit } = this.props
		onDoneAddEdit()
	}

	onCancel = () => {
		const { onCancel } = this.props
		const { addEditShape } = this.state
		onCancel(addEditShape)
	}


	onUpdateCurrentAddEditShape = (data) => this.props.updateCurrentAddEditShape(data)

	onMultilevelTopChange = (topLevel) => this.onUpdateCurrentAddEditShape({ multilevelTypes: { ...this.props.tempShapeInEdit.multilevelTypes, topLevel } })
	onMultilevelBottomChange = (bottomLevel) => this.onUpdateCurrentAddEditShape({ multilevelTypes: { ...this.props.tempShapeInEdit.multilevelTypes, bottomLevel } })
	onMultilevelComplexChange = (isComplex) => this.onUpdateCurrentAddEditShape({ multilevelTypes: { ...this.props.tempShapeInEdit.multilevelTypes, isComplex } })


	render() {
		const { tempShapeInEdit } = this.props
		const { topLevel, bottomLevel, isComplex } = tempShapeInEdit.multilevelTypes


		const style = {
			padding: "17px",
			backgroundColor: "#9396953b",
			borderRadius: "18px",
			marginTop: "10px"
		}

		const canSave = tempShapeInEdit.description && (isComplex || (topLevel && bottomLevel)) && tempShapeInEdit.shape.points.length === 3

		return (
			<div className='addEdit_div' style={style}>

				<ControlledInput
					size="l"
					controlledField="description"
					required={true}
					label={"Description"}
					type={"text"}
					// info={"Describe the area"}
					placeholder={"Describe the area"}
					onChangeValue={this.onDescriptionChange}
					value={!isEmpty(tempShapeInEdit) && has(tempShapeInEdit, "description") ? tempShapeInEdit.description : ""}
				/>

				<div className="separator"></div>
				<div className="MultilevelPickerWrapper">
					<div><span className="required"> *</span></div>
					<MultilevelPicker
						top={topLevel || "Types"}
						bottom={bottomLevel || "Types"}
						isComplex={isComplex}
						topHandler={this.onMultilevelTopChange}
						bottomHandler={this.onMultilevelBottomChange}
						complexHandler={this.onMultilevelComplexChange}
					/>

				</div>

				<div className="separator"></div>

				<div className="actionBtns" >
					<Button bsStyle="success" disabled={!canSave} onClick={this.onDone} >Done</Button>
					<Button bsStyle="danger" onClick={this.onCancel} >Cancel</Button>
				</div>


			</div>
		)
	}

}




const mapStateToProps = ({ shapesStore }) => ({
	tempShapeInEdit: shapesStore.addEditShape,
})

const mapDispatchToProps = (dispatch) => ({
	updateCurrentAddEditShape: bindActionCreators(updateCurrentAddEditShape, dispatch),
})


export default connect(mapStateToProps, mapDispatchToProps)(AddEditComponent);

