

const { SUPERVISOR } = require("../reduxConsts").ACTION_TYPES



export const resetStore = () => ({ type: SUPERVISOR.RESET_STORE })
export const addMultilevelTypesIsComplexToAppliedShapeById = (id, type) => ({ type: SUPERVISOR.SET_IS_COMPLEX_TYPE, value: { id, type } })
export const addMultilevelTypesTopToAppliedShapeById = (id, type) => ({ type: SUPERVISOR.SET_TOP_LEVEL_TYPE, value: { id, type } })
export const addMultilevelTypesBottomToAppliedShapeById = (id, type) => ({ type: SUPERVISOR.SET_BOTTOM_LEVEL_TYPE, value: { id, type } })

export const setSubtask = (subtask) => ({ type: SUPERVISOR.SET_SUBTASK, value: subtask })
export const setIsSensBack = (isSendBack) => ({ type: SUPERVISOR.SET_SEND_BACK_BOOL, value: { isSendBack } })

export const cleanSubtask = () => ({ type: SUPERVISOR.CLEAN_SUBTASK });

export const addShapeInRadius = (shape) => ({ type: SUPERVISOR.ADD_SHAPES_IN_RADIUS, value: shape })

export const addCorrectResult = (id) => ({ type: SUPERVISOR.ADD_CORRECT_RESULT, value: id })

export const removeCorrectResult = (id) => ({ type: SUPERVISOR.REMOVE_CORRECT_RESULT, value: id })

export const onFocus = () => ({ type: SUPERVISOR.FOCUS });

export const addSupervisorComment = (comment) => ({ type: SUPERVISOR.SET_SUPERVISOR_COMMENT, value: comment })
export const onShapeDraw = (shape) => ({ type: SUPERVISOR.ON_SHAPE_DRAW, value: { shape } })
export const addDescriptionToAppliedShape = ({ id, txt }) => ({ type: SUPERVISOR.ADD_APPLIED_SHAPE_DESCRIPTION, value: { id, txt } })
export const onDeleteShape = (id) => ({ type: SUPERVISOR.ON_DELETE_SHAPE, value: id })
// export const addAShapesPrefix = (value) => ({ type: SUPERVISOR.SET_SHAPE_PREFIX, value })

export const fetchingSuggestionsStart = ({ shapeId }) => ({ type: SUPERVISOR.FETCHING_SUGGESTIONS, value: { shapeId } })
export const retryFetchSuggestions = ({ id }) => ({ type: SUPERVISOR.RETRY_APPLIED_SHAPE_SUGGESTIONS, value: { id } })
export const getAppliedShapesSuggestions = ({ boundingBox }) => ({ type: SUPERVISOR.GET_APPLIED_SHAPE_SUGGESTIONS, value: { boundingBox } })
export const setEditShapeID = ({ id }) => ({ type: SUPERVISOR.SET_EDIT_SHAPE_ID, value: id })
export const addAppliedShape = (shape) => ({ type: SUPERVISOR.ADD_APPLIED_SHAPE, value: shape })
export const replaceAppliedAreasArray = (array) => ({ type: SUPERVISOR.REPLACE_APPLIED_SHAPES_ARRAY, value: array })
export const onDoneEdit = () => ({ type: SUPERVISOR.ON_DONE_EDIT })
export const addSuggestedAreas = (suggestionsArray, shapeId, status) => ({ type: SUPERVISOR.ADD_SUGGESTED_AREAS, value: { suggestionsArray, shapeId, reqStatus: status } })

export const setNumReadyToSupervise = (num) => ({ type: SUPERVISOR.SET_NUM_READY_TO_SUPERVISE, value: num })

export const removeAllAppliedShapes = () => ({ type: SUPERVISOR.REMOVE_APPLIED_SHAPES })
export const setAreasInRadiusDisplay = (bool) => ({ type: SUPERVISOR.SET_AREAS_IN_RADIUS_DISPLAY, value: bool })
export const setApproverSendbackShapesDisplay = (bool) => ({ type: SUPERVISOR.SET_APPROVER_SENDBACK_DISPLAY, value: bool })

export const addDiagonalRectangleToCurrentlyAddedShape = (point) => ({ type: SUPERVISOR.ADD_DIAGONAL_RECTANGLE_POINT_TO_CURRENTLY_ADDED_SHAPE, value: point })
export const removeDiagonalRectangleFromCurrentlyAddedShape = (pointOrderToDelete) => ({ type: SUPERVISOR.REMOVE_DIAGONAL_RECTANGLE_POINT_FROM_CURRENTLY_ADDED_SHAPE, value: pointOrderToDelete })
export const updatePointOfDiagonalRectangleCurrentlyAddedShape = (pointOrder, newPoint) => ({ type: SUPERVISOR.EDIT_DIAGONAL_RECTANGLE_POINT_OF_CURRENTLY_ADDED_SHAPE, value: { pointOrder, newPoint } })
export const setIsRequestApprove = (bool) => ({ type: SUPERVISOR.SET_IS_REQUEST_APPROVE, value: bool })

// handle map focus
export const focusMap = (boundingBox) => ({ type: SUPERVISOR.MAP_FOCUS, value: boundingBox })
export const clearFocus = () => ({ type: SUPERVISOR.CLEAR_FOCUS })


/**@param {String} req async start function, req will be the url (?) */
export const async_start = req => ({ type: SUPERVISOR.ASYNC_START, value: req })

/**@param {Object} data async success function, data the object received from the http req */
export const async_success = () => ({ type: SUPERVISOR.ASYNC_SUCCESS })

/**@param {String} error async error function */
export const async_error = (error) => ({ type: SUPERVISOR.ASYNC_ERROR, value: error })


export const cleanError = () => ({ type: SUPERVISOR.CLEAN_ERROR })

/* * * * * * * * * * ** * * * * * ** * ** *  * user results actions handlers * * * * * * * * * * ** * * * * * ** * ** *  */

export const addGoogleSuggestionToAppliedShape = ({ id, text }) => ({ type: SUPERVISOR.ADD_SUGGESTION_TO_SPECIFIC_APPLIED_SHAPE, value: { id, text } })
export const onShapeClick = (id) => ({ type: SUPERVISOR.SHAPE_CLICKED, value: id })
export const editDiagonalRectanglePoint = (pointOrder, newPoint) => ({ type: SUPERVISOR.EDIT_DIAGONAL_RECTANGLE_POINT, value: { pointOrder, newPoint } })
export const removePointOfDiagonalRectangleInEdit = (pointOrderToDelete) => ({ type: SUPERVISOR.DELETE_DIAGONAL_RECTANGLE_POINT_IN_EDIT, value: { pointOrderToDelete } })
export const setUserResultColor = ({ id, styles }) => ({ type: SUPERVISOR.SET_USER_RESULTS_COLOR, value: { id, styles } })
export const setApproverSuggestionsColor = ({ styles }) => ({ type: SUPERVISOR.SET_APPROVER_SUGGESTION_COLOR, value: { styles } })
export const setUserResultDisplay = ({ id, isVisible }) => ({ type: SUPERVISOR.SET_USER_RESULTS_DISPLAY, value: { id, isVisible } })
export const fetchNearby = (BB) => ({ type: SUPERVISOR.FETCH_NEARBY, value: BB })

/* * * * * * * * * * ** * * * * * ** * ** *  * SAGA ACTIONS * * * * * * * * * * ** * * * * * ** * ** *  */

export const postSupervisedSubtask = (superviseObjects, subtaskName) => ({ type: SUPERVISOR.POST_SUPERVISED, value: { superviseObjects, subtaskName } })
export const postSupervisedSubtaskV2 = () => ({ type: SUPERVISOR.POST_SUPERVISEDV2 })

export const fetchReadyForSupervised = () => ({ type: SUPERVISOR.GET_READY_FOR_SUPERVISE })

export const fetchShapeWithBoundingBox = (boundingBox) => ({ type: SUPERVISOR.GET_SHAPES_WITH_RADIUS, value: { boundingBox } })

/* * * * * * * * * * ** * * * * * ** * ** *  * SAGA ACTIONS * * * * * * * * * * ** * * * * * ** * ** *  */

