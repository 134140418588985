
import React, { Component } from "react";

import propTypes from "prop-types";

export class ControlledTextBox extends Component {


	addRequiredSign = (isRequired) => isRequired ? <span style={{ color: "red" }} >  * </span> : null

	render() {

		let {
			// get the  disabled state of the input
			disabled = false,
			// get the place holder for the input
			placeholder = "",
			// get the event handler
			onChangeValue,
			// get the value if any
			value,
			// get the field controlled in the Object (to throw the handler)
			controlledField,
			// the type of the input 
			type = "text",
			// is that field is mandatory 
			required,
			// if checkbox is the type then if the defaultChecked is true the checkbox will render as checked
			checked = false,
			// get the size the user sent
			// size = "m",
			// the width for the input
			width = null,

			height = null

		} = this.props;


		return (
			<div>

				<input
					style={{ width, height }}
					type={type}
					onChange={(element) => { onChangeValue(element, controlledField) }}
					placeholder={placeholder}
					value={value}
					disabled={disabled}
					min={type === "number" ? 1 : undefined}
					checked={checked ? checked : false}
				/>

				{/* add required sign if needed */}
				{this.addRequiredSign.bind(this)(required)}
			</div>
		);
	}

}

ControlledTextBox.propTypes = {
	// get the place holder for the input
	placeholder: propTypes.string,
	// get the event handler
	onChangeValue: propTypes.func.isRequired,
	// get the field controlled in the Object (to throw the handler)
	controlledField: propTypes.string.isRequired,
	// the type of the input 
	type: propTypes.string.isRequired,
	// is that field is mandatory 
	required: propTypes.bool.isRequired,
	// size for the input
	size: propTypes.oneOf(["s", "m", "l"]),
	//  for checkbox type
	checked: propTypes.bool,

	value: propTypes.string
}

export default ControlledTextBox


