// import thunk from "redux-thunk";

import createSagaMiddleware from "redux-saga";
import axios from "axios";

// import { createLogger } from "redux-logger";

import { createStore, applyMiddleware } from "redux";

// import { reduxLoggerOpt } from "./../utils/utils";
import { rootSaga } from "./saga/rootSaga";
import rootReducer from "./reducer";
import { Network, SentryUtils } from "./../common/modules";

// import { SystemNotificationsRedux } from "./../common/components/SystemNotifications";

import { showDialog } from "./../redux/dialog/dialogActions";
import { hideLoader, showLoader } from "./Loader/loaderActions";

import ErrorHandler from "./ErrorHandler"
import { composeWithDevTools } from "redux-devtools-extension";


// setup redux logger options
// const logger = createLogger(reduxLoggerOpt());

const async_success = () => ({ type: "ASYNC_SUCCESS" });
const async_error = (error) => ({ type: "ASYNC_ERROR", value: error })

// return after init with saga
const initStore = () => initNewReduxSaga();

const initNewReduxSaga = () => {
	try {
		const composeEnhancers = composeWithDevTools({
			// DOCS: https://github.com/zalmoxisus/redux-devtools-extension
			// options like actionSanitizer, stateSanitizer
		});

		// create network instance
		const networkService = Network.initNetworkService(axios, 10000);

		// set global URL to the network service
		networkService.setGlobalUrl("/areas-blacklist-manager");

		// constructor for saga middleware
		const sagaMiddleware = createSagaMiddleware();

		//  add immutable error middleware only in dev
		const middleware = process.env.NODE_ENV !== "production" ?
			[require("redux-immutable-state-invariant").default(), sagaMiddleware, SentryUtils.reduxMiddlewareActionsBreadcrumbs] :
			[sagaMiddleware, SentryUtils.reduxMiddlewareActionsBreadcrumbs];


		// create the store with the middleware
		const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

		// run the saga
		sagaMiddleware.run(rootSaga, networkService);


		const { dispatch } = store

		// interceptor to ALL http requests
		networkService.setRequestStartInterceptor(() => {
			dispatch(showLoader())
		})

		// interceptor to ALL http error
		networkService.setErrorInterceptor((error) => {

			dispatch(showDialog(ErrorHandler.getErrorDialogFromError(error.response.data || "General Error")))
			dispatch(async_error())
			dispatch(hideLoader())

		})

		// interceptor to ALL success http  
		networkService.setSuccessInterceptor(() => {
			dispatch(hideLoader())
			dispatch(async_success())
		})

		return store
	} catch (e) {
		console.log({ e });

	}
}

export default initStore