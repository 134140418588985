// import saga helpers
import { put, call, takeLatest, all, spawn, select } from "redux-saga/effects";

// import action type
import { ACTION_TYPES } from "../reduxConsts";
import { getEditID, getAppliedShapes, getSubtask, getSupervisorAnswerObject, getIsRequestApproval } from "../supervise/selectors";
import { ShapeUtils } from "./../../common/sharedGlobal"

import { SafeSaga } from "../../common/modules"


import {
	cleanSubtask,
	async_start,
	async_success,
	setSubtask,
	fetchReadyForSupervised,
	addShapeInRadius,
	setNumReadyToSupervise,
	fetchShapeWithBoundingBox,
	addSuggestedAreas,
	replaceAppliedAreasArray,
	fetchingSuggestionsStart,
	setIsSensBack
	// fetchNearby
} from "../supervise/superviseAction";

import * as Sentry from "@sentry/browser";


import { getUnixTime, getNearbyGeocodeWithStatus, googleResultsOptions } from "./utils"
import isEmpty from "lodash.isempty"

const SuperviseUrls = {
	getReadyForSupervisor: "api/subtasks/supervisor/approve",
	postSupervisedSubtask: "api/subtasks/supervisor/approve",
	getNumReadyToSupervised: "api/subtasks/supervisor/approve/remain",
	inbox: "api/areas/inBox",
	inBoxV2: "api/v2/areas/inbox",
	googleNearby: "https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyBInxBvzXdvEB4PRz4aMWd3arZLWENzW0E&language=en"

}


function* fetchSubtaskForSupervise(network) {
	yield takeLatest(ACTION_TYPES.SUPERVISOR.GET_READY_FOR_SUPERVISE, SafeSaga(function* () {

		yield put(cleanSubtask())


		// dispatch async start action
		yield put(async_start());

		// make the api call
		const response = yield call(network.sendGet, SuperviseUrls.getReadyForSupervisor);


		// get the number of ready to approve from the db/ TODO handle the error
		const { ready_to_approve: ready_to_supervise } = yield call(network.sendGet, SuperviseUrls.getNumReadyToSupervised);

		yield put(setNumReadyToSupervise(ready_to_supervise))
		// check if we have data
		if (!isEmpty(response.data)) {
			const { painting, approverSendBack, _id } = response.data;
			const isSendBack = Object.keys(response.data).indexOf("approverSendBack") > -1 && response.data.approverSendBack !== null


			yield put(setIsSensBack(isSendBack))

			const subtask = {
				results: painting.results.map((result = []) => {
					const areasWithMinMaxBB = _.map(result.areas, area => ({
						...area,
						boundingBox: ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(area.boundingBox)
					}))
					return {
						...result,
						areas: areasWithMinMaxBB
					}
				}),
				name: painting.name,
				centerGeoHash: painting.centerGeoHash,
				boundingBox: ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(painting.shape.boundingBox),
				_id,
				approverSendBack: isSendBack ? approverSendBack : null
			}


			yield put(setSubtask({ ...subtask }))


			// if we have data we need to fetch the already existing shapes in the db
			let { maxLat, maxLon, minLat, minLon } = response.data.painting.shape.boundingBox;

			yield put(fetchShapeWithBoundingBox({ sw: { lat: minLat, lng: minLon }, ne: { lat: maxLat, lng: maxLon } }));
		}




		yield put(async_success());
	}))

}

// function* fetchSubtaskForSupervise(network) {
// 	yield takeLatest(ACTION_TYPES.SUPERVISOR.GET_READY_FOR_SUPERVISE, function* () {

// 		yield put(cleanSubtask())


// 		// dispatch async start action
// 		yield put(async_start());

// 		// make the api call
// 		const response = yield call(network.sendGet, SuperviseUrls.getReadyForSupervisor);

// 		// get the number of ready to approve from the db/ TODO handle the error
// 		const { ready_to_approve: ready_to_supervise } = yield call(network.sendGet, SuperviseUrls.getNumReadyToSupervised);

// 		yield put(setNumReadyToSupervise(ready_to_supervise))
// 		// check if we have data
// 		if (response.length > 0) {
// 			yield put(setSubtask({
// 				results: response[0].painting.results,
// 				name: response[0].painting.name,
// 				centerGeoHash: response[0].painting.centerGeoHash,
// 				boundingBox: response[0].painting.boundingBox,
// 				_id: response[0]["_id"]
// 			}))

// 			// if we have data we need to fetch the already existing shapes in the db
// 			let { maxLat, maxLng, minLat, minLng } = getMinMaxPoints(response[0].painting);

// 			yield put(fetchShapeWithBoundingBox({ sw: { lat: minLat, lng: minLng }, ne: { lat: maxLat, lng: maxLng } }));
// 		}




// 		yield put(async_success());
// 	})

// }

function* postSupervisedSubtask(network) {
	yield takeLatest(ACTION_TYPES.SUPERVISOR.POST_SUPERVISED, SafeSaga(function* ({ value }) {

		// get the access token from the action value
		const { superviseObjects, subtaskName } = value;

		// will delete the id field added in the client to control the shape in the map ui
		const reshapedSupervisedObject = {
			...superviseObjects,
			//  delete unnecessary keys
			appliedShapes: superviseObjects.appliedShapes.map(
				({ boundingBox, description, autoSuggestions, multilevelTypes }) => {

					// if google suggestion exist then take the first result
					if (autoSuggestions.results.length > 0) {
						return ({
							boundingBox,
							description: autoSuggestions.results[0].formatted_address + "_" + getUnixTime(),
							// description: subtaskName + "_" + autoSuggestions.results[0].formatted_address + "_" + getUnixTime(),
							multilevelTypes: multilevelTypes.isComplex ? { isComplex: multilevelTypes.isComplex } : { topLevel: multilevelTypes.topLevel, bottomLevel: multilevelTypes.bottomLevel }
						})
					}

					else
						return ({
							boundingBox,
							description: description + "_" + getUnixTime()
						})
				}
			)
			// shapesPrefix: subtaskName + "_" + superviseObjects.shapesPrefix
		}




		// dispatch async start action
		yield put(async_start());

		// make the api call
		yield call(network.sendPost, SuperviseUrls.postSupervisedSubtask, { ...reshapedSupervisedObject });
		// if got error then throw


		yield put(async_success())

		return yield put(fetchReadyForSupervised())
	}))
}


function* postSupervisedSubtaskV2(network) {
	yield takeLatest(ACTION_TYPES.SUPERVISOR.POST_SUPERVISEDV2, SafeSaga(function* () {

		// get the access token from the action value
		const superviseObjects = yield select(({ supervisorStore }) => getSupervisorAnswerObject(supervisorStore))
		const { name: subtaskName } = yield select(({ supervisorStore }) => getSubtask(supervisorStore))
		const isRequestApproval = yield select(({ supervisorStore }) => getIsRequestApproval(supervisorStore))



		// will delete the id field added in the client to control the shape in the map ui
		const reshapedSupervisedObject = {
			...superviseObjects,
			subtaskId: superviseObjects.subtask_id,
			isRequestApproval,
			//  delete unnecessary keys
			appliedShapes: superviseObjects.appliedShapes.map(
				({ shape, description, autoSuggestions, multilevelTypes }) => {
					// Convert BB of appliedshapes rectangles from NE SW to Min Max
					const tempShape = { ...shape }
					if (tempShape.type === "Rectangle") {
						tempShape.boundingBox = ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(tempShape.boundingBox)
					}


					// if google suggestion exist then take the first result
					if (autoSuggestions.results.length > 0) {
						return ({
							shape: tempShape,
							description: `${autoSuggestions.results[0].formatted_address}_${getUnixTime()}`,
							multilevelTypes: multilevelTypes.isComplex ? { isComplex: multilevelTypes.isComplex } : { topLevel: multilevelTypes.topLevel, bottomLevel: multilevelTypes.bottomLevel }
						})
					}

					else
						return ({
							shape,
							description: description + "_" + getUnixTime()
						})
				}
			)
			// shapesPrefix: subtaskName + "_" + superviseObjects.shapesPrefix
		}




		// dispatch async start action
		yield put(async_start());

		// make the api call
		yield call(network.sendPost, SuperviseUrls.postSupervisedSubtask, { ...reshapedSupervisedObject });
		// if got error then throw


		yield put(async_success())

		return yield put(fetchReadyForSupervised())
	}))
}

function* fetchAreasWithRadius(network) {
	yield takeLatest(ACTION_TYPES.SUPERVISOR.GET_SHAPES_WITH_RADIUS, SafeSaga(function* ({ value }) {

		const { boundingBox } = value

		const minMaxBoundingBox = ShapeUtils.RECTANGLE.convertNeSwBoundingBoxToMinMaxType(boundingBox)

		// dispatch async start
		yield put(async_start())

		const response = yield call(network.sendPost, SuperviseUrls.inBoxV2, { shape: minMaxBoundingBox });

		if (response) {

			// dispatch update shape list
			yield put(addShapeInRadius(response.areas))
		}


		// dispatch async success
		yield put(async_success())

	}))
}

function* replaceAppliedShapesArray() {

	yield takeLatest(ACTION_TYPES.SUPERVISOR.ON_SHAPE_DRAW, SafeSaga(function* ({ value }) {

		const { shape } = value;


		const editShapeID = yield select(({ supervisorStore }) => getEditID(supervisorStore))
		const appliedShapes = yield select(({ supervisorStore }) => getAppliedShapes(supervisorStore))


		let newAppliedShapes;



		//EDIT
		if (editShapeID !== null) {
			newAppliedShapes = appliedShapes.map(area =>
				area.id === editShapeID ?
					({ ...area, shape }) :
					area
			)
		}
		// ADD
		else {
			newAppliedShapes = [...appliedShapes, { shape, description: "" }].map((a, i) => ({ ...a, id: (i + 1) }));

		}

		yield put(replaceAppliedAreasArray(newAppliedShapes))
		yield put(fetchingSuggestionsStart({ shapeId: editShapeID ? editShapeID : newAppliedShapes.length }))


		let location;
		switch (shape.type) {
			case "Rectangle": {
				location = ShapeUtils.RECTANGLE.getCenterOfAnyBoundingBox(shape.boundingBox)
				break
			}
			case "DiagonalRectangle": {
				const boundingBoxOfWrappingRectangle = ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(ShapeUtils.POLYGON.convertPointsToBoundingBox(shape.points))
				location = ShapeUtils.RECTANGLE.getCenterOfAnyBoundingBox(boundingBoxOfWrappingRectangle)
				break
			}
		}


		const { response: results, status } = yield getNearbyGeocodeWithStatus(location);

		if (status !== googleResultsOptions.OK || status !== googleResultsOptions.ZERO_RESULTS)
			yield call(Sentry.captureException, { error: "error while fetching google suggestions", data: { results, status } })

		yield put(addSuggestedAreas(results, editShapeID ? editShapeID : newAppliedShapes.length, status))




	}))
}

function* retryGoogleSuggestion() {

	yield takeLatest(ACTION_TYPES.SUPERVISOR.RETRY_APPLIED_SHAPE_SUGGESTIONS, SafeSaga(function* ({ value }) {

		//  get the retry id
		const { id } = value

		// get all applied shapes 
		const appliedShapes = yield select(({ supervisorStore }) => getAppliedShapes(supervisorStore))

		// get the applied shape object from the array 
		const currentAppliedShape = appliedShapes.filter(appliedShape => appliedShape.id === id)[0]

		const { boundingBox } = currentAppliedShape;

		const location = ShapeUtils.getCenter(boundingBox)

		yield put(fetchingSuggestionsStart({ shapeId: id }))

		const { response: results, status } = yield getNearbyGeocodeWithStatus(location);

		if (status !== googleResultsOptions.OK || status !== googleResultsOptions.ZERO_RESULTS)
			yield call(Sentry.captureException, { error: "error while fetching google suggestions", data: { results, status } })
		// runSentryIfError("error while fetching google suggestions", { results, status })


		yield put(addSuggestedAreas(results, id, status))


		yield put(async_success())

	}))
}

// function* fetchAreasNearby(network) {

// 	yield takeEvery(ACTION_TYPES.SUPERVISOR.GET_APPLIED_SHAPE_SUGGESTIONS, function* ({ value }) {
// 		const location = coordinatesParse.getCenterFromCorners(value.boundingBox);


// 		yield put(async_start())

// 		const { response: results } = yield getNearbyGeocodeWithStatus(location)


// 		yield put(addSuggestedAreas(results, value.id))

// 		console.log("done: ", value.id);

// 		yield put(async_success())

// 	})
// }




export function* superviseSaga(network) {
	yield all([
		spawn(fetchAreasWithRadius, network),
		spawn(postSupervisedSubtask, network),
		spawn(postSupervisedSubtaskV2, network),
		spawn(fetchSubtaskForSupervise, network),
		// spawn(fetchAreasNearby, network),
		spawn(replaceAppliedShapesArray, network),
		spawn(retryGoogleSuggestion, network)
	])
}



