import { connect } from "react-redux";
import React from "react";
import { Loader } from "../../common/components";

const mapStateToProps = ({ loaderStore }) => {
	return {
		showLoader: loaderStore.showLoader
	};
};


class LoaderContainer extends React.Component {
	render() {
		const { showLoader } = this.props;
		return showLoader ? <Loader /> : null
	}
}

export default connect(mapStateToProps)(LoaderContainer);


