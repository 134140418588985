import React, { Component } from "react";
import { answer } from "../paintConsts"
import ControlledInput from "../../hoc/controlledInput"
import { Well, Glyphicon } from "react-bootstrap"



class Action extends Component {

	constructor(props) {
		super(props)

		this.state = {
			wellStyle: {
				border: props.checked && "5px #61fb6175 outset",
				cursor: "pointer",
				backgroundColor: "#c7c7c778",

			}
		}

	}

	getGlyphColor = (answerObject) => {

		const { checked, controlledField } = answerObject;

		if (["no_areas", "has_areas", "problem"].indexOf(controlledField) === -1)
			return "inherit"

		const colorsMap = {
			no_areas: "red",
			has_areas: "green",
			problem: "blue"

		}
		const brightMap = {
			green: "#0080004d",
			red: "#ff000030",
			blue: "#0000ff47"
		}

		let statusColor = colorsMap[controlledField];


		return checked ? statusColor : brightMap[statusColor]


	}






	render() {
		const { controlledField, fontSize, border, glyph, info, label, onChangeValue, checked } = this.props
		const { wellStyle } = this.state
		return (

			<Well onClick={(e) => {
				onChangeValue(controlledField)(e, controlledField);
			}} style={{ ...wellStyle, fontSize, border: checked ? `${border} solid 1px` : "inherit" }}>

				<div style={{ display: "flex" }}>

					<div style={{ flexGrow: "1" }}><Glyphicon glyph={glyph} style={{ display: "inline-block", margin: "5px", color: this.getGlyphColor({ checked, controlledField }) }} /></div>

					<div style={{ flexGrow: "9" }}>
						<div style={{ fontWeight: checked ? "700" : null }} >
							{label}
						</div>
						<div>{info}</div>
					</div>
				</div>

				{/* if problem clicked show input*/}
				{controlledField === "problem" && checked &&
					<ControlledInput autoFocus={true} size="l" required={true} controlledField={answer.conflict_text} label={"Describe your issue"} type={"text"} onChangeValue={onChangeValue(answer.conflict_text)} />}

			</Well>

		)
	}

}

export default Action