import React from "react";
import Comment from "../../previewPage/utilsComponents/comment/Comment";
import { Glyphicon } from "react-bootstrap";
import { timeFormatsConst, formatTimePretty } from "../../../utils/utils";


export default ({ supervisor }) => <div>
	<div><Glyphicon glyph="user" />   {supervisor.userSupervised.userEmail}</div>
	{supervisor.appliedShapes && supervisor.appliedShapes.length > 0 && <div><Glyphicon glyph="tags" />   Areas added: {supervisor.appliedShapes.length}</div>}
	{supervisor.comment && <div> <Comment comment={supervisor.comment} /> </div>}
	<Glyphicon glyph="time" />   {formatTimePretty(supervisor.superviseTime, timeFormatsConst.epochTime, timeFormatsConst.localTimeGmtPreviewFormats)}
</div>