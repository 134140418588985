import React from "react";
import { Jumbotron, Button } from "react-bootstrap";


export default ({ title, text, refreshAction }) => (
	<Jumbotron style={{ width: "100%", textAlign: "-webkit-center" }} >

		<h2> {title ? title : "NO TASKS"} </h2>
		<small>{text ? text : " good for you"} </small>
		{refreshAction && <Button bsSize="xsmall" bsStyle="info" onClick={refreshAction} ><small>refresh</small></Button>}

	</Jumbotron >

)

