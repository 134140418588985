
import React from "react";
import { Button, Glyphicon } from "react-bootstrap";


export default ({ countAsIdTitle, areasArray, onDelete, onEdit, activeShapeID, editShapeID, onDoneEdit }) => {

	const shapeManagerButtonsStyle = (area) => ({
		flex: "1 0 20%",
		padding: "10px",
		border: activeShapeID === area.id ? "#0000007a solid 2px" : "inherit",
		maxWidth: "110px"
	})



	return (
		<div style={{
			display: "flex",
			// height: "13vh",
			maxWidth: "100%",
			// overflowY: "auto",
			alignItems: "center",
			padding: "5px 0",
			flexWrap: "wrap"
		}}>

			{areasArray.map((a, i) => {
				const currentShape = a.shapes ? a.shapes[0] : a.shape
				const isDoneDisable = currentShape.type === "DiagonalRectangle" && currentShape.points.length < 3
				return (
					<div key={i} style={shapeManagerButtonsStyle(a)}>

						<div style={{ textAlignLast: "center" }} > {countAsIdTitle ? i + 1 : a.id + 1}</div>

						{a.id === editShapeID ?
							<div style={{ textAlignLast: "center" }}>
								<Button
									title="DONE"
									bsSize="small"
									bsStyle="success"
									disabled={isDoneDisable}
									onClick={() => { onDoneEdit() }}>
									<Glyphicon glyph="ok" />
								</Button>
							</div>
							:
							<div style={{ display: "flex", flexDirection: "column" }}>
								<Button disabled={!!editShapeID} title="EDIT" bsSize="xs" bsStyle="info" onClick={() => onEdit(a)}><Glyphicon glyph="pencil" /></Button>
								<Button disabled={!!editShapeID} title="DELETE" bsSize="xs" bsStyle="danger" onClick={() => onDelete(a.id)}><Glyphicon glyph="trash" /></Button>
							</div>}

					</div>)
			})}

		</div>

	)
}

