
import React, { Component } from "react";
import ControlledInput from "./../../hoc/controlledInput"
import { has } from "lodash"
import { Button } from "react-bootstrap"
import { getDistance, latitude, longitude } from "geolib";


class AddEditComponent extends Component {

	constructor(props) {
		super(props);

		this.state = {
			boundingBox: {},
			data: props.data ? { ...props.data } : {},
			inputStyle: { margin: "1px" },
			subTaskAreaSize: "200",
			reqRepetition: 3,
			subTasksOffset: 100,
			name: "",
		}

	}



	nameChange = (field) => (e) => {
		this.setState({
			[field]: e.target.value
		})
	}


	onDone = () => {
		// get the object with the data
		const { data, name, reqRepetition, subTaskAreaSize, subTasksOffset } = this.state;

		// delete the data that the map controller
		delete data.boundingBox;

		// tell redux to send the new\edited task
		// this.props.onDoneAddEdit(data)
		this.props.onDoneAddEdit({ name, reqRepetition, subTaskAreaSize, subTasksOffset })

	}



	generateHelperText = (data) => {
		return (has(data, "subTasksOffset") && has(data, "subTaskAreaSize")) ?
			<div style={{ border: "black solid 1px", fontSize: "85%", margin: " 20px 1px 20px 1px", padding: " 10px 10px 10px 10px" }}>
				You are about to create sub tasks with overlapping of :<span style={{ color: data.subTaskAreaSize - data.subTasksOffset < 0 ? "red" : "black" }} >{data.subTaskAreaSize - data.subTasksOffset}</span>
			</div> : null
	}



	calculateArea = (task) => {
		const sw = { lat: task.boundingBox.sw.lat, lng: task.boundingBox.sw.lng };
		const ne = { lat: task.boundingBox.ne.lat, lng: task.boundingBox.ne.lng };
		const se = { lat: task.boundingBox.sw.lat, lng: task.boundingBox.ne.lng };

		// get the data, if not exist then get defaults
		let { subTaskAreaSize = 200, subTasksOffset = 100 } = task;
		// this.sideCalc(sw, ne, se, subTaskWidthHeight, subTasksOffset)

		subTaskAreaSize = Number(subTaskAreaSize)
		subTasksOffset = Number(subTasksOffset)


		let bottomBorder = getDistance(
			// calculate distance between sw & se
			{ latitude: latitude(sw), longitude: longitude(sw) },
			{ latitude: latitude(se), longitude: longitude(se) },
			1
		)

		let rightBorder = getDistance(
			// calculate distance between ne & se
			{ latitude: latitude(ne), longitude: longitude(ne) },
			{ latitude: latitude(se), longitude: longitude(se) },
			1
		)

		if (bottomBorder < subTaskAreaSize || rightBorder < subTaskAreaSize)
			return false

		return Math.ceil((bottomBorder - subTaskAreaSize + subTasksOffset) / subTasksOffset) * Math.ceil((rightBorder - subTaskAreaSize + subTasksOffset) / subTasksOffset)




	}



	sideCalc = (sw, ne, se, subTaskAreaSize, subTasksOffset) => {

		let calculatedDistance_SW_SE = getDistance(
			// calculate distance between sw & se
			{ latitude: latitude(sw), longitude: longitude(sw) },
			{ latitude: latitude(se), longitude: longitude(se) },
			1
		)

		let calculatedDistance_NE_SE = getDistance(
			// calculate distance between ne & se
			{ latitude: latitude(ne), longitude: longitude(ne) },
			{ latitude: latitude(se), longitude: longitude(se) },
			1
		)
		// calculate subtasks without extra
		return Math.ceil(calculatedDistance_NE_SE / subTaskAreaSize) * Math.ceil(calculatedDistance_SW_SE / subTaskAreaSize)


	}

	isNumeric = (str) => {
		if (typeof str != "string") return false // we only process strings!  
		return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
			!isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
	}


	render() {
		const { subTaskAreaSize, subTasksOffset } = this.state;
		const { data } = this.props;
		const calc = data.shape && data.shape.boundingBox ? this.calculateArea({ boundingBox: data.shape.boundingBox, subTasksOffset, subTaskAreaSize: parseInt(subTaskAreaSize) }) : null

		const isDoneDisabled = !this.isNumeric(subTaskAreaSize) ||
			parseInt(subTaskAreaSize) < subTasksOffset ||
			parseInt(subTaskAreaSize) < 1 ||
			subTasksOffset < 1

		const style = {
			"padding": "17px",
			backgroundColor: "#9396953b",
			borderRadius: "18px",
			marginTop: "10px"
		}




		return (
			<div className='addEdit_div' style={style}>

				<ControlledInput
					size="l"
					controlledField="name"
					required
					label={"Name"}
					type={"text"}
					info={"Insert the task name here"}
					placeholder={"insert the task name here"}
					onChangeValue={this.nameChange("name")}
					// value={!isEmpty(data) && has(data, "name") ? data.name : ""}
					value={this.state.name}
				/>

				<ControlledInput
					size="l"
					required={false}
					label={"repetitions required"}
					type={"number"}
					controlledField="reqRepetition"
					info={"Insert the of repetitions required (200"}
					placeholder={"insert the of repetitions required (200"}
					onChangeValue={this.nameChange("reqRepetition")}
					value={this.state.reqRepetition}
				/>

				<ControlledInput
					size="l"
					required={false}
					label={"Area size"}
					type={"text"}
					controlledField="subTaskAreaSize"
					info={"Insert the Sub task area size (3)"}
					placeholder={"insert the Sub task area size (3)"}
					onChangeValue={this.nameChange("subTaskAreaSize")}
					value={this.state.subTaskAreaSize}
				/>
				<ControlledInput
					size="l"
					required={false}
					label={"Sub tasks offset"}
					type={"number"}
					controlledField="subTasksOffset"
					info={"Insert the sub tasks offset to be considered"}
					placeholder={"insert the sub tasks offset to be considered"}
					onChangeValue={this.nameChange("subTasksOffset")}
					value={this.state.subTasksOffset}
				/>


				{/* generate the helper text in the button of the add edit components */}
				{this.generateHelperText(data)}

				<div style={{ display: "grid" }} >
					<Button bsStyle="success" disabled={isDoneDisabled} onClick={this.onDone} >Done</Button>
					<Button bsStyle="danger" onClick={this.props.onCancel} >Cancel</Button>
				</div>

				{calc && <div>
					You are about to generate <small style={{ color: calc < 10000 ? "green" : "red" }} >{calc}#</small> sub tasks
				</div>}
				{!calc && data.boundingBox && <div>
					You are about to generate <small style={{ color: calc < 10000 ? "green" : "red" }} >{1}#</small> sub tasks
				</div>}

			</div>
		)
	}

}

export default AddEditComponent;

