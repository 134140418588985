// import saga helpers
import { put, call, takeLatest, spawn, all } from "redux-saga/effects";
import { SafeSaga } from "../../common/modules"


// import action type
import { ACTION_TYPES } from "../reduxConsts";

import {
	async_start, setSubtasksList, cleanSubtasksList, setInBoxResponseForPreview, filterChanged
} from "../preview/previewAction";

const useNewHistoryPageData = true


const previewUrls = {
	fetchCompletedSubtask: "api/subtasks/preview",
	inbox: "api/areas/inBox"
}

function* fetchCompletedSubtasks(network) {
	yield takeLatest(ACTION_TYPES.PREVIEW.FETCH_COMPLETE_SUBTASK_LIST, SafeSaga(function* ({ value }) {

		// get the access token from the action value
		const {
			// userid
			userid,
			// filter by my answer correctness
			correctness = "all",
			// filter from the date until now
			date = "today",
			// by who, me or all
			byWho = "me",

			byRole = "painter",
			// subtask name
			name = false,
			//limit the results
			limit = 200

		} = value;
		// handle the name object 
		let _name = "";

		// if the bool use in the name object is true
		if (name.use)
			//then use the value in the objec
			_name = name.value;
		// else the use bool if false in the name object
		else
			//send false so the server will know not to look for a name
			_name = false


		// dispatch async start action
		yield put(async_start());


		const response = yield call(network.sendGet, previewUrls.fetchCompletedSubtask + `?userid=${userid}&correctness=${correctness}&date=${date}&byWho=${byWho}&name=${_name}&limit=${limit}&byRole=${byRole}`);

		yield put(cleanSubtasksList())


		const newRes = response.map(res => ({
			appliedShapes: res.supervisor.appliedShapes || [],
			approvedTime: res.supervisor.superviseTime,
			boundingBox: res.painting.boundingBox,
			centerGeoHash: res.painting.centerGeoHash,
			currentResults: res.painting.currentResults,
			name: res.painting.name,
			parent_id: res.painting.parentId,
			requiredResults: res.painting.requiredResults,
			results: res.painting.results,
			subtaskExistingAreasSnapshot: res.supervisor.subtaskExistingAreasSnapshot || [],
			userApproved: res.supervisor.userSupervised,
			_id: res._id,
			approver: res.approver || {}

		}))
		yield put(setSubtasksList(newRes))
		yield put(filterChanged(false))

	}))

}
function* fetchSubtasksForHistory(network) {
	yield takeLatest(ACTION_TYPES.PREVIEW.FETCH_COMPLETE_SUBTASK_LIST, SafeSaga(function* ({ value }) {

		// get the access token from the action value
		const {
			// userid
			userid,
			// filter by my answer correctness
			correctness = "all",
			// filter from the date until now
			date = "today",
			// by who, me or all
			byWho = "me",

			byRole = "painter",
			// subtask name
			name = false,
			//limit the results
			limit = 200

		} = value;
		// handle the name object 
		let _name = "";

		// if the bool use in the name object is true
		if (name.use)
			//then use the value in the objec
			_name = name.value;
		// else the use bool if false in the name object
		else
			//send false so the server will know not to look for a name
			_name = false


		// dispatch async start action
		yield put(async_start());


		const response = yield call(network.sendGet, previewUrls.fetchCompletedSubtask + `?userid=${userid}&correctness=${correctness}&date=${date}&byWho=${byWho}&name=${_name}&byRole=${byRole}&limit=${limit}`);

		yield put(cleanSubtasksList())




		yield put(setSubtasksList(response))
		yield put(filterChanged(false))

	}))

}


function* fetchAreasInBox(network) {
	yield takeLatest(ACTION_TYPES.PREVIEW.GET_SHAPES_WITH_RADIUS_FOR_PREVIEW, SafeSaga(function* ({ value }) {

		const { boundingBox } = value
		// dispatch async start action
		yield put(async_start());


		const response = yield call(network.sendPost, previewUrls.inbox, { shape: boundingBox });

		yield put(setInBoxResponseForPreview(response))
	}))

}


export function* previewSaga(network) {
	let sagasArr = [spawn(fetchAreasInBox, network)]

	if (useNewHistoryPageData)
		sagasArr.push(spawn(fetchSubtasksForHistory, network))
	else
		sagasArr.push(spawn(fetchCompletedSubtasks, network))

	yield all(sagasArr)
}

