import { combineReducers } from "redux";
import { approverDataStore } from "./approverDataReducer";
import { approverTableItemsSelectionsReducer } from "./approverTableItemsSelectionsReducer";
import { approverAnswersReducer } from "./approverAnswersReducer";
import { approverCommentBoxValueReducer } from "./approverCommentBoxValueReducer";
import { approverFilterReducer } from "./approverFilterReducer";

export const approverStore = combineReducers({
	data: approverDataStore,
	tableItemsSelections: approverTableItemsSelectionsReducer,
	approveCommentBoxValue: approverCommentBoxValueReducer,
	approverAnswers: approverAnswersReducer,
	filter: approverFilterReducer
})