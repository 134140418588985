import React from "react"
import "./info.css"
import { Glyphicon } from "react-bootstrap"
import Comment from "./../../previewPage/utilsComponents/comment/Comment"


const prettyStatus = {
	no_areas: "No Multilevel",
	has_areas: "Multilevel",
	problem: "Conflict",
}


export class GtPainterInfo extends React.Component {

	render() {
		const { data } = this.props
		return (<div className="info GT_painterInfo">

			<div>{prettyStatus[data.status]}</div>

			{data.conflictText && <Comment comment={data.conflictText} />}

			{data.markedAs &&
				<div style={{
					color: "green",
					justifyContent: "space-between",
					alignItems: "center",
					width: "52px",
					alignitems: "center",
					display: "flex"
				}}><Glyphicon glyph="ok" />
					<span>Right</span>
				</div>}


			{!data.markedAs &&
				<div style={{
					color: "red",
					justifyContent: "space-between",
					alignItems: "center",
					width: "60px",
					alignitems: "center",
					display: "flex"
				}}><Glyphicon glyph="remove" />
					<span>Wrong</span>
				</div>}

		</div>)
	}

}

