import React from "react"
import { Button } from "react-bootstrap"
import Switch from "react-switch"
import "./questionToApprove.css"


export const QuestionToApprove = ({ question, onTrue, onWrong, answer }) => {

	const onAnswerChanged = (isTrue) => {
		if (isTrue) {
			onTrue()
		}
		else {
			onWrong()
		}
	}

	return <div className="QuestionToApprove">
		<div className="question">
			{question}
		</div>
		<div className="answerOptions">
			<Switch height={15} width={35} onChange={onAnswerChanged} checked={answer} offColor="#ff0000" />
			{/* <Button bsSize='lg' onClick={onTrue} bsStyle="success">Yes </Button> */}
			{/* <Button bsSize='lg' onClick={onWrong} bsStyle="danger">No </Button> */}
		</div>
	</div>
}