const { combineReducers } = require("redux");

const { shapesStore } = require("./shapes/shapeReducer");
const { tasksStore } = require("./tasks/taskReducer");
const { paintStore } = require("./paint/paintReducer");
const { supervisorStore } = require("./supervise/superviseReducer");
const { leaderBoardStore } = require("./leaderBoard/leaderBoardReducer");
const { dialogStore } = require("./dialog/dialogReducer");
const { previewStore } = require("./preview/previewReducer");
const { loaderStore } = require("./Loader/loaderReducer");
const { approverStore } = require("./approve/approverReducer");
const { reportStore } = require("./report/reportReducer");
const { areasStore } = require("./areas/areasReducer");


const { SystemNotificationsRedux } = require("./../common/components/SystemNotifications");
const { modalRedux } = require("./../common/components/Modal");

const { auth0redux } = require("./../common/components/Authentication");

let reducers = {
	areasStore,
	approverStore,
	loaderStore,
	previewStore,
	leaderBoardStore,
	tasksStore,
	shapesStore,
	paintStore,
	reportStore,
	supervisorStore,
	dialogStore,
	systemNotificationsStore: SystemNotificationsRedux.reducer,
	modalStore: modalRedux.modalReducer.modalStore,

}


export default combineReducers(
	// add auth0 store reducer to our app
	auth0redux.reducer.addStore(reducers)
)





