import { handleActions } from "redux-actions"
import { ACTION_TYPES } from "../reduxConsts"

const initialState = null

const setData = (state, action) => {
	return action.value
}

const setItemColor = (state, action) => {
	//id is an array
	const { id, fill, stroke } = action.value
	const newState = { ...state }
	newState[id] = { ...newState[id] }
	newState[id]["colorInfo"] = { ...newState[id]["colorInfo"] }
	newState[id]["colorInfo"]["fillColor"] = fill
	newState[id]["colorInfo"]["strokeColor"] = stroke

	return { ...state, ...newState }
}


const setItemVisibility = (state, action) => {
	const { id, isVisible } = action.value
	const newState = { ...state }
	newState[id] = { ...newState[id] }
	newState[id].visible = isVisible

	return { ...state, ...newState }
}


const setIsAllVisible = (state, action) => {
	const { isVisible, types } = action.value
	const newState = { ...state }

	for (let id in newState) {
		newState[id] = { ...newState[id] }
		newState[id].visible = (newState[id].visible !== -1) && (types.includes(newState[id].type)) ? isVisible : newState[id].visible
	}

	return { ...state, ...newState }
}



const resetState = (state, action) => {
	return initialState
}


export const approverTableItemsSelectionsReducer = handleActions({
	[ACTION_TYPES.APPROVER.SET_DATA_FOR_APPROVER_TABLE]: setData,
	[ACTION_TYPES.APPROVER.SET_COLOR_FOR_APPROVER_TABLE_ITEM]: setItemColor,
	[ACTION_TYPES.APPROVER.SET_VISIBILITY_FOR_APPROVER_TABLE_ITEM]: setItemVisibility,
	[ACTION_TYPES.APPROVER.SET_IS_ALL_VISIBLE]: setIsAllVisible,
	[ACTION_TYPES.APPROVER.RESET_APPROVER_STATE]: resetState,
}, initialState)