import { handleActions } from "redux-actions"
import { ACTION_TYPES } from "../reduxConsts"


const initialState = {
	appliedShapes: [],
	score: {
		shapes: true, // TODO: in old screen default was false
		tags: true,  // TODO: in old screen default was false
		grading: true // TODO: in old screen default was false
	}
}


const setAnswer = (state, action) => {
	return { ...state, score: { ...state.score, ...action.value } }
}


const resetState = (state, action) => {
	return initialState
}


export const approverAnswersReducer = handleActions({
	[ACTION_TYPES.APPROVER.SET_PHASE_ANSWER]: setAnswer,
	[ACTION_TYPES.APPROVER.RESET_APPROVER_STATE]: resetState,
}, initialState)