
import React from "react";


const searchList = (WrappedComponent, listKey) => {

	return class extends React.Component {

		state = { searchTerm: "" }

		handleChange = (searchTerm) => { this.setState({ searchTerm: searchTerm.target.value }); }

		// this function will filter the list
		filterWithTerm = (term = "", list = []) => {

			if (term === "")
				return list

			return list.filter(s => {

				const valuesArr = Object.values(s);

				let filteredValues = valuesArr.filter(v => typeof v === "string");

				for (let i = 0; i < filteredValues.length; i++) {
					if (filteredValues[i].toLowerCase().includes(term.toLowerCase()))
						return true
				}
				return false;
			})

		}






		render() {
			// fetch the search term
			const { searchTerm } = this.state;

			// fetch the shapes list
			const myList = this.props[listKey]

			// filter the list with the search term
			let filteredList = this.filterWithTerm(searchTerm, myList)

			// create new object
			let newProps = Object.create({});

			// copy the object
			Object.assign(newProps, this.props);

			// add a property to the object
			newProps[listKey] = filteredList

			let inputStyle = {
				"width": "100%", "padding": "2px", "margin": "9px 0 9px 0", "textAlign": "center", "border": "#0000004f solid 1px"
			}

			return (
				<div className="list_parent" style={{ display: "flex", flexDirection: "column", height: "100%" }}>

					<div className="input_div" style={{ flexGrow: "1" }} >

						<input onChange={this.handleChange} style={inputStyle} placeholder="Search here" />

					</div>
					<div style={{ flexGrow: "60", overflowY: "auto" }}>
						<WrappedComponent {...newProps} />
					</div>

				</div>
			)
		}
	};
}

export default searchList;
