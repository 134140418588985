import React from "react";
import "./listItemRow.css"

const wrapText = {
	whiteSpace: "nowrap",
	overflow: "hidden",
	textOverflow: "ellipsis",
	maxWidth: "200px"
}

export default ({ keyname, value, addColon = true }) => <div className="listItemRow">
	<div className="keyLabel" >{keyname}{addColon && ":"}</div>
	<div className="value" style={wrapText} title={value}>{value}</div>
</div>