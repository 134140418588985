import { refreshPage } from "./../../utils/utils"

export const errorHandler = (error) => {
	console.log({ error });
	return error
}

export const getErrorDialogFromError = (error) => {

	const prettyObject = (object) => {
		const keys = Object.keys(object);
		const messageObject = keys.reduce((prev, key) => {
			return `${prev}.
			${key} : ${object[key]}`

		}, "")
		return messageObject

	}


	const type = typeof error;

	if (type === "string") {
		// TODO make error code and compare here!!!
		if (error === "subtask not exist")
			return null
		return buildDialogObject({ title: "Error", error })
	}

	if (type === "object") {

		if (error.name === "UnauthorizedError")
			return buildDialogObject({
				title: "Authorization error",
				error: "Token expired. Please refresh the page",
				next: [
					{ action: () => { refreshPage() }, title: "Refresh", buttonStyle: { backgroundColor: "#5cb85c" } }
				]
			})

		if (error.code === 11000) {
			return buildDialogObject({
				title: "Duplicate Error",
				error: "Data already exist : '" + JSON.stringify(error.data) + "'",


			})
		}
		if (error.reason && error.reason === "apiValidation") {
			return buildDialogObject({
				title: "validation error",
				error: error.errors


			})
		}
		if (error.errorCode && error.errorCode === "RECORD_NOT_FOUND") {
			return buildDialogObject({
				title: "Could not find",
				error: `${error.errorMessage}
				(${prettyObject(error.data)})`


			})
		}
		return buildDialogObject({
			title: "Error",
			error: "Server response with error: " + JSON.stringify(error),
		})
	}


}

//dialog props : level, txt, next, confirm, cancel, title
export const buildDialogObject = ({ title, error, next }) => ({
	level: "error",
	txt: error,
	title: title,
	next

})
