export default function () {

	const {
		REACT_APP_AUTH0_LOGOUT_REDIRECT_URI,
		REACT_APP_AUTH0_LOGIN_REDIRECT_URI,
		REACT_APP_AUTH0_ADMIN_GROUP,
		REACT_APP_AUTH0_PAINTER_GROUP,
		REACT_APP_AUTH0_SUPERVISORS_GROUP,
		REACT_APP_AUTH0_REPORT_GROUP,
		REACT_APP_AUTH0_APPROVERS_GROUP
	} = process.env;




	if (
		!REACT_APP_AUTH0_LOGOUT_REDIRECT_URI ||
		!REACT_APP_AUTH0_LOGIN_REDIRECT_URI ||
		!REACT_APP_AUTH0_ADMIN_GROUP ||
		!REACT_APP_AUTH0_PAINTER_GROUP ||
		!REACT_APP_AUTH0_SUPERVISORS_GROUP ||
		!REACT_APP_AUTH0_REPORT_GROUP ||
		!REACT_APP_AUTH0_APPROVERS_GROUP
	)
		throw new Error(
			"not all config variables provided, expected : {REACT_APP_AUTH0_LOGOUT_REDIRECT_URI,REACT_APP_AUTH0_LOGIN_REDIRECT_URI,REACT_APP_AUTH0_ADMIN_GROUP,REACT_APP_AUTH0_PAINTER_GROUP}, but got: " + JSON.stringify(process.env))


}