
const { ACTION_TYPES, ASYNC_STATUS } = require("../reduxConsts")

// initial state for tasks store
const PREVIEW_initial = {
	async_status: null,
	filterManager: {
		// filter by my answer correctness
		correctness: "all",
		// filter from the date until now
		// date: "today",
		// by who, me or all
		byWho: "me",

		byRole: "painter",
		// subtask name
		name: {
			use: false,
			value: ""
		},
		//limit the results
		limit: 200
	},
	filterChanged: false,
	subtaskList: [],
	inBoxResponseForSubtaskPreview: []
}


export function previewStore(state = PREVIEW_initial, action) {
	const { type, value } = action;

	switch (type) {



		case ACTION_TYPES.PREVIEW.FILTER_CHANGED: {

			let { isChanged } = value



			return {
				...state,
				filterChanged: isChanged
			}
		}
		case ACTION_TYPES.PREVIEW.SET_FILTERS: {

			let newValue = value.value

			if (value.key === "name" && value.value.value === "")
				newValue.use = false;


			let filterManager = {
				...state.filterManager,
				[value.key]: newValue
			};

			return {
				...state,
				filterManager
			}
		}
		case ACTION_TYPES.PREVIEW.SET_FULL_FILTERS: {

			return {
				...state,
				filterChanged: true,
				filterManager: {
					...value,
					// name: {
					// 	use: false,
					// 	value: ""
					// },
				}
			}
		}
		case ACTION_TYPES.PREVIEW.SET_FREE_SEARCH_FILTER: {
			if (value.text === "")
				return {
					...state,
					filterChanged: true,
					filterManager:
					{
						correctness: "all",
						byWho: "me",
						byRole: "painter",
						limit: state.filterManager.limit,
						name: {
							use: false,
							value: ""
						},
					}
				}
			else
				return {
					...state,
					filterChanged: true,
					filterManager: {

						limit: state.filterManager.limit,
						name: {
							use: true,
							value: value.text
						},
					}
				}
		}

		// case ACTION_TYPES.PREVIEW.ADVANCED_FILTER_CLICK: {
		// 	return {
		// 		...state,
		// 		ui: {
		// 			advanced: value
		// 		},
		// 		filterManager: {
		// 			...state.filterManager,
		// 			name: {
		// 				value: "",
		// 				use: false
		// 			}

		// 		}
		// 	}
		// }
		case ACTION_TYPES.PREVIEW.SET_INBOX_RESPONSE_FOR_PREVIEW: {


			return {
				...state,
				inBoxResponseForSubtaskPreview: value.areas
			}
		}


		// applied shapes controller
		case ACTION_TYPES.PREVIEW.SET_COMPLETE_SUBTASK_LIST: {

			return {
				...state,
				subtaskList: value
			}
		}

		// applied shapes controller
		case ACTION_TYPES.PREVIEW.CLEAN_COMPLETE_SUBTASK_LIST: {

			return {
				...state,
				subtaskList: []
			}
		}

		/****************** async actions ***************/
		case ACTION_TYPES.PREVIEW.ASYNC_START: {
			return {
				...state,
				async_status: ASYNC_STATUS.START
			}
		}

		case ACTION_TYPES.PREVIEW.ASYNC_SUCCESS: {
			return {
				...state,
				async_status: ASYNC_STATUS.SUCCESS

			}

		}

		case ACTION_TYPES.PREVIEW.ASYNC_ERROR: {
			// const arr = [...state.errors, value];

			return {
				...state,
				async_status: ASYNC_STATUS.ERROR
			}

		}



		default: {
			return state
		}
	}
}