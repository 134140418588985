import { handleActions } from "redux-actions"
const { ACTION_TYPES, ASYNC_STATUS } = require("../reduxConsts")

const initialState = {
	currentlyAddedDiagonalRectanglePoints: [],
	focus: null,
	errors: null,
	async_status: null, // for async calls
	isSendBack: false,
	subtask: null,
	// build the supervisor object to be sent
	supervisorResultObject: {
		// the current subtask_id we working on
		subtask_id: null,
		// shapes to be applied
		appliedShapes: [],
		//all result id's to be correct
		correctArr: [],
		// admin comment optional
		supervisorComment: "",
		//prefix for applied shapes
		// shapesPrefix: "",

		autoSuggestions: {
			async_status: ASYNC_STATUS.SUCCESS,
			results: []
		},



		existAreasSnapshot: []
	},

	isRequestApprove: false,
	numWaitingToSupervise: null,

	// control on the display and color of the user results shapes (the subtask and DB shapes cannot be edited or color changed)
	resultsColorDisplayHandler: [
		// {_id,color,isVisible}
		// by result id, set shape styles:{}
	],
	approverSuggestionColorDisplayHandler: {
		fillColor: "#00000000",
		strokeColor: "#000"
	},


	shapesInSubtaskRadius: [],
	displayShapesInSubtaskRadius: true,
	displayApproverSendBackShapes: true,
	// focus handler
	focusHandler: {
		focusID: 0
	},
	editShapeID: null,
	clickShapeID: null
}



const editDiagonalRectanglePointOfAreaInEdit = (state, action) => {
	const { pointOrder, newPoint } = action.value
	const supervisorResultObjectClone = _.cloneDeep(state.supervisorResultObject)
	supervisorResultObjectClone.appliedShapes[state.editShapeID - 1].shape.points[pointOrder] = newPoint

	return {
		...state,
		supervisorResultObject: { ...supervisorResultObjectClone }
	}

}


const deleteDiagonalRectanglePointInEdit = (state, action) => {
	const { pointOrderToDelete } = action.value
	const supervisorResultObjectClone = _.cloneDeep(state.supervisorResultObject)
	supervisorResultObjectClone.appliedShapes[state.editShapeID - 1].shape.points[pointOrderToDelete] = null
	supervisorResultObjectClone.appliedShapes[state.editShapeID - 1].shape.points = _.filter(supervisorResultObjectClone.appliedShapes[state.editShapeID - 1].shape.points)

	return {
		...state,
		supervisorResultObject: { ...supervisorResultObjectClone }
	}

}



const resetState = (state, action) => ({ ...initialState })


const setIsSendback = (state, action) => {
	const { isSendBack } = action.value;

	return {
		...state,
		isSendBack: isSendBack
	}
}


const setTopLevelType = (state, action) => {
	const { id, type } = action.value;

	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: state.supervisorResultObject.appliedShapes.map(shape => {
				if (shape.id === id)
					return ({
						...shape,
						multilevelTypes: {
							...shape.multilevelTypes,
							topLevel: type
						}
					})
				else return shape
			})
		}
	}
}


const setIsRequestApprove = (state, action) => {
	const isRequestApprove = action.value;

	return {
		...state,
		isRequestApprove
	}
}


const setBottomLevelType = (state, action) => {
	const { id, type } = action.value;

	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: state.supervisorResultObject.appliedShapes.map(shape => {
				if (shape.id === id)
					return ({
						...shape,
						multilevelTypes: {
							...shape.multilevelTypes,
							bottomLevel: type
						}
					})
				else return shape
			})
		}
	}
}


const setIsComplexType = (state, action) => {
	const { id, type } = action.value;

	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: state.supervisorResultObject.appliedShapes.map(shape => {
				if (shape.id === id)
					return ({
						...shape,
						multilevelTypes: {
							bottomLevel: "Types",
							topLevel: "Types",
							isComplex: type
						}
					})
				else return shape
			})
		}
	}
}


const setAreasInRadiusDisplay = (state, action) => {
	const displayShapesInSubtaskRadius = action.value
	return {
		...state,
		displayShapesInSubtaskRadius
	}
}


const setApproverSendbackDisplay = (state, action) => {
	const displayApproverSendBackShapes = action.value
	return {
		...state,
		displayApproverSendBackShapes
	}
}

const addSuggestionToSpecificAppliedShape = (state, action) => {

	const { id, text } = action.value;
	const { supervisorResultObject } = state;

	const updatedAppliedShapes = supervisorResultObject.appliedShapes.map(appliedShape =>
		appliedShape.id === id ?
			({
				...appliedShape,
				googleSuggestion: text
			})
			:
			appliedShape)


	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			// update applied shapes
			appliedShapes: updatedAppliedShapes
		}
	}
}


const addAppliedShapeDescription = (state, action) => {

	const { id, txt } = action.value;
	const { supervisorResultObject } = state;

	const updatedAppliedShapes = supervisorResultObject.appliedShapes.map(appliedShape =>
		appliedShape.id === id ?
			({
				...appliedShape, description: txt
			})
			:
			appliedShape)


	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: updatedAppliedShapes
		}
	}
}

const onDeleteShape = (state, action) => {
	const idToDelete = action.value
	let { appliedShapes } = { ...state.supervisorResultObject }

	let updatedAreas = appliedShapes.filter(({ id }) => id !== idToDelete)
	return {
		...state,
		editShapeID: null,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: updatedAreas
		}
	}
}


const onDoneEdit = (state, action) => {
	return {
		...state,
		editShapeID: null
	}
}


const onShapeClicked = (state, action) => {
	const clickShapeID = action.value

	return {
		...state,
		clickShapeID
	}
}

// ADD_APPLIED_SHAPE
const addAppliedShape = (state, action) => {
	const shape = action.value
	const appliedShapes = [...state.supervisorResultObject.appliedShapes, shape];

	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes
		}
	}
}


//  REPLACE_APPLIED_SHAPES_ARRAY   
const replaceAppliedShapesArray = (state, action) => {
	const appliedShape = action.value

	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: appliedShape.map(shape => {
				if (!shape.multilevelTypes)
					return ({
						...shape,
						multilevelTypes: {
							topLevel: "Types",
							bottomLevel: "Types",
							isComplex: false
						}
					})
				else return shape
			})
		}
	}
}


const addSuggestedAreas = (state, action) => {
	const { suggestionsArray, shapeId, reqStatus } = action.value
	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: state.supervisorResultObject.appliedShapes.map(shape =>
				shape.id === shapeId ?
					({ ...shape, autoSuggestions: { async_status: ASYNC_STATUS.SUCCESS, results: suggestionsArray, requestStatus: reqStatus } }) :
					shape)
		}
	}
}


const onFetchingSuggestions = (state, action) => {
	const { shapeId } = action.value
	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: state.supervisorResultObject.appliedShapes.map(shape =>
				shape.id === shapeId ?
					({
						...shape,
						autoSuggestions: {
							...shape.autoSuggestions,
							async_status: ASYNC_STATUS.START
						}
					}) :
					shape)
		}
	}
}

// REMOVE_APPLIED_SHAPES
const removeAppliedShapes = (state, action) => {
	return {
		...state,
		currentlyAddedDiagonalRectanglePoints: [],
		supervisorResultObject: {
			...state.supervisorResultObject,
			appliedShapes: []
		}
	}
}

// ADD_CORRECT_RESULT 
const addCorrectResult = (state, action) => {
	const correctResultToAdd = action.value
	const correctArr = [...state.supervisorResultObject.correctArr, correctResultToAdd]
	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			correctArr
		}
	}
}


// REMOVE_CORRECT_RESULT
const removeCorrectResult = (state, action) => {
	const correctResultToRemove = action.value
	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			correctArr: state.supervisorResultObject.correctArr.filter(v => v !== correctResultToRemove)
		}
	}
}


// SET_SUBTASK
const setSubtask = (state, action) => {
	const subtask = action.value
	// get all the results id and add to the correct array for initial correct for all results
	// let correctArr = value.results.map(({ _id }) => _id);

	return {
		...state,
		subtask: subtask,
		supervisorResultObject: {
			...state.supervisorResultObject,
			subtask_id: subtask._id,
			correctArr: []

		},
		// filter the conflict  and no_areas from shape draw
		resultsColorDisplayHandler: subtask.results.map(result => ({
			id: result._id,
			visible: subtask.approverSendBack ? false : true,
			colorInfo: {
				fillColor: "default",
				strokeColor: "black"
			}
		}))

	}
}


// CLEAN SUBTASK
const cleanSubtask = (state, action) => ({
	...state,
	subtask: null,
	supervisorResultObject: {
		subtask_id: null,
		correctArr: [],
		appliedShapes: [],
		// shapesPrefix: "",
		supervisorComment: ""
	}
})


// SET SUPERVISOR COMMENT
const setSubtaskComment = (state, action) => {
	const supervisorComment = action.value

	return {
		...state,
		supervisorResultObject: {
			...state.supervisorResultObject,
			supervisorComment
		}
	}
}


const setApproverSuggestionColor = (state, action) => {
	const { styles } = action.value

	return {
		...state,
		approverSuggestionColorDisplayHandler: styles
	}
}


const setUserResultColor = (state, action) => {
	const { id, styles } = action.value
	const resultsColorDisplayHandler = state.resultsColorDisplayHandler.map((resultHandler) => resultHandler.id === id ? ({ ...resultHandler, colorInfo: styles }) : resultHandler)

	return {
		...state,
		resultsColorDisplayHandler
	}
}


const setUserResultDisplay = (state, action) => {
	const { id, isVisible } = action.value
	const resultsColorDisplayHandler = state.resultsColorDisplayHandler.map((resultHandler) => resultHandler.id === id ? ({ ...resultHandler, visible: isVisible }) : resultHandler)
	return {
		...state,
		resultsColorDisplayHandler
	}
}


const setEditShapeId = (state, action) => {
	const editShapeID = action.value

	return {
		...state,
		editShapeID
	}
}



const addShapesInRadius = (state, action) => {
	const shapesInSubtaskRadius = action.value

	return {
		...state,
		shapesInSubtaskRadius,
		supervisorResultObject: {
			...state.supervisorResultObject,
			existAreasSnapshot: shapesInSubtaskRadius
		}
	}
}


const setNumReadyToSupervise = (state, action) => {
	const numWaitingToSupervise = action.value

	return {
		...state,
		numWaitingToSupervise
	}
}


const setAsyncStart = (state, action) => {
	return {
		...state,
		async_status: ASYNC_STATUS.START
	}
}


const setAsyncSuccess = (state, action) => {
	return {
		...state,
		async_status: ASYNC_STATUS.SUCCESS

	}
}


const setAsyncError = (state, action) => {
	const errors = action.value

	return {
		...state,
		async_status: ASYNC_STATUS.ERROR,
		errors
	}
}


const setMapFocus = (state, action) => {
	const focus = action.value

	return {
		...state,
		focus
	}
}


const setHandlerFocus = (state, action) => {
	const idToFocus = state.focusHandler.focusID + 1
	return {
		...state,
		focusHandler: {
			focusID: idToFocus
		}
	}
}

const clearMapFocus = (state, action) => ({
	...state,
	focus: null
})


const addDiagonalRectangleToCurrentlyAddedShape = (state, action) => {
	const point = action.value
	//Adding a point to a new shape - not to an existing shape in edit mode
	if (!state.editShapeID) {
		const pointsArray = [...state.currentlyAddedDiagonalRectanglePoints, point]

		if (pointsArray.length === 3) {
			return {
				...state,
				currentlyAddedDiagonalRectanglePoints: []
			}
		}

		return {
			...state,
			currentlyAddedDiagonalRectanglePoints: pointsArray
		}

	} else {
		// adding a point to an existing shape in edit
		const supervisorResultObjectClone = _.cloneDeep(state.supervisorResultObject)
		const shapeInEditPoints = supervisorResultObjectClone.appliedShapes[state.editShapeID - 1].shape.points
		if (shapeInEditPoints.length === 3) {
			return { ...state }
		} else {
			supervisorResultObjectClone.appliedShapes[state.editShapeID - 1].shape.points.push(point)
		}

		return {
			...state,
			supervisorResultObject: { ...supervisorResultObjectClone }
		}

	}

}


const removeDiagonalRectangleFromCurrentlyAddedShape = (state, action) => {
	const pointOrderToDelete = action.value
	let pointsArray = [...state.currentlyAddedDiagonalRectanglePoints]
	pointsArray[pointOrderToDelete] = null
	pointsArray = _.filter(pointsArray)
	return {
		...state,
		currentlyAddedDiagonalRectanglePoints: pointsArray
	}
}


const updatePointOfDiagonalRectangleCurrentlyAddedShape = (state, action) => {
	const { pointOrder, newPoint } = action.value
	let pointsArray = [...state.currentlyAddedDiagonalRectanglePoints]
	pointsArray[pointOrder] = { ...newPoint }
	return {
		...state,
		currentlyAddedDiagonalRectanglePoints: pointsArray
	}
}


export const supervisorStore = handleActions({
	[ACTION_TYPES.SUPERVISOR.FOCUS]: setHandlerFocus,
	[ACTION_TYPES.SUPERVISOR.CLEAR_FOCUS]: clearMapFocus,
	[ACTION_TYPES.SUPERVISOR.MAP_FOCUS]: setMapFocus,


	// ## Async actions
	[ACTION_TYPES.SUPERVISOR.ASYNC_START]: setAsyncStart,
	[ACTION_TYPES.SUPERVISOR.ASYNC_SUCCESS]: setAsyncSuccess,
	[ACTION_TYPES.SUPERVISOR.ASYNC_ERROR]: setAsyncError,
	// ## End of Async actions


	//## UI actions
	[ACTION_TYPES.SUPERVISOR.SET_NUM_READY_TO_SUPERVISE]: setNumReadyToSupervise,
	[ACTION_TYPES.SUPERVISOR.ADD_SHAPES_IN_RADIUS]: addShapesInRadius,
	[ACTION_TYPES.SUPERVISOR.SET_EDIT_SHAPE_ID]: setEditShapeId,
	[ACTION_TYPES.SUPERVISOR.SET_USER_RESULTS_DISPLAY]: setUserResultDisplay,
	[ACTION_TYPES.SUPERVISOR.SET_USER_RESULTS_COLOR]: setUserResultColor,
	[ACTION_TYPES.SUPERVISOR.SET_APPROVER_SUGGESTION_COLOR]: setApproverSuggestionColor,
	//######### End of UI actions


	[ACTION_TYPES.SUPERVISOR.SET_SUPERVISOR_COMMENT]: setSubtaskComment,
	[ACTION_TYPES.SUPERVISOR.CLEAN_SUBTASK]: cleanSubtask,
	[ACTION_TYPES.SUPERVISOR.SET_SUBTASK]: setSubtask,
	[ACTION_TYPES.SUPERVISOR.REMOVE_CORRECT_RESULT]: removeCorrectResult,
	[ACTION_TYPES.SUPERVISOR.ADD_CORRECT_RESULT]: addCorrectResult,
	[ACTION_TYPES.SUPERVISOR.REMOVE_APPLIED_SHAPES]: removeAppliedShapes,
	[ACTION_TYPES.SUPERVISOR.FETCHING_SUGGESTIONS]: onFetchingSuggestions,
	[ACTION_TYPES.SUPERVISOR.ADD_SUGGESTED_AREAS]: addSuggestedAreas,
	[ACTION_TYPES.SUPERVISOR.REPLACE_APPLIED_SHAPES_ARRAY]: replaceAppliedShapesArray,
	[ACTION_TYPES.SUPERVISOR.ADD_APPLIED_SHAPE]: addAppliedShape,
	[ACTION_TYPES.SUPERVISOR.SHAPE_CLICKED]: onShapeClicked,
	[ACTION_TYPES.SUPERVISOR.ON_DONE_EDIT]: onDoneEdit,
	[ACTION_TYPES.SUPERVISOR.ON_DELETE_SHAPE]: onDeleteShape,
	[ACTION_TYPES.SUPERVISOR.ADD_APPLIED_SHAPE_DESCRIPTION]: addAppliedShapeDescription,
	[ACTION_TYPES.SUPERVISOR.ADD_SUGGESTION_TO_SPECIFIC_APPLIED_SHAPE]: addSuggestionToSpecificAppliedShape,
	[ACTION_TYPES.SUPERVISOR.SET_APPROVER_SENDBACK_DISPLAY]: setApproverSendbackDisplay,
	[ACTION_TYPES.SUPERVISOR.SET_AREAS_IN_RADIUS_DISPLAY]: setAreasInRadiusDisplay,
	[ACTION_TYPES.SUPERVISOR.SET_IS_COMPLEX_TYPE]: setIsComplexType,
	[ACTION_TYPES.SUPERVISOR.SET_BOTTOM_LEVEL_TYPE]: setBottomLevelType,
	[ACTION_TYPES.SUPERVISOR.SET_IS_REQUEST_APPROVE]: setIsRequestApprove,
	[ACTION_TYPES.SUPERVISOR.SET_TOP_LEVEL_TYPE]: setTopLevelType,
	[ACTION_TYPES.SUPERVISOR.SET_SEND_BACK_BOOL]: setIsSendback,
	[ACTION_TYPES.SUPERVISOR.RESET_STORE]: resetState,

	[ACTION_TYPES.SUPERVISOR.EDIT_DIAGONAL_RECTANGLE_POINT]: editDiagonalRectanglePointOfAreaInEdit,
	[ACTION_TYPES.SUPERVISOR.DELETE_DIAGONAL_RECTANGLE_POINT_IN_EDIT]: deleteDiagonalRectanglePointInEdit,


	[ACTION_TYPES.SUPERVISOR.ADD_DIAGONAL_RECTANGLE_POINT_TO_CURRENTLY_ADDED_SHAPE]: addDiagonalRectangleToCurrentlyAddedShape,
	[ACTION_TYPES.SUPERVISOR.REMOVE_DIAGONAL_RECTANGLE_POINT_FROM_CURRENTLY_ADDED_SHAPE]: removeDiagonalRectangleFromCurrentlyAddedShape,
	[ACTION_TYPES.SUPERVISOR.EDIT_DIAGONAL_RECTANGLE_POINT_OF_CURRENTLY_ADDED_SHAPE]: updatePointOfDiagonalRectangleCurrentlyAddedShape,
}, initialState)