
import React from "react"
import { Button, Well } from "react-bootstrap"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { fetchReport, setReportCustomInterval, setReportSortBy, setReportSortByInterval } from "../../redux/report/reportActions"
import { GenericTable } from "../../common/components"
import { SortPicker } from "../utils/Components/sortPicker/sortPicker"
import _ from "lodash"
import { getUserGroups, sortListByPropDesc } from "../../utils/utils"
import { DatesRangePicker } from "../utils/Components/datesRangePicker/datesRangePicker"
import { REPORT_SORT_BY, TIME_INTERVALS } from "../../redux/reduxConsts"
import LeaderBoardHeader from "../leaderBoardPage/components/LeaderBoardHeader"
import "./Report.css"

class Report extends React.Component {

	state = {
		filter: "",
		isCustomDatePickerVisible: false,
	}

	componentDidMount() {
		const { fetchReport } = this.props
		fetchReport()
	}

	refresh = () => {
		const { fetchReport } = this.props
		fetchReport()
	}

	onFilterInputChanged = (e) => {
		this.setState({ filter: e.target.value })
	}

	filterList = (list, filter) => {
		if (typeof filter === "string" && filter.length > 2)
			return list.filter(row => row.user.indexOf(filter) >= 0)
		else return list
	}



	sortList = (list) => {
		const { sortByTerm } = this.props
		switch (sortByTerm) {
			case "PAINT_COUNT":
				return sortListByPropDesc(list, REPORT_SORT_BY.PAINT_COUNT)
			case "SUPERVISE_COUNT":
				return sortListByPropDesc(list, REPORT_SORT_BY.SUPERVISE_COUNT)
			case "APPROVED_COUNT":
				return sortListByPropDesc(list, REPORT_SORT_BY.APPROVED_COUNT)
			case "NAME":
				return _.sortBy(list, item => item[REPORT_SORT_BY.NAME])
			case "DATE":
				return _.sortBy(list, item => item[REPORT_SORT_BY.DATE]).reverse()
			default:
				return list
		}
	}


	render() {

		const { filter } = this.state;
		const { report, waitingReapprove, sentBackWaiting, user, sortByTerm, setReportSortBy, setReportSortByInterval, selectedSortIterval, setReportCustomInterval } = this.props;
		const userGroups = getUserGroups(user);
		const isApprover = userGroups.indexOf("approvers") > -1;
		const currentIntervalType = selectedSortIterval.data.interval || TIME_INTERVALS.CUSTOM.system
		// this.mergeObjectsInArrayByKey(this.flatReport(report), "timeFrame")
		const sortedList = this.sortList([...report])
		return (<div className="Report">
			<div >
				<LeaderBoardHeader mainLabel="Report" intervalTitle={selectedSortIterval} />

				<div style={{ marginLeft: "20px" }}>
					<SortPicker
						sortGroups={[
							[{ label: "Paint Count", value: "PAINT_COUNT" }, { label: "Supervise Count", value: "SUPERVISE_COUNT" }, { label: "Approved count", value: "APPROVED_COUNT", isVisible: isApprover }],
							[{ label: "Date", value: "DATE" }, { label: "Name", value: "NAME" }]
						]}
						label="Sort By:"
						currentSelectedSortOption={sortByTerm}
						onSelect={(sortByTerm) => setReportSortBy(sortByTerm)} />



					<SortPicker
						label="Show:"
						currentSelectedSortOption={currentIntervalType}
						sortGroups={[
							[{ label: "Today", value: "TODAY" }, { label: "Yesterday", value: "YESTERDAY" }, { label: "This Week", value: "WEEK" }, { label: "Last 7 Days", value: "LAST_7_DAYS" }, { label: "This Month", value: "MONTH" }, { label: "Last 30 Days", value: "LAST_30_DAYS" }],
						]}
						onSelect={(sortByTimeTerm) => setReportSortByInterval(sortByTimeTerm)}>

						<div style={{ display: "flex" }}>
							<Button
								bsSize="sm"
								bsStyle={currentIntervalType === TIME_INTERVALS.CUSTOM.system ? "success" : "default"}
								onClick={() => setReportSortByInterval(TIME_INTERVALS.CUSTOM.system)}>
								Custom
							</Button>
							{currentIntervalType === TIME_INTERVALS.CUSTOM.system && <span style={{
								position: "relative",
								bottom: "17px"
							}}>

								<DatesRangePicker
									onDone={(startTime, endTime) => setReportCustomInterval(startTime, endTime)}
								/>
							</span>
							}
						</div>

					</SortPicker>

					<div style={{ display: "flex" }} >
						<Button bsStyle="info" onClick={this.refresh}>Refresh</Button>
						<input style={{ width: "20%" }} type="text" placeholder="filter by username" value={filter} onChange={this.onFilterInputChanged} />
					</div>
				</div>

			</div>
			<div className="UpperTablesContainer">
				<div className="UpperTables">
					<div className="tableTitle">Pending send backs</div>
					{Array.isArray(sentBackWaiting) && sentBackWaiting.length > 0 ?
						<GenericTable
							data={sentBackWaiting}

							mapColumnToRenderer={[{
								column: { title: "Name" },
								cellRenderer: ({ user }) => <div>{user}</div>
							},
							{
								column: { title: "Total SendBacks" },
								cellRenderer: ({ totalSentBacks }) => <div>{totalSentBacks}</div>
							}

							]}

						/> :
						<Well >
							<h4>There is no data to show here...</h4>
						</Well>
					}
				</div>
				<div className="UpperTables">
					<div className="tableTitle">Pending re-approvals</div>
					{Array.isArray(waitingReapprove) && waitingReapprove.length > 0 ? <GenericTable
						data={waitingReapprove}

						mapColumnToRenderer={[{
							column: { title: "Name" },
							cellRenderer: ({ user }) => <div>{user}</div>
						},
						{
							column: { title: "Waiting To ReApprove" },
							cellRenderer: ({ waitingToReApprove }) => <div>{waitingToReApprove}</div>
						}

						]}

					/> :
						<Well >
							<h4>There is no data to show here...</h4>
						</Well>
					}
				</div>
			</div>
			{
				Array.isArray(sortedList) && sortedList.length > 0 ?
					<GenericTable
						data={this.filterList(sortedList, filter)}

						mapColumnToRenderer={[{
							column: { title: "Name" },
							cellRenderer: ({ user }) => <div>{user}</div>
						},
						{
							column: { title: "Date" },
							cellRenderer: ({ timeFrame }) => <div>{timeFrame}</div>
						},
						{
							column: { title: "Paint Count" },
							cellRenderer: ({ paintCount = 0 }) => <div> {paintCount}</div>
						},
						{
							column: { title: "Supervise Count" },
							cellRenderer: ({ superviseCount = 0 }) => <div>{superviseCount}</div>
						},
						{
							column: { title: "Approved Count" },
							cellRenderer: ({ approveCount = 0 }) => <div>{approveCount}</div>,
							isVisible: isApprover
						}
						]}

					/> : <Well >
						<h4>Nothing to show for the picked time frame</h4>
					</Well>}
		</div>

		)
	}
}



const mapActionToProps = (dispatch) => ({
	fetchReport: bindActionCreators(fetchReport, dispatch),
	setReportSortBy: bindActionCreators(setReportSortBy, dispatch),
	setReportSortByInterval: bindActionCreators(setReportSortByInterval, dispatch),
	setReportCustomInterval: bindActionCreators(setReportCustomInterval, dispatch),

})

const mapStateToProps = ({ reportStore, authStore }) => ({
	report: reportStore.report,
	sentBackWaiting: reportStore.sentBackWaiting,
	waitingReapprove: reportStore.waitingReapprove,
	sortByTerm: reportStore.sortBy,
	selectedSortIterval: reportStore.interval,
	user: authStore.user
})

export default connect(mapStateToProps, mapActionToProps)(Report)




