import { handleActions } from "redux-actions"
import { ACTION_TYPES } from "../reduxConsts"

const initialState = ""

const setValue = (state, action) => {
	const value = action.value
	return value
}

const resetState = (state, action) => {
	return initialState
}

export const approverCommentBoxValueReducer = handleActions({
	[ACTION_TYPES.APPROVER.SET_APPROVE_COMMENT_BOX_VALUE]: setValue,
	[ACTION_TYPES.APPROVER.RESET_APPROVER_STATE]: resetState,
	[ACTION_TYPES.APPROVER.RESET_APPROVE_TASK]: resetState,

}, initialState)