import "./HistoryStyle.css"
import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ApproverSummary from "../genericTableComponents/summary/approver";
import SupervisorSummary from "../genericTableComponents/summary/supervisor";
import PainterSummary from "../genericTableComponents/summary/painter";
import { Button, Jumbotron } from "react-bootstrap";

import { fetchUserCompletedSubtasks, setFilter, filterChanged, setFullFilter, setFreeSearchFilter } from "../../redux/preview/previewAction";
// import TableFilter from "./utilsComponents/historyFilter/TableFilter";
import HistoryFilter from "./utilsComponents/historyFilter/HistoryFilters";
import PreviewSpecificSubtask from "./components/PreviewSpecificSubtask";
import { GenericTable } from "./../../common/components";
import { getSubtaskNameFromUrlPath } from "./../utils/urlSearchStringHandler";


class History extends Component {

	componentDidMount() {
		const { history, user, fetchSubtasks } = this.props

		if (getSubtaskNameFromUrlPath() !== null) {
			history.push("/preview/" + getSubtaskNameFromUrlPath())
		}
		else {
			fetchSubtasks(user.profile.sub);
		}
	}


	setNewFilter = (obj) => {
		const { filterChangedAction, setFilter } = this.props
		filterChangedAction(true);
		setFilter(obj);
	}



	searchButtonHandler = () => {
		const { user, filterManager, fetchSubtasks } = this.props

		// fetch a subtask to approve
		fetchSubtasks(user.profile.sub, filterManager);
	}


	isUserApprover = () => {
		const { user } = this.props;
		return user.groups.indexOf("areas-blacklist-manager/approvers") > -1
	}


	renderPainterCell = (results) => results.map((res, i) => <PainterSummary painter={res} key={i} i={i} me={this.props.user.profile.name} />)


	renderNameCell = (subtask, match) => <div><Link to={{ pathname: `${match.url}/${subtask.painting.name}`, state: { subtask: subtask } }}>{subtask.painting.name}</Link></div>


	render() {

		const { subtasks = [], match, filterManager, isFilterChanged } = this.props;

		return (
			<div style={{ height: "100%", width: "100%", margin: "0 auto", padding: "14px" }}>

				{/* if the path is match.path then render this component*/}
				<Route
					exact
					path={`${match.path}`}
					render={
						() => <div>

							<HistoryFilter
								isUserApprover={this.isUserApprover()}
								setFilter={this.props.setFullFilter}
								setFreeSearchFilter={this.props.setFreeSearchFilter}
								filterObject={filterManager}
							/>

							{isFilterChanged ?
								<Button onClick={this.searchButtonHandler} bsStyle={"success"}>GO</Button> :
								<Button onClick={this.searchButtonHandler} bsStyle={"info"}>Refresh</Button>
							}

							{isFilterChanged ?
								<div style={{ margin: "0 auto", width: "50%", textAlign: "center" }}>
									<Jumbotron style={{ borderRadius: "20px" }}>
										<h2>Filters changed!</h2>
										<h3>Press
											<Button style={{ margin: "5px" }} onClick={this.searchButtonHandler} bsStyle={"success"}>GO</Button>
										to show the new results</h3>
									</Jumbotron>
								</div> :
								subtasks.length > 0 ?
									<GenericTable
										data={subtasks}
										mapColumnToRenderer={[
											{
												column: {
													title: "Name",
													icon: ""
												},
												cellRenderer: (item) => this.renderNameCell(item, match),
											},
											{
												column: {
													title: "Painters",
													icon: ""
												},
												cellRenderer: (item) => this.renderPainterCell(item.painting.results),
											},
											{
												column: {
													title: "Supervisor",
													icon: ""
												},
												cellRenderer: (item) => <SupervisorSummary supervisor={item.supervisor} />,
											},
											{
												column: {
													title: "Approver",
													icon: ""
												},
												cellRenderer: (item) => item.approver ? <ApproverSummary approver={item.approver} /> : null,
											}
										]}
									/>
									: null}
						</div>
					} />




				{/* if the path is match.path/: then render this component*/}
				<Route exact path={`${match.path}/:topecid`} component={PreviewSpecificSubtask} />

			</div >

		)
	}

}


let mapStateToProps = ({ authStore, previewStore }) => ({
	user: authStore.user,
	subtasks: previewStore.subtaskList,
	filterManager: previewStore.filterManager,
	ui: previewStore.ui,
	isFilterChanged: previewStore.filterChanged
})


let mapDispatchToProps = (dispatch) => ({
	filterChangedAction: (bool) => dispatch(filterChanged(bool)),
	fetchSubtasks: (userid, options) => dispatch(fetchUserCompletedSubtasks(userid, options)),
	setFilter: (...params) => dispatch(setFilter(...params)),
	setFullFilter: (...params) => dispatch(setFullFilter(...params)),
	setFreeSearchFilter: (...params) => dispatch(setFreeSearchFilter(...params)),
})

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps
)(History));