import { faChevronDown, faChevronUp, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ShapeUtils } from "./../../../../common/sharedGlobal"
import React from "react"
import "./areasFilterByTasks.css"
import _ from "lodash"
import * as classnames from "classnames"
import { Badge, Button } from "react-bootstrap"
import { biggestBoundingBox } from "../../../utils/fetchBiggestBoundingBox"


export class AreasFilterByTasks extends React.Component {
	state = {
		isTasksFilterVisibile: false,
		selectedTasks: {},
		isAllSelected: false
	}


	addTaskToFilter = (task) => this.setState((pState) => ({
		selectedTasks: { ...pState.selectedTasks, [task._id]: task },
		isAllSelected: false
	}))


	removeTaskFromFiltrer = (taskId) => this.setState((pState) => {
		const { [taskId]: remove, ...keep } = pState.selectedTasks
		return ({ selectedTasks: keep })
	}, () => {
		Object.keys(this.state.selectedTasks).length === 0 && this.onReset()
	})


	showTasksFilter = () => this.setState({ isTasksFilterVisibile: true })


	hideTasksFilter = () => this.setState({ isTasksFilterVisibile: false })


	onReset = (e) => {
		e && e.preventDefault()
		e && e.stopPropagation()
		const { dectivateFilterByTask } = this.props
		this.setState({ selectedTasks: {} })
		dectivateFilterByTask()

	}


	handleDropDownOptionSelect = (task) => {
		!this.state.selectedTasks[task._id] ? this.addTaskToFilter(task) : this.removeTaskFromFiltrer(task._id)
	}


	renderTaskSelectOptions = (tasks) => _.map(tasks, (task, i) => {
		const { selectedTasks } = this.state
		const itemClassNames = classnames({
			"filterListItem": true,
			"selected": selectedTasks[task._id]
		})
		return <div
			className={itemClassNames}
			onClick={() => this.handleDropDownOptionSelect(task)}
			key={i}>
			{task.name}
		</div>
	})


	onFilter = () => {
		// calculate bounds
		const { selectedTasks, isAllSelected } = this.state
		const { onFilterByTask, getAllAreas } = this.props
		if (isAllSelected) {
			getAllAreas()
			this.hideTasksFilter()
			return
		}
		const selectedTasksBoundingBox = _.map(selectedTasks, task => ShapeUtils.convertNewStructureToOldStructure(task.shape).boundingBox);
		console.log({ selectedTasksBoundingBox });



		const bounds = biggestBoundingBox(selectedTasksBoundingBox)


		onFilterByTask(bounds)
		this.hideTasksFilter()
	}


	handleAllClicked = () => {
		this.setState(pState => ({ isAllSelected: !pState.isAllSelected }), () => {
			this.state.isAllSelected && this.unSelectAll()
		})
	}


	// selectAll = () => {
	// 	const { tasks } = this.props
	// 	const selectedTasks = {}
	// 	_.map(tasks, task => selectedTasks[task._id] = task)
	// 	this.setState({ selectedTasks })
	// }

	unSelectAll = () => this.setState({ selectedTasks: {} })

	render() {
		const { isTasksFilterVisibile, selectedTasks, isAllSelected } = this.state
		const numOfSelectedTasks = Object.keys(selectedTasks).length
		const { tasks } = this.props
		const allOptionClassNames = classnames({
			"filterListItem": true,
			"selected": isAllSelected
		})
		return <div className="AreasFilterByTasks">
			<div className="filterbyTaskBar" onClick={isTasksFilterVisibile ? this.hideTasksFilter : this.showTasksFilter}>
				<div className="titleWrapper">
					<span>Filter By Tasks</span>
					{numOfSelectedTasks > 0 && <Badge >{numOfSelectedTasks}</Badge>}
					{numOfSelectedTasks > 0 && <FontAwesomeIcon color="red" onClick={this.onReset} icon={faTimesCircle} />}
				</div>

				{isTasksFilterVisibile ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
			</div>

			{isTasksFilterVisibile && <div className="filterByTasksWrapper">
				<div className="filterByTasksContainer">
					<div className="list">
						<div
							className={allOptionClassNames}
							onClick={() => this.handleAllClicked()}>ALL</div>
						{this.renderTaskSelectOptions(tasks)}
					</div>

					<div className="actionBtns">
						<Button bsSize="small" disabled={isAllSelected === false && numOfSelectedTasks === 0} bsStyle="success" onClick={this.onFilter} >Filter</Button>
						<Button bsSize="small" disabled={numOfSelectedTasks === 0} bsStyle="danger" onClick={this.onReset} >Reset</Button>
					</div>
				</div>
			</div>}
		</div>
	}
}