import React from "react"
import _ from "lodash"
import { Button, ButtonGroup } from "react-bootstrap"
import * as classnames from "classnames"
import "./sortPicker.css"


export const SortPicker = ({ sortGroups, onSelect, currentSelectedSortOption, label, children, disabled }) => {

	// const [selectedItem, setSelectedItem] = useState("")


	const onItemSelect = (item) => {
		// setSelectedItem(item.label)
		onSelect(item.value)
	}


	const renderSortGroups = () => _.map(sortGroups, (itemsGroup, index) => {
		const groupClassNames = classnames({
			"group": true,
		})
		return <span className={groupClassNames} key={index}>
			<ButtonGroup >
				{
					_(itemsGroup).map((item, index) => {

						if (item.isVisible !== undefined && !item.isVisible) return null

						const isSelected = (currentSelectedSortOption && currentSelectedSortOption === item.value)

						const btnClassNames = classnames({
							"groupBtn": true,
							"selected": isSelected
						})

						return <span className={btnClassNames} key={index} >
							<Button disabled={disabled} bsSize="sm" bsStyle={isSelected ? "success" : "default"} onClick={() => onItemSelect(item)}>{item.label}</Button>
						</span>

					}).filter().value()
				}
			</ButtonGroup>
		</span>
	})



	return <div className="SortPicker">
		{label && <span>{label}</span>}
		<div style={{ display: "flex", maxHeight: "30px" }}>
			{renderSortGroups()}
			{children}
		</div>
	</div>
}