export const SHAPE_COLORS_CONSTS = {
	transparent: "transparent",
	black: "#000",
	red: "#f00",
	blue: "#0f00ff",
	yellow: "#fbff00",
	green: "#1cff00",
	orange: "orange"

}


export const SHAPE_COLORS = [
	{ fillColor: SHAPE_COLORS_CONSTS.transparent, strokeColor: SHAPE_COLORS_CONSTS.black },
	{ fillColor: SHAPE_COLORS_CONSTS.yellow, strokeColor: SHAPE_COLORS_CONSTS.black },
	{ fillColor: SHAPE_COLORS_CONSTS.orange, strokeColor: SHAPE_COLORS_CONSTS.black },
	{ fillColor: SHAPE_COLORS_CONSTS.red, strokeColor: SHAPE_COLORS_CONSTS.black },
	{ fillColor: SHAPE_COLORS_CONSTS.green, strokeColor: SHAPE_COLORS_CONSTS.black },
	{ fillColor: SHAPE_COLORS_CONSTS.blue, strokeColor: SHAPE_COLORS_CONSTS.black },
]
