import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { v4 as uuid } from "uuid";
import WrapWithSubtaskFetcher from "./WrapWithSubtaskFetcher";
import PreviewMap from "./PreviewMap";
import PreviewTable from "./PreviewTable";
import PreviewHeader from "./PreviewHeader";
import { constHelpers } from "./utils";
import { ShapeUtils } from "../../../common/sharedGlobal";




const PreviewSpecificSubtask = (props) => {
	const { subtask } = props.location.state;


	const [previewDisplay, updatePreviewDisplay] = useState(constHelpers.previewDisplay.supervisor)
	const [mapFocus, updateMapFocus] = useState(null)


	const [layersArr, updateLayersArr] = useState([

		{
			...subtask.painting,
			layerType: constHelpers.layersTypes.subtask,
			style: constHelpers.layersTypeStyle.subtask,
			visible: true
		},

		...subtask.painting.results.map(res => ({
			...res,
			layerType: constHelpers.layersTypes.painterAnswer,
			style: constHelpers.layersTypeStyle.painterAnswer,
			visible: true
		})),
		// add the supervisor answer only if he added shapes
		...(subtask.supervisor ? [{
			...subtask.supervisor,
			_id: uuid(),
			layerType: constHelpers.layersTypes.supervisorAnswer,
			style: constHelpers.layersTypeStyle.supervisorAnswer,
			visible: true
		}] : []),

		...(subtask.approver ? [{
			...subtask.approver,
			appliedShapes: Array.isArray(subtask.approver.appliedShapes) ? subtask.approver.appliedShapes : [],
			_id: uuid(),
			layerType: constHelpers.layersTypes.approverAnswer,
			style: constHelpers.layersTypeStyle.approverAnswer,
			visible: true
		}] : []),

		...(subtask.supervisor.subtaskExistingAreasSnapshot && subtask.supervisor.subtaskExistingAreasSnapshot.length > 0 ? [{
			layerType: constHelpers.layersTypes.snapShotWhileSupervise,
			shapes: subtask.supervisor.subtaskExistingAreasSnapshot,
			_id: uuid(),
			style: constHelpers.layersTypeStyle.snapShotWhileSupervise,
			visible: true
		}] : []),
	])




	const returnToHistoryPage = () => {
		props.history.goBack()
	}



	const changeLayerStyle = ({ fill, border, id }) => updateLayersArr(layersArr.map(layer => {
		if (layer._id === id)
			return ({ ...layer, style: { fill, border } })
		else return layer
	}))


	const changeLayerVisibility = (isVisible, id) => updateLayersArr(layersArr.map(layer => {
		if (layer._id === id)
			return ({ ...layer, visible: isVisible })
		else return layer
	}))


	const handelFocus = (shape) => {
		switch (shape.type) {
			case "Rectangle": {
				updateMapFocus(ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(shape.boundingBox))
				return

			}
			case "DiagonalRectangle": {
				updateMapFocus(ShapeUtils.RECTANGLE.convertMinMaxBoundingBoxToNeSwType(ShapeUtils.POLYGON.convertPointsToBoundingBox(shape.points)))
				return
			}
		}
	}


	const goToApproveSpecificSubtask = () => {
		props.history.push("./../approver?task=" + subtask.painting.name)

	}

	const isSubTaskApproved = () => (Object.keys(subtask).indexOf("approver") > 0)

	return <div style={{ height: "100%" }}>

		<div style={{ height: "9%", display: "flex", alignItems: "center" }}>

			<div>
				<PreviewHeader
					subtaskName={subtask.painting.name}
					backHandler={returnToHistoryPage}
				/>
			</div>
			{!isSubTaskApproved() && <div style={{ marginLeft: "10px" }}><Button bsStyle="link" onClick={goToApproveSpecificSubtask}>Approve this subtask</Button></div>}

		</div>

		<div style={{ display: "flex", height: "91%" }}>
			<div style={{ flexGrow: "1", paddingRight: "10px", overflowY: "auto" }}>


				<PreviewTable
					onFocus={handelFocus}
					changeLayerStyle={changeLayerStyle}
					changeLayerVisibility={changeLayerVisibility}
					layersArr={layersArr}
					previewDisplayString={previewDisplay}
					updatePreviewDisplayString={updatePreviewDisplay}
					isApproved={Object.keys(subtask).indexOf("approver") > -1} />
			</div>

			<div style={{ flexGrow: "5" }}>
				<PreviewMap
					focusShape={mapFocus}
					layersArr={layersArr}
					previewDisplayString={previewDisplay} />
			</div>
		</div>
	</div >

}



export default WrapWithSubtaskFetcher(PreviewSpecificSubtask);