import React from "react";
import { Modal, Button, Glyphicon } from "react-bootstrap";

import { connect } from "react-redux";
import { closeDialog } from "./../../redux/dialog/dialogActions";





// const _ConfirmModal = ({ visible, onConfirm, onCancel, confirmationText }) => {
const _ConfirmModal = ({ level, onCancel, onConfirm, txt, visible, closeDialog, title = "", next = [] }) => {


	const mapLevelToBackgroundColor = {
		"info": "#0000ff3b",
		"error": "#ff00004f"
	}
	const mapLevelToIcon = {
		"info": "info-sign",
		"error": "remove"
	}
	const mapLevelToIconColor = {
		"info": "blue",
		"error": "red"
	}

	const callConfirmAction = (confirmArray) => {

		// get the args for the function
		const args = confirmArray.slice(1, confirmArray.length)

		// call the function with the args
		confirmArray[0](...args)

		//TODO the modal will close in any case! (if error on the confirm action the modal will close)

		// close the dialog
		closeDialog();
	}


	return <Modal bsSize="sm" show={visible} onHide={onCancel}>

		<Modal.Header style={{ backgroundColor: mapLevelToBackgroundColor[level] }}>
			<div style={{ display: "flex" }} >
				<div style={{ alignSelf: "center", flex: 1 }}>
					{level && <Glyphicon glyph={mapLevelToIcon[level] || "info"} style={{ color: mapLevelToIconColor[level] || "info" }} />}
				</div>

				<div style={{ alignSelf: "center", flex: 30 }}>
					<Modal.Title>{title}</Modal.Title>
				</div>
			</div>

		</Modal.Header>

		<Modal.Body style={{ overflow: "scroll" }} >
			<div>
				{typeof txt === "string" ? txt : JSON.stringify(txt)}
			</div>
		</Modal.Body>
		<Modal.Footer>
			<div style={{ textAlign: "left" }}>

				{onConfirm && <Button bsStyle="success" onClick={() => { callConfirmAction(onConfirm) }}>
					Confirm
			  </Button >
				}
				{onCancel && <Button bsStyle="danger" onClick={onCancel}>
					Abort
			  </Button >}

				{!onCancel && <Button bsStyle="danger" onClick={closeDialog}>
					Close
			  </Button >}

				{next.map((n, i) => <Button key={i} onClick={n.action} style={{ ...n.buttonStyle || null }}>
					{n.title}
				</Button >
				)}




			</div>
		</Modal.Footer>
	</Modal >
}



const ConfirmModal = connect(
	state => {
		return ({
			...state.dialogStore
		})
	},
	dispatch => ({
		closeDialog: () => dispatch(closeDialog()),
	})
)(_ConfirmModal);

export default ConfirmModal