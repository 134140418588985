const { TASK } = require("../reduxConsts").ACTION_TYPES
const { MAP_STATE } = require("../reduxConsts")


// /**@param {String} req async start function, req will be the url (?) */
export const async_start = req => ({ type: TASK.ASYNC_START, value: req })


/******************************** * * * SAGA ACTIONS * * * ********************************/
export const getTasks = (access_token) => ({ type: TASK.ASYNC_GET_TASKS, value: access_token })
export const postTask = (task) => ({ type: TASK.ASYNC_ADD_TASK, value: { task } })
// export const getSubtasksByParentID = (id, access_token) => ({ type: TASK.ASYNC_GET_SUBTASKS, value: { id, access_token } })
export const deleteTask = (id) => ({ type: TASK.ASYNC_DELETE_TASK, value: { id } })
/******************************** * * * SAGA ACTIONS * * * ********************************/

/******************************** * * * Action * * * ********************************/
export const updateTaskList = (tasks) => ({ type: TASK.UPDATE_TASKS_LIST, value: { tasks } })
export const updateMapState = (state) => Object.values(MAP_STATE).indexOf(state) > -1 ? ({ type: TASK.SET_MAP_STATE, value: state }) : ({ type: TASK.SET_MAP_STATE, value: MAP_STATE.READ })
export const resetAddEditState = () => ({ type: TASK.RESET_TMP })
export const addSubtasksToTask = (subtasks, taskid) => ({ type: TASK.ADD_SUBTASK_TO_TASK, value: { subtasks, taskid } })
export const onFocusShape = (boundingBox) => ({ type: TASK.ON_FOCUS_SHAPE, value: { boundingBox } })
export const addBoundsTmpShape = (shape) => ({ type: TASK.ADD_BOUNDS_TO_TEMP, value: shape })
export const cancelAddEdit = () => ({ type: TASK.ON_CANCEL_ADD_EDIT })
export const onAddShape = (service) => ({ type: TASK.ON_ADD_SHAPE, value: service });
export const onDoneFocusShape = () => ({ type: TASK.ON_FOCUS_DONE, value: null })
// export const removeSubTasks = ({ parentId }) => ({ type: TASK.REMOVE_SUBTASKS_BY_PARENT_ID, value: { parentId } })
export const resetFocus = () => ({ type: TASK.RESET_FOCUS })
export const resetState = () => ({ type: TASK.RESET_STATE })
export const cleanError = () => ({ type: TASK.CLEAN_ERROR })

/******************************** * * * Action * * * ********************************/



