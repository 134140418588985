//react
import React, { Component } from "react";

// redux
import { connect } from "react-redux";
import { Prompt } from "react-router";
import isempty from "lodash.isempty";


// helpers
import { prepareAnswerForPost } from "./paintConsts";

import {
	setError,
	get_random_subtask,
	post_subtask_answer,
	setStatus,
	addConflictText,
	removeAnswerShapes,
	focusMap,
	onShapeEdit,
	onDoneEdit,
	onDeleteShape,
	cleanAnswerObject,
	resetStore
} from "./../../redux/paint/paintAction";


// component to be rendered
import AnswerOptions from "./component/AnswerOptions"
import NoContentComponent from "../hoc/NoContentComponent";
import PaintMapContainer from "./containers/PaintMapContainer";
import SubtaskName from "./component/SubtaskName";
import PaintAction from "./component/PaintAction";

// style
import "./style.css";
import LoaderContainer from "../LoaderContainer";

class PaintManager extends Component {


	constructor(props) {
		super(props)


		// fetch random task to paint
		props.fetch_random_sub_task();

	}


	componentWillUnmount() {
		this.props.resetStore()
	}


	sendAnswer = () => {

		const { answerObject } = this.props;

		// omit all null values and empty array
		let postAnswer = prepareAnswerForPost(answerObject);

		if (!postAnswer)
			this.props.setError(`answer not ready : ${JSON.stringify(answerObject)}`)
		else {
			this.props.post_sub_task_answer(postAnswer);

			this.setState({
				subTaskAnswer: null,
				conflictText: null
			})
		}

	}


	focusSubtask = () => {

		const { current_subtask } = this.props;

		this.props.focusMap(current_subtask.boundingBox)

	}


	//  for refresh button
	fetchRandomTask = () => {

		// fetch random task to paint
		this.props.fetch_random_sub_task();

	}

	shouldPromptMessage = () => {
		const { answerObject } = this.props;
		if (!isempty(answerObject.status))
			return true
		else return false

	}



	render() {
		const { editShapeID, current_subtask, answerObject, shapeClickedId, onShapeEdit, onDoneEdit, onDeleteShape, showLoader } = this.props;
		return (

			<div style={{ height: "100%", width: "100%" }} >

				<Prompt
					when={this.shouldPromptMessage()}
					message={"You have unsaved changes, Leave page?"}
				/>


				{/* paint UI */}
				{current_subtask && <div className="paint_page" style={{ width: "100%", height: "100%", alignItems: "stretch", paddingLeft: "7px" }}>


					{/* subtask name headline */}
					<SubtaskName name={current_subtask.name} />


					<div style={{ width: "100%", height: "89%", display: "flex", alignItems: "stretch" }} >

						{/* subtask actions buttons */}
						<div className="actions" style={{ width: "30%", padding: "5px", marginTop: "12px", overflowY: "auto" }} >


							<AnswerOptions
								// data
								resetOnChange={current_subtask._id}
								answerObject={answerObject}
								// functions
								clearAnswerShapes={this.props.removeAnswerShapes}
								setStatus={this.props.setStatus}
								addConflictText={this.props.addConflictText}
								shapeClickedId={shapeClickedId}
								onShapeEdit={onShapeEdit}
								editShapeID={editShapeID}
								onDoneEdit={onDoneEdit}
								onDeleteShape={onDeleteShape}

							/>
							<PaintAction answerObject={answerObject} sendAnswer={this.sendAnswer} />

						</div>

						{/* subtask map */}
						<div className="map" style={{ width: "70%" }}>
							<PaintMapContainer />
						</div>

					</div>


				</div>}

				{/* no subtask UI */}
				{!current_subtask && !showLoader && <NoContentComponent refreshAction={this.fetchRandomTask} />}
				<LoaderContainer />

			</div >
		)
	}

}

let mapStateToProps = ({ paintStore, loaderStore }) => {
	return {
		async_status: paintStore.async_status,
		current_subtask: paintStore.fetched_sub_task,
		answerObject: paintStore.answerObject,
		shapeClickedId: paintStore.shapeClickedId,
		editShapeID: paintStore.editShapeID,
		overlayFocus: paintStore.overlayFocus,
		showLoader: loaderStore.showLoader
	}
}

let mapDispatchToProps = (dispatch) => {
	return {
		resetStore: () => dispatch(resetStore()),
		fetch_random_sub_task: () => dispatch(get_random_subtask()),
		post_sub_task_answer: (answer) => dispatch(post_subtask_answer(answer)),
		setStatus: (status) => dispatch(setStatus(status)),
		setError: (e) => dispatch(setError(e)),
		addConflictText: (text) => dispatch(addConflictText(text)),
		removeAnswerShapes: () => dispatch(removeAnswerShapes()),
		focusMap: (bb) => dispatch(focusMap(bb)),
		onShapeEdit: (s) => dispatch(onShapeEdit(s)),
		onDoneEdit: () => dispatch(onDoneEdit()),
		onDeleteShape: (id) => dispatch(onDeleteShape(id)),
		cleanAnswerObject: () => dispatch(cleanAnswerObject()),


	};
}

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaintManager);
