import React from "react";
import { Well } from "react-bootstrap"


export default () => {

	return (
		<Well >
			<h4>Nothing to show for the picked time frame</h4>
		</Well>
	)
}
