
import React from "react";
import "./previewTable.css";
import { GenericTable } from "./../../../common/components";
import { GtPainterInfo as PainterInfo } from "./../../genericTableComponents/info/painter";
import { GtSupervisorInfo as SupervisorInfo } from "./../../genericTableComponents/info/supervisor";
import { GtShapeColor as ColorHandler } from "./../../genericTableComponents/shapeColor/shapeColor";
import { GtShapeVisibility as VisibilityHandler } from "./../../genericTableComponents/shapeVisibility/shapeVisibility";
import { Label, Glyphicon, Button } from "react-bootstrap";
import { constHelpers, spreadSupervisorAnswer, spreadApproverAnswer } from "./utils";
import Comment from "../utilsComponents/comment/Comment";
import PreviewSubtaskSummary from "../utilsComponents/previewSubtaskSummary/PreviewSubtaskSummary";
import SupervisorApproverDisplayHandler from "../utilsComponents/supervisorApproverDisplayHandler/SupervisorApproverDisplayHandler";
import TableHeader from "../utilsComponents/tableHeader/TableHeader";
import ApproverGrading from "../utilsComponents/approverGrading/approverGrading";


export default ({ layersArr, changeLayerVisibility, changeLayerStyle, previewDisplayString, isApproved, updatePreviewDisplayString, onFocus }) => {


	const filterByPreviewDisplay = (layer) => {
		if (previewDisplayString === constHelpers.previewDisplay.approver && layer.layerType === constHelpers.layersTypes.supervisorAnswer)
			return false
		else if (previewDisplayString === constHelpers.previewDisplay.supervisor && layer.layerType === constHelpers.layersTypes.approverAnswer)
			return false

		else return true

	}
	const filterForSupervisorAndApprover = (layer) => {
		if (
			layer.layerType === constHelpers.layersTypes.subtask ||
			layer.layerType === constHelpers.layersTypes.painterAnswer ||
			layer.layerType === constHelpers.layersTypes.snapShotWhileSupervise)
			return false

		else if (layer.layerType === constHelpers.layersTypes.approverAnswer || layer.layerType === constHelpers.layersTypes.supervisorAnswer)
			if (!Array.isArray(layer.appliedShapes) || layer.appliedShapes.length === 0)
				return false

			else return true

	}
	const prettyLayerType = ({ layerType, ...data }) => {

		switch (layerType) {
			case constHelpers.layersTypes.painterAnswer: return <div><div>Painter: {data.userCreated.userEmail}</div> {data.status === "problem" && <div><Label> {data.conflict_text}</Label><div>{data.answerTime}</div></div>} </div>
			case constHelpers.layersTypes.supervisorAnswer: return <div> <div>Supervisor: {data.userSupervised.userEmail}</div>{data.comment && <div>  <Comment comment={data.comment} /></div>}</div>
			case constHelpers.layersTypes.approverAnswer: return <div> <div>Approver: {data.userApproved.userEmail}</div> {data.comment && <div> <Comment comment={data.comment} /> </div>}</div>
			case constHelpers.layersTypes.snapShotWhileSupervise: return <div>Existing areas while supervise</div>
			default: return null
		}

	}



	const layerRenderer = {
		Visibility: (item) => {
			let idDisabled = ((item.layerType === constHelpers.layersTypes.painterAnswer && item.status !== "has_areas")
				|| (item.layerType === constHelpers.layersTypes.approverAnswer && (item.appliedShapes && item.appliedShapes.length === 0))
				|| (item.layerType === constHelpers.layersTypes.supervisorAnswer && (item.appliedShapes && item.appliedShapes.length === 0)))

			if (idDisabled)
				return <Glyphicon glyph="ban-circle" />
			return <VisibilityHandler
				item={item}
				changeVisibility={(id, isVisible) => { changeLayerVisibility(isVisible, item._id) }}
				isVisible={item.visible} />
		},
		Layer: (data) => <div>{prettyLayerType(data)}</div>,

		Info: ({ layerType, ...data }) => {
			if (layerType === constHelpers.layersTypes.painterAnswer)
				return <PainterInfo data={{ ...data, markedAs: data.isCorrect }} />

			else if (layerType === constHelpers.layersTypes.supervisorAnswer)
				return <div>{data.appliedShapes && data.appliedShapes.length > 0 && <span>Added areas: {data.appliedShapes.length}</span>}</div>


			else if (layerType === constHelpers.layersTypes.approverAnswer)
				return <ApproverGrading data={data} />

			else if (layerType === constHelpers.layersTypes.snapShotWhileSupervise)
				return <div>Count: ({data.shapes.length})</div>
			else return null

		},
		InfoSpreaded: ({ layerType, multilevelTypes }) => {
			if (layerType === constHelpers.layersTypes.supervisorAnswer || layerType === constHelpers.layersTypes.approverAnswer)
				return <SupervisorInfo isEditable={false} data={multilevelTypes} />
			else return null

		},
		Color: ({ style, _id, layerType, status, ...data }) => {
			// color handler will be disabled only if this is a snapshot while supervise OR painter answer with status other then has areas
			let idDisabled = (
				layerType === constHelpers.layersTypes.snapShotWhileSupervise ||
				(
					layerType === constHelpers.layersTypes.painterAnswer && status !== "has_areas"
				) ||
				(
					layerType === constHelpers.layersTypes.approverAnswer && data.appliedShapes && data.appliedShapes.length === 0
				) ||
				(
					layerType === constHelpers.layersTypes.supervisorAnswer && data.appliedShapes && data.appliedShapes.length === 0
				)
			)

			let lockOnBlueBorder = (layerType === constHelpers.layersTypes.approverAnswer || layerType === constHelpers.layersTypes.supervisorAnswer);

			return <ColorHandler
				onColorSelect={(fill, stroke) => changeLayerStyle({ fill, border: lockOnBlueBorder ? "blue" : stroke, id: _id })}
				fillStrokeCombo={{
					strokeColor:
						lockOnBlueBorder ? "blue" : style.border, fillColor: style.fill
				}}
				isDisabled={idDisabled} />
		},
		Description: ({ description }) => <div style={{
			wordBreak: "break-all",
			display: "block",
			maxWidth: "300px",
			margin: "0 auto",
			whiteSpace: "normal",
		}}>{description}</div>,
		Actions: (data) => <Button title="FOCUS" bsSize="small" bsStyle="primary" onClick={() => onFocus(data.shape)}>
			<Glyphicon glyph="map-marker" />
		</Button>
	}




	const tableArr = layersArr
		.filter(({ layerType }) => layerType !== constHelpers.layersTypes.subtask)
		.filter(filterByPreviewDisplay)


	const layersTableData = layersArr
		.filter(({ layerType }) => layerType !== constHelpers.layersTypes.subtask && layerType !== constHelpers.layersTypes.supervisorAnswer && layerType !== constHelpers.layersTypes.approverAnswer)
		.filter(filterByPreviewDisplay)


	const approverAndSupervisorSummary = layersArr.reduce((accumulator, currentValue) => {
		if (currentValue.layerType === constHelpers.layersTypes.approverAnswer)
			return {
				...accumulator,
				approverAnswer: currentValue
			}
		else if (currentValue.layerType === constHelpers.layersTypes.supervisorAnswer)
			return {
				...accumulator,
				supervisorAnswer: currentValue
			}
		else return accumulator
	}, { supervisorAnswer: {}, approverAnswer: {} })


	const approverSupervisorSpreader = tableArr
		.filter(filterForSupervisorAndApprover)
		.map(layer => {
			if (layer.layerType === constHelpers.layersTypes.approverAnswer)
				return spreadApproverAnswer(layer)
			else if (layer.layerType === constHelpers.layersTypes.supervisorAnswer)
				return spreadSupervisorAnswer(layer)
			else return layer
		})
		.flat()


	return <div>
		<div className="tableSection">

			<TableHeader style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: "0 0 10px",
				fontSize: "20px",
				fontWeight: "bolder"
			}} title="Subtask Summary" />

			<PreviewSubtaskSummary approverAndSupervisorSummary={approverAndSupervisorSummary} />
		</div>

		<div className="tableSection">

			<TableHeader style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				padding: "0 0 10px",
				fontSize: "20px",
				fontWeight: "bolder"
			}} title="Layers" />


			<GenericTable
				data={layersTableData}
				mapColumnToRenderer={[{
					column: { title: <div>Visibility</div> },
					cellRenderer: layerRenderer.Visibility
				},
				{
					column: { title: "Layer" },
					cellRenderer: layerRenderer.Layer
				},
				{
					column: { title: "Info" },
					cellRenderer: layerRenderer.Info
				},
				{
					column: { title: "Color" },
					cellRenderer: layerRenderer.Color
				}]} />

		</div>

		<div className="tableSection">

			{approverAndSupervisorSummary.approverAnswer && approverAndSupervisorSummary.approverAnswer.answerType !== "SendBack" && approverAndSupervisorSummary.approverAnswer.answerType !== "Done" && <SupervisorApproverDisplayHandler isApproved={isApproved} previewDisplayString={previewDisplayString} updatePreviewDisplayString={updatePreviewDisplayString} />}
			<TableHeader
				style={{
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					padding: "0 0 10px",

				}}
				title={previewDisplayString === constHelpers.previewDisplay.supervisor ? "Supervisor’s answer" : "Approver's answer"} >
				<div style={{
					margin: "0 10px",
					height: "13px",
					width: "47px",
					border: "2px solid rgb(15, 0, 255)"
				}}></div>
			</TableHeader>



			<GenericTable
				data={approverSupervisorSpreader}
				mapColumnToRenderer={[
					{
						column: { title: "Description" },
						cellRenderer: layerRenderer.Description
					},
					{
						column: { title: "Info" },
						// cellRenderer: () => <div>asd</div>
						cellRenderer: layerRenderer.InfoSpreaded
					},
					{
						column: { title: "Actions" },
						cellRenderer: layerRenderer.Actions
					}]}
			/>


		</div>
	</div >
}
